define('templates/geosearch',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/geosearch"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"geosearch__buttonwrap geosearch__buttonwrap--mypos\"><button type=\"button\" class=\"geosearch__button geosearch__button--mypos\" title=\"Meine Position\"></button></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"geosearch__bar\">\n	<div class=\"geosearch__buttonwrap geosearch__buttonwrap--favs geosearch__buttonwrap--hidden\"><button type=\"button\" class=\"geosearch__button geosearch__button--favs\" title=\"Favoriten\"></button></div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.canGeolocate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	<label class=\"geosearch__field field\"><input type=\"text\" placeholder=\"Adresse hier eingeben …\" class=\"geosearch__input field__input field__input--text\" value=\"\" /></label>\n</div>\n\n<div class=\"geosearch__favlist\"></div>\n";
},"useData":true});

return this["JST"];

});
