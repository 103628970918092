define('views/panels/bookee', [
	'underscore',
	'moment',
	'views/_panel',
	'helpers/template',
	'views/blocks/remarks',
	'views/modals/confirmBooking',
	'casirest2/collections/price',
	'casirest2/helpers/data',
	'views/modals/login'
], function (
	_,
	moment,
	PanelView,
	templateHelper,
	RemarksBlock,
	ConfirmBookingView,
	PriceCollection,
	DataHelper,
	LoginModalView
) {
	'use strict';

	/**
	 * BookeePanel
	 *
	 * @module views/panels/bookee
	 * @class BookeePanelView
	 * @augments PanelBaseView
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return PanelView.extend({
		panelName: ['bookee'],
		panelEvents: {
			'click button.createPrelimBooking': 'createPrelimBooking'
		},

		/**
		 * @param {object} options
		 * @param {BookeeModel} options.model
		 * @param {BookingProposalCollection} options.proposalCollection
		 * @param {TimeHelper} options.timeHelper
		 * @param {PlaceModel} options.place

		 * @param {isLoading} isLoading
		 *
		 * @returns {module:views/panels/bookee}
		 * @private
		 */
		_initialize: function (options) {
			var v = this;
			options = options || {};

			v.proposalCollection = options.proposalCollection || null;
			v.timeHelper = options.timeHelper;
			v.placeModel = options.place;
			v.renderCallback = options.renderCallback;

			// set place in model if not already there
			// TODO: MAKE IT MORE AWESOME
			if (!this.model.get('place') && options.place) {
				this.model.set('place', options.place.toJSON());
			}
		},


		render: function () {
			var v = this;

			v.listenToAndCall(v.proposalCollection, 'sync reset', function () {
				/**
				 * find and set the correct proposal for this model.
				 */
				v.proposal = null;
				if (v.proposalCollection && v.proposalCollection.length > 0) {
					v.proposal = v.proposalCollection.find(function (proposal) {
						return proposal.get('bookee').id === v.model.id;
					});
				}

				/**
				 * get prices from proposal
				 */
				v.prices = v.proposal ? v.proposal.getPrices() : null;

				v.updateButtons();
				v.checkTimeRatingAndUpdate();
			});

			v.listenTo(v.proposalCollection, 'request', function () {
				v.updateButtons();
			});

			v.renderContent();
		},

		renderContent: function () {
			var v = this;

			/**
			 * get image for bookee
			 */
			var bookeeImage = v.model.getImage();

			templateHelper('panels/bookee', {
				panelInvert: true,
				bookee: v.model.toJSON(),
				bookeeImage: bookeeImage ? bookeeImage.toJSON() : {},
				timerangeFromProposal: {
					isDifferent: !!v.proposal && v.proposal.get('rating').time === false ? true : false,
					start: v.proposal && v.proposal.get('rating').time === false ? moment(v.proposal.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY HH:mm') : null,
					end: v.proposal && v.proposal.get('rating').time === false ? moment(v.proposal.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY HH:mm') : null
				},
				bookeeAvailable: !!v.proposal,
				prices: v.prices ? v.prices.prepareForRender() : {},
				isFlexOrFloat: (v.model.get('locality') === 'FLEX' || v.model.get('locality') === 'FLOAT') && v.model.get('locality') !== 'FIX'
			}, function (html) {
				v.$el.html(html);

				v.updateButtons();

				var remarksBlock = new RemarksBlock({
					remarks: v.model.get('remarks')
				});
				remarksBlock.appendTo(v, '.panel__remarks');

				if (_.isFunction(v.renderCallback)) {
					v.renderCallback(v);
					v.renderCallback = null;
				}
			});
		},

		/**
		 * updates the buttons initially and after every proposal sync
		 */
		updateButtons: function () {
			var v = this;
			
			var $loading = v.$el.find('.button--loading');
			var $notAvailable = v.$el.find('.notAvailable');
			var $bookable = v.$el.find('.createPrelimBooking');

			$loading.toggle(!!v.proposalCollection.syncing);
			$notAvailable.toggle(!v.proposal && !v.proposalCollection.syncing);
			$bookable.toggle(!!v.proposal && !v.proposalCollection.syncing);
		},

		checkTimeRatingAndUpdate: function () {
			var v = this;
			// check if bookee is bookeable and we have a rating
			if (!!v.proposal && !v.proposalCollection.syncing && v.proposal.get('rating').time === false) {
				// TODO: only render alerts!
				v.renderContent();
			}

			if (!v.proposal && v.$el.find('.panel__alerts').length > 0) {
				v.$el.find('.panel__alerts').remove();
			}
		},

		createPrelimBooking: function () {
			var v = this;

			/**
			 * render LoginView if not already logged in.
			 */
			if (!DataHelper.isLoggedIn()) {
				var login = new LoginModalView();
				window.myApp.view.renderView(login);

				v.listenToOnce(login, 'remove', function () {
					if (DataHelper.isLoggedIn()) {
						v.createPrelimBooking();
					}
				});

				return v;
			}

			/**
			 * render confirmBookingView
			 */
			var confirmBookingView = new ConfirmBookingView({
				bookee: v.model,
				proposal: v.proposal,
				timeHelper: v.timeHelper
			});
			window.myApp.view.renderView(confirmBookingView);

			v.listenTo(confirmBookingView, 'confirmed', function () {
				v.$el.find('.button--book').text('Fahrzeug gebucht').prop('disabled', true).addClass('button--confirmed');
			});
		}
	});
});

