define('views/blocks/customerFavoritePlace', [
	'underscore',
	'views/_view',
	'helpers/template'
], function (
	_,
	View,
	templateHelper
) {
	'use strict';

	/**
	 * CustomerFavoritePlaceBlockView
	 *
	 * @module views/blocks/customerFavoritePlace
	 * @class CustomerFavoritePlaceBlockView
	 * @augments BaseView
	 */
	return View.extend({
		tagName: 'li',
		className: 'favs__item',

		events: {
			'click button.favs__place': 'activated',
			'click button.favs__delete': 'deletePlace'
		},

		/**
		 * Renders the block.
		 *
		 * @returns {CustomerFavoritePlaceBlockView}
		 */
		render: function () {
			var v = this;

			templateHelper('blocks/customerFavoritePlace', {
				name: v.model.get('name')
			}, function (html) {
				v.$el.html(html);
			});

			return v;
		},

		activated: function () {
			var v = this;
			v.trigger('setFavorite', v.model.get('geoRange'));
		},

		deletePlace: function () {
			var v = this;

			require(['views/modals/prompt'], function (ErrorModal) {
				var errorModal = new ErrorModal({
					title: 'Wirklich löschen',
					message: 'Wollen Sie diesen Ort wirklich löschen?',
					buttonText: 'Löschen bestätigen',
					type: 'delete'
				});

				errorModal.once('accept', function() {
					v.model.destroy();
					v.remove();
				});
				window.myApp.view.renderView(errorModal);
			});
		}
	});
});

