define('templates/blocks/remarks',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/remarks"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"panel__alerts\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.alert : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"panel__alert panel__alert--invert alert\">\n		<h3 class=\"alert__headline\">"
    + container.escapeExpression(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"subject","hash":{},"data":data}) : helper)))
    + "</h3>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reference : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.content : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<img class=\"alert__image\" src=\""
    + alias4(((helper = (helper = helpers.reference || (depth0 != null ? depth0.reference : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reference","hash":{},"data":data}) : helper)))
    + "?width=450\" alt=\""
    + alias4(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subject","hash":{},"data":data}) : helper)))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<p class=\"alert__text\">"
    + ((stack1 = (helpers.striptags || (depth0 && depth0.striptags) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.content : depth0),{"name":"striptags","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"remarks__item"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reference : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isWarning : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<button class=\"remarks__subject"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isWarning : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasContent : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"subject","hash":{},"data":data}) : helper)))
    + "</button>\n		<div class=\"remarks__content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reference : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.content : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " remarks__item--image";
},"10":function(container,depth0,helpers,partials,data) {
    return " remarks__item--warning";
},"12":function(container,depth0,helpers,partials,data) {
    return " remarks__subject--warning";
},"14":function(container,depth0,helpers,partials,data) {
    return " remarks__subject--empty";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<img class=\"remarks__image\" src=\""
    + alias4(((helper = (helper = helpers.reference || (depth0 != null ? depth0.reference : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reference","hash":{},"data":data}) : helper)))
    + "?width=450\" alt=\""
    + alias4(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subject","hash":{},"data":data}) : helper)))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"remarks__text\">"
    + ((stack1 = (helpers.striptags || (depth0 && depth0.striptags) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.content : depth0),{"name":"striptags","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.alert : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.remarks : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});

return this["JST"];

});
