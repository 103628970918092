define('version', [], function () {
	'use strict';
	
	return {
		'manually': false,
		'environment': 'ewi3-roaming1.cantamen.de',
		'commit': 'b65a2b41a5d76a5b7288c68be2b01581241440c1',
		'buildId': '26666',
		'versionBuildId': 6,
		'version': null,
		'name': 'legacy/develop b65a2b41 (Build 6)'
	};
});
