define('casirest2/collections/bookee',['./_', '../models/bookee'], function (Collection, BookeeModel) {
	'use strict';

	/**
	 * @class BookeeCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: BookeeModel,
		filterMethods: ['bookee', 'place', 'city', 'bounds', 'location', 'provider', 'localities', 'timeRange']
	});
});

