define('lib/moment',['moment', 'moment/locale/de'], function (moment) {
	'use strict';
	
	moment.updateLocale('de', {
		calendar : {
			sameDay: '[heute]LT',
			nextDay: '[morgen]LT',
			nextWeek: 'DD.MM.YYLT',
			lastDay: 'DD.MM.YYLT',
			lastWeek: 'DD.MM.YYLT',
			sameElse: 'DD.MM.YYLT'
		}
	});

	return moment;
});

