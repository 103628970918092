define('casirest2/models/bookee',[
	'underscore',
	'./_',
	'./remark',
	'./price',
	'../collections/bookeeType',
	'../collections/unavailability',
	'../collections/unavailabilityTimeRange'
], function (
	_,
	Model,
	RemarkModel,
	PriceModel,
	BookeeTypeCollection,
	UnavailabilityCollection,
	UnavailabilityTimeRangeCollection
) {
	'use strict';

	/**
	 * @class BookeeModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/bookees',
		expand: ['bookeeType', 'bookeeProduct.remark'],

		/**
		 * Get the PriceModel of the current Bookee
		 * @returns {PriceModel}
		 */
		getPrice: function () {
			var price = new PriceModel();
			price.isAlwaysSingle = true;
			price.urlRoot = this.url({noParams: true}) + '/price';
			return price;
		},

		/**
		 * Get Unavailabilities from this Bokee
		 * @returns {UnavailabilityCollection}
		 */
		getUnavailabilities: function () {
			return this._buildSubCollection({
				collection: UnavailabilityCollection,
				url: '/bookees/' + this.id + '/unavailability'
			});
		},

		/**
		 * Get Unavailabilities from this Bokee
		 * @returns {UnavailabilityTimeRangeCollection}
		 */
		getUnvailabilityTimeRanges: function () {
			return this._buildSubCollection({
				collection: UnavailabilityTimeRangeCollection,
				url: '/bookees/' + this.id + '/unavailability/unavailabilityTimeRanges'
			});
		},

		/**
		 * Get BookeeType of this Bokee
		 * @returns {BookeeType}
		 */
		getBookeeType: function (view) {
			return this._buildSubCollection({
				collection: BookeeTypeCollection,
				attribute: 'bookeeType',
				view: view
			});
		},

		/**
		 * Returns a RemarkModel containing an image for this bookee
		 * @param {object} [options]
		 * @param {boolean} [options.onlyMarketing] Set to true, to return marketing images only
		 * @returns {RemarkModel}
		 */
		getImage: function (options) {
			var m = this,
				bookeeImage;

			options = options || {};
			if (m.get('bookeeProduct')) {
				bookeeImage = m._getImage(m.get('bookeeProduct').remarks, options);
			}
			if (!bookeeImage) {
				bookeeImage = m._getImage(m.get('remarks'), options);
			}

			if (bookeeImage) {
				return new RemarkModel(bookeeImage);
			}
			return null;
		},

		/**
		 * Internally used by getImage()
		 * @private
		 */
		_getImage: function (remarks, options) {
			var imageRemarks = _.filter(remarks, function (remark) {
				return remark.contentType.indexOf('image') !== -1;
			});

			// only one image => return
			if (imageRemarks.length === 1 && !options.onlyMarketing) {
				return imageRemarks[0];
			}

			// search for marketing image => return
			if (imageRemarks.length >= 1) {
				return _.find(imageRemarks, function (remark) {
					return _.find(remark.infoType, function (infoType) {
						return infoType === 'MARKETING';
					});
				}) || (options.onlyMarketing ? null : imageRemarks[0]);
			}

			return null;
		},

		/**
		 * Returns true if this bookee is a pedelec…
		 * @returns {boolean}
		 */
		isPedelec: function () {
			return this.get('bookeeType').id === '1132';
		},
		
		/**
		 * Returns true if this bookee is a pedelec…
		 * @returns {boolean}
		 */
		isCargoWheel: function () {
			return this.get('bookeeType').id === '1228';
		}
	});
});

