define('templates/blocks/customerPassword',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/customerPassword"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form class=\"customerPassword__fieldset fieldset changePassword\">\n\n	<h2 class=\"customerPassword__headline\">Passwort</h2>\n\n	<fieldset>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.password : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		\n		<label class=\"customerPassword__field field\">\n			<span class=\"field__label field__label--account\">Aktuelles Passwort</span>\n			<input name=\"oldPassword\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.password : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" />\n		</label>\n		\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.passwordEqual : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<label class=\"customerPassword__field field\">\n			<span class=\"field__label field__label--account\">Neues Passwort</span>\n			<input name=\"newPassword\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.passwordEqual : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.newpassword : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" />\n		</label>\n\n		<label class=\"customerPassword__field field\">\n			<span class=\"field__label field__label--account\">Neues Passwort <span>(wiederholen)</span></span>\n			<input name=\"newPasswordRepeat\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.passwordEqual : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.newpassword : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" />\n		</label>\n\n	</fieldset>\n\n	<button class=\"customerPassword__submit button button--account\">Passwort aktualisieren</button>\n\n</form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"customerPassword__errorText errorText\">\n			<p>Das aktuelle Passwort ist nicht korrekt.<br>Änderung nicht möglich.</p>\n		</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " field__input--error";
},"6":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"customerPassword__errorText errorText\">\n			<p>Die eingegebenen Passwörter stimmen nicht überein.</p>\n		</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form class=\"customerPin__fieldset fieldset changePin\">\n\n	<h2 class=\"customerPassword__headline\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.setPinHeadline",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</h2>\n\n	<fieldset>\n		\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.pin : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<label class=\"customerPassword__field field\">\n			<div class=\"field__info field__info--account\"><span class=\"field__infotext\">Bitte genau 4 Ziffern eingeben.</span></div>\n			<span class=\"field__label field__label--account\">PIN</span>\n			<input name=\"pin\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.pin : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" pattern=\"[0-9]{4,4}\" maxlength=\"4\" minlength=\"4\" />\n		</label>\n	</fieldset>\n\n	<button class=\"customerPassword__submit button button--account\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.setPin",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n\n</form>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"customerPassword__errorText errorText\">\n			<p>PIN nicht akzeptabel.</p>\n		</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form class=\"customerPin__fieldset fieldset changePinAndPassword\">\n\n	<h2 class=\"customerPassword__headline\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.setPinAndPasswordHeadline",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</h2>\n\n	<fieldset>\n		\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.password : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		\n		<label class=\"customerPassword__field field\">\n			<span class=\"field__label field__label--account\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.oldPassword",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n			<input name=\"oldPassword\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.password : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" />\n		</label>\n		\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.passwordEqual : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<label class=\"customerPassword__field field\">\n			<div class=\"field__info field__info--account\"><span class=\"field__infotext\">Bitte genau 4 Ziffern eingeben.</span></div>\n			<span class=\"field__label field__label--account\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.newPassword",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n			<input name=\"newPassword\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.passwordEqual : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.newpassword : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" pattern=\"[0-9]{4,4}\" maxlength=\"4\" minlength=\"4\" />\n		</label>\n\n		<label class=\"customerPassword__field field\">\n			<div class=\"field__info field__info--account\"><span class=\"field__infotext\">Bitte genau 4 Ziffern eingeben.</span></div>\n			<span class=\"field__label field__label--account\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.newPassword",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + " <span>(wiederholen)</span></span>\n			<input name=\"newPasswordRepeat\" type=\"password\" class=\"field__input field__input--account field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.passwordEqual : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.newpassword : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" pattern=\"[0-9]{4,4}\" maxlength=\"4\" minlength=\"4\" />\n		</label>\n	</fieldset>\n\n	<button class=\"customerPassword__submit button button--account\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"customerPassword.setPinAndPassword",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n\n</form>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<div class=\"customerPassword__errorText errorText\">\n			<p>"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"customerPassword.setPinAndPasswordError",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n		</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.PasswordAllowed : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.PinAllowed : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.PinPasswordIdentical : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"customerPassword__divider\"></div>\n";
},"useData":true});

return this["JST"];

});
