define('casirest2/models/provider',[
	'underscore',
	'./_'
], function (_, Model) {
	'use strict';

	/**
	 * @class ProviderModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/providers',
		expand: ['remark'],

		getSettings: function (options) {
			if (_.isObject(options) && _.isNumber(options.provId)) {
				this.url = '/providers/' + options.provId + '/settings';
			}
		}
	});
});

