define('views/account/settings', [
	'underscore',
	'views/_account',
	'helpers/template',
	'casirest2/collections/customer',
	'casirest2/models/customer',
	'views/account/settingsAccessMedias',
	'views/blocks/customerPassword',
	'views/modals/prompt',
	'helpers/settings',
	'jquery',
	'formparams'
], function (
	_,
	AccountBaseView,
	templateHelper,
	CustomerCollection,
	CustomerModel,
	SettingsAccessMediasAccountView,
	CustomerPasswordView,
	PromptModalView,
	SettingsHelper
) {
	'use strict';

	return AccountBaseView.extend({
		className: 'customerSettings loading--inline',

		events: {
			'click .customerSettings__submit': 'saveSettings',
			'submit .customerSettings__form': 'saveSettings'
		},

		_initialize: function (options) {
			this.collection = options.collection || new CustomerCollection();
			this.model = options.model;
			this.errors = false;
		},

		render: function () {
			var v = this,
			uiSettings = SettingsHelper.getProviderSettings().get('bookingUISettings'),
			dataReadOnly = uiSettings.customerBaseDataChangeAllowed === 'DISPLAY',
			dataHidden = uiSettings.customerBaseDataChangeAllowed === 'NONE',
			canSubmit = uiSettings.customerBaseDataChangeAllowed === 'CHANGE' || uiSettings.customerAccessCardChangeAllowed === 'CHANGE';

			// fetch model by collection or not?
			if (!v.model) {
				v.model = new CustomerModel();

				if (v.collection.length > 0) {
					v.model.set(
						v.collection.first().toJSON()
					);
				} else {
					v.listenToOnce(v.collection, 'sync', function () {
						v.model.set(
							v.collection.first().toJSON()
						);
					});
					v.collection.fetch();
				}
			}

			templateHelper('account/settings', {
				customer: v.model.toJSON(),
				readonly: dataReadOnly ? 'readonly' : null,
				canSubmit: canSubmit
			}, function (html) {
				var accessMedias = v.model.getAccessMedias(v);
				
				v.$el.html(html);
				v.$form = v.$el.find('.customerSettings__form');
				
				if (dataHidden) {
					v.$form.find('.customerSettings__baseData').addClass('customerSettings__fieldset--hidden');
				}
				
				v.setupOneWayBinding('.customerSettings__container--cards', accessMedias, SettingsAccessMediasAccountView);
				
				v.setupOneWayBinding('[name="customerNumber"]', 'customerNumber');
				
				v.setupOneWayBinding('[name="prename"]', 'prename');
				v.setupOneWayBinding('[name="name"]', 'name');
				v.setupOneWayBinding('[name="address[street]"]', 'address.street');
				v.setupOneWayBinding('[name="address[streetNr]"]', 'address.streetNr');
				v.setupOneWayBinding('[name="address[country]"]', 'address.country');
				v.setupOneWayBinding('[name="address[postalCode]"]', 'address.postalCode');
				v.setupOneWayBinding('[name="address[city]"]', 'address.city');
				v.setupOneWayBinding('[name="phoneNr"]', 'phoneNr');
				v.setupOneWayBinding('[name="mobilePhoneNr"]', 'mobilePhoneNr');
				v.setupOneWayBinding('[name="emailAddress"]', 'emailAddress');

				new CustomerPasswordView({model: v.model}).appendTo(v);

				if (v.model.id) {
					v.$form.removeClass('loading--overlay');
				} else {
					v.listenToOnce(v.model, 'change:id', function () {
						v.$form.removeClass('loading--overlay');
					});
				}
			});

			v.listenToOnce(v.collection, 'sync', function () {
				v.$el.removeClass('loading--inline');
			});
		},

		validate: function (data) {
			var v = this,
				errors = false;

			v.$form.find('.field__input--error').removeClass('field__input--error');

			// validate emailAddress
			if (!data.emailAddress.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
				// https://stackoverflow.com/a/46181
				v.$form.find('input[name="emailAddress"]').addClass('field__input--error');
				errors = true;
			}

			// validate phone
			if (data.phoneNr !== '' && data.phoneNr.replace(/[^\d]/g, '').length < 5) {
				v.$form.find('input[name="phoneNr"]').addClass('field__input--error');
				errors = true;
			}

			// validate mobilePhoneNr
			if (data.mobilePhoneNr !== '' && data.mobilePhoneNr.replace(/[^\d]/g, '').length < 7) {
				v.$form.find('input[name="mobilePhoneNr"]').addClass('field__input--error');
				errors = true;
			}

			// validate PLZ if country = DE
			if (data.address.country === 'DE' && !_.isNumber(data.address.postalCode) && !data.address.postalCode.match(/^[0-9]{5}$/)) {
				v.$form.find('input[name="address[postalCode]"]').addClass('field__input--error');
				errors = true;
			}

			return !errors;
		},

		saveSettings: function (e) {
			var v = this,
				data;

			e.preventDefault();
			if (v.saving) {
				return;
			}

			v.$el.find('input').blur();
			v.saving = true;

			// get data from form
			data = v.$form.formParams(false);

			// basic validation
			if (!v.validate(data)) {
				v.saving = false;
				return;
			}

			// accessMedias
			var currentAccessMedias = v.model.getAccessMedias(v); // get current Cards
			data.accessMedias = _.without(_.map(data, function (m, k) {
				if (k.indexOf('accessmedia-') === 0) {

					delete data[k];

					// check if accessMedia just wasn't changed
					var foundAccessMedia = currentAccessMedias.findWhere({number: m});
					if (foundAccessMedia) {
						return; // return nothing. The API is picky when sending same Cards again.
					}

					if (m.length > 0) {
						return {number: m};
					}
				}
			}), undefined);

			v.model.set(data);

			// check if we have any changes
			if (!v.model.hasChanged()) {
				v.saving = false;
				return;
			}

			v.$form.addClass('loading--overlay');
			v.$form.find('.field__input--error').removeClass('field__input--error');

			v.model.save({}, {
				success: function () {
					v.saving = false;
					v.$form.removeClass('loading--overlay');

					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Ihre Daten',
							message: 'Daten erfolgreich geändert.',
							type: 'success'
						})
					);
				},
				report: function (errorMessage) {
					// Ungültige Karte
					if (errorMessage && errorMessage.indexOf('no card found with that number') > -1) {
						return false;
					}

					// Karte eintragen aktuell nicht möglich
					if (errorMessage && errorMessage.indexOf('not found in keycard Class') > -1) {
						return false;
					}

					// Karte bereits zugeordnet
					return errorMessage && errorMessage.indexOf('card already assigned to ') > -1;
				},
				error: function (model, response) {
					var $errorField;
					v.saving = false;
					v.$form.removeClass('loading--overlay');

					if (
						response.responseJSON &&
						response.responseJSON.errorMessage &&
						response.responseJSON.errorMessage.indexOf('no card found with that number') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Speichern fehlgeschlagen',
								message: 'Die eingetragene Karte wurde nicht gefunden oder ist ungültig.',
								type: 'error'
							})
						);
						return;
					}

					if (
						response.responseJSON &&
						response.responseJSON.errorMessage &&
						response.responseJSON.errorMessage.indexOf('not found in keycard Class') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Speichern fehlgeschlagen',
								message: 'Zugangskarten eintragen ist aktuell nicht möglich.',
								type: 'error'
							})
						);
						return;
					}

					if (
						response.responseJSON &&
						response.responseJSON.errorMessage &&
						response.responseJSON.errorMessage.indexOf('card already assigned to ') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Speichern fehlgeschlagen',
								message: 'Zugangskarte ist bereits einem anderem Kunden zugeordnet.',
								type: 'error'
							})
						);
						return;
					}

					if (
						response.responseJSON &&
						response.responseJSON.fieldName &&
						response.responseJSON.fieldName === 'accessMedia/number'
					) {
						$errorField = v.$form.find('input[name^="accessmedia"]');
						if ($errorField.length) {
							$errorField.addClass('field__input--error');
						}

						return;
					}
					else if (response.responseJSON && response.responseJSON.fieldName) {
						$errorField = v.$form.find('input[name="' + response.responseJSON.fieldName + '"]');
						if ($errorField.length) {
							$errorField.addClass('field__input--error');
							return;
						}
					}


					if (response.responseJSON && response.responseJSON.errorMessage) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Speichern fehlgeschlagen',
								message: response.responseJSON.errorMessage,
								type: 'error'
							})
						);
					}
				}
			});
		}
	});
});

