define('views/blocks/datetimePicker', [
	'underscore',
	'views/_view',
	'helpers/template',
	'lib/moment',
	'jquery',
	'DPicker',
	'DPicker.polyfills' // rly needed?
], function (
	_,
	View,
	templateHelper,
	moment,
	$,
	DPicker
) {
	'use strict';

	/**
	 * DateTimePickerBlockView
	 *
	 * @module views/blocks/datetimePicker
	 * @class DateTimePickerBlockView
	 * @augments BaseView
	 */
	return View.extend({
		tagName: 'div',
		className: 'picker',
		events: {
			'click': 'active'
		},

		/**
		 * Constructor of this view.
		 * Just saves the options we need later.
		 *
		 * @returns DateTimePickerBlockView
		 */
		_initialize: function (options) {
			this.options = options || {};
			return this;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {DateTimePickerBlockView}
		 */
		render: function () {
			var v = this;
			v.isActive = false;

			if (!v.model) {
				return;
			}

			templateHelper('blocks/datetimePicker', {
				name: v.options.name || '',
				label: v.options.label || '',
				datetime: v.model.toDate(),
				step: v.options.step || 60
			}, function (html) {
				v.$el.html(html);
				
				DPicker.dateAdapter.format = function format(date) {
					if (moment.isMoment(date)) {
						return date.calendar();
					}
					if (date instanceof Date) {
						return moment(date).calendar();
					}
				};
				
				DPicker.renders.closeButton = function closeButton() {
					var button = document.createElement('button');
					button.innerHTML = 'Schließen';
					button.type = 'button';
					button.className = 'dpicker__close button';
					button.addEventListener('click', function() {
						v.active(false);
					});
					return button;
				};

				DPicker.renders.startHeadline = function startHeadline () {
					var headline = document.createElement('h3');
					headline.innerHTML = 'Fahrtbeginn';
					headline.className = 'dpicker__headline';
					return headline;
				};
				
				DPicker.renders.endHeadline = function endHeadline () {
					var headline = document.createElement('h3');
					headline.innerHTML = 'Fahrtende';
					headline.className = 'dpicker__headline';
					return headline;
				};
				
				// render DPicker
				v.DateTime = new DPicker(v.$el.find('.picker__container').get(0), {
					model: v.model.toDate(),
					min: v.options.min.toDate() || undefined,
					max: v.options.max.toDate() || undefined,
					format: 'DD.MM.YYYY HH:mm', // can be removed. format() overwritten.,
					days: moment.weekdaysMin(),
					display: true, //always show,
					hideOnOutsideClick: false,
					hideOnDayClick: false,
					siblingMonthDayClick: true,
					onChange: v.change,
					order: [v.options.headline || null, 'time', 'monthsAndYears', 'days', 'closeButton'],
					time: true,
					step: v.options.step || 60,
					concatHoursAndMinutes: true
				});

				// trigger focus
				// v.$el.find('input').on('focus', function () {
				// 	v.pickerFocus();
				// });

				// blur, because eventually a normal onscreen-keyboard is coming up
				v.$el.find('.dpicker > input').on('focus', function () {
					v.$el.find('.dpicker > input').blur();
				});

				v.on('update', function (values) {
					v.model = values.model;
					// official way to change the model.
					v.DateTime.model = v.model.toDate();
					v.DateTime.min = values.min.toDate() || undefined;
					v.DateTime.max = values.max.toDate() || undefined;
					v.DateTime.step = values.step || 60;
				});

				// Prevents EndTime Step Bug
				// see https://cantaloupe.cantamen.de/admin/issues/12302 for details…
				_.defer(function () {
					v.DateTime.step = v.options.step || 60;
				});
			});

			return v;
		},

		change: function (data, DPickerEvent) {
			var v = this;
			if (DPickerEvent.modelChanged) {
				v.model = moment(data.model);
				this.trigger('change');
			}
		},

		// pickerFocus: function () {
		// 	this.trigger('pickerFocus');
		// },
		
		active: function (toggle) {
			var v = this,
				e;
			if (_.has(toggle, 'target')) { // click event
				e = toggle;
				toggle = undefined;
				
				if ($(e.target).closest('.dpicker-container').length > 0) {
					e.stopPropagation();
					return v.isActive;
				}
			}
			if (toggle === v.isActive) {
				return v.isActive;
			}
			v.isActive = _.isBoolean(toggle) ? toggle : !v.isActive;
			v.$el.toggleClass(v.className + '--active', v.isActive);
			v.trigger('active', v.isActive);
			
			if (e) {
				e.stopPropagation();
			}
			
			return v.isActive;
		}
	});
});

