define('views/_account', ['views/_view'], function (BaseView) {
	'use strict';

	/**
	 * @module views/_account
	 * @class AccountBaseView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({});
});

