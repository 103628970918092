define('templates/modals/cancelBooking',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/cancelBooking"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "beenden";
},"3":function(container,depth0,helpers,partials,data) {
    return "stornieren";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<img class=\"modal__image\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1), depth0))
    + "?width=450\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1), depth0))
    + ")";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal__data\"><div class=\"modal__label\">Station:</div> <div class=\"modal__value\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"cancelBooking.action.stop.label",{"name":"string","hash":{},"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isFixBookee : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + " stornieren";
},"14":function(container,depth0,helpers,partials,data) {
    return "Buchung";
},"16":function(container,depth0,helpers,partials,data) {
    return "Reservierung";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing, alias5="function";

  return "<h1 class=\"modal__headline\">Wirklich "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.stop : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cancel : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "?</h1>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"modal__data\"><div class=\"modal__label\">Fahrzeug:</div> <div class=\"modal__value\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "<small class=\"modal__subvalue\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</small></div></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"modal__data modal__data--start\"><div class=\"modal__label\">Von:</div> <div class=\"modal__value\">"
    + alias3(((helper = (helper = helpers.start || (depth0 != null ? depth0.start : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"start","hash":{},"data":data}) : helper)))
    + " Uhr</div></div>\n\n<div class=\"modal__data modal__data--end\"><div class=\"modal__label\">Bis:</div> <div class=\"modal__value\">"
    + alias3(((helper = (helper = helpers.end || (depth0 != null ? depth0.end : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"end","hash":{},"data":data}) : helper)))
    + " Uhr</div></div>\n\n<div class=\"modal__buttonwrap\">\n	<button class=\"modal__button modal__button--book button confirmButton\" type=\"button\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.stop : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cancel : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</button>\n</div>\n";
},"useData":true});

return this["JST"];

});
