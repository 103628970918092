/**
 * DataHelper
 *
 * Stellt Methoden zur Verfügung, die allgemein das fetchen von Models / Collections
 * erlauben. Implementiert dabei auch einen Cache und unterstützt aktiv Patching.
 *
 * ## Events:
 *
 * - **xhr:request**: Ein XHR-Request wird abgeschickt. Parameter: DataHelperXHR, options
 * - **xhr:response**: Ein XHR-Request hat eine Antwort erhalten
 * - **auth:loggedIn**: Der Login war erfolgreich
 *
 */

define('casirest2/helpers/data',['backbone', 'underscore', './store'], function (Backbone, _, StoreHelper) {
	'use strict';

	var data = {},
		priv = {};

	// Synchronisation
	priv.methodMap = {
		'create': 'POST',
		'update': 'PUT',
		'patch': 'PATCH',
		'delete': 'DELETE',
		'read': 'GET'
	};

	// Performance Data
	priv.performance = [];

	/**
	 * @class DataHelperXHR
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 * @param {BaseModel} model Model this request is attached to
	 */
	function DataHelperXHR (model) {
		this.requests = [];
		this.model = model || null;
	}

	/**
	 * addRequest()
	 * Adds a request to the DataHelperXHR instance
	 *
	 * @param {jqXHR} $xhr
	 */
	DataHelperXHR.prototype.addRequest = function ($xhr) {
		this.requests.push($xhr);
	};

	/**
	 * abort()
	 * Aborts all XHR-requests attached to this instance…
	 */
	DataHelperXHR.prototype.abort = function () {
		if (this.model) {
			delete this.model.syncing;
		}

		_.each(this.requests, function ($xhr) {
			if ($xhr && $xhr.readyState !== 4) {
				$xhr.abort();
			}
		});
	};


	_.extend(data, Backbone.Events);
	_.extend(DataHelperXHR.prototype, Backbone.Events);


	/**
	 * Initializes the DataHelper
	 *
	 * @param options
	 *
	 * @example dataHelper.initialize({
	 *     endpoint: 'https://de6preview.cantamen.de/casirest',
	 *     apiKey: '********-****-****-****-************'
	 * });
	 */
	data.initialize = function (options) {
		if (data.initialize.initialized) {
			return data;
		}

		if (!options.endpoint) {
			throw new Error('DataHelper: initialize() called without `endpoint`');
		}
		if (!options.apiKey && !options.technicalAdmin) {
			throw new Error('DataHelper: initialize() called without `apiKey` or `technicalAdmin`');
		}
		if (!options.TokenModel) {
			throw new Error('DataHelper: initialize() called without `TokenModel`');
		}

		if (options.endpoint.substr(0, 8) !== 'https://' && options.endpoint.substr(0, 7) !== 'http://') {
			options.endpoint = 'https://' + options.endpoint;
		}
		if (options.endpoint.substr(-1) === '/') {
			options.endpoint = options.endpoint.substr(0, options.endpoint.length - 1);
		}
		if (options.endpoint.substr(-9) !== '/casirest') {
			options.endpoint += '/casirest';
		}

		priv.config = _.extend({}, options);

		// AuthToken + Events
		/** @type TokenModel */
		priv.authToken = new options.TokenModel();
		priv.authToken.on('change:id', function () {
			console.log('DataHelper:', priv.authToken.id ? 'auth:loggedIn' : 'auth:loggedOut');
			data.trigger(priv.authToken.id ? 'auth:loggedIn' : 'auth:loggedOut');
		});

		data.initialize.initialized = true;
		data.trigger('initialized');

		return data;
	};


///////////////////////////////////////////////////////////////////////////////////////////
/////
/////    Authorization
/////
///////////////////////////////////////////////////////////////////////////////////////////

	/**
	 * Returns a positive boolean, if the user is currently logged
	 * in, otherwise false.
	 *
	 * @returns {boolean}
	 */
	data.isLoggedIn = function () {
		if (data.fakeLogin.available) {
			return !!data.fakeLogin.enabled;
		}

		return !!(priv.authToken && priv.authToken.id);
	};

	/**
	 * Method to easily login the user
	 *
	 * @param {object} [options]
	 * @param {string} [options.username] Username
	 * @param {string} [options.password] Password
	 * @param {string} [options.provId] Provider-ID
	 * @param {string} [options.loginType] One of 'AUTO', 'LOGIN', 'EMAIL', 'CARDID' or 'CUSTOMERID', defaults to ' AUTO'
	 * @returns {TokenModel}
	 */
	data.login = function (options) {
		options = options || {};

		var username = options.username,
			password = options.password,
			provId = options.provId,
			loginType = options.loginType || 'AUTO';

		if (data.fakeLogin.available) {
			username = username || priv.config.testUsername;
			password = password || priv.config.testPassword;
			provId = provId || priv.config.testProvId;
		}

		if (!username) {
			throw new Error('DataHelper.login(): Not possible: no username given!');
		}
		if (!password) {
			throw new Error('DataHelper.login(): Not possible: no password given!');
		}
		if (!provId) {
			throw new Error('DataHelper.login(): Not possible: no provId given!');
		}
		if (_.indexOf(['AUTO', 'LOGIN', 'EMAIL', 'CARDID', 'CUSTOMERID'], loginType) === -1) {
			throw new Error('DataHelper.login(): Not possible: loginType not allowed!');
		}

		// don't redo login if fakeLogin token already exists
		if (data.fakeLogin.data) {
			return priv.authToken;
		}

		if (loginType === 'AUTO' && username.substr(0, 1) === '#') {
			loginType = 'CARDID';
			username = username.substr(1);
		}
		if (loginType === 'AUTO' && _.indexOf(username, '@') > -1) {
			loginType = 'EMAIL';
		}
		if (loginType === 'AUTO') {
			loginType = 'CUSTOMERID';
		}

		if (loginType === 'CARDID') {
			username = '#' + username;
		}

		// Raven
		if (window.Raven && Raven.setUserContext) {
			Raven.setUserContext({
				id: loginType + ':' + username + '/' + provId,
				username: username
			});
		}

		priv.authToken.id = null;

		_.defer(function () {
			priv.authToken.save({}, {
				attrs: {
					login: username,
					credential: password,
					// loginType: loginType,
					provId: provId
				},
				success: function (data, textStatus, $xhr) {
					if (_.isFunction(options.success)) {
						options.success(data, textStatus, $xhr);
					}
				},
				error: function ($xhr, textStatus, errorThrown) {
					if (_.isFunction(options.error)) {
						options.error($xhr, textStatus, errorThrown);
					}
				},
				report: function (errorMessage) {
					return !errorMessage || errorMessage.indexOf('Authentication invalid') === -1;
				}
			});
		});

		return priv.authToken;
	};

	/**
	 * Method to easily logout the user…
	 *
	 * @param {function} cb Callback
	 * @returns {object} DataHelper
	 */
	data.logout = function (cb) {
		StoreHelper.set('auth', {});

		// Raven
		if (window.Raven && Raven.setUserContext) {
			Raven.setUserContext();
		}

		if (priv.authToken.id) {
			_.defer(function () {
				priv.authToken.destroy({
					success: function () {
						priv.authToken.clear();
						data.trigger('auth:loggedOut');

						if (data.fakeLogin.available) {
							data.fakeLogin.available = false;
							data.fakeLogin.enabled = false;
						}

						if (cb && _.isFunction(cb)) {
							cb();
						}
					},
					error: function() {
						priv.authToken.clear();
						data.trigger('auth:loggedOut');
					}
				});
			});
		} else {
			data.trigger('auth:loggedOut');

			_.defer(function () {
				if (cb && _.isFunction(cb)) {
					cb();
				}
			});
		}

		return data;
	};

	/**
	 * Internally used method for unit testing; allows to mock login/logout
	 *
	 * @param {boolean} enable
	 * @param {function} callback
	 * @returns {object} DataHelper
	 */
	data.fakeLogin = function (enable, callback) {
		if (enable === undefined) {
			return !!data.fakeLogin.enabled;
		}

		if (!priv.config.testUsername) {
			throw new Error('DataHelper: fakeLogin() called without `testUser` in initialize()!');
		}
		if (!priv.config.testPassword) {
			throw new Error('DataHelper: fakeLogin() called without `testProvId` in initialize()!');
		}
		if (!priv.config.testProvId) {
			throw new Error('DataHelper: fakeLogin() called without `testProvId` in initialize()!');
		}

		data.fakeLogin.available = true;
		data.fakeLogin.enabled = !!(enable);

		if (enable && !priv.authToken.id) {
			if (_.isFunction(callback)) {
				data.once('auth:loggedIn', function () {
					callback();
				});
			}

			data.login();
		}
		else if (_.isFunction(callback)) {
			_.defer(function () {
				callback();
			});
		}

		return data;
	};


///////////////////////////////////////////////////////////////////////////////////////////
/////
/////    Synchronisation
/////
///////////////////////////////////////////////////////////////////////////////////////////

	/**
	 * Helper method used to generate request ids
	 * @returns {string}
	 */
	data.s4 = function () {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	};

	/**
	 * Sync method used for collections and models
	 *
	 * @param {string} method (create, update, patch, delete or read)
	 * @param {BaseCollection|BaseModel} model Model or Collection to work on
	 * @param {object} options
	 * @returns DataHelperXHR
	 */
	data.sync = function (method, model, options) {
		var type = priv.methodMap[method],
			params = {url: _.result(model, 'url'), type: type, dataType: 'json'};

		// Ensure that we have the appropriate request data.
		if (!options.data && model && (method === 'create' || method === 'update' || method === 'patch')) {
			params.data = options.attrs || model.toJSON(options);
		}

		// Don't process data on a non-GET request.
		if (params.type !== 'GET') {
			params.processData = false;
		}

		// Ensure we have a proper DataHelperXHR object attached
		params.DataHelperXHR = new DataHelperXHR(model);

		// Make the request, allowing the user to override any Ajax options.
		model.syncing = true;
		model.trigger('request', model, null, options);

		return data.ajax(_.extend(params, options));
	};

	/**
	 * Ajax method with locking and idempotency
	 *
	 * @param {object} options
	 * @returns DataHelperXHR
	 */
	data.ajax = function (options) {
		var beforeSend = options.beforeSend || null,
			success = options.success,
			error = options.error,
			reqOptions = {},
			performance = {},
			retryTimeout,
			$xhr;

		// Default options, unless specified.
		options = options || {};
		options.try = (options.try || 0) + 1;
		options.tryDone = (options.tryDone || 0);
		options.done = options.done || false;
		options.locked = options.locked || false;

		// Ensure we have an ID
		if (!options.id) {
			options.id = data.s4() + data.s4() + '-' + data.s4() + '-' + data.s4() + '-' +
				data.s4() + '-' + data.s4() + data.s4() + data.s4();
		}

		// Ensure we have an DataHelperXHR Object to return
		if (!options.DataHelperXHR) {
			options.DataHelperXHR = options.DataHelperXHR || new DataHelperXHR();
		}

		// Ensure DataHelper is initialized
		if (!data.initialize.initialized) {
			data.once('initialized', function () {
				options.locked = false;
				data.ajax(options);
			});

			return options.DataHelperXHR;
		}

		// Ensure that we have a valid URL
		if (options.url.substr(0, 1) === '/') {
			options.url = priv.config.endpoint + options.url;
		}
		if (!options.url) {
			throw new Error('Oops, DataHelper.fetch() called without URL!');
		}

		// Ensure we don't fetch locked objects
		if (options.locked) {
			console.warn('DataHelper: Object (' + options.url + ') already started to fetch, don\'t do this multiple times!');
			return options.DataHelperXHR;
		}
		options.locked = true;
		_.delay(function () {
			options.locked = false;
		}, 5000);

		// Ensure we have proper data
		if (typeof options.data === 'object') {
			options.contentType = 'application/json';
			options.data = JSON.stringify(options.data);
		}

		// Ensure we have json when object empty
		if(options.contentType === 'application/json' && !options.data) {
			options.data = '{}';
		}

		// Log Performance Data
		performance.id = options.id;
		performance.url = options.url;
		performance.type = options.type;
		performance.start = new Date().getTime();
		priv.performance.push(performance);
		if(priv.performance.length > 250) {
			priv.performance.shift();
		}

		// Ensure that we have the correct authorization headers attached
		options.beforeSend = function (xhr) {
			if (priv.config.apiKey) {
				xhr.setRequestHeader('X-API-Key', priv.config.apiKey);
			}
			if (priv.config.technicalAdmin) {
				xhr.setRequestHeader('X-Technical-Admin', priv.config.technicalAdmin);
			}

			// send Idempotency-Key for every request for debugging purposes
			xhr.setRequestHeader('Idempotency-Key', options.id);

			if (
				(!data.fakeLogin.available && priv.authToken.id) ||
				(data.fakeLogin.available && data.fakeLogin.enabled)
			) {
				xhr.setRequestHeader('Authorization', 'Basic ' + btoa(priv.authToken.id + ':'));
			}

			if (beforeSend) {
				return beforeSend.apply(this, arguments);
			}
		};

		reqOptions.success = function (data, textStatus) {
			if (retryTimeout) {
				clearTimeout(retryTimeout);
			}
			performance.end = new Date().getTime();
			performance.status = null;

			if (options.done) {
				return;
			}

			options.done = true;
			options.tryDone += 1;
			options.DataHelperXHR.abort();

			if (success) {
				success.call(options.context, data, textStatus);
			}
		};

		// Pass along `textStatus` and `errorThrown` from jQuery.
		reqOptions.error = function ($xhr, textStatus, errorThrown) {
			options.textStatus = textStatus;
			options.errorThrown = errorThrown;
			options.tryDone += 1;

			if (retryTimeout) {
				clearTimeout(retryTimeout);
			}
			performance.end = new Date().getTime();
			performance.status = $xhr.status;

			if (options.done) {
				return;
			}

			if ($xhr.status === 401 && priv.authToken.id && this.url.indexOf('/tokens') === -1) {
				data.logout();
				return;
			}
			else if ($xhr.status === 401 && this.url.indexOf('/tokens') === -1) {
				console.error(new Error('CasiREST DataHelper Error: Authentication Token seems to be invalid!'));
				return;
			}

			// Server 500: retry request (max 3 times)
			if ($xhr.status >= 500 && $xhr.status < 600 && options.try < 3) {
				options.locked = false;
				return data.ajax(options);
			}

			// there are still requests running, wait for the others…
			if (options.tryDone < options.try) {
				return;
			}

			options.done = true;
			options.DataHelperXHR.abort();
			if (
				(
					typeof options.report !== 'function' &&
					options.report !== false
				) || (
					typeof options.report === 'function' &&
					options.report(
						$xhr.responseJSON && $xhr.responseJSON.errorMessage,
						$xhr, textStatus, errorThrown
					) !== false
				)
			) {
				data.ajaxReport(options, $xhr, errorThrown || textStatus);
			}

			if (error) {
				error.call(options.context, $xhr, textStatus, errorThrown);
			}
		};

		// Retry Request, if it takes longer than 5 seconds (max 3 times)…
		if (options.try < 3) {
			retryTimeout = setTimeout(function () {
				data.ajax(options);
			}, 5000);
		}

		$xhr = Backbone.ajax(_.extend({}, options, reqOptions));
		options.DataHelperXHR.addRequest($xhr);
		data.trigger('xhr:request', options.DataHelperXHR, options);

		return options.DataHelperXHR;
	};

	/**
	 * Internally used by DataHelper.ajax() to report errors
	 *
	 * @param {object} options
	 * @param {jqXHR} $xhr
	 * @param {Error|string} message
	 */
	data.ajaxReport = function (options, $xhr, message) {
		if (!window.Raven) {
			return;
		}

		if (!(message instanceof Error)) {
			message = new Error(message);
		}
		if ($xhr.responseJSON && $xhr.responseJSON.errorMessage) {
			message.message = $xhr.responseJSON.errorMessage;
		}

		// don't report if request was aborted…
		if ($xhr.status === 0) {
			return;
		}

		// don't report invalid authentications
		if(message.message && message.message.indexOf('Authentication invalid') > -1) {
			return;
		}

		var fingerprint = ['{{ default }}'];
		if ($xhr.responseJSON && $xhr.responseJSON.errorMessage) {
			fingerprint.push($xhr.responseJSON.errorMessage);
		}
		else if ($xhr.responseJSON && $xhr.responseJSON.resultCode) {
			fingerprint.push($xhr.responseJSON.resultCode);
		}
		else {
			fingerprint.push($xhr.responseText);
		}

		Raven.captureException(message, {
			fingerprint: fingerprint,
			extra: {
				requestId: options.id,
				requestURL: options.url,
				requestData: options.data || null,
				responseStatus: $xhr.status,
				responseText: ($xhr.responseText || '').substr(0, 256) + (($xhr.responseText || '').length > 256 ? '…' : '')
			}
		});
	};

	/**
	 * Get some performance data for the last 250 requests sent…
	 *
	 * @returns {Array<Object>}
	 */
	data.getPerformanceData = function() {
		return priv.performance;
	};

	return data;
});

