define('views/blocks/remarks', [
	'underscore',
	'views/_view',
	'helpers/template',
	'jquery',
	'casirest2/collections/remark'
], function (
	_,
	View,
	templateHelper,
	$,
	RemarkCollection
) {
	'use strict';

	/**
	 * RemarksBlockView
	 *
	 * @module views/blocks/remarks
	 * @class RemarksBlockView
	 * @augments BaseView
	 */
	return View.extend({
		remarks: null,
		tagName: 'section',
		className: 'remarks',
		events: {
			'click .remarks__subject': 'toggleRemark'
		},

		/**
		 * Constructor of this view.
		 * Just saves the options we need later.
		 *
		 * @returns RemarksBlockView
		 */
		_initialize: function (options) {
			options = options || {};
			this.remarks = options.remarks || null;
			this.showAlerts = options.showAlerts === undefined ? true : options.showAlerts;
			this.prepareRemarks();
			this.autoOpen = !!options.autoOpen;
			return this;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {RemarksBlockView}
		 */
		render: function () {
			var v = this,
				warning = _.filter(v.remarks, function (remark) {
					return _.contains(remark.infoType, 'WARNING');
				}) || [],
				alert = _.filter(v.remarks, function (remark) {
					return _.contains(remark.infoType, 'ALERT');
				}) || [],
				remarksToDisplay = _.difference(v.remarks, _.union(alert, warning)),
				maxSeverity = _.max(v.remarks, function(remark) { return remark.severity; });

			// mark warning as warning
			_.each(warning, function (warn) {
				warn.isWarning = true;
				warn.severity = warn.severity - maxSeverity.severity; // add maxSeverity, to be the first in the list
			});
			// first the warning, than the other remarks
			remarksToDisplay = _.union(warning, remarksToDisplay);
			// make a real collection for order reasons (comparator = '-severity')
			remarksToDisplay = new RemarkCollection(remarksToDisplay);

			templateHelper('blocks/remarks', {
				remarks: remarksToDisplay.toJSON(),
				alert: v.showAlerts ? alert : null
			}, function (html) {
				v.$el.html(html);

				if(v.autoOpen) {
					v.$('.remarks__subject:not(.remarks__subject--open)').each(function() {
						v.toggleRemark({target: $(this)});
					});
				}
			});

			return v;
		},

		toggleRemark: function (ev) {
			var $button = $(ev.target);

			if ($button.hasClass('remarks__subject--empty')) {
				return;
			}

			var $remark = $(ev.target).closest('.remarks__item');
			$remark.find('.remarks__subject').toggleClass('remarks__subject--open');
			$remark.find('.remarks__content').slideToggle(200);
		},

		prepareRemarks: function () {
			var v = this;

			// add flag to prevent toggeling empty remarks
			v.remarks = _.map(v.remarks, function (remark) {
				remark.hasContent = !!(remark.reference || remark.content);
				return remark;
			});
		}
	});
});

