define('casirest2/collections/customerFavorite',['./_', '../models/customerFavorite'], function (Collection, CustomerFavoriteModel) {
	'use strict';

	/**
	 * @class CustomerFavoriteCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: CustomerFavoriteModel
	});
});

