define('casirest2/collections/booking',['./_', '../models/booking'], function (Collection, BookingModel) {
	'use strict';

	/**
	 * @class BookingCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: BookingModel,
		filterMethods: ['bookee', 'timeRange', 'bookingType']
	});
});

