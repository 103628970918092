define('casirest2/collections/priceItem',['./_', '../models/priceItem'], function (Collection, PriceItemModel) {
	'use strict';

	/**
	 * @class PriceItemCollection
	 */
	return Collection.extend({
		model: PriceItemModel,
		filterMethods: [],

		/**
		 * @returns {Array<string>}
		 */
		prepareForRender: function () {
			var prices = [];

			this.each(function (price) {
				price.get('priceIncl').amountFormated = price.format();
				prices.push(price.toJSON());
			});

			return prices;
		}

	});
});

