define('views/blocks/unavailabilityDay', [
	'jquery',
	'underscore',
	'moment',
	'views/_view',
	'helpers/settings',
	'views/blocks/unavailabilityInterval'
], function (
	$,
	_,
	moment,
	View,
	SettingsHelper,
	UnavailabilityIntervalBlockView
) {
	'use strict';

	/**
	 * UnavailabilityDayBlock
	 *
	 * @module views/blocks/unavailabiliyDay
	 * @class UnavailabilityDayBlock
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'unavailability__day loading loading--overlay',

		_initialize: function (options) {
			this.timeHelper = options.timeHelper;
			this.moment = options.moment;
			this.providerSettings = options.providerSettings;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {UnavailabilityDayBlock}
		 */
		render: function () {
			var v = this,
				unavailabilities,
				interval,
				ll,
				i;

			if (!v.model.get('provId')) {
				v.listenToOnce(v.model, 'change:provId', v.render);
				if (!v.model.syncing) {
					v.model.fetch();
				}
				return v;
			}

			if (!v.providerSettings) {
				v.providerSettings = SettingsHelper.getProviderSettings(v.model.get('provId'));
			}
			if (!v.providerSettings.get('defaultBookingModel')) {
				v.listenToOnce(v.providerSettings, 'change:defaultBookingModel', v.render);

				// https://cantaloupe.cantamen.de/admin/issues/15575
				v.listenToOnce(v.providerSettings, 'error', function () {
					v.providerSettings.set({
						defaultBookingModel: _.extend(
							{},
							SettingsHelper.getProviderSettings().get('defaultBookingModel'),
							{
								interval: 'PT30M',
								minBookingLength: 'PT1H'
							}
						)
					});
				});

				if (!v.providerSettings.syncing) {
					v.providerSettings.fetch();
				}
				return v;
			}

			unavailabilities = v.model.getUnvailabilityTimeRanges();
			unavailabilities.filterByTimeRange(
				moment(v.moment).startOf('day'),
				moment(v.moment).endOf('day')
			);
			interval = moment.duration(v.providerSettings.get('defaultBookingModel').interval).asSeconds();
			v.$el.empty();
			ll = -1;

			unavailabilities.fetch();

			for (i = moment(v.moment).startOf('day'); i.isSame(v.moment, 'day'); i.add(interval, 's')) {
				new UnavailabilityIntervalBlockView({
					timeHelper: v.timeHelper,
					moment: moment(i),
					model: v.model,
					providerSettings: v.providerSettings,
					label: i.hours() !== ll && i.hours() % 2 === 0,
					unavailabilities: unavailabilities
				}).appendTo(v.$el, v);
				ll = i.hours();
			}

			v.listenTo(unavailabilities, 'sync', function () {
				v.$el.removeClass('loading loading--overlay');
			});

			return v;
		}
	});
});

