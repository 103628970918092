define('views/blocks/closeButton', [
	'underscore',
	'views/_view'
], function (
	_,
	View
) {
	'use strict';

	/**
	 * CloseButtonBlockView
	 *
	 * @module views/blocks/closeButton
	 * @class CloseButtonBlockView
	 * @augments BaseView
	 */
	return View.extend({
		tagName: 'button',
		className: 'button button--close',
		attributes: {
			'type': 'button'
		},
		events: {
			'click': 'onClick'
		},

		/**
		 * Constructor of this view.
		 * Just saves the options we need later.
		 *
		 * @returns CloseButtonBlockView
		 */
		_initialize: function (options) {
			options = options || {};
			this.removeOnClick = options.remove === true;
			return this;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {CloseButtonBlockView}
		 */
		render: function () {
			var v = this;
			v.$el.html('&times;');

			return v;
		},

		onClick: function(e) {
			this.trigger('click', e);
			this.trigger('close', e);
			if(this.removeOnClick) {
				this.remove();
			}
		}

	});
});

