define('templates/blocks/accountMenu',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/accountMenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " tabmenu__button--active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<ul class=\"tabmenu__list\">\n	<li class=\"tabmenu__item\"><button type=\"button\" class=\"tabmenu__button tabmenu__button--bookings "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bookings : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-url=\"account/bookings\">Fahrten</button></li><li class=\"tabmenu__item\"><button type=\"button\" class=\"tabmenu__button tabmenu__button--data "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.settings : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-url=\"account/settings\">Meine Daten</button></li><li class=\"tabmenu__item tabmenu__item--special\"><button type=\"button\" class=\"tabmenu__specialbutton\" data-url=\"logout\">Logout</button></li>\n</ul>\n";
},"useData":true});

return this["JST"];

});
