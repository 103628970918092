define('casirest2/models/unavailabilityTimeRange',['./_'], function (Model) {
	'use strict';

	/**
	 * @class UnavailabilityTimeRangeModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/unavailability-time-range'
	});
});

