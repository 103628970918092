define('templates/panels/unavailability',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/unavailability"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<h1 class=\"panel__headline panel__headline--unavailability\">Verfügbarkeiten</h1>\n";
},"useData":true});

return this["JST"];

});
