define('views/blocks/unavailabilityLayer', [
	'jquery',
	'underscore',
	'moment',
	'views/_view'
], function (
	$,
	_,
	moment,
	View
) {
	'use strict';

	/**
	 * UnavailabilityLayerBlock
	 *
	 * @module views/blocks/unavailabilityLayer
	 * @class UnavailabilityLayerBlock
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'unavailability__layer unavailablelayer',
		events: {
			'click .unavailablelayer__time': 'selectTime'
		},

		before: 3,
		after: 4,

		_initialize: function (options) {
			this.timeHelper = options.timeHelper;
			this.providerSettings = options.providerSettings;
			this.moment = options.moment;
			this.label = options.label || false;
			this.unavailabilities = options.unavailabilities;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {UnavailabilityLayerBlock}
		 */
		render: function () {
			var v = this,
				h = v.moment.hours(),
				$close = $('<button class="unavailablelayer__close" />')
					.html('&times;')
					.attr('title', 'schließen')
					.appendTo(v.$el),
				$title = $('<h5 class="unavailablelayer__title" />').appendTo(v.$el),
				$startTitle = $('<h6 class="unavailablelayer__subtitle unavailablelayer__subtitle--start" />').appendTo(v.$el),
				$endTitle = $('<h6 class="unavailablelayer__subtitle unavailablelayer__subtitle--end" />').appendTo(v.$el),
				$startTimes = $('<div class="unavailablelayer__times unavailablelayer__times--start" />').appendTo(v.$el),
				$endTimes = $('<div class="unavailablelayer__times unavailablelayer__times--end" />').appendTo(v.$el);

			if(h < 8) {
				v.$el.addClass('unavailablelayer--left unavailability__layer--left');
			}
			else if(h < 16) {
				v.$el.addClass('unavailablelayer--middle unavailability__layer--middle');
			}
			else {
				v.$el.addClass('unavailablelayer--right unavailability__layer--right');
			}

			// Close
			$close.click(function(e) {
				e.stopPropagation();
				v.remove();
			});

			// Title
			$title.text(v.moment.format('dd, D. MMM YYYY'));
			$startTitle.text('Startzeitpunkt');
			$endTitle.text('Endzeitpunkt');

			// Times
			v.listenTo(v.providerSettings, 'change:defaultBookingModel', v.renderTimes);
			v.listenToAndCall(v.timeHelper, 'change', function() {
				v.renderTimes('start', $startTimes);
				v.renderTimes('end', $endTimes);
			});

			return v;
		},
		renderTimes: function(mode, $el) {
			var v = this,
				interval = moment.duration(v.providerSettings.get('defaultBookingModel').interval),
				first = moment(v.moment).subtract(interval.asSeconds() * v.before, 'seconds'),
				last = moment(v.moment).add(interval.asSeconds() * v.after, 'seconds'),
				min,
				max;

			if(mode === 'start') {
				min = moment()
					.subtract(moment.duration(v.providerSettings.get('defaultBookingModel').interval));
				max = moment()
					.add(moment.duration(v.providerSettings.get('defaultBookingModel').maxAdvance))
					.subtract(interval);
			}else{
				min = moment()
					.subtract(moment.duration(v.providerSettings.get('defaultBookingModel').interval))
					.add(moment.duration(v.providerSettings.get('defaultBookingModel').minBookingLength));
				max = moment()
					.add(moment.duration(v.providerSettings.get('defaultBookingModel').maxAdvance));
			}

			$el.empty();
			for(var i = moment(first); i.isSameOrBefore(last); i.add(interval)) {
				$('<button class="unavailablelayer__time" type="button" />')
					.data('time', i.toJSON())
					.data('mode', mode)
					.text(i.format('HH:mm'))
					.toggleClass('unavailablelayer__time--invalid', !i.isBetween(min, max, null, '[]'))
					.toggleClass('unavailablelayer__time--current', v.timeHelper.get(mode).isSame(i))
					.toggleClass('unavailablelayer__time--occupied', v.hasUnavailability(i, mode))
					.appendTo($el);
			}
		},
		hasUnavailability: function(myMoment, mode) {
			var v = this;

			return !!v.unavailabilities.filter(function(u) {
				var occStart = moment(u.get('timeRange').start),
					occEnd = moment(u.get('timeRange').end);

				if(mode === 'start') {
					return occEnd.isAfter(myMoment) && occStart.isSameOrBefore(myMoment);
				}else{
					return occEnd.isSameOrAfter(myMoment) && occStart.isBefore(myMoment);
				}
			}).length;
		},
		selectTime: function(e) {
			var v = this,
				mode = $(e.target).data('mode'),
				selected = moment($(e.target).data('time'));

			e.preventDefault();
			e.stopPropagation();

			v.timeHelper.update(
				mode === 'start' ? selected : null,
				mode === 'end' ? selected : null
			);
		}
	});
});

