define('routers/app', [
	'backbone',
	'underscore',
	'views/app',
	'helpers/store',
	'helpers/settings',
	'helpers/mapState'
], function (
	Backbone,
	_,
	AppView,
	storeHelper,
	settingsHelper,
	mapStateHelper
) {
	'use strict';

	return Backbone.Router.extend({

		initialize: function () {
			var r = this;

			_.bindAll(r,
				'buildRoute',
				'subNavigate',
				'notFound',
				'home',
				'login',
				'logout',
				'resetPassword',
				'setPassword',
				'map',
				'mapPlace',
				'mapPlaceRemarks',
				'mapPlaceAvailability',
				'mapPlaceBookee',
				'accountRedirect',
				'accountBookings',
				'accountSettings'
			);

			r.view = new AppView();
			_.defer(r.view.render);

			r.on('route', function (route, params) {
				if (params && params[0]) {
					mapStateHelper.checkState(params[0]);
				}
			});
		},

		routes: {
			// Login
			':mapstate/login': 'login',
			':mapstate/login/reset': 'resetPassword',
			':mapstate/login/token': 'setPassword',
			':mapstate/login/token/:secret': 'setPassword',

			// Logout
			':mapstate/logout(/:route)(/:mode)(/:submode)': 'logout',

			// More
			':mapstate/more': 'more',
			':mapstate/more/:id': 'more',

			// Kundenbereich
			':mapstate/account': 'accountRedirect',
			':mapstate/account/bookings': 'accountBookings',
			':mapstate/account/settings': 'accountSettings',

			'': 'map',
			':mapstate': 'map',
			':mapstate/place/:placeID': 'mapPlace',
			':mapstate/place/:placeID/about': 'mapPlaceRemarks',
			':mapstate/place/:placeID/availability': 'mapPlaceAvailability',
			':mapstate/place/:placeID/:bookeeID': 'mapPlaceBookee',

			':mapstate/filter': 'mapFilter',


			// Error 404
			'*path': 'notFound'
		},


		buildRoute: function(route) {
			var parts = [mapStateHelper.generateState()];
			if (route) {
				parts.push(route);
			}
			return parts.join('/');
		},
		subNavigate: function (route, options) {
			this.navigate(this.buildRoute(route), options);
		},

		/**
		 * Error 404 anzeigen
		 */
		notFound: function (path) {
			// @todo 404
			console.error(404, path);
			return this;
		},

		// Home
		home: function () {
			var r = this;
			r.navigate(storeHelper.get('last-route') || 'map', {
				trigger: true
			});
		},

		// Login
		login: function () {
			var r = this;
			require(['views/modals/login'], function (LoginView) {
				var loginView = new LoginView();
				r.view.renderView(loginView);
				r.listenToOnce(loginView, 'remove', function () {
					if (location.hash.indexOf('/login') > -1) {
						r.subNavigate('', {trigger: true});
					}
				});
			});
		},

		// Logout
		logout: function (route, mode, submode) {
			var r = this;
			require(['casirest2/helpers/data'], function (DataHelper) {
				DataHelper.logout();

				if (r.view.accountView) {
					r.view.accountView.hide();
				}

				if (route) {
					var newRoute = route + (mode ? '/' + mode : '') + (submode ? '/' + submode : '');
					r.navigate(newRoute, {trigger: true});
				}
			});
		},

		// More
		more: function(state, id) {
			var r = this;
			require([
				'views/panels/more',
				'views/panels/moreFrame',
				'helpers/settings'
			], function (MorePanelView, MoreFramePanelView, SettingsHelper) {
				if(!r.view.getOpenedPanel(0) || !(r.view.getOpenedPanel(0) instanceof MorePanelView)) {
					r.view.renderView(new MorePanelView(), {isRoot: true});
				}

				if((!id && r.view.getOpenedPanel(1)) || (id && r.view.getOpenedPanel(1) && r.view.getOpenedPanel(1).model.id !== id)) {
					r.view.getOpenedPanel(1).remove();
				}
				if(id && !r.view.getOpenedPanel(1)) {
					r.view.renderView(new MoreFramePanelView({
						model: SettingsHelper.getEndPointSettings().getExtraMenuEntries().get(id)
					}));
				}
			});
		},

		resetPassword: function () {
			var r = this;
			require(['views/modals/resetPassword'], function (ResetPasswordView) {
				var resetPasswordView = new ResetPasswordView();
				r.view.renderView(resetPasswordView);
			});
		},

		setPassword: function (mapstate, secret) {
			var r = this;
			require(['views/modals/setPassword'], function (SetPasswordView) {
				var setPasswordView = new SetPasswordView({secret: secret});
				r.view.renderView(setPasswordView);
			});
		},

		// Map
		map: function () {

		},

		mapPlace: function (mapState, placeId) {
			if (!this.view.bookingAreaView) {
				_.defer(this.mapPlace, mapState, placeId)
				return;
			}
			this.view.bookingAreaView.openPlace(placeId);
		},
		mapPlaceRemarks: function (mapState, placeId) {
			if (!this.view.bookingAreaView) {
				_.defer(this.mapPlaceRemarks, mapState, placeId)
				return;
			}
			this.view.bookingAreaView.openPlace(placeId, function (placePanel) {
				placePanel.openRemarks();
			});
		},
		mapPlaceAvailability: function (mapState, placeId) {
			if (!this.view.bookingAreaView) {
				_.defer(this.mapPlaceAvailability, mapState, placeId)
				return;
			}
			this.view.bookingAreaView.openPlace(placeId, function (placePanel) {
				placePanel.showUnavailability();
			});
		},
		mapPlaceBookee: function (mapState, placeId, bookeeId) {
			if (!this.view.bookingAreaView) {
				_.defer(this.mapPlaceBookee, mapState, placeId, bookeeId)
				return;
			}
			this.view.bookingAreaView.openPlace(placeId, function (placePanel) {
				placePanel.openBookee(bookeeId);
			});
		},

		mapFilter: function (mapState, filter) {
			var r = this;
			_.defer(function () {
				if (!r.view || !r.view.bookingAreaView) {
					_.defer(r.mapFilter, mapState, filter);
					return;
				}
				r.view.bookingAreaView.openFilter();
			});
		},

		// Kundenbereich
		accountRedirect: function () {
			this.subNavigate('account/bookings', {trigger: true});
		},
		accountBookings: function () {
			var r = this;

			require(['views/account/customerBookings'], function (CustomerBookingsAccountView) {
				var view = new CustomerBookingsAccountView();
				r.view.renderView(view);
			});
		},
		accountSettings: function () {
			var r = this;

			require(['views/account/settings'], function (CustomerBookingsSettingsView) {
				var view = new CustomerBookingsSettingsView();
				r.view.renderView(view);
			});
		},

		current: function () {
			var Router = this,
				fragment = Backbone.history.fragment,
				routes = _.pairs(Router.routes),
				route = null, params = null, matched;

			matched = _.find(routes, function (handler) {
				route = _.isRegExp(handler[0]) ? handler[0] : Router._routeToRegExp(handler[0]);
				return route.test(fragment);
			});

			if (matched) {
				params = Router._extractParameters(route, fragment);
				route = matched[1];
			}

			return {
				route: route,
				fragment: fragment,
				params: params
			};
		}
	});
});

