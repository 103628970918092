define('casirest2/collections/extraMenuEntry',['./_', '../models/extraMenuEntry'], function (Collection, ExtraMenuEntryModel) {
	'use strict';

	/**
	 * @class ExtraMenuEntryCollection
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return Collection.extend({
		model: ExtraMenuEntryModel,
		filterMethods: []
	});
});

