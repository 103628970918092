define('views/modals/confirmBooking', [
	'underscore',
	'jquery',
	'views/_modal',
	'helpers/template',
	'casirest2/models/prelimBooking',
	'casirest2/helpers/data',
	'helpers/time',
	'moment',
	'helpers/booking',
	'views/modals/login',
	'views/modals/prompt',
	'views/blocks/addPropType',
	'helpers/string',
	'casirest2/models/price',
	'helpers/externalAPI',
	'helpers/settings'
], function (
	_,
	$,
	ModalView,
	templateHelper,
	PrelimBookingModel,
	DataHelper,
	TimeHelper,
	moment,
	bookingHelper,
	LoginView,
	PromptModalView,
	AddPropTypeBlock,
	stringHelper,
	PriceModel,
	ExternalAPI,
	SettingsHelper
) {
	'use strict';

	return ModalView.extend({
		modalName: 'confirmBooking modal',

		modalEvents: {
			'click .confirmBooking': 'confirm',
			'click .button--close': 'cancel',
			'click .computePrice': 'computePrice'
		},

		_initialize: function (options) {
			this.options = options || null;
			this.bookee = options.bookee || null;
			this.proposal = options.proposal || null;
			this.timeHelper = options.timeHelper || new TimeHelper();
		},

		render: function () {
			var v = this;

			if (!v.bookee.id) {
				return;
			}

			v.$el.addClass('loading--overlay');

			// createPrelimBookingModel
			if (!v.model) {
				v.model = new PrelimBookingModel();
			}

			v.model.set('timeRange', {
				start: v.timeHelper.get('start').toISOString(),
				end: v.timeHelper.get('end').toISOString()
			});
			v.model.set('bookeeId', v.bookee.id);

			// Abweichender Buchungszeitraum
			if (v.proposal && v.proposal.get('rating').time === false) {
				v.model.set('timeRange', {
					start: v.proposal.get('timeRange').start,
					end: v.proposal.get('timeRange').end
				});
			}
			
			v.providerSettings = SettingsHelper.getProviderSettings('default');
			
			v.createPrelimBooking();
		},

		renderContent: function () {
			var v = this,
				constraints = [],
				bookeeImage,
				price,
				displayPrice;
			
			v.listenToOnce(v.providerSettings, 'change:provId', v.renderContent);
			
			if (v.model.get('constraints')) {
				if (_.isNumber(v.model.get('constraints').batteryCharge)) {
					var chargeLevel = parseInt(v.model.get('constraints').batteryCharge, 10);
					constraints.push({
						title: stringHelper.get('constraints.batteryCharge.title'),
						message: stringHelper.get('constraints.batteryCharge.message', {chargeLevel: chargeLevel / 100})
					});
				} else if (v.model.get('constraints').overlappingBookings) {
					constraints.push({
						title: stringHelper.get('constraints.overlappingBookings.title'),
						message: stringHelper.get('constraints.overlappingBookings.message')
					});
				} else {

					require(['views/modals/prompt'], function (SuccessModal) {
						var successModal = new SuccessModal({
							title: 'Fehler',
							message: 'Die Buchung konnte nicht durchgeführt werden.',
							type: 'error'
						});
						window.myApp.view.renderView(successModal);
					});

					v.remove();
				}
			}

			if (v.model.get('addPropTypes')) {
				v.addPropTypeBlocks = [];

				v.addPropTypesCollection = v.model.getAddPropTypes();
				v.addPropTypesCollection.each(function (addPropTypeModel) {
					var addPropTypeBlock = new AddPropTypeBlock({model: addPropTypeModel});
					v.addPropTypeBlocks.push(addPropTypeBlock);
				});
			}

			bookeeImage = v.bookee.getImage();

			// check for prices.
			if (
				v.bookee.get('locality') !== 'FLOAT' &&
				v.model.has('price') &&
				v.model.get('price').priceItems.length > 0
			) {
				price = new PriceModel(v.model.get('price'));
				displayPrice = price.getCombinedPriceItem('DRIVING_TIME') ? price.getCombinedPriceItem('DRIVING_TIME').format() : null;
			}
			
			// offerPriceComputation only when allowd and in own orga
			var offerPriceComputation = null;
			if (
				_.has(bookingHelper.bookingUISettings(), 'offerPriceComputationDuringBooking') &&
				bookingHelper.bookingUISettings().offerPriceComputationDuringBooking &&
				v.model.id &&
				v.providerSettings.get('provId') === v.bookee.get('provId')
			) {
				offerPriceComputation = true;
			}
			
			templateHelper('modals/confirmBooking', {
				start: moment(v.model.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				end: moment(v.model.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				constraints: constraints.length > 0 ? constraints : null,
				bookee: v.bookee.toJSON(),
				isFixBookee: v.bookee.get('locality') === 'FIX',
				bookeeImage: bookeeImage ? bookeeImage.toJSON() : {},
				errors: v.errors,
				offerPromotionCodes: _.has(bookingHelper.bookingUISettings(), 'offerPromotionCodes') ? bookingHelper.bookingUISettings().offerPromotionCodes : null,
				price: displayPrice,
				offerPriceComputation: offerPriceComputation,
			}, function (html) {
				v.$el.removeClass('loading--overlay');
				v.$el.html(html);

				_.each(v.addPropTypeBlocks, function (addPropTypeBlock) {
					addPropTypeBlock.appendTo(v, '.modal__additionalData');
				});
				
				// fuelLevel
				var $fuel = v.$el.find('.modal__fuelLevel'),
					$fuelbar = $('<span class="fuelLevel__level" />').appendTo($fuel);
				v.listenToAndCall(v.bookee, 'change:isElectroMobile change:fuelLevel', function () {
					var level = v.bookee.get('isElectroMobile') ? v.bookee.get('fuelLevel') : null;
					$fuel
						.toggleClass('fuelLevel--hidden', !level)
						.attr('title', level ? ('Ladestand: ' + level + ' %') : '');

					$fuelbar.css('width', (level || '0') + '%');
				});
			});
		},

		createPrelimBooking: function (cb) {
			var v = this;

			v.model.save({}, {
				success: function () {
					if (_.isFunction(cb)) {
						cb();
						return;
					}
					v.renderContent();
				},
				report: function (msg, response) {
					if (
						response.status === 422 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'FIXABLE_ON_CONSTRAINTS'
					) {
						return false;
					}

					if (
						response.status === 500 &&
						response.responseJSON &&
						response.responseJSON.errorMessage &&
						response.responseJSON.errorMessage.indexOf('Member required but none found') !== -1
					) {
						return false;
					}

					if (
						response.status === 500 &&
						response.responseJSON &&
						response.responseJSON.errorMessage &&
						response.responseJSON.resultCode === 'HANDLERERROR' &&
						(
							response.responseJSON.errorMessage.indexOf('BOOKEENOTALLOWED') > -1 ||
							response.responseJSON.errorMessage.indexOf('PARALLELBOOKING') > -1 ||
							response.responseJSON.errorMessage.indexOf('BOOKINGMUSTSTARTINFUTURE') > -1
						)
					) {
						return false;
					}

					if(msg && msg.indexOf('BOOKING_NOT_AVAILABLE: POOLFULL') > -1) {
						return false;
					}
				},
				error: function (model, response) {
					if (
						response.status === 422 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'FIXABLE_ON_CONSTRAINTS'
					) {
						v.model.set('constraints', response.responseJSON.bookingConstraints);
						v.renderContent();
					}

					else if (
						response.status === 500 &&
						response.responseJSON &&
						response.responseJSON.errorMessage &&
						response.responseJSON.errorMessage.indexOf('Member required but none found') !== -1
					) {
						v.listenToOnce(DataHelper, 'auth:loggedIn', function () {
							v.createPrelimBooking();
						});

						// closing the loginView doesn't close this view. now it does. but it's an ugly solution
						_.delay(function () {
							_.each(window.myApp.view.modals, function (modal) {
								if (modal instanceof LoginView) {
									v.listenToOnce(modal, 'remove', function () {
										v.remove();
									});
								}
							});
						}, 100);

					}

					else if (
						response.status === 500 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'HANDLERERROR' &&
						response.responseJSON.errorMessage.indexOf('BOOKEENOTALLOWED') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Buchung abgebrochen',
								message: 'Das gewünschte Fahrzeug kann nicht gebucht werden.',
								type: 'error',
								cb: function () { ExternalAPI.applyHook('booking.error'); }
							})
						);

						v.remove();
					}

					else if (
						response.status === 500 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'HANDLERERROR' &&
						response.responseJSON.errorMessage.indexOf('BOOKINGMUSTSTARTINFUTURE') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Buchung abgebrochen',
								message: 'Der Buchungszeitraum muss in der Zukunft liegen.',
								type: 'error',
								cb: function () { ExternalAPI.applyHook('booking.error'); }
							})
						);

						v.remove();
					}

					else if (
						response.status === 500 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'HANDLERERROR' &&
						response.responseJSON.errorMessage.indexOf('PARALLELBOOKING') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Buchung abgebrochen',
								message: 'Sie haben zu viele Buchungen gleichzeitig durchgeführt.',
								type: 'error',
								cb: function() { ExternalAPI.applyHook('booking.error'); }
							})
						);

						v.remove();
					}

					else if (
						response.responseJSON &&
						response.responseJSON.errorMessage.indexOf('BOOKING_NOT_AVAILABLE: POOLFULL') > -1
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Fahrzeug ausgebucht',
								message: 'Bitte weichen Sie auf alternative Fahrzeuge aus…',
								type: 'error',
								cb: function () { ExternalAPI.applyHook('booking.error'); }
							})
						);

						v.remove();
					}

					else {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Bestätigung der Buchung fehlgeschlagen',
								message: (
									response && response.responseJSON && response.responseJSON.errorMessage ?
										response.responseJSON.errorMessage :
										'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
								),
								type: 'error',
								cb: function () { ExternalAPI.applyHook('booking.error'); }
							})
						);

						v.remove();
					}
				}
			});
		},

		confirm: function () {
			var v = this;
			if (!v.model.id) {
				v.createPrelimBooking(v.confirm);
				return;
			}

			v.loading(true);

			var booking = v.model.confirm();

			// set additionals
			var bookingRemark = v.$el.find('input[name="bookingRemark"]').val();
			if (bookingRemark !== '') {
				booking.set('bookingRemark', bookingRemark);
			}

			var promotionCode = v.$el.find('input[name="promotionCode"]').val();
			if (promotionCode) {
				booking.set('promotionCode', promotionCode);
			}

			// set addProps
			if (v.addPropTypeBlocks && v.addPropTypeBlocks.length > 0) {
				var addProps = [];

				// check if addProps are valid
				var allAddPropsValid = true;
				_.each(v.addPropTypeBlocks, function (addPropTypeBlock) {
					if (!addPropTypeBlock.isValid()) {
						allAddPropsValid = false;
					}
				});

				if (!allAddPropsValid) {
					v.loading(false);
					return;
				}

				// fill in booking
				_.each(v.addPropTypeBlocks, function (addPropTypeBlock) {
					var addProp = addPropTypeBlock.getAddProp();
					// check if addProp is filled;
					if (addProp.get('value') !== null && addProp.get('value') !== '') {
						addProps.push(addProp.toJSON());
					}
				});
				booking.set('addProps', addProps);
			}

			// remove expandings from url
			// there's an api bug when expanding prices on quernutzung
			// see: https://cantaloupe.cantamen.de/admin/issues/14523
			booking.url = booking.url({noParams: true});

			booking.save({}, {
				success: function () {
					v.$el.find('.confirmBooking__button').remove();
					v.remove();
					v.trigger('confirmed');

					ExternalAPI.applyHook('booking.success');

					window.myApp.view.currentBookingView.collection.fetch();

					window.myApp.subNavigate('');
					window.myApp.subNavigate('account/bookings', {trigger: true});
				},
				report: function (model, response) {
					return !(
						(
							response.status !== 422 ||
							!response.responseJSON &&
							response.responseJSON.resultCode !== 'PROMOTION_CODE_INVALID'
						) ||
						(
							response.status !== 400 ||
							!response.responseJSON &&
							response.responseJSON.resultCode !== 'BOOKING_NOT_AVAILABLE' &&
							response.responseJSON.errorMessage.indexOf('POOLFULL') < 0
						)
					);
				},
				error: function (model, response) {
					v.errors = {};
					if (
						response.status === 422 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'PROMOTION_CODE_INVALID'
					) {
						v.errors.promotionCode = true;
						v.renderContent();
						return;
					}

					if (
						response.status === 400 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'BOOKING_NOT_AVAILABLE' &&
						response.responseJSON.errorMessage.indexOf('POOLFULL') > 0
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Buchung fehlgeschlagen',
								message: 'Fahrzeug ausgebucht',
								type: 'error',
								cb: function () { ExternalAPI.applyHook('booking.error'); }
							})
						);
						return;
					}

					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Buchung fehlgeschlagen',
							message: (
								response && response.responseJSON && response.responseJSON.errorMessage ?
									response.responseJSON.errorMessage :
									'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
							),
							type: 'error',
							cb: function () { ExternalAPI.applyHook('booking.error'); }
						})
					);

					v.loading(false);
					v.remove();
				}
			});
		},
		
		computePrice: function () {
			var v = this;
			
			require(['views/modals/computePrice'], function (ComputePriceModal) {
				v.computePriceModal = new ComputePriceModal({
					bookee: v.bookee,
					model: v.model
				});

				window.myApp.view.renderView(v.computePriceModal);
			});
		},

		cancel: function () {
			var v = this;
			v.remove();
			if (v.model.has('id')) {
				v.model.destroy();
			}
		}
	});
});

