define('views/bookingArea', [
	'underscore',
	'jquery',
	'version',
	'views/_view',
	'views/currentBooking',
	'views/map',
	'views/list',
	'views/panels/filter',
	'views/panels/bookee',
	'views/panels/placeWidgetInfo',
	'views/panels/place',
	'views/modals/prompt',
	'casirest2/collections/place',
	'casirest2/collections/bookingProposal',
	'casirest2/models/place',
	'casirest2/models/bookee',
	'helpers/settings',
	'casirest2/helpers/data',
	'helpers/mapState'
], function (
	_,
	$,
	version,
	BaseView,
	CurrentBookingView,
	MapView,
	ListView,
	FilterPanelView,
	BookeePanelView,
	PlaceWidgetInfoPanelView,
	PlacePanelView,
	PromptModalView,
	PlaceCollection,
	BookingProposalCollection,
	PlaceModel,
	BookeeModel,
	SettingsHelper,
	DataHelper,
	MapStateHelper
) {
	'use strict';

	/**
	 * ContentView
	 *
	 * Renders either a MapView or a ListView
	 *
	 * @module views/bookingArea
	 * @class BookingAreaView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		className: 'bookingArea',

		_initialize: function (options) {
			var v = this;
			v.timeHelper = options.timeHelper;
			v.placeCollection = options.placeCollection || new PlaceCollection();
			v.proposalCollection = options.proposalCollection || new BookingProposalCollection();
		},

		/**
		 * Renders the BookingAreaView.
		 * Also prepares two <div /> to hold MapView und ListView
		 *
		 * @returns {BookingAreaView}
		 */
		render: function () {
			var v = this,
				endpointSettings = SettingsHelper.getEndPointSettings(),
				$version;

			// comparator for placeCollection
			v.placeCollection.comparator = 'distance';

			// MAP_WIDGET: exchange PlaceCollection expands
			v.listenToAndCall(endpointSettings, 'change:bookingInterfaceType', function () {
				if (endpointSettings.get('bookingInterfaceType') === 'MAP_WIDGET') {
					v.placeCollection.expand = ['remark', 'provider.remark'];
				} else {
					delete v.placeCollection.expand;
				}
			});

			// Change Events from MapStateHelper
			v.listenTo(MapStateHelper, 'change:lat change:lng change:zom', function () {
				v.openLocation();
			});
			v.listenTo(MapStateHelper, 'change:fbt change:flt', function () {
				v.fetch();
			});

			// Change Events from TimeHelper
			v.listenTo(v.timeHelper, 'change', v.resetProposals);

			// Login/Logout -> Reset my Proposals
			v.listenTo(DataHelper, 'auth:loggedIn auth:loggedOut', v.resetProposals);

			// Render MapView when required
			v.listenToAndCall(MapStateHelper, 'change:lim', function () {
				if (!MapStateHelper.isListMode() && !v.mapView) {
					v.mapView = new MapView({
						timeHelper: v.timeHelper,
						placeCollection: v.placeCollection,
						proposalCollection: v.proposalCollection
					}).appendTo(v, v.$bookingArea);
				}
				else if (!MapStateHelper.isListMode()) {
					v.mapView.$el.removeClass('map--hidden');
					_.defer(function () {
						v.mapView.openLocation();
					});
				}
				else if (v.mapView) {
					v.mapView.$el.addClass('map--hidden');
					v.mapView.disableGeoWatchingIfNotRequired(true);
				}
			});

			// Render ListView when required
			v.listenToAndCall(MapStateHelper, 'change:lim', function () {
				if (MapStateHelper.isListMode() && !v.listView) {
					v.listView = new ListView({
						timeHelper: v.timeHelper,
						placeCollection: v.placeCollection,
						proposalCollection: v.proposalCollection
					}).appendTo(v, v.$bookingArea);
				}
				else if (MapStateHelper.isListMode()) {
					v.listView.$el.removeClass('list--hidden');
					v.fetch(); // 16236
				}
				else if (v.listView) {
					v.listView.$el.addClass('list--hidden');
				}
			});

			// Render Version
			$version = $('<span class="bookingArea__version" />').text(version.name).appendTo(v.$el);
			v.listenToAndCall(MapStateHelper, 'change:lim', function () {
				$version.toggleClass('bookingArea__version--list', MapStateHelper.isListMode());
				$version.toggleClass('bookingArea__version--map', !MapStateHelper.isListMode());
			});

			// Initialize live() for places and proposals
			v.placeCollection.live(v, {fetch: false});
			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', function (settings) {
				if (settings.get('bookingInterfaceType') === 'MAP_WIDGET') {
					v.proposalCollection.unlive();
				} else {
					v.proposalCollection.live(v, {fetch: false});
				}
			});

			// SettingsHelper: Open default place
			if (endpointSettings.get('defaultPlace')) {
				v.openDefaultPlace();
			}
			v.listenTo(endpointSettings, 'change:defaultPlace', function () {
				if (endpointSettings.get('defaultPlace')) {
					v.openDefaultPlace();
				}
			});

			// Fetch it baby!
			_.defer(function () {
				if (!v.fetch.called) {
					v.fetch();
				}
			});

			// Loading
			v.listenToAndCall(v.placeCollection, 'request sync', function () {
				v.$el.toggleClass('bookingArea--place-loading', !!v.placeCollection.syncing);
			});

			return this;
		},

		/**
		 * Fetches data from the API by using Place- and BookingProposalCollection.
		 * This method always takes it's parameters from the GoogleMaps Object, so
		 * it's required to set the wanted center and zoom level in the map first.
		 *
		 * This method wount fire actual requests of the requested bounds are in
		 * the latest fetched ones to save requests and bandwidth.
		 *
		 * @returns {MapView}
		 */
		fetch: function () {
			/*
			 * - Wenn Karte vorhanden:
			 *    - Collections wird anhand von Bounds gefetcht
			 *    - MapStateHelper wird mit Location gefüttert
			 * - Wenn Liste (heute):
			 *    - Collection wird anhand von Standart-Location und -Radius gefetched
			 * - Wenn Liste (morgen):
			 *    - Collection wird anhand von Location paginiert abgerufen
			 *
			 *
			 * ## Wechsel:
			 *
			 * - Karte -> Liste (heute): Die Collection wird 1-zu-1 in der Liste angezeigt. Das ist nicht besonders
			 *   Performant, ist aber aufgrund der API-Limitierungen nicht sinnvoll anders zu lösen.
			 *
			 * - Karte -> Liste (morgen): Die Collection wird nach Entfernung zum Mittelpunkt sortiert (falls nicht
			 *   schon Backend-seitig so gegeben). Anschließend werden alle Einträge außer die ersten X (wobei X die
			 *   Anzahl Elemente pro Seite darstellt) aus der Collection gelöscht. Das ist wahrscheinlich notwendig,
			 *   um anschließend mit paginierten Requests fortfahren zu können.
			 *
			 * - Liste (heute) -> Karte: Die Collection kann 1-zu-1 übernommen werden.
			 *
			 * - Liste (morgen) -> Karte: Die Collection wird um den ausgewählten Standart-Radius Radius erweitert und
			 *   neu vom Server geladen. Damit sind die Elemente, die bereits in der Liste angezeigt wurden, sofort in
			 *   der Karte zu sehen; andere Elemente werden nachgeladen.
			 */

			var v = this,
				filterBookeeTypes = MapStateHelper.isFiltered() ? MapStateHelper.filterByBookeeTypeIds().slice(0) : [],
				defaultSearchRange = SettingsHelper.getProviderSettings().get('bookingUISettings').defaultSearchRange.range,
				bounds,
				extendedBounds,
				done,
				error,
				report;

			this.fetch.called = true;

			report = function (msg, response) {
				return !(
					response.status === 400 &&
					response.responseJSON &&
					response.responseJSON.resultCode === 'REQUESTED_AREA_TOO_LARGE'
				);
			};

			// TimeHelper not ready yet: try it again when helper is ready
			if (!v.timeHelper.isReady()) {
				return v.listenToOnce(v.timeHelper, 'initialized', v.fetch);
			}

			/*
			 * Get places and proposaly by location and defaultSearchRange. We can't use the bounds!
			 */
			if (MapStateHelper.isListMode()) {
				v.placeCollection.resetFilter();
				v.proposalCollection.resetFilter();

				// filters for PlaceCollection
				v.placeCollection.filterByLocation(
					MapStateHelper.getCenter(),
					defaultSearchRange
				);
				v.placeCollection.filterByTimeRange(
					v.timeHelper.get('start'),
					v.timeHelper.get('end')
				);

				// filters for ProposalCollection
				v.proposalCollection.filterByLocation(
					MapStateHelper.getCenter(),
					defaultSearchRange
				);
				v.proposalCollection.filterByTimeRange(
					v.timeHelper.get('start'),
					v.timeHelper.get('end')
				);

				// filters for BookeeTypes
				_.each(filterBookeeTypes, function (id) {
					v.proposalCollection.addFilter('bookeeTypeId', id);
				});

				if (v.fetch.lastPlaceXHR) {
					v.fetch.lastPlaceXHR.abort();
				}
				if (v.fetch.lastProposalXHR) {
					v.fetch.lastProposalXHR.abort();
				}

				done = function () {
					done.counter = done.counter || 0;
					done.counter += 1;

					if (done.counter >= 2) {
						v.$el.removeClass('loading');
						v.trigger('sync');
					}
				};
				error = function (model, response) {
					if (
						response.status === 400 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'REQUESTED_AREA_TOO_LARGE'
					) {
						done();
					}
				};

				v.fetch.lastPlaceXHR = v.placeCollection.fetch({
					success: done,
					error: error,
					report: report
				});
				v.fetch.lastProposalXHR = v.proposalCollection.fetch({
					success: done,
					error: error,
					report: report
				});

				v.fetch.lastFilterBookeeTypes = filterBookeeTypes;
				return v;
			}


			// Map has no bounds yet, but is initialized. Wait for them…
			if (!v.mapView || !v.mapView.map) {
				_.defer(v.fetch);
				return v;
			}
			bounds = v.mapView.map.getBounds();
			if (!bounds || v.openLocation.initialized) {
				v.openLocation();
				return _.defer(v.fetch);
			}

			// show loader only for visible missing data
			if (
				!v.fetch.lastBounds ||
				!v.fetch.lastBounds.contains({
					lat: bounds.getNorthEast().lat(),
					lng: bounds.getNorthEast().lng()
				}) ||
				!v.fetch.lastBounds.contains({
					lat: bounds.getSouthWest().lat(),
					lng: bounds.getSouthWest().lng()
				}) ||
				!v.fetch.lastFilterBookeeTypes ||
				(v.fetch.lastFilterBookeeTypes ? v.fetch.lastFilterBookeeTypes.length : 0) !== (MapStateHelper.isFiltered() ? filterBookeeTypes.length : 0)
			) {
				v.$el.addClass('loading');
			}

			extendedBounds = v.mapView.extendBounds(bounds, v.mapView.map.getZoom());
			v.placeCollection.resetFilter();
			v.proposalCollection.resetFilter();

			// filters for PlaceCollection
			v.placeCollection.filterByBounds(extendedBounds);
			v.placeCollection.filterByTimeRange(
				v.timeHelper.get('start'),
				v.timeHelper.get('end')
			);

			// filters for ProposalCollection
			v.proposalCollection.filterByBounds(extendedBounds);
			v.proposalCollection.filterByTimeRange(
				v.timeHelper.get('start'),
				v.timeHelper.get('end')
			);

			// filters for BookeeTypes
			_.each(filterBookeeTypes, function (id) {
				v.proposalCollection.addFilter('bookeeTypeId', id);
			});

			// only fetch when bounds or filters changed
			if (
				!v.fetch.lastBounds ||
				!v.fetch.lastBounds.contains({			// bounds should contain upper left corner
					lat: extendedBounds.getNorthEast().lat(),
					lng: extendedBounds.getNorthEast().lng()
				}) ||
				!v.fetch.lastBounds.contains({			// bounds should contain lower right corner
					lat: extendedBounds.getSouthWest().lat(),
					lng: extendedBounds.getSouthWest().lng()
				}) ||
				!v.fetch.lastFilterBookeeTypes ||
				(v.fetch.lastFilterBookeeTypes ? v.fetch.lastFilterBookeeTypes.length : 0) !== (MapStateHelper.isFiltered() ? filterBookeeTypes.length : 0)
			) {
				if (v.fetch.lastPlaceXHR) {
					v.fetch.lastPlaceXHR.abort();
				}
				if (v.fetch.lastProposalXHR) {
					v.fetch.lastProposalXHR.abort();
				}

				done = function () {
					done.counter = done.counter || 0;
					done.counter += 1;
					if (
						done.counter < 2 &&
						SettingsHelper.getEndPointSettings().get('bookingInterfaceType') !== 'MAP_WIDGET'
					) {
						v.trigger('sync');
						return;
					}

					if (!done.error) {
						v.fetch.lastBounds = extendedBounds;
					}

					if(done.counter >= 2) {
						v.$el.removeClass('loading');
						v.trigger('sync');
					}
				};
				error = function (model, response) {
					if (
						response.status === 400 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'REQUESTED_AREA_TOO_LARGE'
					) {
						done.error = true;
						done();
					}
				};

				v.fetch.lastPlaceXHR = v.placeCollection.fetch({
					success: done,
					error: error
				});

				// no proposals for MAP_WIDGET
				if (SettingsHelper.getEndPointSettings().get('bookingInterfaceType') !== 'MAP_WIDGET') {
					v.fetch.lastProposalXHR = v.proposalCollection.fetch({
						success: done,
						error: error,
						report: report
					});
				}

				v.fetch.lastFilterBookeeTypes = filterBookeeTypes;
			}

			return this;
		},

		/**
		 * Removes all fetched proposals and refetches them directly.
		 * All stationary markers will get an "?/0" till new data is
		 * fetched. Required for time range changes or login/logout
		 * events.
		 *
		 * @returns {MapView}
		 */
		resetProposals: function () {
			var v = this;

			v.proposalCollection.reset();
			v.fetch.lastBounds = null; // empty bounds cache, see fetch()

			// no proposals for MAP_WIDGET
			if (SettingsHelper.getEndPointSettings().get('bookingInterfaceType') !== 'MAP_WIDGET') {
				v.fetch();
			}
			return v;
		},

		/**
		 * Open a single location on the map. Optionally it's also possible to
		 * change the zoom level as well. Uses Google Maps panTo() to animate
		 * the movement, if the change is less than both the width and height
		 * of the map.
		 *
		 * @param {LatLng} [center] Center as GoogleMaps LatLng Object. Defaults to the current MapState center
		 * @param {Number} [zoom] Zoom Level, defaults to the current MapState value
		 * @returns {MapView}
		 */
		openLocation: function (center, zoom) {
			var v = this;

			/*
			 * If MapView is initialized and we're not in ListView, open the location by calling
			 * MapView's openLocation(), so the map navigates to the new
			 * location and the collection gets fetched afterwards…
			 */
			if (!MapStateHelper.isListMode() && v.mapView) {
				v.mapView.openLocation(center, zoom);

				if (v.mapView.map.getBounds()) {
					v.fetch();
				}
			}

			/*
			 * If there's no MapView available for now, we need to update
			 * the MapStateHelper ourselves and fetch the collection manually…
			 */
			else {
				if (center) {
					MapStateHelper.setCenter(center);
				}
				if (zoom) {
					MapStateHelper.setZoomLevel(zoom);
				}

				MapStateHelper.apply();
				v.fetch();
			}
		},

		/**
		 * Opens a place on the map / list. Will load the place if it's not
		 * in the internal collection already.
		 *
		 * @param {Number|PlaceModel} place PlaceModel or Place ID to go to
		 * @param {Function} [cb] Callback, first parameter is the PlacePanelView instance
		 * @returns {MapView}
		 */
		openPlace: function (place, cb) {
			var v = this;

			if (!(place instanceof PlaceModel)) {
				place = new PlaceModel({id: place});
			}

			/*
			 * First we check if the given place is already opened by
			 * checking the current root panel type and it's model id
			 */
			if (
				myApp.view.panels[0] &&
				myApp.view.panels[0].model instanceof PlaceModel &&
				myApp.view.panels[0].model.id === place.id
			) {
				if (_.isFunction(cb)) {
					cb(myApp.view.panels[0]);
				}
				return v;
			}

			/*
			 * If there's a panel open, it's not the required on, so it's good
			 * to close all panels. Because closing the panel enforces a route
			 * update (/), we need to update the route afterwards to the new one.
			 */
			v.closePanel();
			window.myApp.subNavigate('place/' + place.id);

			/*
			 * In case we have the asked place in our prefetched collection,
			 * use this place to make another request unnecessary…
			 */
			if (v.placeCollection.get(place.id)) {
				place = v.placeCollection.get(place.id);
			}

			/*
			 * If the place is already fetched (we try to find out by testing for
			 * the `geoPosition` attribute), we can pass to `_openPlaceFetched()`, …
			 */
			if (place.has('geoPosition')) {
				v._openPlaceFetched(place, cb);
			}

			/*
			 * … otherwise we need to fetch the place first.
			 */
			else {
				v.listenToOnce(place, 'change:geoPosition', function () {
					v.$el.removeClass('loading');
					v._openPlaceFetched(place, cb);
				});

				if (!place.syncing) {
					v.$el.addClass('loading');
					place.fetch({
						report: function (msg) {
							return msg.indexOf('No element found') !== 0;
						},
						error: function (model, $xhr) {
							console.log($xhr);
							if (
								$xhr.responseJSON &&
								$xhr.responseJSON.errorMessage &&
								$xhr.responseJSON.errorMessage.indexOf('No element found') === 0
							) {
								window.myApp.view.renderView(
									new PromptModalView({
										title: 'Station nicht gefunden',
										message: 'Die angeforderte Station konnte leider nicht gefunden werden.',
										type: 'error'
									})
								);
							}
						}
					});
				}
			}
		},

		/**
		 * Internally used to open the right panel after PlaceModel loaded
		 * sucessfully. Automatically decides between Bookee- or PlacePanel.
		 *
		 * @param {PlaceModel} place Fetched PlaceModel to use
		 * @param {Function} [cb] Callback
		 * @private
		 */
		_openPlaceFetched: function (place, cb) {
			var v = this,
				panel;

			/*
			 * If we don't have this place in our collection (so
			 * it's not within our current range), reset the list
			 * so the fetched place is the only item.
			 *
			 * Then, in case we don't have a MapView initialized,
			 * use the place's location to fetch places nearby…
			 */
			if (!v.placeCollection.get(place.id)) {
				v.placeCollection.set([place]);

				if (!v.mapView) {
					v.openLocation({
						lat: place.get('geoPosition').latitude,
						lng: place.get('geoPosition').longitude
					});
				}
			}

			/*
			 * it's possible, that another panel was opened while
			 * fetching the place. close it. that's not ideal,
			 * but we can change this later…
			 */
			v.closePanel();

			/*
			 * highlight the place on our map in case the
			 * MapView is initialized. Needs to be called
			 * after closePanel(), otherwise the highlight
			 * is destroyed with closePanel() again…
			 */
			if (v.mapView) {
				v.mapView.highlightPlace(place);
			}


			/*
			 * If place is a freefloating place and there's only
			 * one bookee type in there, open a BookeePanelView…
			 */
			if (
				!place.get('isFixed') &&
				(
					place.get('bookees').length === 1 ||
					_.chain(place.get('bookees')).groupBy('bookeeTypeId').size().value() === 1
				)
			) {
				panel = new BookeePanelView({
					model: new BookeeModel(place.get('bookees')[0]),
					proposalCollection: v.proposalCollection,
					timeHelper: v.timeHelper,
					placeModel: place,
					renderCallback: cb
				});
			}

			/*
			 * If we are in Widget Mode, open a
			 * PlaceWidgetInfoPanelView instead…
			 */
			else if (SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET') {
				panel = new PlaceWidgetInfoPanelView({
					model: place,
					timeHelper: v.timeHelper,
					renderCallback: cb
				});
			}

			/*
			 * Otherwise, we open the default PlacePanelView
			 */
			else {
				panel = new PlacePanelView({
					model: place,
					proposalCollection: v.proposalCollection,
					timeHelper: v.timeHelper,
					renderCallback: cb
				});
			}

			window.myApp.view.renderView(panel);


			/*
			 * If the bookingInterfaceType changes, close
			 * the opened panel as it it invalid now…
			 */
			v.listenToOnce(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', function () {
				panel.remove();
			});

			// remove selected marker on panel close

			/*
			 * On panel close, remove place's highlight
			 * and update the url…
			 */
			v.listenToOnce(panel, 'remove', function () {
				if (v.mapView) {
					v.mapView.unhighlightPlace();
				}

				if (location.hash.indexOf('/place/' + place.id) >= 0) {
					window.myApp.subNavigate('');
				}
			});
		},

		/**
		 * Open the PlaceGemoetry in Map or List. Will use the location
		 * for the list and the geometry's viewport for the MapView.
		 *
		 * @param {google.maps.places.PlaceGeometry} placeGeometry
		 */
		openPlaceGeometry: function (placeGeometry) {
			var v = this;
			if (!MapStateHelper.isListMode() && v.mapView) {
				if (/Android [4-6]/.test(navigator.appVersion)) {
					// https://cantaloupe.cantamen.de/admin/issues/15570
					_.delay(function () {
						v.mapView.map.fitBounds(placeGeometry.viewport);
					}, 500);
				} else {
					v.mapView.map.fitBounds(placeGeometry.viewport);
				}
			} else {
				v.openLocation(placeGeometry.location);
			}
		},

		/**
		 * Opens the configured default place.
		 * If place is not available or not set, nothing happens.
		 *
		 * @returns {MapView}
		 */
		openDefaultPlace: function () {
			var v = this,
				place = SettingsHelper.getEndPointSettings().getDefaultPlace();

			if (!place) {
				return v;
			}

			v.listenToOnce(place, 'sync', function () {
				v.openPlace(place);
			});

			place.fetch();
			return v;
		},

		/**
		 * Opens a bookee on the map / list. Will load the bookee if it's not
		 * in the internal collection already.
		 *
		 * @param {Number|BookeeModel} bookee BookeeModel or Bookee ID to go to
		 * @param {Function} [cb] Callback
		 * @returns {MapView}
		 */
		openBookee: function (bookee, cb) {
			var v = this;

			if (!(bookee instanceof BookeeModel)) {
				bookee = new BookeeModel({id: bookee});
			}

			/*
			 * First we check if the given bookee is already opened by
			 * checking the current root panel type and it's model id
			 */
			if (
				myApp.view.panels[0] &&
				myApp.view.panels[0].model instanceof BookeeModel &&
				myApp.view.panels[0].model.id === bookee.id
			) {
				if (_.isFunction(cb)) {
					cb(myApp.view.panels[0]);
				}
				return v;
			}

			/*
			 * If there's a panel open, it's not the required on, so it's good
			 * to close all panels.
			 */
			v.closePanel();

			/*
			 * In case we have the asked bookee in our prefetched place collection,
			 * use this bookee to make another request unnecessary…
			 */
			v.placeCollection.each(function (place) {
				if (place.getBookees().get(bookee.id)) {
					bookee = place.getBookees().get(bookee.id);
				}
			});

			/*
			 * If the bookee is already fetched (we try to find out by testing for
			 * the `geoPosition` attribute), we can pass to `_openBookeeFetched()`, …
			 */
			if (bookee.has('place') && bookee.get('place').geoPosition) {
				v._openBookeeFetched(bookee, cb);
			}

			/*
			 * … otherwise we need to fetch the bookee first.
			 */
			else {
				v.listenToOnce(bookee, 'change:place', function () {
					v.$el.removeClass('loading');
					v._openBookeeFetched(bookee, cb);
				});

				if (!bookee.syncing) {
					v.$el.addClass('loading');
					bookee.expand.push('place');

					bookee.fetch({
						report: function (msg) {
							return msg.indexOf('No element found') !== 0;
						},
						error: function (model, $xhr) {
							if (
								$xhr.responseJSON &&
								$xhr.responseJSON.errorMessage &&
								$xhr.responseJSON.errorMessage.indexOf('No element found') === 0
							) {
								window.myApp.view.renderView(
									new PromptModalView({
										title: 'Fahrzeug nicht gefunden',
										message: 'Das angeforderte Fahrzeug konnte leider nicht gefunden werden.',
										type: 'error'
									})
								);
							}
						}
					});
				}
			}
		},

		/**
		 * Internally used to open the right panel after BookeeModel loaded
		 * sucessfully.
		 *
		 * @param {BookeeModel} bookee Fetched BookeeModel to use
		 * @param {Function} [cb] Callback
		 * @private
		 */
		_openBookeeFetched: function (bookee, cb) {
			var v = this,
				panel;

			/*
			 * Reset maps location to the bookee's one
			 */
			v.openLocation({
				lat: bookee.get('place').geoPosition.latitude,
				lng: bookee.get('place').geoPosition.longitude
			});

			/*
			 * It's possible, that another panel was opened while
			 * fetching the bookee. close it. that's not ideal,
			 * but we can change this later…
			 */
			v.closePanel();

			/*
			 * Unhighlight all places on our map in case the
			 * MapView is initialized.
			 */
			if (v.mapView) {
				v.mapView.unhighlightPlace();
			}


			/*
			 * Render BookeePanelView
			 */
			panel = new BookeePanelView({
				model: bookee,
				proposalCollection: v.proposalCollection,
				timeHelper: v.timeHelper,
				placeModel: new PlaceModel(bookee.get('place')),
				renderCallback: cb
			});

			window.myApp.view.renderView(panel);
			window.myApp.subNavigate('place/' + bookee.get('place').id + '/' + bookee.id);


			/*
			 * If the bookingInterfaceType changes, close
			 * the opened panel as it it invalid now…
			 */
			v.listenToOnce(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', function () {
				panel.remove();
			});

			/*
			 * On panel close, remove place's highlight
			 * and update the url…
			 */
			v.listenToOnce(panel, 'remove', function () {
				if (v.mapView) {
					v.mapView.unhighlightPlace();
				}

				if (location.hash.indexOf('/place/' + bookee.get('place').id) >= 0) {
					window.myApp.subNavigate('');
				}
			});
		},

		/**
		 * Asks the browser for the current geolocation and compass data and
		 * visualize this data in our map.
		 *
		 * @param {function} [cb] optional Callback
		 * @returns {BookingAreaView}
		 */
		openMyPosition: function (cb) {
			var v = this;

			if (MapStateHelper.isListMode() && v.listView) {
				v.listView.openMyPosition(cb);
			}
			else if (!MapStateHelper.isListMode() && v.mapView) {
				v.mapView.openMyPosition(cb);
			}

			return v;
		},

		/**
		 * Closes all panels which might be open…
		 *
		 * @returns {MapView}
		 */
		closePanel: function () {
			var v = this,
				openedRootPanel = window.myApp.view.getOpenedPanel(0);

			if (v.mapView) {
				v.mapView.unhighlightPlace();
			}
			if (openedRootPanel) {
				openedRootPanel.remove();
			}

			return this;
		},

		/**
		 * Opens the FilterPanelView
		 */
		openFilter: function () {
			var v = this,
				view = new FilterPanelView(),
				done = function () {
					window.myApp.subNavigate('', {trigger: true});
				};

			window.myApp.subNavigate('filter');
			window.myApp.view.renderView(view, {isRoot: true});

			v.listenToOnce(view, 'remove', done);
			v.listenToOnce(window.myApp, 'route', function () {
				v.stopListening(view, 'remove', done);
				view.remove();
			});
		}
	});
});

