define('casirest2/models/customer',[
	'underscore',
	'./_',
	'./customerSettings',
	'../collections/accessMedia',
	'../helpers/data'
], function (
	_,
	Model,
	CustomerSettingsModel,
	AccessMediaCollection,
	DataHelper
) {
	'use strict';

	/**
	 * @class CustomerModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/customers',

		getSettings: function (options) {
			return new CustomerSettingsModel({
				customerId: this.id,
				providerId: options.provId
			});
		},

		/**
		 * @param {BaseView} [view]
		 * @returns {AccessMediaCollection}
		 */
		getAccessMedias: function (view) {
			return this._buildSubCollection({
				collection: AccessMediaCollection,
				attribute: 'accessMedias',
				view: view
			});
		},

		/**
		 * Sets the user's password.
		 *
		 * @param {object} options
		 * @param {string} options.oldPassword Old password (required for authentication)
		 * @param {string} options.newPassword New password to use
		 * @param {function} cb Callback
		 * @returns {DataHelperXHR}
		 */
		setPassword: function(options, cb) {
			return DataHelper.ajax({
				type: 'POST',
				url: '/customers/' + this.id + '/passwords',
				data: _.omit(options, 'newPasswordRepeat'),
				success: function () {
					cb(null);
				},
				error: function () {
					cb(true);
				}
			});
		}
	});
});

