define('templates/appUpdater',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/appUpdater"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<h2 class=\"appUpdater__headline\">Neue Version verfügbar!</h2>\n<p class=\"appUpdater__text\">Die Buchungs&shy;anwendung wurde im Hintergrund für Sie aktualisiert.</p>\n<button class=\"appUpdater__button button\">Jetzt neu laden</button>";
},"useData":true});

return this["JST"];

});
