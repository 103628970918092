define('views/blocks/unavailabilityBookee', [
	'jquery',
	'underscore',
	'moment',
	'views/_view',
	'views/blocks/unavailabilityDay',
	'views/modals/confirmBooking',
	'casirest2/helpers/data',
	'views/modals/login'
], function (
	$,
	_,
	moment,
	View,
	UnavailabilityDayBlockView,
	ConfirmBookingView,
	DataHelper,
	LoginModalView
) {
	'use strict';

	/**
	 * UnavailabilityBookeeBlock
	 *
	 * @module views/blocks/unavailabiliyBookee
	 * @class UnavailabilityBookeeBlock
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'unavailability__bookee',
		events: {
			'click .unavailability__addday': 'addDay'
		},

		lastDay: null,
		days: 0,

		_initialize: function (options) {
			this.timeHelper = options.timeHelper;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {UnavailabilityBookeeBlock}
		 */
		render: function () {
			var v = this;

			v.listenToAndCall(v.model, 'change:displayName', function() {
				v.$title = $('<h2 class="unavailability__title unavailability__title--expanded" />')
					.text(v.model.get('displayName'))
					.click(function () {
						v.$title.toggleClass('unavailability__title--expanded');
						v.$container.stop().slideToggle(200);
					})
					.on('touchstart', function () {
						v.$title.addClass('unavailability__title--touched');
					})
					.appendTo(v.$el);
				v.listenToAndCall(v.model, 'change:bookeeType change:licenseNumber', function () {
					var meta = v.model.get('bookeeType').name;
					if (v.model.has('licenseNumber')) {
						meta += ' (' + v.model.get('licenseNumber') + ')';
					}
					v.$title.append($('<div class="unavailability__info" />').text(meta));
					
					v.$title.toggleClass('unavailability__title--cargowheel', v.model.isCargoWheel());
				});
			});
			
			// Use container for `slideToggle` title
			v.$container = $('<div class="unavailability__container" />').appendTo(v.$el);
			v.$range = $('<span class="unavailability__bookee__range" />').text('–').appendTo(v.$container);
			v.$days = $('<div class="unavailability__bookee__days" />').appendTo(v.$container);
			v.days = 0;

			$('<button class="button button--small unavailability__addday" />')
				.text('+ 1 Tag')
				.appendTo(v.$container);
			$('<button class="button unavailability__booking" />')
				.text('Jetzt Buchen')
				.click(function () {
					v.createPrelimBooking();
				})
				.appendTo(v.$container);
			

			v.addDay();
			if(moment().hours() >= 18) {
				v.addDay();
			}

			return v;
		},

		addDay: function () {
			var v = this;

			if(!v.days) {
				v.lastDay = moment().startOf('day');
			}else{
				v.lastDay.add(1, 'd');
			}

			v.days += 1;

			// update range
			v.updateRangeText();

			// add day
			new UnavailabilityDayBlockView({
				model: v.model,
				moment: moment(v.lastDay),
				timeHelper: v.timeHelper
			}).appendTo(v.$days, v);
		},

		updateRangeText: function() {
			var v = this,
				now = moment(),
				then = moment().add(v.days - 1, 'd'),
				text = '';

			if(!now.isSame(then, 'day')) {
				text += now.format('D.');

				if(!now.isSame(then, 'month')) {
					text += now.format(' MMM');
				}
				if(!now.isSame(then, 'year')) {
					text += now.format(' YYYY');
				}

				text += '&thinsp;–&thinsp;';
			}
			text += then.format('D. MMM YYYY');

			v.$range.html(text);
			return v;
		},
		
		createPrelimBooking: function () {
			var v = this;
			
			/**
			 * render LoginView if not already logged in.
			 */
			if(!DataHelper.isLoggedIn()) {
				var login = new LoginModalView();
				window.myApp.view.renderView(login);

				v.listenToOnce(login, 'remove', function() {
					if(DataHelper.isLoggedIn()) {
						v.createPrelimBooking();
					}
				});

				return v;
			}
			
			/**
			 * render confirmBookingView
			 */
			var confirmBookingView = new ConfirmBookingView({
				bookee: v.model,
				timeHelper: v.timeHelper
			});
			window.myApp.view.renderView(confirmBookingView);
		}
	});
});

