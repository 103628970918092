define('casirest2/models/bookeeType',['underscore', './_'], function (_, Model) {
	'use strict';

	/**
	 * @class BookeeTypeModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/bookeetypes',
		expand: []
	});
});

