define('casirest2/models/_',[
	'backbone',
	'underscore',
	'../collections/_',
	'../helpers/data',
	'../helpers/subscription'
], function (Backbone, _, Collection, dataHelper, SubscriptionHelper) {
	'use strict';

	/**
	 * @class BaseModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Backbone.Model.extend({
		idAttribute: 'id',
		expand: [],
		activeFilters: [],
		liveInterval: 30000,

		sync: dataHelper.sync,

		initialize: function (attributes, options) {
			var m = this;

			for (var i in m) {
				if (typeof m[i] === 'function' && !Backbone.Model.prototype[i]) {
					_.bindAll(m, i);
				}
			}

			if(_.isFunction(m._initialize)) {
				m._initialize(attributes, options);
			}
		},
		url: function (options) {
			var url = '' + this.urlRoot,
				queryParams = [],
				queryStrings = [];
			options = options || {};

			if (!this.id && (!this.isAlwaysSingle || options.isAlwaysSingle) && !this.activeFilters) {
				return url;
			}

			// expands
			_.each(this.expand, function (expand) {
				queryParams.push(['expand', expand]);
			});

			// filters
			if(this.activeFilters) {
				_.each(this.activeFilters, function (filter) {
					queryStrings.push(filter);
				});
			}

			if(!this.isAlwaysSingle && this.id) {
				url += '/' + this.id;
			}
			if ((!queryParams.length && !queryStrings) || options.noParams) {
				return url;
			}

			_.each(queryParams, function (p) {
				queryStrings.push(
					encodeURIComponent(p[0]) + '=' + encodeURIComponent(p[1])
				);
			});

			url += '?' + queryStrings.join('&');
			return url;
		},
		addFilter: function (key, value) {
			var c = this,
				s;

			if (key && _.isArray(value)) {
				_.each(value, function (v) {
					c.addFilter(key, v);
				});

				return c;
			}
			else if (_.isObject(key)) {
				_.each(key, function (v, k) {
					c.addFilter(k, v);
				});

				return c;
			}

			if (
				typeof value.milliseconds === 'function' &&
				typeof value.toJSON === 'function'
			) {
				value = value.toJSON();
			}

			s = encodeURIComponent(key) + '=' + encodeURIComponent(value);
			if (_.indexOf(c.activeFilters, s) === -1) {
				c.activeFilters.push(s);
			}

			return c;
		},
		resetFilter: function () {
			this.activeFilters = [];
			return this;
		},
		live: function (view, options) {
			SubscriptionHelper.live(this, view, options);
		},
		unlive: function() {
			SubscriptionHelper.unlive(this);
		},
		_buildSubCollection: function (options) {
			if (!options.collection || !(options.collection.prototype instanceof Collection)) {
				throw new Error('`options.collection` has to be a proper collection!');
			}

			if (options.attribute) {
				return this._buildSubCollectionByAttribute(options);
			}
			else if (options.url) {
				return this._buildSubCollectionByURL(options);
			}
			else {
				throw new Error('Either `options.attribute` or `options.url` must be set to build SubCollection!');
			}
		},
		_buildSubCollectionByAttribute: function (options) {
			var m = this,
				data = m.get(options.attribute) || [],
				collection;

			if(options.filter) {
				data = _.filter(data, options.filter);
			}

			collection = new options.collection(data);

			// live binding
			if(options.view) {
				options.view.listenTo(m, 'change:' + options.attribute, function() {
					var data = m.get(options.attribute) || [];
					if(options.filter) {
						data = _.filter(data, options.filter);
					}
					collection.set(data);
				});
				options.view.listenTo(collection, 'update reset', function() {
					if(options.filter) {
						return;
					}

					var data = collection.toJSON();
					m.set(options.attribute, data);
				});
			}

			// save()
			collection.save = function(attributes, options) {
				if(attributes) {
					throw new Error('save() `attributes` is unsupported for collections build with _buildSubCollectionByAttribute(), sorry…');
				}

				m.set(options.attribute, collection.toJSON());
				return m.save({}, options || {});
			};

			return collection;
		},
		_buildSubCollectionByURL: function (options) {
			var collection = new options.collection();
			collection.urlRoot = _.result(options, 'url');
			return collection;
		}
	});
});

