define('casirest2/models/customerSettings',[
	'./_'
], function (Model) {
	'use strict';

	/**
	 * @class CustomerSettingsModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/customers',

		initialize: function (options) {
			if (_.isObject(options) && options.customerId) {
				this.url = '/customers/' + options.customerId + '/settings';
			}
		}
	});
});

