define('views/modals/bookingDetails', [
	'underscore',
	'jquery',
	'moment',
	'helpers/template',
	'views/_modal'
], function (
	_,
	$,
	moment,
	templateHelper,
	ModalBaseView
) {
	'use strict';

	/**
	 * BookingDetailsModalView
	 *
	 * @module views/modals/bookingDetails
	 * @class BookingDetailsModalView
	 * @augments ModalBaseView
	 */
	var BookingDetailsModalView = ModalBaseView.extend({
		modalName: 'bookingDetails',
		modalEvents: {
			'click button.printButton': 'print'
		},
		modalModifier: ['printable'],

		/**
		 * Render method
		 * @returns {BookingDetailsModalView}
		 */
		render: function () {
			var v = this,
				bookeeImage;

			if (!v.model.id) {
				return v;
			}

			bookeeImage = v.model.getImage();
			templateHelper('modals/bookingDetails', {
				start: moment(v.model.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				end: moment(v.model.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				bookee: v.model.get('bookee'),
				booking: v.model.toJSON(),
				isFixBookee: v.model.get('bookee').locality === 'FIX',
				bookeeImage: bookeeImage ? bookeeImage.toJSON() : {}
			}, function (html) {
				v.$el.html(html);
			});

			return v;
		},
		print: function () {
			window.print();
		}
	});

	return BookingDetailsModalView;
});

