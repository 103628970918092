define('views/account/customerBookings-current', [
	'underscore',
	'views/_account',
	'helpers/template',
	'helpers/string',
	'casirest2/collections/booking',
	'views/blocks/booking'
], function (
	_,
	AccountBaseView,
	templateHelper,
	stringHelper,
	BookingCollection,
	BookingBlock
) {
	'use strict';

	return AccountBaseView.extend({
		className: 'customerBookings__group customerBookings__group--current customerBookings__group--hidden',

		_initialize: function (options) {
			var v = this;
			v.collection = options.collection || new BookingCollection();
			v.collection.comparator = function (model) {
				return new Date(model.get('timeRange').start).getTime();
			};

			v.bookingBlocks = [];
		},

		render: function () {
			var v = this;

			templateHelper('account/customerBookings', {
				headline: stringHelper.get('account.customerBookings.bookings.current')
			}, function (html) {
				v.$el.html(html);

				v.collection.each(v.addBooking);
				v.listenTo(v.collection, 'add', v.addBooking);
				
				v.on('change:block', function () {
					v.$el.toggleClass('customerBookings__group--hidden', _.isEmpty(v.bookingBlocks));
				});
				
				
			});
		},

		addBooking: function (booking) {
			var v = this;
		
			// check for booking changes.
			v.listenToAndCall(booking, 'change:changeInfo', function() {
				if (booking.isCurrent() && !v.bookingBlocks[booking.id]) {
					v.renderBooking(booking);
				}
			});
			
			return v;
		},
		
		renderBooking: function(booking) {
			var v = this,
				view;
			
			view = new BookingBlock({model: booking});
			view.appendTo(v, v.$el.find('.bookings__list'), { order: true });
			
			v.bookingBlocks.push(booking.id);
			v.trigger('change:block');
			
			v.listenTo(v.collection, 'remove', function (removedBooking) {
				if(removedBooking.id === booking.id) {
					view.remove();
					var index = v.bookingBlocks.indexOf(booking.id);
					if (index > -1) {
						v.bookingBlocks.splice(index, 1);
					}
					v.trigger('change:block');
				}
			});
			
			v.listenTo(booking, 'change:changeInfo', function() {
				if (!booking.isCurrent()) {
					view.remove();
					var index = v.bookingBlocks.indexOf(booking.id);
					if (index > -1) {
						v.bookingBlocks.splice(index, 1);
					}
					v.trigger('change:block');
				}
			});
		}
	});
});

