define('views/blocks/addPropType', [
	'underscore',
	'views/_view',
	'helpers/template',
	'casirest2/models/addProp'
], function (
	_,
	View,
	templateHelper,
	AddPropModel
) {
	'use strict';

	/**
	 * AddPropTypeBlockView
	 *
	 * @module views/blocks/addPropType
	 * @class AddPropTypeBlockView
	 * @augments BaseView
	 */
	return View.extend({
		className: 'addPropType',

		/**
		 * Renders the item.
		 *
		 * @returns {AddPropTypeBlockView}
		 */
		render: function () {
			var v = this;
			
			templateHelper('blocks/addPropType', {
				id: v.model.id,
				name: v.model.get('name'),
				desc: v.model.get('description'),
				obligatory: v.model.get('obligatory'),
				type: {
					checkbox: v.model.get('dataType') === 'BOOLEAN',
					text: v.model.get('dataType') === 'STRING',
					select: v.model.get('dataType') === 'COMBO'
				},
				options: v.model.get('comboValues') || null
			}, function (html) {
				v.$el.html(html);
			});
		},
		
		isValid: function () {
			var v = this,
				isValid,
				val;
			
			if (v.model.get('obligatory') === true) {
				val = v.$el.find('#addProp_' + v.model.id).val();
				isValid = val !== '' && val !== null;
			} else {
				return true;
			}
			
			v.$el.find('.field').toggleClass('field__error', !isValid);
			return isValid;
		},
		
		getAddProp: function () {
			var v = this,
			value = v.model.get('dataType') === 'BOOLEAN' ? v.$el.find('#addProp_' + v.model.id).prop('checked') : v.$el.find('#addProp_' + v.model.id).val();
			
			if (value === '') {
				value = null;
			}
			
			return new AddPropModel({
				value: value,
				addPropTypeId: v.model.id
			});
		}

	});
});

