define('views/_modal', [
	'underscore',
	'jquery',
	'views/_view'
], function (
	_,
	$,
	View
) {
	'use strict';

	/**
	 * @module views/_modal
	 * @class ModalBaseView
	 * @augments BaseView
	 */
	return View.extend({
		mainEvents: {
			'click button.button--close': 'remove'
		},

		events: function () {
			return _.extend({}, this.mainEvents, this.modalEvents);
		},

		/**
		 * @param {Object} [options]
		 * @returns {ModalBaseView}
		 */
		_initialize: function (options) {
			var v = this;

			if (_.isFunction(v.__initialize)) {
				v.__initialize(options || {});
			}

			$('body').on('keyup', v.checkEscape);
			v.once('remove', function () {
				$('body').off('keyup', v.checkEscape);
			});

			return v;
		},

		loading: function (toggle) {
			var v = this;
			v.$loadingFrame = v.$loadingFrame || v.$el.find('.modal__frame');

			if (v.$loadingFrame.length === 0) {
				v.$loadingFrame = v.$el;
			}


			v.$loadingFrame.toggleClass('loading', toggle);
		},

		className: function () {
			var v = this;
			var classNames = [];

			// modalModifiers
			if (v.modalModifier) {
				if (_.isArray(v.modalModifier)) {
					_.each(v.modalModifier, function (modalModifier) {
						classNames.push('modal--' + modalModifier);
					});
				} else {
					classNames.push('modal--' + v.modalModifier);
				}
			}

			// modalNames as main classes
			if (v.modalName) {
				if (_.isArray(v.modalName)) {
					_.each(v.modalName, function (modalName) {
						classNames.push('modal' + modalName);
					});
				} else {
					classNames.push('modal' + v.modalName);
				}
			}

			return classNames.length ? 'modal ' + classNames.join(' ') : 'modal';
		},

		checkEscape: function (e) {
			if (e.keyCode === 27) {
				this.remove();
			}
		}
	});
});

