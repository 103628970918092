define('views/blocks/timerange', [
	'underscore',
	'views/_panel',
	'helpers/template',
	'helpers/time',
	'helpers/booking',
	'views/blocks/datetimePicker',
	'moment'
], function (
	_,
	View,
	templateHelper,
	TimeHelper,
	bookingHelper,
	DatetimePickerBlockView,
	moment
) {
	'use strict';

	/**
	 * TimeRangeBlockView
	 *
	 * @module views/blocks/timerange
	 * @class TimeRangeBlockView
	 * @augments PanelBaseView
	 */
	return View.extend({
		className: 'timerange',

		/**
		 * Constructor of this view.
		 * Just saves the options we need later.
		 *
		 * @returns TimeRangeBlockView
		 */
		_initialize: function (options) {
			this.options = options;

			if (this.options.start && this.options.end) {
				this.start = this.options.start || null;
				this.end = this.options.end || null;
				this.startMin = this.options.startMin || null;
				this.endMin = this.options.endMin || null;
				this.max = this.options.max || null;

				this.timeHelper = new TimeHelper();
				this.timeHelper.initialize();

				this.timeHelper.set('start', this.start);
				this.timeHelper.set('end', this.end);
				if (this.startMin) {
					this.timeHelper.set('startMin', this.startMin);
				}
				if (this.endMin) {
					this.timeHelper.set('endMin', this.endMin);
				}
				if (this.max) {
					this.timeHelper.set('max', this.max);
				}


			} else {
				this.timeHelper = options.timeHelper || new TimeHelper();
			}

			return this;
		},

		/**
		 * Renders the item and adds the event listeners.
		 *
		 * @returns {TimeRangeBlockView}
		 */
		render: function () {
			var v = this;
			v.isActive = false;

			templateHelper('blocks/timerange', function (html) {
				v.$el.html(html);


				v.startDatetimePickerBlock = new DatetimePickerBlockView({
					model: v.timeHelper.get('start'),
					name: 'start',
					label: 'Fahrtbeginn',
					step: v.timeHelper.get('step').as('minutes'),
					min: v.timeHelper.get('startMin'),
					max: v.timeHelper.get('max'),
					headline: 'startHeadline'
				});
				v.startDatetimePickerBlock.appendTo(v, '.timerange__picker--start');

				v.endDatetimePickerBlock = new DatetimePickerBlockView({
					model: v.timeHelper.get('end'),
					name: 'end',
					label: 'Fahrtende',
					step: v.timeHelper.get('step').as('minutes'),
					min: v.timeHelper.get('endMin'),
					max: v.timeHelper.get('max'),
					headline: 'endHeadline'
				});
				v.endDatetimePickerBlock.appendTo(v, '.timerange__picker--end');

				v.listenTo(v.startDatetimePickerBlock, 'change', function () {
					v.updateDates(v.startDatetimePickerBlock.model, null);
				});
				v.listenTo(v.endDatetimePickerBlock, 'change', function () {
					v.updateDates(null, v.endDatetimePickerBlock.model);
				});

				v.listenTo(v.timeHelper, 'change', v.updateValues);

				// pickerFocus
				// v.listenTo(v.startDatetimePickerBlock, 'pickerFocus', function () {
				// 	v.trigger('pickerFocus');
				// });
				// v.listenTo(v.endDatetimePickerBlock, 'pickerFocus', function () {
				// 	v.trigger('pickerFocus');
				// });
				
				// pickerFocus
				v.listenTo(v.startDatetimePickerBlock, 'active', function (active) {
					if(active === true) {
						v.active(true);
						v.endDatetimePickerBlock.active(false);
					}
					if (active === false && v.endDatetimePickerBlock.isActive === false) {
						v.active(false);
					}
				});
				v.listenTo(v.endDatetimePickerBlock, 'active', function (active) {
					if(active === true) {
						v.active(true);
						v.startDatetimePickerBlock.active(false);
					}
					if (active === false && v.startDatetimePickerBlock.isActive === false) {
						v.active(false);
					}
				});

				// check if too much time has passed, than update the picker
				setInterval(function () {
					if (v.timeHelper.round(moment(), 'floor').isAfter(v.startDatetimePickerBlock.model)) {
						v.updateDates(moment(), null);
					}
				}, 60000);
			});

			return v;

		},

		updateDates: function (start, end) {
			var v = this;
			v.timeHelper.update(start, end);
		},

		updateValues: function () {
			var v = this;
			v.startDatetimePickerBlock.trigger('update', {
				model: v.timeHelper.get('start'),
				min: v.timeHelper.get('startMin'),
				max: v.timeHelper.get('max'),
				step: v.timeHelper.get('step').as('minutes')
			});
			v.endDatetimePickerBlock.trigger('update', {
				model: v.timeHelper.get('end'),
				min: v.timeHelper.get('endMin'),
				max: v.timeHelper.get('max'),
				step: v.timeHelper.get('step').as('minutes')
			});
		},

		getValues: function () {
			var v = this;

			return {
				start: v.timeHelper.get('start'),
				end: v.timeHelper.get('end')
			};
		},
		
		active: function (toggle) {
			var v = this;
			
			if (toggle === v.isActive) {
				return v.isActive;
			}
			v.isActive = _.isBoolean(toggle) ? toggle : !v.isActive;
			v.$el.toggleClass(v.className + '--active', v.isActive);
			v.trigger('active', v.isActive);
			
			// close all children, when this is closed
			if (v.isActive === false) {
				v.startDatetimePickerBlock.active(false);
				v.endDatetimePickerBlock.active(false);
			}
			return v.isActive;
		}
	});
});

