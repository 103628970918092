define('casirest2/models/token',[
	'./_',
	'../helpers/store'
], function (Model, storeHelper) {
	'use strict';

	/**
	 * @class TokenModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/tokens',
		expand: [],

		_initialize: function () {
			var m = this;

			m.load();

			m.on('sync change:id', function () {
				storeHelper.set('auth', {
					id: m.id,
					till: m.get('till'),
					duration: m.get('duration')
				});
			});
		},

		load: function () {
			this.set(storeHelper.get('auth'));
			this.trigger('sync');
		}
	});
});

