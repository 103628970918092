define('views/blocks/morePanelEntry', [
	'underscore',
	'jquery',
	'views/_view'
], function (
	_,
	$,
	View
) {
	'use strict';

	/**
	 * MorePanelEntryView
	 *
	 * @module views/blocks/morePanelEntry
	 * @class MorePanelEntryView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		tagName: 'li',
		className: 'more__entry',

		/**
		 * @returns {MorePanelEntryView}
		 */
		render: function () {
			var v = this,
				$a = $('<a class="more__link" />').appendTo(v.$el);

			v.listenToAndCall(v.model, 'change:id', function() {
				$a.attr('href', '#' + myApp.buildRoute('more/' + v.model.id));
			});

			v.listenToAndCall(v.model, 'change:name', function() {
				$a.text(v.model.get('name'));
			});

			return v;
		}
	});
});

