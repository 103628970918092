define('views/blocks/currentBooking', [
	'underscore',
	'views/_view',
	'moment',
	'views/blocks/booking',
	'helpers/string',
	'helpers/template'
], function (
	_,
	View,
	moment,
	BookingBlockView,
	stringHelper,
	templateHelper
) {
	'use strict';

	return View.extend({
		tagName: 'li',
		className: 'currentBookings__item',

		events: {
			'click .currentBookings__button--starttrip': 'startTrip',
			'click .currentBookings__button--endtrip': 'endTrip',
			'click .currentBookings__button--reopen': 'reOpen',
			'click .currentBookings__button--cancel': 'cancel',
			'click .currentBookings__button--stop': 'stop',
			'click .currentBookings__button--enlarge': 'enlarge',
			'click .currentBookings__button--details': 'openPlace'
		},

		render: function () {
			this.listenToAndCall(this.model, 'change:changeInfo', this.renderContent);
		},
		renderContent: function () {
			var v = this,
				now = moment(),
				changePossibilities = (v.model.get('changeInfo') || {}).changePossibilities || [],
				d = {model: v.model.toJSON(), actions: []},
				field;

			// d.time
			d.time = {
				field: v.model.getBookee().get('locality') === 'FLOAT' ? 'start' : (moment(v.model.get('timeRange').start).isAfter(now) ? 'start' : 'end')
			};

			d.label = stringHelper.get('currentBooking.time.label.' + d.time.field);
			field = moment(v.model.get('timeRange')[d.time.field]);
			d.time.shouldShowDate = field.isSame(now, 'day');

			_.each(['date', 'time'], function (attr) {
				d.time[attr] = field.format(
					stringHelper.get('currentBooking.time.format.' + attr)
				);
			});


			// d.actions
			_.each([
				['STARTABLE', 'starttrip'],
				['ENDABLE', 'endtrip'],
				['REOPENABLE', 'reopen'],
				['CANCEL', 'cancel'],
				['STOP', 'stop'],
				['ENLARGE', 'enlarge', false]
			], function (action) {
				
				if (
					(
						_.indexOf(changePossibilities, action[0]) === -1
					) || (
						// Flex darf nicht: ENLARGE
						v.model.getBookee().get('locality') === 'FLEX' && action[2] === false
					) || (
						// Flex darf manchmal: STOP (14638)
						v.model.getBookee().get('locality') === 'FLEX' && action[0] === 'STOP' && !(_.indexOf(changePossibilities, 'STOP') !== -1 && _.indexOf(changePossibilities, 'STARTABLE') !== -1)
					) || (
						// ENDABLE ? = no STOP via currentBooking, only /account/bookings
						action[0] === 'STOP' && _.indexOf(changePossibilities, 'ENDABLE') !== -1
					)
				) {
					return;
				}
				
				var label = '';
				// optional "Tür" in front of "auf/abschließen"
				if ((action[0] === 'STARTABLE' || action[0] === 'ENDABLE') && v.model.getBookee().get('doorCount') > 0) {
					label = stringHelper.get('currentBooking.action.trips.door') + ' ';
				}
				label += stringHelper.get('currentBooking.action.' + action[1] + '.label');
				
				d.actions.push({
					id: action[1],
					label: label
				});
			});


			templateHelper('blocks/currentBooking', d, function (html) {
				v.$el.html(html);

				// Pedelec Icon Quickfix
				if (v.model.getBookee() && v.model.getBookee().isPedelec()) {
					v.$el.find('.currentBookings__vehicle').addClass('currentBookings__vehicle--pedelec');
				}
				if (v.model.getBookee() && v.model.getBookee().isCargoWheel()) {
					v.$el.find('.currentBookings__vehicle').addClass('currentBookings__vehicle--cargowheel');
				}

			});
		},
		startTrip: function () {
			new BookingBlockView({model: this.model}).startTrip();
		},
		endTrip: function () {
			new BookingBlockView({model: this.model}).endTrip();
		},
		reOpen: function () {
			new BookingBlockView({model: this.model}).reopenDoor();
		},
		cancel: function () {
			new BookingBlockView({model: this.model}).cancelBooking();
		},
		stop: function () {
			new BookingBlockView({model: this.model}).stopBooking();
		},
		enlarge: function () {
			new BookingBlockView({model: this.model}).enlargeBooking();
		},

		openPlace: function () {
			var v = this;

			window.myApp.subNavigate('place/' + v.model.get('bookee').place.id + '/' + v.model.get('bookee').id, {trigger: true});
		}
	});
});

