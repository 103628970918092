define('templates/account/customerBookings',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/account/customerBookings"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.headline || (depth0 != null ? depth0.headline : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"headline","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "Meine Fahrten";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h2 class=\"customerBookings__headline\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.headline : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h2>\n<table class=\"customerBookings__bookings bookings\">\n	<tbody class=\"bookings__list\"></tbody>\n</table>\n<div class=\"customerBookings--empty\" style=\"display: none;\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(alias1,"customerBookings.empty",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});

return this["JST"];

});
