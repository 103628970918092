define('templates/modals/modal-close',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/modal-close"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<button class=\"button button--close\" type=\"button\">&times;</button>\n";
},"useData":true});

return this["JST"];

});
