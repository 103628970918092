define('views/panels/moreFrame', ['views/_panel', 'helpers/template'], function (PanelView, templateHelper) {
	'use strict';

	/**
	 * MoreFramePanelView
	 *
	 * @module views/panels/moreFrame
	 * @class MoreFramePanelView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return PanelView.extend({
		panelName: ['moreframe'],
		panelEvents: {},

		render: function () {
			var v = this;

			if(!v.model) {
				window.myApp.subNavigate('more', {trigger: true});
				return;
			}
				
			templateHelper('panels/moreFrame', v.model.toJSON(), function (html) {
				v.$el.html(html);
			});

			v.once('remove', function() {
				if (location.hash.indexOf('/more/' + v.model.id) > -1) {
					window.myApp.subNavigate('more');
				}
			});

			v.listenTo(window.myApp, 'route', function() {
				if (location.hash.indexOf('/more/' + v.model.id) === -1) {
					v.remove();
				}
			});
		}
	});
});

