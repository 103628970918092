define('templates/blocks/backendSlow',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/backendSlow"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<h2 class=\"backendSlow__headline\">Einen Moment, bitte&nbsp;…</h2>\n<p class=\"backendSlow__text\">Erhöhte Aktivität im Buchungssystem, gleich geht es weiter!</p>";
},"useData":true});

return this["JST"];

});
