define('casirest2/collections/provider',['./_', '../models/provider'], function (Collection, ProviderModel) {
	'use strict';

	/**
	 * @class ProviderCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: ProviderModel
	});
});

