define('templates/panels/remarks',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/remarks"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<h1 class=\"panel__headline panel__headline--remarks\">Standort&shy;informationen</h1>\n<div class=\"panel__remarks panel__remarks--place\"></div>\n";
},"useData":true});

return this["JST"];

});
