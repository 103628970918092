define('views/app', [
	'underscore',
	'jquery',
	'views/_view',
	'views/_account',
	'views/_modal',
	'views/_panel',
	'views/account',
	'views/appUpdater',
	'views/bookingArea',
	'views/currentBooking',
	'views/geoSearch',
	'views/halMode',
	'views/menu',
	'views/timePicker',
	'helpers/settings',
	'helpers/mapState',
	'helpers/time',
	'helpers/externalAPI',
	'views/logo',
	'views/modals/login',
	'views/overlayButtons',
], function (
	_,
	$,
	BaseView,
	AccountBaseView,
	ModalBaseView,
	PanelBaseView,
	AccountView,
	AppUpdaterView,
	BookingAreaView,
	CurrentBookingView,
	GeoSearchView,
	HalModeView,
	MenuView,
	TimePickerView,
	SettingsHelper,
	MapStateHelper,
	TimeHelper,
	ExternalAPIHelper,
	LogoView,
	LoginModalView,
	OverlayButtonsView
) {
	'use strict';

	/**
	 * AppView
	 *
	 * Root view of this project, able to render ModalViews, PanelViews and AccountViews.
	 * Initialized by AppRouter to render content.
	 *
	 * @module views/app
	 * @class AppView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		el: '#app',

		/**
		 * Array of ModalView instances currently visible
		 *
		 * @type {Array.<ModalBaseView>}
		 */
		modals: [],

		/**
		 * Array of PanelView instances currently visible
		 *
		 * @type {Array.<PanelBaseView>}
		 */
		panels: [],

		/**
		 * Renders the App View and all it's children views
		 *
		 *  - v.menuView        -> MenuView
		 *  - v.bookingAreaView -> BookingAreaView
		 *  - v.timePickerView  -> TimePickerView
		 *  - v.geoSearchView   -> GeoSearchView
		 *  - v.accountView     -> AccountView
		 *
		 *  @returns {AppView}
		 */
		render: function () {
			var v = this;

			v.$bookingArea = v.$el.children('#mainArea');
			v.$accountArea = v.$el.children('#accountArea');

			v.timeHelper = new TimeHelper();

			// Render CurrentBookingView
			v.currentBookingView = new CurrentBookingView().appendTo(v, v.$bookingArea);

			// Render MenuView
			v.menuView = new MenuView().appendTo(v, v.$el.find('#menuArea'));

			// Render MapView
			v.bookingAreaView = new BookingAreaView({timeHelper: v.timeHelper}).appendTo(v, v.$bookingArea);

			// Render TimePickedView
			v.timePickerView = new TimePickerView({timeHelper: v.timeHelper}).appendTo(v, v.$bookingArea);

			// render GeoSearchView
			v.geoSearchView = new GeoSearchView({bookingAreaView: v.bookingAreaView}).appendTo(v, v.$bookingArea);
			v.listenTo(v.geoSearchView, 'changemap', v.bookingAreaView.openPlaceGeometry);
			v.listenTo(v.geoSearchView, 'gotoLatLng', v.bookingAreaView.openLocation);
			
			// render OverlayButtonsView
			v.overlayButtonsView = new OverlayButtonsView({currentBookingCollection: v.currentBookingView.collection});
			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', function () {
				if (SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET') {
					MapStateHelper.isListMode(false).apply();
					MapStateHelper.showCurrentBookings(false).apply();
					v.overlayButtonsView.remove();
				} else {
					v.overlayButtonsView.appendTo(v, v.$bookingArea);
				}
			});

			// Render AccountView
			v.accountView = new AccountView().appendTo(v, v.$accountArea);

			// Render AppUpdaterView
			v.appUpdaterView = new AppUpdaterView().appendTo(v);

			// Render HalModeView
			if(SettingsHelper.isDeveloperEndpoint() && $('body').width() >= 800) {
				v.halModeView = new HalModeView().appendTo(v);
			}

			// Initialize External API
			ExternalAPIHelper.initialize({
				timeHelper: v.timeHelper,
				bookingAreaView: v.bookingAreaView
			});

			// global class for map or list
			v.listenToAndCall(MapStateHelper, 'change:lim', function() {
				v.$el.toggleClass('app--list-mode', MapStateHelper.isListMode());
			});


			/** @see {@link https://cantaloupe.cantamen.de/admin/issues/13624|i13624} */
			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', function (settings) {
					v.$el.toggleClass('app--widget', settings.get('bookingInterfaceType') === 'MAP_WIDGET');
				}
			);
			
			// Render Logo as Header
			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:useLogoOnHeader', function (settings) {
				v.$el.toggleClass('app--withLogo', !!settings.get('useLogoOnHeader'));
			});
			v.logoView = new LogoView().appendTo(v, v.$bookingArea);

			return this;
		},

		/**
		 * Renders a ModalView, PanelView or an AccountView
		 *
		 * @param {ModalBaseView|PanelBaseView|AccountBaseView} view View to render now
		 * @param options Options
		 * @param options.isRoot Set to true, if this panel should be a root element. Only for PanelViews.
		 * @event render:panel
		 * @returns {AppView}
		 */
		renderView: function (view, options) {
			var v = this;
			options = options || {};

			$('body').scrollTop(0);

			if (view instanceof ModalBaseView) {
				// prevent double login-modals
				if (view instanceof LoginModalView && _.find(v.modals, function (modal) {
						return modal instanceof LoginModalView;
				})) {
					return v;
				}
				
				view.appendTo(v, v.$bookingArea);

				if (_.indexOf(v.modals, view) < 0) {
					v.modals.push(view);
				}

				v.listenTo(view, 'remove', function () {
					v.modals = _.without(v.modals, view);
				});

				v.trigger('render:modal');
			}
			else if (view instanceof PanelBaseView) {

				// is this panel a root element? close old one
				if (options.isRoot && v.panels.length > 0) {
					v.panels[0].remove();
					v.panels = [];
				}

				// this is a root element
				if (v.panels.length === 0 && _.isFunction(view.isRoot)) {
					view.isRoot();
				}

				v.panels.push(view);

				// delete view from panels[] after removing
				v.listenTo(view, 'remove', function () {
					// delete direct child
					var child = v.getChildPanelOf(view);
					if (child) {
						child.remove();
					}

					v.panels = _.without(v.panels, view);

					// updatePanelCount
					v.updatePanelCount();
				});

				view.appendTo(v, v.$bookingArea);
				v.updatePanelCount();
				v.trigger('render:panel');
			}

			else if (view instanceof AccountBaseView) {
				// close any panels
				if (v.panels.length > 0) {
					v.panels[0].remove();
					v.panels = [];
				}

				// delegate rendering to AccountView
				v.accountView.renderView(view);

				v.trigger('render:account');
			}

			return v;
		},

		/**
		 * Updates all Panels with a calculated `data-panel`-Attributes
		 * for styling purposes…
		 *
		 * @returns {AppView}
		 */
		updatePanelCount: function () {
			var v = this,
				panelSum = v.panels.length;

			_.each(v.panels, function (panel, i) {
				var num = i + 1,
					countValue = [];

				// add data-attribute for styling
				for (var count = 1; count <= panelSum; count += 1) {
					if (num <= count) {
						countValue.push(num + '-' + count);
					}
				}

				panel.$el.attr('data-panel', countValue.join(' '));
			});

			return v;
		},


		/**
		 * Get the PanelView instance for the given index.
		 * If no index is given, the array is returned.
		 *
		 * @param {Number} [index] Index
		 * @returns {PanelBaseView|Array<PanelBaseView>}
		 */
		getOpenedPanel: function (index) {
			var v = this;

			if (index === undefined) {
				return v.panels;
			}
			if (v.panels[index]) {
				return v.panels[index];
			}

			return null;
		},

		/**
		 * Returns the Child Panel of the given view or index
		 *
		 * @param {Number|PanelBaseView} view
		 * @returns {PanelBaseView}
		 */
		getChildPanelOf: function (view) {
			var v = this,
				i;

			if (_.isNumber(view)) {
				view = v.getOpenedPanel(view);
			}
			i = _.indexOf(v.panels, view);
			if (i < 0) {
				return null;
			}

			i += 1;
			return v.getOpenedPanel(i);
		},

		/**
		 * Renders a panel for the given view
		 *
		 * @param {PanelBaseView} of
		 * @param {PanelBaseView} view
		 * @returns {AppView}
		 */
		renderChildPanelOf: function (of, view) {
			var v = this,
				current = v.getChildPanelOf(of);

			if (current) {
				current.remove();
			}

			v.renderView(view);
			return v;
		}
	});
});

