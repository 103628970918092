define('views/timePicker', [
	'underscore',
	'views/_view',
	'helpers/time',
	'helpers/template',
	'helpers/settings',
	'casirest2/helpers/data',
	'views/blocks/timerange',
	'views/blocks/closeButton',
	'jquery'
], function (
	_,
	View,
	TimeHelper,
	templateHelper,
	SettingsHelper,
	DataHelper,
	TimeRangeBlock,
	CloseButtonBlock,
	$
) {
	'use strict';

	return View.extend({
		className: 'timePicked',

		activeTimeRange: false,
		events: {
			'click': 'openTimePicker',
			// 'click .button--ok': 'closeTimePicker'
		},

		_initialize: function (options) {
			this.timeHelper = options.timeHelper || new TimeHelper();

			// update view, when user has logged in
			this.listenTo(DataHelper, 'auth:loggedIn', this.render);
		},

		render: function () {
			var v = this;

			if (!v.timeHelper.isReady()) {
				v.listenToOnce(v.timeHelper, 'initialized', this.render);
				return;
			}

			/** @see {@link https://cantaloupe.cantamen.de/admin/issues/13637|i13637} */
			v.listenToAndCall(
				SettingsHelper.getEndPointSettings(),
				'change:bookingInterfaceType change:businessModels',
				function (settings) {
					var bm = settings.get('businessModels') || [],
						fix = _.find(bm, function (m) {
							return m.locality === 'FIX';
						});
						
					v.$el.toggleClass('timePicked--visible', !!fix && settings.get('bookingInterfaceType') !== 'MAP_WIDGET');
				}
			);

			templateHelper('timePicked', function (html) {
				v.$el.html(html);
				
				// v.closeButton = new CloseButtonBlock();
				// v.closeButton.appendTo(v);
				// v.closeButton.$el.hide();
				// v.listenTo(v.closeButton, 'close', v.closeTimePicker);

				v.timeRangeBlock = new TimeRangeBlock({timeHelper: v.timeHelper});
				v.timeRangeBlock.appendTo(v);

				// v.listenTo(v.timeRangeBlock, 'pickerFocus', function () {
				// 	v.openTimePicker();
				// });
				
				v.listenTo(v.timeRangeBlock, 'active', function (active) {
					v.toggleTimePicker(active);
				});

				// v.$el.append('<button class="button button--timePicked button--autofit button--ok" style="display: none" type="button">Schließen</button>');
			});
		},

		toggleTimePicker: function (newState) {
			var v = this;
			
			if (newState === v.activeTimeRange) {
				return;
			}
			v.activeTimeRange = _.isBoolean(newState) ? newState : !v.activeTimeRange;
			v.$el.toggleClass(v.className + '--active', v.activeTimeRange);
			
			// register click event for closing all active childs + self when clicking somewhere else
			if (v.activeTimeRange === true) {
				$(document).one('click', function(e) {
					v.closeTimePicker(e);
				});
			}
		},

		closeTimePicker: function (e) {
			var v = this;
			e.stopPropagation();
			// v.$el.find('.button--ok').hide();
			// v.closeButton.$el.hide();
			v.toggleTimePicker(false);
			v.timeRangeBlock.active(false);
		},

		openTimePicker: function () {
			var v = this;
			// v.$el.find('.button--ok').show();
			// v.closeButton.$el.show();
			v.toggleTimePicker(true);
		}
	});
});

