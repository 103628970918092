define('views/blocks/customerPassword', [
	'underscore',
	'views/_view',
	'views/modals/prompt',
	'helpers/template',
	'helpers/booking',
	'jquery',
	'formparams'
], function (
	_,
	View,
	PromptModalView,
	templateHelper,
	BookingHelper,
	$
) {
	'use strict';

	/**
	 * CustomerPasswordBlockView
	 *
	 * @module views/blocks/customerPassword
	 * @class CustomerPasswordBlockView
	 * @augments BaseView
	 */
	return View.extend({
		className: 'customerPassword',

		events: {
			'submit .changePassword': 'savePassword',
			'submit .changePin': 'savePin',
			'submit .changePinAndPassword': 'savePassword'
		},

		/**
		 * Constructor of this view.
		 * Just saves the options we need later.
		 *
		 * @returns CustomerPasswordBlockView
		 */
		_initialize: function (options) {
			this.errors = {};
			this.options = options || null;
			return this;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {CustomerPasswordBlockView}
		 */
		render: function () {
			var v = this;

			if (!BookingHelper.init) {
				_.defer(v.render);
				return v;
			}

			if (!BookingHelper.pinPasswordChangeSettings().isSomethingChangeable) {
				return v;
			}

			// remove all laodings
			v.$el.find('.loading--overlay').removeClass('loading--overlay');

			templateHelper('blocks/customerPassword', {
				errors: v.errors,
				PinAllowed: BookingHelper.pinPasswordChangeSettings().pinAllowed,
				PasswordAllowed: BookingHelper.pinPasswordChangeSettings().passwordAllowed,
				PinPasswordIdentical: BookingHelper.pinPasswordChangeSettings().pinPasswordIdentical
			}, function (html) {
				v.$el.html(html);
			});

			return v;
		},

		savePassword: function (e) {
			var v = this,
				passwords;
			e.preventDefault();

			if (BookingHelper.pinPasswordChangeSettings().pinPasswordIdentical) {
				passwords = $(v.el).find('.changePinAndPassword').formParams(false); // false = don't convert numbers to integer or 'true' to boolean
			} else {
				passwords = $(v.el).find('.changePassword').formParams(false); // false = don't convert numbers to integer or 'true' to boolean
			}

			v.errors.password = false;
			v.errors.newpassword = false;
			v.errors.passwordEqual = false;

			if (!passwords.oldPassword) {
				v.errors.password = true;
				v.render();
				return;
			}

			if (!passwords.newPassword || !passwords.newPasswordRepeat) {
				v.errors.newpassword = true;
				v.render();
				return;
			}

			if (passwords.newPassword !== passwords.newPasswordRepeat) {
				v.errors.passwordEqual = true;
				v.render();
				return;
			}

			v.$el.find('form.changePassword, form.changePinAndPassword').addClass('loading--overlay');

			v.model.setPassword(passwords, function (error) {
				if (error) {
					v.errors.password = true;
					v.render();
					return;
				}

				if (BookingHelper.pinPasswordChangeSettings().pinPasswordIdentical) {
					v.savePin(e, passwords.newPassword);
				}

				window.myApp.view.renderView(new PromptModalView({
					title: 'Passwort',
					message: 'Passwort erfolgreich geändert.',
					type: 'success'
				}));

				v.$el.find('form.changePassword, form.changePinAndPassword').removeClass('loading--overlay');

				v.errors.password = false;
				v.render();
			});

			// clear input after submitting
			v.$el.find('form.changePassword input, form.changePinAndPassword input').val('');
		},

		savePin: function (e, newPin) {
			var v = this,
				pinObject = {};
			e.preventDefault();
			v.errors.pin = false;

			if (newPin) {
				pinObject.pin = newPin;
			} else {
				pinObject = $(v.$el.find('form.changePin')).formParams(false); // false = don't convert numbers to integer or 'true' to boolean
			}

			var pin = pinObject.pin;

			if (pin && /^\d{4}$/.test(pin)) {

				v.$el.find('form.changePin, form.changePinAndPassword').addClass('loading--overlay');

				// set new PIN
				var accessMedias = _.clone(v.model.get('accessMedias'));
				if (!accessMedias[0]) {
					require(['views/modals/prompt'], function (SuccessModal) {
						var errorModal = new SuccessModal({
							title: 'PIN',
							message: 'Keine Zugangskarte.',
							type: 'error'
						});
						window.myApp.view.renderView(errorModal);
					});

					v.render();
					return;
				}
				accessMedias[0].pin = pin;
				v.model.set('accessMedias', accessMedias);

				v.model.save({
					'accessMedias': accessMedias
				}, {
					patch: true,
					success: function () {
						require(['views/modals/prompt'], function (SuccessModal) {
							var successModal = new SuccessModal({
								title: 'PIN',
								message: 'PIN erfolgreich geändert.',
								type: 'success'
							});
							window.myApp.view.renderView(successModal);
						});

						// remove loading
						v.$el.find('form.changePin, form.changePinAndPassword').removeClass('loading--overlay');
					},
					error: function () {
						require(['views/modals/prompt'], function (SuccessModal) {
							var errorModal = new SuccessModal({
								title: 'PIN',
								message: 'PIN konnte nicht geändert werden.',
								type: 'error'
							});
							window.myApp.view.renderView(errorModal);
						});

						v.$el.find('form.changePin, form.changePinAndPassword').removeClass('loading--overlay');
					}
				});
			} else {
				v.errors.pin = true;
				v.render();
			}

			// clear input after submitting
			v.$el.find('form.changePin input, form.changePinAndPassword input').val('');
		}
	});
});

