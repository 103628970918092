define('casirest2/collections/unavailability',['./_', '../models/unavailability'], function (Collection, UnavailabilityModel) {
	'use strict';

	/**
	 * @class UnavailabilityCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: UnavailabilityModel,
		filterMethods: ['timeRange']
	});
});

