define('views/modals/login', [
	'backbone',
	'underscore',
	'jquery',
	'views/_modal',
	'helpers/template',
	'casirest2/helpers/data',
	'helpers/store',
	'casirest2/collections/provider',
	'formparams'
], function (
	Backbone,
	_,
	$,
	ModalBaseView,
	templateHelper,
	DataHelper,
	StoreHelper,
	ProviderCollection
) {
	'use strict';

	/**
	 * LoginModalView
	 *
	 * @module views/modals/login
	 * @class LoginModalView
	 * @augments ModalBaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	var LoginModalView = ModalBaseView.extend({
		modalName: 'login',
		modalEvents: {
			'submit': 'submit',
			'click button.resetbutton': 'resetPassword'
		},

		/**
		 * @param {object} [options]
		 * @param {ProviderCollection} [options.collection] ProviderCollection this view should use
		 */
		__initialize: function (options) {
			var v = this;
			v.collection = options.collection || new ProviderCollection().addFilter('action', 'CUSTOMER_LOGIN');
		},

		/**
		 * Render method
		 * @returns {LoginModalView}
		 */
		render: function () {
			var v = this;
			
			// check if already logged in => close view
			if (DataHelper.isLoggedIn()) {
				v.remove();
				if (Backbone.history.getFragment().indexOf('/login') !== -1) {
					window.myApp.subNavigate('', {trigger: true});
				}
			}

			templateHelper('modals/login', function (html) {
				v.$el.html(html);

				var $username = v.$el.find('#login_username');
				$username.val(StoreHelper.get('loginUsername') || '');

				v.listenTo(v.collection, 'add', v.addProvider);
				v.collection.each(v.addProvider);

				_.defer(function() {
					if(StoreHelper.get('loginUsername')) {
						v.$el.find('#login_password').focus();
					}else {
						$username.focus();
					}
				});
			});

			// sync collection
			if(!v.collection.syncing) {
				v.collection.fetch();
			}

			return v;
		},

		/**
		 * Adds a provider to the select options…
		 * @param {ProviderModel} provider
		 */
		addProvider: function(provider) {
			var v = this,
				$option;
			
			v.$providerSelector = v.$providerSelector || v.$el.find('#login_provId');
			$option = $('<option />').attr('value', provider.id).text(provider.get('name'));
			
			// restore last used provider
			if (StoreHelper.get('loginProvID') === provider.id) {
				$option.prop('selected', true);
			}
			
			$option.appendTo(v.$providerSelector);
			
			if(v.collection.length === 1) {
				v.$providerSelector.val(provider.id);
				v.$providerSelector.prop('disabled', true);
			}
		},

		/**
		 * @param {event} e
		 */
		submit: function (e) {
			var v = this,
				values = v.$el.find('.login__form').formParams(false);
			
			

			e.preventDefault();
			v.$el.addClass('loading');


			// only one orga to choose from: no select needed
			if (v.collection.length === 1) {
				values.provId = v.collection.first().id;
			}
			else if(v.$providerSelector) {
				values.provId = v.$providerSelector.val(); // stupid formParams fix
			}
			else {
				return;
			}
			
			// Callbacks
			values.error = function() {
				v.$el.removeClass('loading');
				v.$el.find('input').addClass('field__error');

				_.delay(function () {
					v.$el.find('input').removeClass('field__error');
				}, 3000);

				v.$el.find('.modal__frame').addClass('modal__frame--error');
				_.delay(function () {
					v.$el.find('.modal__frame').removeClass('modal__frame--error');
				}, 1000);
			};
			values.success = function() {
				// store username and provider for a better login experience
				StoreHelper.set('loginUsername', values.username);
				StoreHelper.set('loginProvID', values.provId);
				
				v.remove();
				if (Backbone.history.getFragment().indexOf('/login') !== -1) {
					window.myApp.subNavigate('', {trigger: true});
				}
			};
			
			if (!values.username || !values.password || !values.provId) {
				values.error();
				return;
			}

			// do the login dance.
			DataHelper.login(values);
		},
		resetPassword: function () {
			var v = this;

			v.remove();
			window.myApp.subNavigate('login/reset', {trigger: true});
		}
	});

	return LoginModalView;
});

