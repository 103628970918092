define('helpers/externalAPI', [
	'underscore',
	'backbone',
	'moment',
	'helpers/store',
	'helpers/mapState'
], function (_, Backbone, moment, StoreHelper, MapStateHelper) {
	'use strict';

	var state = {
		timeHelper: null,
		bookingAreaView: null,
		hooks: {},
		jsMap: {
			'time': 'setTime',
			'loginDefaults': 'setLoginDefaults',
			'openPlace': 'openPlace.id',
			'openPlaceRemarks': 'openPlaceRemarks.id',
			'openPlaceAvailabilities': 'openPlaceAvailabilities.id',
			'openBookee': 'openBookee.id',
			'location': 'openLocation',
			'openMyBookings': 'openMyBookings',
			'hooks': 'setHooks',

			// old and deprecated
			// see https://cantaloupe.cantamen.de/admin/issues/12477
			'bookee_id': 'openBookee.id',
			'start_time': 'setTime.start',
			'end_time': 'setTime.end',
			'gps_latitude': 'openLocation.lat',
			'gps_longitude': 'openLocation.lng',
			'user_provider_id': 'setLoginDefaults.organisation',
			'user_id': 'setLoginDefaults.username',
			'place_id': 'openPlace.id'
		}
	};

	var internalAPI,
		externalAPI;

	/**
	 * ExternalAPIHelper
	 *
	 * Implements the external JavaScript API used
	 * to control EWI3 inside an iframe or an app
	 *
	 * @module helpers/externalAPI
	 * @class ExternalAPIHelper
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	externalAPI = {

		/**
		 * Sets start and end timeHelper values
		 *
		 * @param {object} [options]
		 * @param {string} [options.start]
		 * @param {string} [options.end]
		 */
		setTime: function (options) {
			options = options || {};

			_.each(['start', 'end'], function (p) {
				if (!options[p]) {
					return;
				}

				var m = moment(options[p], moment.ISO_8601);
				if (!m.isValid()) {
					m = moment(options[p], 'YYYYMMDDHHmm');
				}
				if (!m.isValid()) {
					console.warn('EWI3API.setTime: Invalid value for `%s`', p);
					return;
				}

				state.timeHelper.update(p === 'start' ? m : null, p === 'end' ? m : null);
			});

			return externalAPI;
		},

		/**
		 * Sets login defaults
		 *
		 * @param {object} [options]
		 * @param {string} [options.username]
		 * @param {string} [options.organisation]
		 */
		setLoginDefaults: function (options) {
			options = options || {};

			if (options.username) {
				StoreHelper.set('loginUsername', options.username);
			}
			if (options.organisation) {
				StoreHelper.set('loginProvID', options.organisation);
			}

			return externalAPI;
		},

		/**
		 * Opens the given place
		 *
		 * @param {object} [options]
		 * @param {string} [options.id]
		 */
		openPlace: function (options) {
			options = options || {};
			if (!options.id) {
				console.warn('EWI3API.openPlace: ID not set!');
				return externalAPI;
			}

			state.bookingAreaView.openPlace(options.id);

			return externalAPI;
		},

		/**
		 * Opens the given place remarks
		 *
		 * @param {object} [options]
		 * @param {string} [options.id]
		 */
		openPlaceRemarks: function (options) {
			options = options || {};
			if (!options.id) {
				console.warn('EWI3API.openPlaceRemarks: ID not set!');
				return externalAPI;
			}

			state.bookingAreaView.openPlace(options.id, function (panel) {
				if (panel.openRemarks) {
					panel.openRemarks();
				}
			});

			return externalAPI;
		},

		/**
		 * Opens the given place unavailabilities
		 *
		 * @param {object} [options]
		 * @param {string} [options.id]
		 */
		openPlaceAvailabilities: function (options) {
			options = options || {};
			if (!options.id) {
				console.warn('EWI3API.openPlaceAvailabilities: ID not set!');
				return externalAPI;
			}

			state.bookingAreaView.openPlace(options.id, function (panel) {
				if (panel.showUnavailability) {
					panel.showUnavailability();
				}
			});

			return externalAPI;
		},

		/**
		 * Opens the given bookee
		 *
		 * @param {object} [options]
		 * @param {string} [options.id]
		 */
		openBookee: function (options) {
			options = options || {};
			if (!options.id) {
				console.warn('EWI3API.openBookee: ID not set!');
				return externalAPI;
			}

			state.bookingAreaView.openBookee(options.id);
			return externalAPI;
		},

		/**
		 * Opens the location
		 *
		 * @param {object} [options]
		 * @param {number} [options.lat]
		 * @param {number} [options.lng]
		 * @param {number} [options.zoom]
		 */
		openLocation: function (options) {
			options = options || {};

			MapStateHelper.setCenter(options);
			if (options.zoom) {
				MapStateHelper.setZoomLevel(options.zoom);
			}

			MapStateHelper.apply();
			return externalAPI;
		},

		/**
		 * Opens my bookings
		 */
		openMyBookings: function (/*options*/) {
			myApp.subNavigate('account/bookings', {trigger: true});
			return externalAPI;
		},

		/**
		 * Apply query parameters
		 * @param {String} parameters
		 */
		applyAppParameters: function (parameters) {
			var p = internalAPI.getParameters(parameters);
			_.each(p, function (i) {
				var method = state.jsMap[i[0]],
					parameter = i[0].substr(i[0].split('.')[0].length + 1) || null,
					options = {};

				if (!method) {
					method = state.jsMap[i[0].split('.')[0]];
				}
				if (!method) {
					console.warn('EWI3API.applyAppParameters: Parameter `%s` unknown!', i[0]);
					if (window.Raven) {
						Raven.captureException(new Error('EWI3API: Unexpected App Parameter: ' + i[0]));
					}
					return;
				}

				if (!parameter && method && method.indexOf('.') > -1) {
					parameter = method.substr(method.split('.')[0].length + 1);
					method = method.split('.')[0];
				}
				if (parameter) {
					options[parameter] = i[1];
				}

				externalAPI[method](options);
			});

			return externalAPI;
		},

		/**
		 * Update EWI3 Hooks
		 * @param {Object} [options]
		 */
		setHooks: function (options) {
			options = options || {};

			for (var i in options) {
				if (options.hasOwnProperty((i))) {
					if (options[i]) {
						state.hooks[i] = options[i];
					}
					else if (state.hooks[i]) {
						delete state.hooks[i];
					}
				}
			}

			return externalAPI;
		}
	};

	internalAPI = {
		initialize: function (options) {
			state.timeHelper = options.timeHelper;
			state.bookingAreaView = options.bookingAreaView;

			window.EWI3API = externalAPI;
			document.getElementById('app').dispatchEvent(new Event('ewi3-api-ready'));
			document.dispatchEvent(new Event('ewi3-api-ready'));

			externalAPI.applyAppParameters(window.location.search);
		},

		external: function () {
			return externalAPI;
		},

		applyHook: function (hook) {
			if (state.hooks[hook]) {
				document.location.href = state.hooks[hook];
			}
		},

		getParameters: function (s) {
			var a = s || '',
				b = [],
				p;

			if (a.indexOf('?') > -1) {
				a = a.substr(a.indexOf('?') + 1);
			}
			if (a === '') {
				return [];
			}

			a = a.split('&');
			for (var i = 0; i < a.length; i += 1) {
				p = a[i].split('=', 2);
				if (p.length === 1) {
					b.push([p[0], null]);
				} else {
					b.push([p[0], decodeURIComponent(p[1].replace(/\+/g, ' '))]);
				}
			}

			return b;
		}
	};

	_.extend(externalAPI, Backbone.Events);
	return internalAPI;
});

