define('casirest2/collections/freeFloatArea',['./_', '../models/freeFloatArea'], function (Collection, FreeFloatAreaModel) {
	'use strict';

	/**
	 * @class FreeFloatAreaCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: FreeFloatAreaModel,
		filterMethods: ['provider', 'timeRange']
	});
});

