define('templates/modals/login',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/login"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "				<option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<form class=\"login__form\" action=\"#\" onsubmit=\"event.preventDefault();\">\n	<div class=\"login__field field\">\n		<div class=\"field__info\"><span class=\"field__infotext\">Kundennummer, E-Mail-Adresse oder Kartennummer (mit vorangestellter Raute z.B.:&nbsp;#K1337)</span></div>\n		<label class=\"field__label\" for=\"login_username\">Login</label>\n		<input tabindex=\"1\" name=\"username\" id=\"login_username\" type=\"text\" class=\"field__input field__input--text\" placeholder=\"Login\" value=\"\" autofocus />\n	</div>\n\n	<div class=\"login__field field\">\n		<label class=\"field__label field__label--withaction\" for=\"login_password\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"login.password.label",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</label>\n		<button tabindex=\"5\" type=\"button\" class=\"field__label field__label--action resetbutton\">vergessen?</button>\n		<input tabindex=\"2\" name=\"password\" id=\"login_password\" type=\"password\" class=\"field__input field__input--text field__input--password\" placeholder=\""
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias2).call(alias1,"login.password.label",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "\" value=\"\" />\n	</div>\n\n	<div class=\"login__field field provider\">\n		<label class=\"field__label\" for=\"login_provId\">Organisation</label>\n		<select tabindex=\"3\" class=\"login__select field__select\" name=\"provId\" id=\"login_provId\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.providers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</select>\n	</div>\n	\n	<button tabindex=\"4\" class=\"login__submit button\">Einloggen</button>\n</form>\n";
},"useData":true});

return this["JST"];

});
