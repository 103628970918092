define('views/modals/endTrip', [
	'underscore',
	'helpers/template',
	'views/_modal',
	'jquery'
], function (
	_,
	templateHelper,
	ModalBaseView,
	$
) {
	'use strict';

	/**
	 * EndTripModalView
	 *
	 * @module views/modals/endTrip
	 * @class EndTripModalView
	 * @augments ModalBaseView
	 */
	var EndTripModalView = ModalBaseView.extend({

		modalName: 'endTrip',
		modalEvents: {
			'click .returnPlace': 'selectPlace'
		},

		/**
		 * Render method
		 * @returns {EndTripModalView}
		 */
		render: function () {
			var v = this;

			templateHelper('modals/endTrip', {
				places: v.model.get('flexInfo').returnPlaces
			}, function (html) {
				v.$el.html(html);
			});

			return v;
		},

		selectPlace: function (e) {
			var v = this;

			e.preventDefault();
			v.trigger('placeSelected', $(e.target).attr('data-placeId'));
			v.remove();
		}
	});

	return EndTripModalView;
});

