define('templates/blocks/filterType',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/filterType"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<h2 class=\"panelfilter__typehead headline\">"
    + container.escapeExpression(((helper = (helper = helpers.filterTypeName || (depth0 != null ? depth0.filterTypeName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"filterTypeName","hash":{},"data":data}) : helper)))
    + "</h2>\n\n<div class=\"panelfilter__buttonlist\"></div>";
},"useData":true});

return this["JST"];

});
