define('casirest2/collections/addPropType',['./_', '../models/addPropType'], function (Collection, AddPropTypeModel) {
	'use strict';

	/**
	 * @class AddPropTypeCollection
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return Collection.extend({
		model: AddPropTypeModel,
		filterMethods: []
	});
});

