define('views/overlayButtons', [
	'views/_view',
	'helpers/mapState',
	'helpers/settings',
	'jquery'
], function (
	View,
	MapStateHelper,
	SettingsHelper,
	$
) {
	'use strict';

	/**
	 * OverlayButtonsView
	 *
	 * @module views/overlayButtons
	 * @class overlayButtons
	 * @augments BaseView
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return View.extend({
		className: 'overlayButtons',
		
		events: {
			'click button.overlayButtons__viewtype--map': 'openMap',
			'click button.overlayButtons__viewtype--list': 'openList',
			'click button.overlayButtons__viewtype--currentBookings': 'toggleCurrentBookings',
		},
		
		_initialize: function (options) {
			this.currentBookingCollection = options.currentBookingCollection;
		},

		/**
		 * @returns {OverlayButtonsView}
		 */
		render: function () {
			var v = this,
				$mapButton = $('<button title="Zur Kartenansicht" type="button" class="overlayButtons__viewtype overlayButtons__viewtype--map" />'),
				$listButton = $('<button title="Zur Listenansicht" type="button" class="overlayButtons__viewtype overlayButtons__viewtype--list" />'),
				$currentBookingsButton = $('<button title="Aktuelle Buchungen" type="button" class="overlayButtons__viewtype overlayButtons__viewtype--currentBookings"><span class="overlayButtons__badge"></span></button>').appendTo(v.$el);
			
				
			v.listenToAndCall(MapStateHelper, 'change:lim', function() {
				var isListMode = MapStateHelper.isListMode();
				$mapButton.toggleClass('overlayButtons__viewtype--active', isListMode);
				$listButton.toggleClass('overlayButtons__viewtype--active', !isListMode);
			});
			
			v.listenToAndCall(v.currentBookingCollection, 'sync reset', function () {
				$currentBookingsButton.toggleClass('overlayButtons__viewtype--active', !!v.currentBookingCollection.length);
				$currentBookingsButton.find('.overlayButtons__badge').text(v.currentBookingCollection.length);
			});
			
			// for now: only show toggler for instances with mode 'LIST'
			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceStartsWith', function (settings) {
				if (settings.has('bookingInterfaceStartsWith') && settings.get('bookingInterfaceStartsWith') === 'LIST') {
					$mapButton.appendTo(v.$el);
					$listButton.appendTo(v.$el);
				} else {
					$mapButton.detach();
					$listButton.detach();
				}
			});

			return v;
		},
		
		openMap: function() {
			MapStateHelper.isListMode(false).apply();
		},
		openList: function() {
			MapStateHelper.isListMode(true).apply();
		},
		toggleCurrentBookings: function () {
			MapStateHelper.showCurrentBookings(!MapStateHelper.showCurrentBookings()).apply();
		}
	});
});

