define('views/appUpdater', [
	'underscore',
	'views/_view',
	'helpers/template'
], function (
	_,
	View,
	templateHelper
) {
	'use strict';

	/**
	 * AppUpdaterView
	 *
	 * @module views/appUpdater
	 * @class AppUpdaterView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'appUpdater appUpdater--hidden',
		events: {
			'click .appUpdater__button': 'update'
		},

		/**
		 * @returns {AppUpdaterView}
		 */
		render: function () {
			var v = this,
				ac = window.applicationCache;

			if (!ac) {
				// AppCache is unsupported in this browser
				return this;
			}

			templateHelper('appUpdater', function (html) {
				v.$el.html(html);
			});

			// search for updates every 5 minutes
			setInterval(function () {
				if (ac.status === ac.IDLE) {
					try {
						ac.update();
					}
					catch (err) {
						console.log('AppUpdater: %s', err);
					}
				}
			}, 1000 * 60 * 2);

			// on update ready: show view
			ac.addEventListener('updateready', function () {
				v.check();
			}, false);

			v.check();
			return v;
		},
		check: function () {
			var v = this,
				ac = window.applicationCache;

			if (ac.status === ac.UPDATEREADY) {
				try {
					ac.swapCache();
					v.$el.removeClass('appUpdater--hidden');
				}
				catch (err) {
					console.log('AppUpdater: %s', err);
				}
			}
		},
		update: function () {
			window.location.reload();
		}
	});
});

