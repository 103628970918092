define('casirest2/collections/_',[
	'backbone',
	'underscore',
	'../helpers/data',
	'../helpers/subscription'
], function (Backbone, _, dataHelper, SubscriptionHelper) {
	'use strict';

	/**
	 * @class BaseCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Backbone.Collection.extend({
		model: null,
		baseURL: null,
		filterMethods: [],
		activeFilters: [],
		liveInterval: 30000,

		sync: dataHelper.sync,

		initialize: function () {
			var m = this;

			for (var i in m) {
				if (m.hasOwnProperty(i) && _.isFunction(m[i]) && !Backbone.Collection.prototype[i]) {
					_.bindAll(m, i);
				}
			}

			// reset
			m.activeFilters = [];
			m.liveInterval = m.liveInterval || m.model.prototype.liveInterval || 30000;

			if (!m.baseURL) {
				m.baseURL = m.model.prototype.urlRoot;
			}

			// filterByBookee
			if (_.indexOf(m.filterMethods, 'bookee') > -1) {
				m.filterByBookee = function filterCollectionByBookee (bookee) {
					if (bookee && bookee.id) {
						m.addFilter('bookeeId', bookee.id);
					}
					else if (bookee >= 1) {
						m.addFilter('bookeeId', bookee);
					}
				};
			}

			// filterByPlace
			if (_.indexOf(m.filterMethods, 'place') > -1) {
				m.filterByPlace = function filterCollectionByPlace (place) {
					if (place && place.id) {
						m.addFilter('placeId', place.id);
					}
					else if (place >= 1) {
						m.addFilter('placeId', place);
					}
				};
			}

			// filterByChargePoint
			if (_.indexOf(m.filterMethods, 'chargepoint') > -1) {
				m.filterByChargePoint = function filterCollectionByChargePoint (chargepoint) {
					if (chargepoint && chargepoint.id) {
						m.addFilter('chargepointId', chargepoint.id);
					}
					else if (chargepoint >= 1) {
						m.addFilter('chargepointId', chargepoint);
					}
				};
			}

			// filterByCity
			if (_.indexOf(m.filterMethods, 'city') > -1) {
				m.filterByCity = function filterCollectionByCity (city) {
					if (city && city.id) {
						m.addFilter('cityId', city.id);
					}
					else if (city >= 1) {
						m.addFilter('cityId', city);
					}
				};
			}

			// filterByLocation
			if (_.indexOf(m.filterMethods, 'location') > -1) {
				m.filterByLocation = function filterCollectionByLocation (point, radius) {
					m.addFilter({
						lat: _.result(point, 'lat'),
						lng: _.result(point, 'lng'),
						range: radius || 1000
					});
				};
			}

			// filterByBounds
			if (_.indexOf(m.filterMethods, 'bounds') > -1) {
				m.filterByBounds = function filterCollectionByBounds (bounds) {
					if (bounds) {
						m.addFilter({
							neLat: bounds.getNorthEast().lat(),
							neLng: bounds.getNorthEast().lng(),
							swLat: bounds.getSouthWest().lat(),
							swLng: bounds.getSouthWest().lng()
						});
					}
				};
			}

			// filterByLocality
			if (_.indexOf(m.filterMethods, 'localities') > -1) {
				m.filterByLocality = function filterCollectionByLocality (which) {
					which = which.toUpperCase();
					m.addFilter('localities', which);
				};
			}

			// filterByTimeRange
			if (_.indexOf(m.filterMethods, 'timeRange') > -1) {
				m.filterByTimeRange = function filterCollectionByTimeRange (start, end) {
					m.addFilter({
						start: start,
						end: end
					});
				};
			}

			// filterByProvider
			if (_.indexOf(m.filterMethods, 'provider') > -1) {
				m.filterByProvider = function filterCollectionByProvider (provider) {
					if (provider && provider.id) {
						m.addFilter('provId', provider.id);
					}
					else if (provider >= 1) {
						m.addFilter('provId', provider);
					}
					else {
						m.addFilter('provId', 'default');
					}
				};
			}

			// filterByBookingTypes
			if (_.indexOf(m.filterMethods, 'bookingType') > -1) {
				m.filterByBookingType = function filterCollectionByBookingType (bookingTypes) {
					bookingTypes = bookingTypes || ['NORMAL', 'INSTANT_ACCESS'];
					m.addFilter('bookingType', bookingTypes);
				};
			}
		},

		addFilter: function (key, value) {
			var c = this,
				s;

			if (key && _.isArray(value)) {
				_.each(value, function (v) {
					c.addFilter(key, v);
				});

				return c;
			}
			else if (_.isObject(key)) {
				_.each(key, function (v, k) {
					c.addFilter(k, v);
				});

				return c;
			}

			if (
				typeof value.milliseconds === 'function' &&
				typeof value.toJSON === 'function'
			) {
				value = value.toJSON();
			}

			s = encodeURIComponent(key) + '=' + encodeURIComponent(value);
			if (_.indexOf(c.activeFilters, s) === -1) {
				c.activeFilters.push(s);
			}

			return c;
		},
		resetFilter: function () {
			this.activeFilters = [];
			return this;
		},

		url: function () {
			var c = this,
				url = c.urlRoot || '' + c.model.prototype.urlRoot,
				expands = c.expand || c.model.prototype.expand,
				queryStrings = [];

			// expands
			_.each(expands, function (expand) {
				queryStrings.push('expand=' + encodeURIComponent(expand));
			});

			// filters
			_.each(c.activeFilters, function (filter) {
				queryStrings.push(filter);
			});

			if (queryStrings.length) {
				url += '?' + queryStrings.join('&');
			}
			return url;
		},

		fetch: function (options) {
			options = options || {};

			var m = this,
				success = options.success,
				error = options.error;

			options = _.extend({parse: true}, options);

			options.success = function (resp) {
				var serverAttrs = options.parse ? m.parse(resp, options) : resp;

				if (!m.set(serverAttrs, options)) {
					return false;
				}

				if (success) {
					success.call(options.context, m, resp, options);
				}

				m.trigger('sync', m, resp, options);
			};

			options.error = function (resp) {
				if (error) {
					error.call(options.context, m, resp, options);
				}

				m.trigger('error', m, resp, options);
			};

			return m.sync('read', this, options);
		},

		live: function (view, options) {
			SubscriptionHelper.live(this, view, options);
		},
		unlive: function () {
			SubscriptionHelper.unlive(this);
		}
	});
});

