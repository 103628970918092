define('views/account/customerBookings', [
	'underscore',
	'views/_account',
	'helpers/template',
	'casirest2/collections/booking',
	'views/account/customerBookings-current',
	'views/account/customerBookings-future'
], function (
	_,
	AccountBaseView,
	templateHelper,
	BookingCollection,
	CurrentBookingsView,
	FutureBookingsView
) {
	'use strict';

	return AccountBaseView.extend({
		className: 'customerBookings',

		_initialize: function (options) {
			var v = this;
			v.collection = options.collection || new BookingCollection();
			v.collection.comparator = function (model) {
				return new Date(model.get('timeRange').start).getTime();
			};
		},

		render: function () {
			var v = this;
			
			v.collection.filterByBookingType(); // default is NORMAL and INSTANT_ACCESS
			v.collection.fetch();
			
			var currentBookingsView = new CurrentBookingsView({collection: v.collection});
			currentBookingsView.appendTo(v);
			var futureBookingsView = new FutureBookingsView({collection: v.collection});
			futureBookingsView.appendTo(v);
		}
	});
});

