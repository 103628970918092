define('casirest2/helpers/store',['backbone', 'underscore'], function (Backbone, _) {
	'use strict';

	var StoreHelper = {};
	_.extend(StoreHelper, Backbone.Events);

	StoreHelper.get = function (key) {
		try {
			return JSON.parse(localStorage['casi-' + key]);
		}
		catch (err) {
			return null;
		}
	};

	StoreHelper.set = function (key, value) {
		try {
			localStorage['casi-' + key] = JSON.stringify(value);
			return true;
		}
		catch (err) {
			return false;
		}
	};

	StoreHelper.reset = function() {
		for(var i in localStorage) {
			if(i.indexOf('casi-') === 0) {
				try {
					delete localStorage[i];
				}
				catch(error) {
					// do nothing
				}
			}
		}

		return StoreHelper;
	};

	return StoreHelper;
});

