define('views/list', [
	'underscore',
	'jquery',
	'views/_view',
	'views/listItem',
	'helpers/mapState',
	'helpers/string',
	'views/modals/prompt',
	'helpers/gmaps'
], function (
	_,
	$,
	BaseView,
	ListItemView,
	MapStateHelper,
	StringHelper,
	PromptModalView,
	google
) {
	'use strict';

	/**
	 * ListView
	 *
	 * Another central element of this application. Basically renders
	 * a List with all places and their occupancies. Like MapView. But
	 * without the map.
	 *
	 * @module views/list
	 * @class ListView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		className: 'list',

		/**
		 * Initialises the list by receiving the TimeHelper instance.
		 * If no Instance is passed, a new Instance is created.
		 *
		 * @param {Object} [options]
		 * @param {TimeHelper} options.timeHelper TimeHelper Instance this View should use. Default: new TimeHelper()
		 * @param {PlaceCollection} options.placeCollection PlaceCollection this View should use
		 * @param {BookingProposalCollection} options.proposalCollection ProposalCollection this View should use
		 * @constructor
		 */
		_initialize: function (options) {
			var v = this;
			v.timeHelper = options.timeHelper;
			v.placeCollection = options.placeCollection;
			v.proposalCollection = options.proposalCollection;
		},

		/**
		 * Renders the List
		 *
		 * @returns {MapView}
		 */
		render: function () {
			var v = this;
			$('<h2 class="list__headline" />').html(StringHelper.get('list.headline')).appendTo(v.$el);
			v.locationTitle = $('<h3 class="list__address" />').appendTo(v.$el);

			var $ul = $('<ul class="list__items" />').appendTo(v.$el);
			v.renderChildren(ListItemView, {
				collection: v.placeCollection,
				childOptions: {
					proposals: v.proposalCollection,
					timeHelper: v.timeHelper
				},
				where: $ul
			});

			var $empty = $('<div class="list__empty" />')
				.text(StringHelper.get('list.empty'))
				.appendTo(v.$el);

			v.listenToAndCall(v.placeCollection, 'add remove reset', function () {
				$empty.toggleClass('list__empty--visible', v.placeCollection.length === 0);
			});

			// show address from which we show the results
			v.listenToAndCall(MapStateHelper, 'change:lat change:lng change:lim', function () {
				if (MapStateHelper.isListMode()) {
					v.geocoder = v.geocoder || new google.maps.Geocoder();
					v.updateLocationTitle();
				}
			});


			return this;
		},

		/**
		 * Asks the browser for the current geolocation and compass data and
		 * visualize this data in our map.
		 *
		 * @param {function} [cb] Optional Callback
		 * @returns {ListView}
		 */
		openMyPosition: function (cb) {
			var v = this;

			if (!navigator.geolocation) {
				if (_.isFunction(cb)) {
					cb(new Error('Browser doesn\'t support navigator.geolocation!'));
				}

				return v;
			}

			navigator.geolocation.getCurrentPosition(function (position) {
				MapStateHelper.setCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				}).apply();

				if (_.isFunction(cb)) {
					_.defer(function () {
						cb(null);
					});
				}
			}, function (error) {
				if (error && error.code === 1) {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Ortung fehlgeschlagen',
							message: 'Leider haben wir nicht die nötigen Berechtigungen, um eine Ortung durchführen zu können.',
							type: 'error'
						})
					);
				} else {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Ortung fehlgeschlagen',
							message: 'Leider konnten wir dich nicht orten. Bitte versuche es später erneut.',
							type: 'error'
						})
					);
				}

				if (!error.code || [1, 2, 3].indexOf(error.code) === -1) {
					Raven.captureException(new Error(error.toString()));
				}

				if (_.isFunction(cb)) {
					cb(new Error('Browser doesn\'t support navigator.geolocation!'));
				}
			}, {
				timeout: 10000
			});

			return v;
		},

		updateLocationTitle: function () {
			var v = this;

			v.geocoder.geocode({
				location: MapStateHelper.getCenter()
			}, function (results, status) {
				if(status === 'OK' && results[0]) {
					var street = _.find(_.result(results[0], 'address_components'), function (c) {
						return _.contains(c.types, 'route');
					});

					var streetNr = _.find(_.result(results[0], 'address_components'), function (c) {
						return _.contains(c.types, 'street_number');
					});

					var postalCode = _.find(_.result(results[0], 'address_components'), function (c) {
						return _.contains(c.types, 'postal_code');
					});

					var city = _.find(_.result(results[0], 'address_components'), function (c) {
						return _.contains(c.types, 'locality');
					});

					v.locationTitle.text(
						(_.result(street, 'short_name') || '') + ' ' +
						(_.result(streetNr, 'short_name') || '') + ', ' +
						(_.result(postalCode, 'short_name') || '') + ' ' +
						(_.result(city, 'short_name') || '')
					);
				}
				else if(status === 'OK' && results[1]) {
					v.locationTitle.text(_.result(results[0], 'formatted_address'));
				}
				else if(status === 'OK') {
					v.locationTitle.text('Suchpunkt unbekannt');
					console.warn('No results found');
				}
				else {
					v.locationTitle.text('Suchpunkt unbekannt');
					console.warn('Geocoder failed due to: ' + status);
				}
			});
		}
	});
});

