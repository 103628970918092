define('casirest2/models/bookingProposal',['./_', '../collections/price'], function (Model, PriceCollection) {
	'use strict';

	/**
	 * @class BookingProposalModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/booking_proposals',
		expand: ['bookeeId', 'bookee.placeId', 'rating'],
		liveInterval: 90000,

		/**
		 * Returns a PriceCollection for this BookingProposal
		 * @returns {PriceCollection}
		 */
		getPrices: function() {
			var m = this;

			if (m.has('price')) {
				return new PriceCollection(m.get('price').priceItems);
			}
			
			return null;
		},
	});
});

