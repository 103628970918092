/**
 * Created by sebbo2002 on 18.11.14.
 * modified by phlp on 30.07.17
 */

define('helpers/template', [
	'backbone',
	'underscore',
	'helpers/settings',
	'handlebars',
	'helpers/handlebars'
], function (
	Backbone,
	_,
	SettingsHelper
) {
	'use strict';

	var templateHelper = function (name, context, callback) {
		if (!name) {
			throw 'name required';
		}

		if (_.isFunction(context)) {
			callback = context;
			context = {};
		}
		if (!_.isObject(context)) {
			context = {};
		}
		if (!_.isFunction(callback)) {
			throw 'callback required';
		}
		
		// global variables 
		context.ewi3 = {};
		context.ewi3.rootPath = SettingsHelper.getRootPath();
		

		if (name.split('/')[0] === 'modals') {
			name = name.substring(name.indexOf('/') + 1);
			require(['templates/modals/' + name, 'templates/modals/modal-close'], function (template, close) {
				var closeHTML = close['templates/modals/modal-close']({invert: context.modalInvert || false});
				var html = template['templates/modals/' + name](context);

				var markup = '<div class="modal__frame modal__frame--' + name + '">' + closeHTML + html + '</div>';

				callback(markup);
			});
		} else if (name.split('/')[0] === 'panels') {
			name = name.substring(name.indexOf('/') + 1);
			require(['templates/panels/' + name, 'templates/panels/panel-close'], function (template, close) {
				var closeHTML = close['templates/panels/panel-close']({invert: context.panelInvert || false});
				var html = template['templates/panels/' + name](context);
				callback(closeHTML + html);
			});
		} else {
			require(['templates/' + name], function (template) {
				var html = template['templates/' + name](context);
				callback(html);
			});
		}
	};

	return templateHelper;
});

