define('views/mapPlace', [
	'underscore',
	'views/_view',
	'helpers/gmaps',
	'helpers/mapState',
	'helpers/settings'
], function (
	_,
	BaseView,
	GoogleMapsHelper,
	MapStateHelper,
	SettingsHelper
) {
	'use strict';

	/**
	 * MapPlaceView
	 *
	 * View which represents a single place in our map. render() will
	 * render this place in our map as a marker.
	 *
	 * @module views/mapPlace
	 * @class MapPlaceView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({

		/**
		 * Initializes the MapPlaceView
		 *
		 * @param {Object} options
		 * @param {PlaceModel} options.model PlaceModel to render
		 * @param {MapView} options.map Reference to MapView instance to render the marker
		 * @param {BookingProposalCollection} options.proposals ProposalCollection the MapView creates to display labels
		 * @constructor
		 */
		_initialize: function (options) {
			this.map = options.map;
			this.proposals = options.proposals;
		},


		/**
		 * Renders the Place in our Map
		 *
		 * @returns {MapPlaceView}
		 */
		render: function () {
			var v = this,
				icons;

			v.highlighted = false;
			v.initialProposalSync = false;

			// get marker config
			v.updateMarkerConfig();
			icons = v.getIcons();
			v.listenTo(v.model, 'change:provId', v.updateMarkerConfig);

			// create marker
			v.marker = new GoogleMapsHelper.maps.Marker({
				position: new GoogleMapsHelper.maps.LatLng(
					v.model.get('geoPosition').latitude,
					v.model.get('geoPosition').longitude
				),
				title: v.model.get('name'),
				icon: icons.iconDefault,
				icons: icons,
				zIndex: 20,
				infoWindow: null,
				optimized: false,

				// https://developers.google.com/maps/documentation/javascript/3.exp/reference?hl=de#MarkerLabel
				label: v.label
			});

			v.map.clusterer.addMarker(v.marker);
			v.visible = 1;

			// initialSync
			v.listenToOnce(v.proposals, 'sync', function () {
				v.initialProposalSync = true;
				v.updateLabel();
			});

			// label
			v.listenTo(v.model, 'change:provId', v.updateLabel);
			v.listenTo(v.proposals, 'reset request sync', v.updateLabel);
			v.listenTo(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', v.updateLabel);
			v.updateLabel();

			// icon
			v.listenTo(v.model, 'change:provId', v.updateIcon);
			v.listenTo(v.proposals, 'sync reset request', function() {
				_.defer(v.updateIcon);
			});
			v.listenTo(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', v.updateIcon);
			v.updateIcon();

			// show / hide
			v.listenTo(v.model, 'change:isFixed', v.updateVisibility);
			v.listenTo(v.proposals, 'sync reset request', v.updateVisibility);
			v.listenTo(MapStateHelper, 'change:flt change:fbt', v.updateVisibility);
			v.listenTo(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', v.updateVisibility);
			v.updateVisibility();

			v.once('remove', function () {
				if(v.visible) {
					v.map.clusterer.removeMarker(v.marker);
				}
			});

			GoogleMapsHelper.maps.event.addListener(v.marker, 'click', function () {
				window.myApp.subNavigate('place/' + v.model.id, {trigger: true});
			});
			GoogleMapsHelper.maps.event.addListener(v.marker, 'mouseover', function () {
				v.marker.setZIndex(40);
			});
			GoogleMapsHelper.maps.event.addListener(v.marker, 'mouseout', function () {
				if (!v.highlighted) {
					v.marker.setZIndex(20);
				}
			});

			return this;
		},

		/**
		 * Gets the right marker config for this marker
		 * and saves it in v.config.
		 *
		 * @returns {MapPlaceView}
		 */
		updateMarkerConfig: function () {
			var v = this,
				fallback = null,
				markers = SettingsHelper.getMarkerConfig(),
				result;

			if(v.model) {
				result = _.find(markers, function (provider) {
					if (provider.id === 'default') {
						fallback = provider;
					}
					return v.model && provider.id === v.model.get('provId');
				});
			}
			else {
				result = _.find(markers, function (provider) {
					if (provider.id === 'default') {
						fallback = provider;
					}
					return v.model && provider.id === SettingsHelper.getProviderSettings().get('provId');
				});
			}

			v.config = _.extend({}, _.first(markers), fallback, result);
			return v;
		},

		/**
		 * Switch highlighted state of this marker
		 *
		 * @param {Boolean} highlighted True, if this marker should be highlighted, otherwise false
		 * @returns {MapPlaceView}
		 */
		highlight: function (highlighted) {
			var v = this;

			v.highlighted = highlighted === undefined ? true : highlighted;
			v.updateIcon();
			v.marker.setZIndex(v.highlighted ? 30 : null);
			return v;
		},


		/**
		 * Find and build the required icons, returns the structure
		 * for Marker.icons
		 *
		 * @returns {Object}
		 */
		getIcons: function () {
			var v = this;

			return _.mapObject(v.config.icons, function (iconType) {
				var result = {};

				_.each(iconType, function (value, type) {
					if (type === 'fileName') {
						result.url = SettingsHelper.getRootPath() + 'marker/' + v.config.id + '/' + value;
					}
					else if (type === 'size') {
						result.size = new GoogleMapsHelper.maps.Size(value[0], value[1]);
						result.scaledSize = new GoogleMapsHelper.maps.Size(value[0], value[1]);
					}
					else if(['anchor', 'labelOrigin'].indexOf(type) > -1) {
						result[type] = new GoogleMapsHelper.maps.Point(value[0], value[1]);
					}
					else if(['textSize', 'textColor', 'width', 'height'].indexOf(type) > -1) {
						result[type] = value;
					}
				});

				return result;
			});
		},

		/**
		 * Updates the marker icon
		 *
		 * @returns {MapPlaceView}
		 */
		updateIcon: function () {
			var v = this,
				fixed = !!v.model.get('isFixed'),
				available,
				icon;

			available = v.proposals.filter(function (proposal) {
				return v.model.id === proposal.get('bookee').placeId;
			}).length;

			if(SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET') {
				icon = 'iconWidget';
			}
			else if (!fixed) {
				icon = 'iconFreeFloater';
			}
			else if (available === 0) {
				icon = 'iconDefault';
			}
			else if (available > 0) {
				icon = 'iconFree';
			}
			else {
				icon = 'iconDefault';
			}

			if (v.highlighted) {
				icon += 'Selected';
			}

			v.marker.setIcon(v.marker.icons[icon]);
			return v;
		},

		/**
		 * Get the label text the marker should have currently
		 *
		 * @returns {String}
		 */
		getLabelText: function () {
			var v = this,
				available;

			if (!v.model.get('isFixed') || !v.model.get('bookees') || !v.model.get('bookees').length) {
				return ' ';
			}

			available = v.proposals.filter(function (proposal) {
				return v.model.id === proposal.get('bookee').placeId;
			}).length;

			if ((!v.initialProposalSync || v.model.collection.syncing) && !available) {
				available = '?';
			}

			return available + '/' + v.model.get('bookees').length;
		},

		/**
		 * Updates the marker label by using getLabelText()
		 *
		 * @returns {MapPlaceView}
		 */
		updateLabel: function () {
			var v = this,
				label = {};

			if (!v.config.label) {
				return v;
			}
			if(SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET') {
				v.marker.setLabel(null);
				return v;
			}

			label = _.extend(label, v.config.label);
			label.fontFamily = 'Tahoma, Geneva, sans-serif'; // one font to rule them all
			label.text = v.getLabelText();
			v.marker.setLabel(label);
			return v;
		},

		/**
		 * Updates the marker opacity
		 */
		updateVisibility: function() {
			var v = this,
				available = v.proposals.filter(function (proposal) {
					return v.model.id === proposal.get('bookee').placeId;
				}).length,
				opacity = (available || !v.initialProposalSync || v.model.collection.syncing) ? 1 : 0.5;

			if(
				SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET' &&
				!v.model.get('isFixed')
			) {
				opacity = 0;
			}

			if(opacity && !v.visible) {
				v.map.clusterer.addMarker(v.marker);
				v.visible = 1;
			}
			else if(!opacity && v.visible) {
				v.map.clusterer.removeMarker(v.marker);
				v.visible = 0;
			}

			v.marker.setOptions({
				opacity: opacity ? 1 : 0.5,
				zIndex: opacity * 20
			});
		}
	});
});

