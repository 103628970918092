define('views/halMode', [
	'underscore',
	'views/_view',
	'helpers/template',
	'casirest2/helpers/data'
], function (
	_,
	View,
	templateHelper,
	DataHelper
) {
	'use strict';

	/**
	 * HalModeView
	 *
	 * @module views/halMode
	 * @class HalModeView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'halMode halMode--hidden',

		/**
		 * @returns {HalModeView}
		 */
		render: function () {
			var v = this,
				i;

			templateHelper('halMode', function (html) {
				v.$el.html(html);
				v.$progress = v.$el.find('.halMode__progress');
				v.updateProgress();

				i = setInterval(function () {
					v.updateProgress();
				}, 5000);
				v.once('remove', function () {
					clearInterval(i);
				});
			});

			return v;
		},
		updateProgress: function () {
			var v = this,
				d = DataHelper.getPerformanceData(),
				requests = 0,
				duration = 0,
				rDuration;

			_.each(d, function (r) {
				if (r.end) {
					duration += r.end - r.start;
					requests += 1;
				} else {
					rDuration = new Date().getTime() - r.start;
					if(rDuration > 250) {
						duration += 10000;
						requests += 1;
					}
				}
			});

			v.$el.toggleClass('halMode--hidden', requests === 0);
			if (requests === 0) {
				return;
			}

			v.setProgress(duration / (requests || 1));
		},
		setProgress: function(_progress) {
			var v = this,
				progress;

			v.$el.toggleClass('halMode--hidden', _progress <= 100);
			
			progress = _progress - 50;
			progress = progress * 0.0006;
			progress = Math.max(progress, 0);
			progress = Math.min(progress, Math.PI / 2);
			progress = Math.sin(progress);
			progress = 100 - (progress * 100);

			v.$progress.css({
				width: Math.floor(progress) + '%',
				background: 'hsl(' + (progress / 100 * 120).toString(10) + ',100%,50%)',
				'border-right-color': 'hsl(' + (progress / 100 * 120).toString(10) + ',100%,50%)'
			});
		}
	});
});

