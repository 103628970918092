define('views/blocks/morePanelInternalEntry', [
	'underscore',
	'jquery',
	'views/_view',
	'helpers/template',
	'helpers/mapState'
], function (
	_,
	$,
	View,
	templateHelper,
	mapStateHelper
) {
	'use strict';

	/**
	 * MenuItemBlockView
	 *
	 * @module views/blocks/morePanelInternalEntry
	 * @class MenuItemBlockView
	 * @augments BaseView
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return View.extend({
		tagName: 'li',
		className: 'more__entry',
		events: {
			'click .button': 'click'
		},

		/**
		 * Constructor of this view.
		 * Just saves the options we need later.
		 *
		 * @param {Object} options
		 * @param {String} options.name Name of this item
		 * @param {String} options.route Route this item should trigger
		 * @param {Array<Object>} [options.showListeners] Listerer definitions
		 * @param {Function} [options.showWhen] Function to set if this item should be visible or not
		 * @returns MenuItemBlockView
		 */
		_initialize: function (options) {
			this.options = options;
			return this;
		},

		/**
		 * Renders the item and adds the event listeners.
		 *
		 * @returns {MenuItemBlockView}
		 */
		render: function () {
			var v = this;
			v.$el.addClass('more__entry--' + v.options.name);

			templateHelper('blocks/morePanelInternalEntry', v.options, function (html) {
				v.$el.html(html);
				v.$button = v.$el.children('button');

				if (v.options.route) {
					v.listenTo(window.myApp, 'route', function() {
						v.checkActiveRouteState();
						_.defer(function() {
							v.checkActiveRouteState();
						});
					});
					v.checkActiveRouteState();
				}
				else if(v.options.mapState) {
					v.listenToAndCall(mapStateHelper, 'applied', function() {
						v.$button.toggleClass('menu__button--active', !!mapStateHelper[v.options.mapState]());
					});
				} else {
					// active when nothing else is active
					v.listenTo(window.myApp, 'route', function() {
						v.checkActiveHashState();
						_.defer(function() {
							v.checkActiveHashState();
						});
					});
					v.checkActiveHashState();
				}

				// showListeners
				_.each(v.options.showListeners, function (listener) {
					v.listenTo(listener.object, listener.events, v.checkVisibility);
				});
				v.checkVisibility();

				// badge
				if (v.options.badge && v.options.badgeListeners) {
					v.$count = $('<span class="menu__filtercount" />').appendTo(v.$button);

					_.each(v.options.badgeListeners, function (listener) {
						v.listenTo(listener.object, listener.events, v.checkBadge);
					});
					v.checkBadge();
				}
			});

			return v;
		},

		/**
		 * Called when the item is clicked
		 *
		 * @param {Event} e Click-Event
		 */
		click: function (e) {
			var v = this,
				n = location.hash.split('/');
			e.preventDefault();

			if (v.options.route !== undefined && n.length >= 2 && n[1] === v.options.route.split('/')[0]) {
				window.myApp.subNavigate('', {trigger: true});
			}
			else if (v.options.route !== undefined) {
				window.myApp.subNavigate(v.options.route, {trigger: true});
			}

			if(v.options.mapState) {
				mapStateHelper[v.options.mapState](
					!mapStateHelper[v.options.mapState]()
				).apply();
			}
		},

		/**
		 * Checks item visibility by asking the given `showWhen()` method
		 *
		 * @return {MenuItemBlockView}
		 */
		checkVisibility: function () {
			var v = this,
				show = true;

			if (_.isFunction(v.options.showWhen)) {
				show = v.options.showWhen();
			}

			v.$el.toggleClass('more__entry--hidden', !show);
			return v;
		},

		checkActiveRouteState: function () {
			var hash = location.hash.split('/');
			hash.shift();
			hash = hash.join('/');

			this.$button.toggleClass('menu__button--active', hash.substr(0, this.options.route.length) === this.options.route);
		},
		
		checkActiveHashState: function () {
			var hash = location.hash.split('/');
			hash.shift();
			
			this.$button.toggleClass('menu__button--active', !hash.join('/'));
		},

		checkBadge: function() {
			var v = this,
				value = v.options.badge();

			v.$count.text(value || '');
		}
	});
});

