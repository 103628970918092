define('templates/modals/setPassword',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/setPassword"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"set__content modal__frame\">\n	<form class=\"set__form\">\n		<label class=\"set__field field\">\n			<span class=\"field__label\">Token</span>\n			<input name=\"secret\" type=\"text\" class=\"field__input field__input--text\" value=\""
    + container.escapeExpression(((helper = (helper = helpers.secret || (depth0 != null ? depth0.secret : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"secret","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"(via E-Mail erhalten)\" />\n		</label>\n		\n		<label class=\"set__field field\">\n			<span class=\"field__label\">Neues Passwort</span>\n			<input name=\"newPassword\" type=\"password\" class=\"field__input field__input--text field__input--password\" placeholder=\"Passwort\" value=\"\"  autofocus autocomplete=\"new-password\" />\n		</label>\n		\n		<button class=\"set__submit button\">Passwort setzen</button>\n	</form>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"modal__frame prompt__wrap prompt__wrap--success\">\n	<h3 class=\"prompt__headline prompt__headline--success\">\n		Passwort wurde zurückgesetzt\n	</h3>\n	<p class=\"prompt__message\">Sie erhalten eine E-Mail mit einem Link, über den Sie ein neues Passwort festlegen können.</p>\n	<button class=\"button prompt__button prompt__button--success\">Schließen</button>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.secret : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});

return this["JST"];

});
