require([
	'backbone',
	'underscore',
	'routers/app',
	'helpers/settings',
	'casirest2/helpers/data',
	'casirest2/models/token',
	'helpers/store'
], function (
	Backbone,
	_,
	AppRouter,
	SettingsHelper,
	DataHelper,
	TokenModel,
	StoreHelper
) {
	'use strict';

	window.myApp = new AppRouter();
	DataHelper.initialize({
		TokenModel: TokenModel,
		endpoint: SettingsHelper.getEndpointURL(),
		apiKey: SettingsHelper.getAuthKey(),
		technicalAdmin: SettingsHelper.getTechnicalAdmin()
	});
	Backbone.history.start();

	// here be dragons and global polyfill hacks
	if (/Android [4-6]/.test(navigator.appVersion)) {
		window.addEventListener('resize', function () {
			if (
				(document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') &&
				typeof document.activeElement.scrollIntoViewIfNeeded === 'function'
			) {
				window.setTimeout(function () {
					document.activeElement.scrollIntoViewIfNeeded();
				}, 0);
			}
		});
	}

	// prevent scrolling of body on iOS, even it shouldn't be scrollable at all.
	if ((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || !!window.cordova) {
		window.addEventListener('scroll', function () {
			window.scrollTo(0, 0);
		});
	}

	try {
		if (!StoreHelper.get('map-listfix')) {
			StoreHelper.set('map-listfix', true);
			// reset mapState
			StoreHelper.set('map-state', {});
			window.myApp.navigate('');
			window.location.reload();
		}
	} catch (e) {
		console.info('shitty browser detected');
	}

});

define("main", function(){});

