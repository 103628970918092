define('templates/modals/computeprice',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/computeprice"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"computeprice__data computeprice__data--duration\">\n	<div class=\"computeprice__subhead\">Dauer</div>\n	<div class=\"computeprice__presets\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.presets : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n	<div class=\"computeprice__input\">\n		<label class=\"computeprice__field computeprice__field--multi field\">\n			<input name=\"duration--hours\" type=\"number\" maxlength=\"5\" min=\"0\" pattern=\"\\d*\" class=\"field__input field__input--text field__input--duration\" value=\"\">\n			<span class=\"computeprice__unit\">h</span>\n		</label>\n		<label class=\"computeprice__field computeprice__field--multi field\">\n			<input name=\"duration--minutes\" type=\"number\" maxlength=\"5\" min=\"0\" pattern=\"\\d*\" class=\"field__input field__input--text field__input--duration\" value=\"\">\n			<span class=\"computeprice__unit\">min</span>\n		</label>\n	</div>\n	<div class=\"computeprice__result\">Zeitpreis: <span class=\"computeprice__value computeprice__value--duration\">–</span></div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "		<button class=\"computeprice__preset\" type=\"button\" data-id=\""
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "&thinsp;h</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h1 class=\"computeprice__headline modal__headline\">Kostenabschätzung</h1>\n\n<div class=\"computeprice__data computeprice__data--distance\">\n	<div class=\"computeprice__subhead\">Distanz</div>\n	<div class=\"computeprice__input\">\n		<label class=\"computeprice__field field\">\n			<input name=\"distance\" type=\"number\" maxlength=\"5\" min=\"0\" pattern=\"\\d*\" class=\"field__input field__input--text field__input--distance\" value=\"\">\n			<span class=\"computeprice__unit\">km</span>\n			\n		</label>\n	</div>\n	<div class=\"computeprice__result\">Kilometerpreis: <span class=\"computeprice__value computeprice__value--distance\">–</span></div>\n</div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showDuration : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"modal__buttonwrap\">\n	<button class=\"modal__button button button--autofit close\" type=\"button\">Schließen</button>\n</div>\n";
},"useData":true});

return this["JST"];

});
