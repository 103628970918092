define('casirest2/collections/price',['./_', '../models/price'], function (Collection, PriceModel) {
	'use strict';

	/**
	 * @class PriceCollection
	 */
	return Collection.extend({
		model: PriceModel,
		filterMethods: []
	});
});

