define('templates/panels/filter',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/filter"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panelfilter__types\"></div>\n\n<button class=\"button applyFilter\">Filter anwenden</button>\n<button class=\"button button--outline resetFilter\">Filter zurücksetzen</button>\n";
},"useData":true});

return this["JST"];

});
