define('views/currentBooking', [
	'underscore',
	'moment',
	'views/_view',
	'casirest2/collections/booking',
	'casirest2/helpers/data',
	'helpers/template',
	'views/blocks/currentBooking',
	'helpers/mapState',
	'jquery'
], function (
	_,
	moment,
	View,
	BookingCollection,
	DataHelper,
	templateHelper,
	CurrentBookingBlock,
	MapStateHelper,
	$
) {
	'use strict';

	return View.extend({
		className: 'currentBookings',

		events: {
			'mousein': 'refresh'
		},

		initialize: function () {
			this.collection = this.collection || new BookingCollection();
		},
		render: function () {
			var v = this,
				start = moment().subtract(10, 'm'),
				end = moment().add(2, 'h'),
				interval;

			templateHelper('currentBooking', function (html) {
				v.$el.html(html);

				v.blocks = {};

				v.collection.filterByTimeRange(start, end);
				v.listenTo(v.collection, 'add', function (booking) {
					v.addBooking(booking);
				});
				v.listenTo(v.collection, 'remove', function (booking) {
					v.removeBooking(booking);
				});
				v.listenTo(v.collection, 'reset', function () {
					MapStateHelper.showCurrentBookings(false);
					MapStateHelper.apply();
				});
				v.listenTo(v.collection, 'sync', function () {
					if (!v.collection.length) {
						MapStateHelper.showCurrentBookings(false);
						MapStateHelper.apply();
					}
				});
				v.listenToOnce(v.collection, 'sync', function () {
					if (($(window).width() > 900 || $(window).height() > 900) && !MapStateHelper.isListMode()) { // initially show only in mapView and on big screens
						MapStateHelper.showCurrentBookings(!!v.collection.length);
						MapStateHelper.apply();
					}
				});
				v.collection.each(function (booking) {
					v.addBooking(booking);
				});
				v.refresh();

				v.listenTo(DataHelper, 'auth:loggedIn auth:loggedOut', function () {
					v.refresh();
				});

				interval = setInterval(function () {
					v.refresh();
				}, 60000);
				v.once('remove', function () {
					clearInterval(interval);
				});

				v.listenToAndCall(MapStateHelper, 'change:scb', function () {
					v.forceMapStateStatus();
				});
			});

			v.listenToAndCall(MapStateHelper, 'change:lim', function () {
				v.$el.toggleClass('currentBookings--list-mode', MapStateHelper.isListMode());
			});
		},
		refresh: function () {
			var v = this;

			if (!DataHelper.isLoggedIn()) {
				v.collection.each(function (booking) {
					v.removeBooking(booking);
				});
				v.collection.reset([]);
				return;
			}
			if (v.refresh.lastRefresh && v.refresh.lastRefresh > new Date().getTime() - (1000 * 5)) {
				return;
			}

			v.refresh.lastRefresh = new Date().getTime();
			v.collection.fetch();
		},
		addBooking: function (booking) {
			var v = this;
			v.blocks[booking.id] = new CurrentBookingBlock({model: booking}).appendTo(v, '.currentBookings__list', {order: v.collection});
		},
		removeBooking: function (booking) {
			if (this.blocks[booking.id]) {
				this.blocks[booking.id].remove();
			}

			delete this.blocks[booking.id];
		},
		forceMapStateStatus: function () {
			var v = this;
			v.$el[MapStateHelper.showCurrentBookings() ? 'addClass' : 'removeClass']('currentBookings--visible');

			v.$el.find('.currentBookings__wrap')[MapStateHelper.showCurrentBookings() ? 'addClass' : 'removeClass']('currentBookings__wrap--visible');
		}
	});
});

