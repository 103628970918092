define('views/modals/setPassword', [
	'underscore',
	'jquery',
	'views/_view',
	'helpers/template',
	'views/blocks/closeButton',
	'casirest2/collections/customer',
	'formparams'
], function (
	_,
	$,
	View,
	templateHelper,
	CloseButtonView,
	CustomerCollection
) {
	'use strict';

	var setPasswordView = View.extend({

		modalName: 'set',

		modalEvents: {
			'submit .set__form': 'submit',
			'click .prompt__button': 'close'
		},

		_initialize: function (options) {
			this.options = options || null;
			this.secret = options.secret || null;
		},

		render: function () {
			var v = this;

			templateHelper('modals/setPassword', {secret: v.secret}, function (html) {
				v.$el.html(html);

				var closeButton = new CloseButtonView({
					inverted: true
				});
				closeButton.prependTo(v.$el.find('.set__form, .prompt__wrap'));

				v.listenTo(closeButton, 'close', function () {
					v.close();
				});
			});
		},

		submit: function (e) {
			var v = this;
			e.preventDefault();

			v.$el.addClass('loading');

			// prepare Values
			var values = $(e.target).formParams(false);

			if (!values.secret && !values.newPassword) {
				return;
			}

			CustomerCollection.applyPasswordReset(values, function(error) {
				if(!error) {
					v.remove();
					window.myApp.subNavigate('login', {trigger: true});
					return;
				}

				v.$el.removeClass('loading');
				v.$el.addClass('set__form');
			});
		},

		close: function () {
			var v = this;

			v.remove();
			// redirect to map.
			window.myApp.subNavigate('', {trigger: true});
		}
	});

	return setPasswordView;
});

