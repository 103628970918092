define('views/modals/prompt', [
	'underscore',
	'views/_modal',
	'helpers/template'
], function (
	_,
	ModalBaseView,
	templateHelper
) {
	'use strict';

	/**
	 * PromptModalView
	 *
	 * @module views/modals/prompt
	 * @class PromptModalView
	 * @augments ModalBaseView
	 */
	var PromptModalView = ModalBaseView.extend({
		title: null,
		message: null,
		buttonText: null,
		type: null,
		cb: null,

		modalName: 'prompt',
		modalEvents: {
			'click .prompt__button': 'accept'
		},

		/**
		 * @param {object} [options]
		 * @param {string} [options.title] Title of this promt, defaults to 'Bestätigung'
		 * @param {string} [options.message] Message for this promt, optional
		 * @param {string} [options.buttonText] Button text for this promt, optional
		 * @param {string} [options.type] Type, used go generate CSS classes
		 * @private
		 */
		__initialize: function (options) {
			this.title = options.title || 'Bestätigung';
			this.message = options.message || null;
			this.buttonText = options.buttonText || null;
			this.type = options.type || null;
			this.cb = options.cb || null;
		},

		/**
		 * Render method
		 * @returns {PromptModalView}
		 */
		render: function () {
			var v = this;

			templateHelper('modals/prompt', {
				title: v.title,
				message: v.message,
				buttonText: v.buttonText,
				type: v.type
			}, function (html) {
				var $frame = v.$el.html(html).find('.modal__frame');

				$frame.addClass('prompt');
				if (v.type) {
					$frame.addClass('prompt--' + v.type);
				}
			});

			return v;
		},

		/**
		 * @param {event} e
		 */
		accept: function (e) {
			var v = this;
			v.trigger('accept');
			e.preventDefault();
			if (_.isFunction(v.cb)) {
				v.cb();
			}
			v.remove();
		}
	});

	return PromptModalView;
});

