define('views/modals/cancelBooking', [
	'underscore',
	'jquery',
	'views/_modal',
	'views/modals/prompt',
	'helpers/template',
	'casirest2/models/prelimBooking',
	'moment'
], function (
	_,
	$,
	ModalBaseView,
	PromptModalView,
	templateHelper,
	PrelimBookingModel,
	moment
) {
	'use strict';

	/**
	 * CancelBookingModalView
	 *
	 * @module views/modals/cancelBooking
	 * @class CancelBookingModalView
	 * @augments ModalBaseView
	 */
	var CancelBookingModalView = ModalBaseView.extend({
		modalName: 'cancelBooking',
		modalEvents: {
			'click button.confirmButton': 'confirm'
		},

		__initialize: function (options) {
			this.options = options || null;
			this.booking = options.booking || null;
		},

		/**
		 * Render method
		 * @returns {CancelBookingModalView}
		 */
		render: function () {
			var v = this,
				bookeeImage;

			if (!v.booking.id) {
				return v;
			}
			
			bookeeImage = v.booking.getImage();

			// createPrelimBookingModel
			v.model = new PrelimBookingModel().cancelBooking(v.booking.get('id'));

			v.model.save({}, {
				success: function () {
					templateHelper('modals/cancelBooking', {
						start: moment(v.model.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
						end: moment(v.model.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
						bookee: v.booking.get('bookee'),
						isFixBookee: v.booking.get('bookee').locality === 'FIX',
						bookeeImage: bookeeImage ? bookeeImage.toJSON() : {},
						stop: false,
						cancel: true
					}, function (html) {
						v.$el.html(html);
					});
				},
				error: function (model, response) {
					v.remove();

					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Beenden fehlgeschlagen',
							message: (
								response && response.responseJSON && response.responseJSON.errorMessage ?
									response.responseJSON.errorMessage :
									'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
							),
							type: 'error'
						})
					);
				}
			});

			return v;
		},

		confirm: function () {
			var v = this,
				booking = v.model.confirm();

			v.listenToOnce(booking, 'sync', function () {
				v.trigger('bookingCanceled');
				v.remove();
				window.myApp.subNavigate('account/bookings', {trigger: true});
				window.myApp.view.currentBookingView.collection.fetch();
			});
			
			// remove expandings from url
			// there isn't much to expand on a canceled booking
			// see: https://cantaloupe.cantamen.de/admin/issues/14520
			booking.url = booking.url({noParams: true});

			booking.save({
				error: function (model, response) {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Beenden fehlgeschlagen',
							message: (
								response && response.responseJSON && response.responseJSON.errorMessage ?
									response.responseJSON.errorMessage :
									'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
							),
							type: 'error'
						})
					);
				}
			});
		},


		cancel: function () {
			var v = this;
			v.remove();
		}
	});

	return CancelBookingModalView;
});

