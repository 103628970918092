define('views/listItemBookee', [
	'underscore',
	'jquery',
	'views/_view',
	'views/panels/bookee',
	'helpers/string',
	'casirest2/models/bookee'
], function (
	_,
	$,
	BaseView,
	BookeePanelView,
	StringHelper,
	BookeeModel
) {
	'use strict';

	/**
	 * ListItemDetailsView
	 *
	 * @module views/listItemDetails
	 * @class ListItemDetailsView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		tagName: 'li',
		className: 'listbookees__bookee',
		events: {
			'click': 'openBookeePanel'
		},


		_initialize: function (options) {
			this.place = options.place;
			this.proposals = options.proposals;
			this.timeHelper = options.timeHelper;
		},

		/**
		 * @returns {MapView}
		 */
		render: function () {
			var v = this,
				$bookeewrap = $('<div class="listbookees__wrap" />').appendTo(v.$el),
				$fuel = $('<span class="listbookees__bookeeFuelLevel fuelLevel" />').appendTo($bookeewrap),
				$fuelbar = $('<span class="fuelLevel__level" />').appendTo($fuel),
				$name;
				v.$bookeeAvailabilitysize = $('<span class="listbookees__availabilitysize availabilitysize" />').appendTo(v.$el);
				v.$bookeeAvailable = $('<span class="availabilitysize__available" />').appendTo(v.$bookeeAvailabilitysize);
				v.$bookeeAmount = $('<span class="availabilitysize__amount" />').appendTo(v.$bookeeAvailabilitysize);

			// fuel level
			v.listenToAndCall(v.model, 'change:isElectroMobile change:fuelLevel', function () {
				var level = v.model.get('isElectroMobile') ? v.model.get('fuelLevel') : null;

				$fuel
					.toggleClass('fuelLevel--hidden', !level)
					.attr('title', level ? ('Ladestand: ' + level + ' %') : '');

				$fuelbar.css('width', (level || '0') + '%');
			});

			// name
			$name = $('<span class="listbookees__name" />').appendTo($bookeewrap);
			v.listenToAndCall(v.model, 'change:displayName', function () {
				$name.text(v.model.get('displayName'));
			});

			// small
			$name = $('<span class="listbookees__small" />').appendTo($bookeewrap);
			v.listenToAndCall(v.model, 'change:bookeeType change:licenseNumber', function () {
				var text = v.model.get('bookeeType').name;
				if (v.model.has('licenseNumber')) {
					text += ' (' + v.model.get('licenseNumber') + ')';
				}

				$name.text(text);
			});

			// bookeeType classes
			v.listenToAndCall(v.model, 'change:bookeeType', function () {
				v.$el.toggleClass('listbookees__bookee--pedelec', v.model.isPedelec());
				v.$el.toggleClass('listbookees__bookee--cargowheel', v.model.isCargoWheel());
			});

			// available class
			v.listenTo(v.proposals, 'sync reset request', v.updateAvailibity);
			v.updateAvailibity();
			
			v.listenToAndCall(v.model, 'change:isPool', function () {
				v.$bookeeAvailabilitysize.toggleClass('availabilitysize--hidden', !v.model.get('isPool'));
			});

			// info + buchen
			// var $buttonWrap = $('<div class="listbookees__actionwrap" />').appendTo(v.$el);
			// $('<button class="button listitems__button listbookees__info" />')
			// 	.text(StringHelper.get('list.bookee.info'))
			// 	.click(function () {
			// 		//window.myApp.subNavigate('place/' + v.place.id + '/' + v.model.id, {trigger: true});
			// 		v.openBookeePanel();
			// 	})
			// 	.appendTo($buttonWrap);
		},

		updateAvailibity: function () {
			var v = this,
				proposal = v.proposals.find(function (proposal) {
					return proposal.get('bookee').id === v.model.id;
				});
				
			if (proposal && proposal.has('availabilitySize')) {
				v.$bookeeAvailable.text(proposal.get('availabilitySize'));
			} else {
				v.$bookeeAvailable.text(0);
			}
			v.$bookeeAmount.text('X');

			v.$el.toggleClass('listbookees__bookee--available', !!proposal);
		},


		openBookeePanel: function () {
			var v = this,
				place = v.place,
				bookee = v.model,
				app = window.myApp.view,
				panel;

			if (
				bookee &&
				app.getOpenedPanel(0) &&
				app.getOpenedPanel(0).model &&
				app.getOpenedPanel(0).model instanceof BookeeModel &&
				app.getOpenedPanel(0).model.id === v.model.id
			) {
				return;
			}

			panel = new BookeePanelView({
				model: bookee,
				proposalCollection: v.proposals,
				timeHelper: v.timeHelper,
				place: place
			});
			v.listenTo(panel, 'remove', function () {
				if (location.hash.indexOf('/place/' + place.id) >= 0) {
					window.myApp.subNavigate('', {trigger: true});
				}
			});

			app.renderView(panel, {isRoot: true});
			window.myApp.subNavigate('place/' + place.id + '/' + bookee.id);
		}
	});
});

