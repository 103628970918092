define('views/modals/changeBooking', [
	'underscore',
	'moment',
	'helpers/template',
	'helpers/time',
	'casirest2/models/prelimBooking',
	'views/_modal',
	'views/blocks/timerange',
	'views/modals/prompt',
	'helpers/string'
], function (
	_,
	moment,
	templateHelper,
	TimeHelper,
	PrelimBookingModel,
	ModalBaseView,
	TimeRangeBlockView,
	PromptModalView,
	stringHelper
) {
	'use strict';

	/**
	 * ChangeBookingModalView
	 *
	 * @module views/modals/changeBooking
	 * @class ChangeBookingModalView
	 * @augments ModalBaseView
	 */
	var ChangeBookingModalView = ModalBaseView.extend({
		modalName: 'changeBooking',
		modalEvents: {
			'click .changeBooking__button--change': 'change',
			'click .changeBooking__button--changeAccept': 'change',
			'click .changeBooking__close': 'cancel'
		},

		__initialize: function (options) {
			this.options = options || null;
			this.booking = options.booking || null;
			this.prelimTimeRange = null;
		},

		/**
		 * Render method
		 * @returns {ChangeBookingModalView}
		 */
		render: function () {
			var v = this;

			if (!v.booking.id) {
				return v;
			}

			// search for image in remarks. 1. bookeeProduct 2. bookee.
			var bookeeImage = v.booking.getImage(),
				timeHelper = new TimeHelper();

			templateHelper('modals/changeBooking', {
				start: v.prelimTimeRange ? moment(v.prelimTimeRange.start, moment.ISO_8601).format('DD.MM.YYYY HH:mm') : moment(v.booking.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				end: v.prelimTimeRange ? moment(v.prelimTimeRange.end, moment.ISO_8601).format('DD.MM.YYYY HH:mm') : moment(v.booking.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				showTimeAsTextInsteadOfTimePicker: !!v.prelimTimeRange,
				constraints: v.constraints && v.constraints.length > 0 ? v.constraints : null,
				bookee: v.booking.get('bookee'),
				bookeeImage: bookeeImage ? bookeeImage.toJSON() : {},
				errors: v.errors
			}, function (html) {
				v.$el.html(html);

				if (!v.timeRangeBlock) {
					v.timeRangeBlock = new TimeRangeBlockView({
						start: v.prelimTimeRange ? moment(v.prelimTimeRange.start, moment.ISO_8601) : moment(v.booking.get('timeRange').start, moment.ISO_8601),
						end: v.prelimTimeRange ? moment(v.prelimTimeRange.end, moment.ISO_8601) : moment(v.booking.get('timeRange').end, moment.ISO_8601),
						startMin: moment(v.booking.get('changeInfo').minStart, moment.ISO_8601),
						endMin: v.prelimTimeRange ? timeHelper.getEndMin(moment(v.prelimTimeRange.start, moment.ISO_8601)) : timeHelper.getEndMin(moment(v.booking.get('timeRange').start, moment.ISO_8601)),
						max: moment(v.booking.get('changeInfo').maxEnd, moment.ISO_8601)
					});
				}
				v.timeRangeBlock.appendTo(v, '.modal__pickerwrap');
			});

			return v;
		},

		renderPrelimContent: function () {
			var v = this;

			v.constraints = [];
			if (v.booking.get('constraints')) {
				if (_.isNumber(v.booking.get('constraints').batteryCharge)) {
					var chargeLevel = parseInt(v.booking.get('constraints').batteryCharge, 10);
					v.constraints.push({
						title: stringHelper.get('constraints.batteryCharge.title'),
						message: stringHelper.get('constraints.batteryCharge.message', {chargeLevel: chargeLevel / 100})
					});
				} else if (v.booking.get('constraints').overlappingBookings) {
					v.constraints.push({
						title: stringHelper.get('constraints.overlappingBookings.title'),
						message: stringHelper.get('constraints.overlappingBookings.message')
					});
				} else {

					require(['views/modals/prompt'], function (SuccessModal) {
						var successModal = new SuccessModal({
							title: 'Fehler',
							message: 'Die Buchung konnte nicht durchgeführt werden.',
							type: 'error'
						});
						window.myApp.view.renderView(successModal);
					});

					v.remove();
				}
			}

			if (v.prelimTimeRange) {
				v.constraints.push({
					title: 'Abweichender Buchungszeitraum',
					message: 'Die Verfügbarkeit dieses Fahrzeugs weicht vom angefragten Zeitraum ab! Bitte beachten Sie den angepassten Buchungszeitraum!'
				});
			}

			v.render();
		},

		change: function () {
			var v = this;

			if (v.prelimTimeRange) {
				v.confirm();
				return;
			}

			v.loading(true);

			// clone booking into model, because return of save is an PrelimBookingModel
			v.model = v.booking.clone();
			v.model.set(v.booking.attributes);

			// set timeRange for prelim
			var timeRange = {
				start: v.timeRangeBlock.getValues().start.toISOString(),
				end: v.timeRangeBlock.getValues().end.toISOString()
			};

			v.model.save({
				timeRange: timeRange
			}, {
				success: function (model, response) {
					v.model = new PrelimBookingModel();
					v.model.set(model);

					if (
						!moment(response.timeRange.start, moment.ISO_8601).isSame(moment(timeRange.start, moment.ISO_8601)) ||
						!moment(response.timeRange.end, moment.ISO_8601).isSame(moment(timeRange.end, moment.ISO_8601))
					) {
						v.prelimTimeRange = response.timeRange;
						v.renderPrelimContent();
						return;
					}

					v.confirm();

				},
				report: function (model, response) {
					return (
						response.status !== 422 ||
						!response.responseJSON ||
						response.responseJSON.resultCode !== 'FIXABLE_ON_CONSTRAINTS'
					);
				},
				error: function (model, response) {
					if (
						response.status === 422 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'FIXABLE_ON_CONSTRAINTS'
					) {
						v.loading(false);
						v.booking.set('constraints', response.responseJSON.bookingConstraints);
						v.renderPrelimContent();
					} else if (
						response.status === 422 &&
						response.responseJSON &&
						response.responseJSON.resultCode === 'NOT_MODIFIED'
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Nichts geändert',
								message: 'Es wurde keine Änderung vorgenommen.',
								type: 'success'
							})
						);
						
						v.remove();
					} else {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Änderung fehlgeschlagen',
								message: (
									response && response.responseJSON && response.responseJSON.errorMessage ?
										response.responseJSON.errorMessage :
										'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
								),
								type: 'error'
							})
						);

						v.remove();
					}
				}
			});
		},

		confirm: function () {
			var v = this,
				confirmation = v.model.confirm();

			// remove expandings from url
			// there's an api bug when expanding prices on quernutzung
			// see: https://cantaloupe.cantamen.de/admin/issues/14523
			confirmation.url = confirmation.url({noParams: true});
			
			confirmation.save({}, {
				success: function () {
					v.booking.set(confirmation.toJSON());
					
					v.trigger('bookingChanged', v.booking);

					v.loading(false);
					v.remove();

					window.myApp.subNavigate('account/bookings', {trigger: true});
					window.myApp.view.currentBookingView.collection.fetch();
				},
				report: function (model, response) {
					return !(
						(
							response.status !== 422 ||
							!response.responseJSON &&
							response.responseJSON.resultCode !== 'PROMOTION_CODE_INVALID'
						) ||
						(
							response.status !== 400 ||
							!response.responseJSON &&
							response.responseJSON.resultCode !== 'BOOKING_NOT_AVAILABLE' &&
							response.responseJSON.errorMessage.indexOf('POOLFULL') < 0
						)
					);
				},
				error: function (model, response) {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Änderung fehlgeschlagen',
							message: (
								response && response.responseJSON && response.responseJSON.errorMessage ?
									response.responseJSON.errorMessage :
									'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
							),
							type: 'error'
						})
					);
					
					v.loading(false);
					v.remove();
				}
			});
		},

		cancel: function () {
			var v = this;

			if (!v.model) {
				v.remove();
				return;
			}

			v.model.destroy({
				success: function () {
					v.remove();
				},
				error: function () {
					v.remove();
				}
			});
		}
	});

	return ChangeBookingModalView;
});

