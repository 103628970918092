define('templates/blocks/timerange',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/timerange"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"timerange__pickers\">\n	<div class=\"timerange__picker timerange__picker--start\"></div>\n	<div class=\"timerange__picker timerange__picker--end\"></div>\n</div>\n";
},"useData":true});

return this["JST"];

});
