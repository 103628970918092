define('views/logo', [
	'views/_view',
	'helpers/settings',
	'jquery'
], function (
	View,
	SettingsHelper,
	$
) {
	'use strict';

	/**
	 * LogoView
	 *
	 * @module views/logo
	 * @class LogoView
	 * @augments BaseView
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return View.extend({
		className: 'logo',

		/**
		 * @returns {LogoView}
		 */
		render: function () {
			var v = this,
				$clone = v.$el.clone(),
				$img = $('<img class="logo__image" />');

			$img.attr('src', SettingsHelper.getRootPath() + 'img/logo.svg').appendTo(v.$el);

			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:useLogoOnHeader', function (settings) {
				if (settings.get('useLogoOnHeader')) {
					$clone = v.$el.clone();
					$('body').prepend($clone); // very dirty hack, to get arround moving everything
				} else {
					$clone.remove();
					v.$el.detach();
				}
			});

			return v;
		}
	});
});

