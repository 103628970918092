define('templates/currentBooking',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/currentBooking"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"currentBookings__wrap\">\n	<ul class=\"currentBookings__list\"></ul>\n</div>\n";
},"useData":true});

return this["JST"];

});
