define('templates/blocks/booking',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/booking"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<img class=\"bookings__image\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1), depth0))
    + "?width=200\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.bookeeProduct : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" onerror=\"this.onerror=null;this.src='img/homer.jpg'\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " bookings__vehicle--nolink";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<span class=\"bookings__plate\">("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.licenseNumber : stack1), depth0))
    + ")</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " bookings__station--nolink";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.city : stack1)) != null ? stack1.name : stack1), depth0))
    + ")\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "	<div class=\"bookings__remark\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookingRemark : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookingRemark : stack1), depth0))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"bookings__prices prices\">\n		<div class=\"prices__tablewrap\">\n			<ul class=\"prices__list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.prices : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n	</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "				<li class=\"prices__item\">\n					<span class=\"prices__type\">"
    + alias1(((helper = (helper = helpers.costTypeName || (depth0 != null ? depth0.costTypeName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"costTypeName","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"prices__amount\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.priceIncl : depth0)) != null ? stack1.amountFormated : stack1), depth0))
    + "</span>\n				</li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"booking.action.trips.door",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + " ";
},"18":function(container,depth0,helpers,partials,data) {
    return "		<li class=\"bookings__action bookings__action--enlarge\"><button type=\"button\" class=\"bookings__button bookings__button--enlarge\">Ende ändern</button></li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "		<li class=\"bookings__action bookings__action--change\"><button type=\"button\" class=\"bookings__button bookings__button--change\">Ändern</button></li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "		<li class=\"bookings__action bookings__action--stop\"><button type=\"button\" class=\"bookings__button bookings__button--stop\">Beenden</button></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing, alias5="function";

  return "<td class=\"bookings__cell bookings__cell--image\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n\n<td class=\"bookings__cell bookings__cell--station\">\n\n	<button class=\"bookings__vehicle"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.isFixed : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.bookeeProduct : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.licenseNumber : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</button>\n\n	<div class=\"bookings__bookeeType\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\n\n	<button class=\"bookings__station"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.isFixed : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.city : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</button>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookingRemark : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n\n</td>\n\n<td class=\"bookings__cell bookings__cell--startend\">\n	<div class=\"bookings__start\">"
    + ((stack1 = ((helper = (helper = helpers.start || (depth0 != null ? depth0.start : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"start","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n	<div class=\"bookings__end\">"
    + ((stack1 = ((helper = (helper = helpers.end || (depth0 != null ? depth0.end : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"end","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.prices : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n\n<td class=\"bookings__cell bookings__cell--buttons\">\n\n<button type=\"button\" class=\"bookings__morebutton button button--account\"><span class=\"bookings__buttontext\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias4).call(alias1,"booking.options",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</span></button>\n\n	<ul class=\"bookings__actions\">\n		<li class=\"bookings__action bookings__action--details\"><button type=\"button\" class=\"bookings__button bookings__button--details\">Details</button></li>\n		<li class=\"bookings__action bookings__action--startable\"><button type=\"button\" class=\"bookings__button bookings__button--startable\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.doorCount : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias4).call(alias1,"booking.action.starttrip.label",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</button></li>\n		<li class=\"bookings__action bookings__action--endable\"><button type=\"button\" class=\"bookings__button bookings__button--endable\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.doorCount : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias4).call(alias1,"booking.action.endtrip.label",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</button></li>\n		<li class=\"bookings__action bookings__action--reopenable\"><button type=\"button\" class=\"bookings__button bookings__button--reopenable\">Türe öffnen</button></li>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFlexDontShow : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFlexDontShow : depth0),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFlexShowUnderConditions : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<li class=\"bookings__action bookings__action--cancel\"><button type=\"button\" class=\"bookings__button bookings__button--cancel\">Stornieren</button></li>\n		<li class=\"bookings__action bookings__action--abortendtrip\"><button type=\"button\" class=\"bookings__button bookings__button--abortendtrip\">Rückgabe abbrechen</button></li>\n		<li class=\"bookings__action bookings__action--editremark\"><button type=\"button\" class=\"bookings__button bookings__button--editremark\">Bemerkung ändern</button></li>\n	</ul>\n</td>\n";
},"useData":true});

return this["JST"];

});
