define('views/blocks/unavailabilityInterval', [
	'jquery',
	'underscore',
	'moment',
	'views/_view',
	'views/blocks/unavailabilityLayer',
	'helpers/settings'
], function (
	$,
	_,
	moment,
	View,
	UnavailabilityLayerBlockView
) {
	'use strict';

	/**
	 * UnavailabilityIntervalBlock
	 *
	 * @module views/blocks/unavailabilityInterval
	 * @class UnavailabilityIntervalBlock
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'unavailability__interval',
		events: {
			click: 'click'
		},

		_initialize: function (options) {
			this.timeHelper = options.timeHelper;
			this.providerSettings = options.providerSettings;
			this.moment = options.moment;
			this.label = options.label || false;
			this.unavailabilities = options.unavailabilities;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {UnavailabilityDayBlock}
		 */
		render: function () {
			var v = this;

			if(v.label) {
				$('<span class="unavailability__label" />')
					.text(v.moment.format('H:mm'))
					.appendTo(v.$el);
			}

			v.$el.append('<span class="unavailability__layerhelper"></span>');

			if (v.moment.format('mm') === '00') {
				v.$el.addClass('unavailability__interval--fullhour');
			}

			v.listenTo(v.timeHelper, 'change', v.update);
			v.listenTo(v.unavailabilities, 'sync', v.update);
			v.update();
			return v;
		},
		update: function() {
			var v = this,
				momentEnd = moment(this.moment).add(moment.duration(this.providerSettings.get('defaultBookingModel').interval)),
				minAllowed = moment().add(moment.duration(v.providerSettings.get('defaultBookingModel').minAdvance)),
				maxAllowed = moment().add(moment.duration(v.providerSettings.get('defaultBookingModel').maxAdvance)),
				unavailability;

			unavailability = !!v.unavailabilities.filter(function(u) {
				var occStart = moment(u.get('timeRange').start),
					occEnd = moment(u.get('timeRange').end);

				return occEnd.isSameOrAfter(v.moment) && occStart.isSameOrBefore(momentEnd);
			}).length;

			v.$el.toggleClass('unavailability__interval--prerange', v.moment.isSameOrBefore(minAllowed));
			v.$el.toggleClass('unavailability__interval--postrange', v.moment.isSameOrAfter(maxAllowed));
			v.$el.toggleClass('unavailability__interval--postrange', v.moment.isSameOrAfter(maxAllowed));
			v.$el.toggleClass('unavailability__interval--occupied', unavailability);

			v.$el.toggleClass(
				'unavailability__interval--inrange',
				v.moment.isSameOrAfter(v.timeHelper.get('start')) && v.moment.isBefore(v.timeHelper.get('end'))
			);

			v.$el.toggleClass('unavailability__interval--start', v.moment.isSame(v.timeHelper.get('start')));
			v.$el.toggleClass('unavailability__interval--end', v.moment.isSame(moment(v.timeHelper.get('end')).subtract(moment.duration(v.providerSettings.get('defaultBookingModel').interval))));
		},
		click: function(e) {
			var v = this;
			e.preventDefault();
			e.stopPropagation();

			v.timeHelper.trigger('unavailabilityLayer:open');

			v.layer = new UnavailabilityLayerBlockView({
				timeHelper: v.timeHelper,
				moment: v.moment,
				providerSettings: v.providerSettings,
				unavailabilities: v.unavailabilities
			});
			v.listenToOnce(v.layer, 'remove', function() {
				if(v.layer) {
					v.$el.find('.unavailability__layerhelper').removeClass('unavailability__layerhelper--visible');
					v.layer.remove();
					delete v.layer;
				}
			});
			$('body').one('click', function() {
				if(v.layer) {
					v.layer.remove();
					delete v.layer;
				}
			});

			v.layer.appendTo(v.$el.parent());
			v.$el.find('.unavailability__layerhelper').addClass('unavailability__layerhelper--visible');

			v.listenToOnce(v.timeHelper, 'unavailabilityLayer:open', function() {
				if(v.layer) {
					v.layer.remove();
					delete v.layer;
				}
			});
		}
	});
});

