define('views/panels/placeWidgetInfo', [
	'underscore',
	'views/_panel',
	'views/blocks/remarks',
	'helpers/template'
], function (
	_,
	PanelView,
	RemarksBlock,
	templateHelper
) {
	'use strict';

	return PanelView.extend({
		panelName: ['placeInfo'],

		_initialize: function (options) {
			this.showAlerts = options.showAlerts === undefined ? true : options.showAlerts;
			this.renderCallback = options.renderCallback;
		},

		render: function () {
			var v = this;

			templateHelper('panels/placeWidgetInfo', {
				panelInvert: true,
				place: v.model.toJSON(),
				provider: v.model.get('provider'),
				logo: _.find(v.model.get('provider').remarks, function (remark) {
					return remark.infoType.indexOf('LOGO') !== -1;
				}),
				provUrl: _.find(v.model.get('provider').remarks, function (remark) {
					return remark.contentType.indexOf('text/url') !== -1;
				}),
				placeUrl: _.find(v.model.get('remarks'), function (remark) {
					return remark.contentType.indexOf('text/url') !== -1;
				})
			}, function (html) {
				v.$el.html(html);

				// render Remarks
				var remarksBlock = new RemarksBlock({
					remarks: v.model.get('remarks'),
					showAlerts: v.showAlerts,
					provider: v.model.get('provider'),
					place: v.model,
					autoOpen: true
				});
				remarksBlock.appendTo(v, '.panel__remarks');

				if(_.isFunction(v.renderCallback)) {
					v.renderCallback(v);
					v.renderCallback = null;
				}
			});
		}

	});
});

