define('casirest2/collections/place',['./_', '../models/place'], function (Collection, PlaceModel) {
	'use strict';

	/**
	 * @class PlaceCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: PlaceModel,
		filterMethods: ['city', 'bounds', 'location', 'provider', 'isFixed', 'timeRange']
	});
});

