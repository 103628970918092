define('helpers/mapState', [
	'underscore',
	'backbone',
	'helpers/store',
	'helpers/settings',
	'helpers/gmaps'
], function (_, Backbone, storeHelper, SettingsHelper, google) {
	'use strict';

	/**
	 * MapStateHelper
	 *
	 * Holdes the MapState and synchronizes it partly with
	 * the user's localStorage and the app URL.
	 *
	 * @module helpers/mapState
	 * @class MapStateHelper
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	var mapStateHelper = {
		state: {
			lat: null, // Center -> Latitude
			lng: null, // Center -> Longitude
			zom: null, // Zoom Level
			ffa: null, // show free float areas?
			flt: null, // filter active?
			fbt: [],   // filter by bookee type id
			scb: null, // show current bookings?
			lim: null  // show list instead of map
		},
		
		/**
		 * Regex for the Paramter of :mapState
		 */
		parameterReg : /(\d+(?:\.\d+)?)-(-?\d+(?:\.\d+)?)-(\d+)-(\d+)/,

		/**
		 * Initialises the helper. Returns true, if the helper is ready
		 * to use, otherwise 0. In this case, usually BookingHelper is
		 * not ready to be used.
		 *
		 * @returns {boolean} Initializing successful?
		 */
		initialize: function () {
			var providerSettings = SettingsHelper.getProviderSettings(),
				endpointSettings = SettingsHelper.getEndPointSettings(),
				searchRange;

			if (!providerSettings.get('bookingUISettings')) {
				return false;
			}
			if (mapStateHelper.initialize.done) {
				return true;
			}

			searchRange = providerSettings.get('bookingUISettings').defaultSearchRange;
			mapStateHelper.state = _.extend(mapStateHelper.state, storeHelper.get('map-state'));


			// Fallback Center
			if (!mapStateHelper.state.lat || !mapStateHelper.state.lng) {
				mapStateHelper.state.lat = searchRange.position.latitude;
				mapStateHelper.state.lng = searchRange.position.longitude;
			}

			// Fallback Zoom Level
			if (!mapStateHelper.state.zom || isNaN(mapStateHelper.state.zom)) {
				if (_.isNumber(searchRange.range)) {
					mapStateHelper.state.zom = Math.round(14 - Math.log(searchRange.range / 1640.42) / Math.LN2) + 1;
				} else {
					mapStateHelper.state.zom = 14; // Fallback of the Fallback
				}
			}

			// delete deprecated values
			delete mapStateHelper.state.plc;
			delete mapStateHelper.state.ffl;

			// Fallback showFreeFloatAreas
			if (mapStateHelper.state.ffa === null) {
				mapStateHelper.state.ffa = 1;
			}

			// Fallback showCurrentBookings
			if (mapStateHelper.state.scb === null) {
				mapStateHelper.state.scb = 1;
			}

			// Fallback list mode
			if (mapStateHelper.state.lim === null) {
				mapStateHelper.state.lim = endpointSettings.get('bookingInterfaceStartsWith') === 'LIST' ? 1 : 0;
			}

			mapStateHelper.initialize.done = 1;
			return true;
		},

		/**
		 * Checks MapStateHelper state by calling apply() internally
		 *
		 * @params {string} state State
		 */
		checkState: function (state) {
			if (!mapStateHelper.initialize()) {
				_.defer(function () {
					mapStateHelper.checkState(state);
				});
				return;
			}
			if(!state) {
				return;
			}
			
			var states,
				lat,
				lng,
				zom,
				code;

			states = state.match(mapStateHelper.parameterReg);
			
			if (states === null) {
				return;
			}
			
			lat = parseFloat(states[1]);
			lng = parseFloat(states[2]);
			zom = parseInt(states[3], 10);
			code = parseInt(states[4], 10);
			
			// lat / lng
			if (lat > 0 && mapStateHelper.state.lat.toString().substr(0, lat.length) !== lat) {
				mapStateHelper.state.lat = lat;
			}
			if (lng && mapStateHelper.state.lng.toString().substr(0, lng.length) !== lng) {
				mapStateHelper.state.lng = lng;
			}

			// zoom
			if (zom && mapStateHelper.state.zom.toString() !== zom) {
				mapStateHelper.state.zom = zom;
			}

			// FreeFloatAreaVisible
			code %= 16;
			mapStateHelper.state.ffa = code >= 8;

			// List Mode
			code %= 8;
			mapStateHelper.state.lim = code >= 4;

			// Current Bookings visible
			code %= 4;
			mapStateHelper.state.scb = code >= 2;

			// Filter active
			code %= 2;
			mapStateHelper.state.flt = code >= 1;

			mapStateHelper.apply();
		},

		/**
		 * Generates the URI state used to pass MapState state
		 * via URLs.
		 *
		 * @returns {string}
		 */
		generateState: function () {
			var state = [],
				intState = 0;

			mapStateHelper.initialize();

			// Latutude / Longitude
			state.push(Math.round(mapStateHelper.state.lat * 10000) / 10000);
			state.push(Math.round(mapStateHelper.state.lng * 10000) / 10000);

			// Zoom Level
			state.push(mapStateHelper.state.zom);

			// Filter States
			if (mapStateHelper.state.ffa) {
				intState += 8; // FreeFloatAreaVisible
			}
			if (mapStateHelper.state.lim) {
				intState += 4; // List Mode
			}
			if (mapStateHelper.state.scb) {
				intState += 2; // Current Bookings visible
			}
			if (mapStateHelper.state.flt) {
				intState += 1; // Filter active
			}
			state.push(intState);

			return state.join('-');
		},

		/**
		 * Applys the state by triggering events and saving the state
		 * to localStorage and the URL.
		 *
		 * @returns {Object}
		 */
		apply: function () {
			var oldState = storeHelper.get('map-state');
			mapStateHelper.initialize();

			mapStateHelper.trigger('apply');
			storeHelper.set('map-state', mapStateHelper.state);

			_.each(mapStateHelper.state, function (newState, key) {
				if (!oldState || JSON.stringify(newState) !== JSON.stringify(oldState[key])) {
					mapStateHelper.trigger('change:' + key, oldState ? oldState[key] : null, newState);
				}
			});

			var isStateFragments = (location.hash || '').substr(1).split('/'),
				isStateFragment = isStateFragments[0],
				shouldStateFragment;

			shouldStateFragment = mapStateHelper.generateState();
			if (isStateFragment !== shouldStateFragment) {
				isStateFragments[0] = shouldStateFragment;
				window.myApp.navigate(
					isStateFragments.join('/')
				);
			}

			mapStateHelper.trigger('applied');
			return mapStateHelper;
		},

		getCenter: function () {
			mapStateHelper.initialize();

			return new google.maps.LatLng(
				mapStateHelper.state.lat,
				mapStateHelper.state.lng
			);
		},
		setCenter: function (center) {
			mapStateHelper.initialize();
			mapStateHelper.state.lat = _.result(center, 'lat') || mapStateHelper.state.lat;
			mapStateHelper.state.lng = _.result(center, 'lng') || mapStateHelper.state.lng;
			return mapStateHelper;
		},
		getZoomLevel: function () {
			mapStateHelper.initialize();
			return mapStateHelper.state.zom;
		},
		setZoomLevel: function (level) {
			mapStateHelper.initialize();
			mapStateHelper.state.zom = level;
			return mapStateHelper;
		},

		showFreeFloatAreas: function (yep) {
			mapStateHelper.initialize();

			if (yep === undefined) {
				return mapStateHelper.state.ffa;
			}

			mapStateHelper.state.ffa = !!yep;
			return mapStateHelper;
		},
		isFiltered: function (yep) {
			mapStateHelper.initialize();

			if (yep === undefined) {
				return mapStateHelper.state.flt;
			}

			mapStateHelper.state.flt = !!yep;

			return mapStateHelper;
		},
		filterByBookeeTypeIds: function (ids) {
			mapStateHelper.initialize();

			if (ids === undefined) {
				return mapStateHelper.state.fbt;
			}

			ids.sort();
			mapStateHelper.state.fbt = ids;

			return mapStateHelper;
		},
		showCurrentBookings: function (yep) {
			mapStateHelper.initialize();

			if (yep === undefined) {
				return mapStateHelper.state.scb;
			}

			mapStateHelper.state.scb = !!yep;
			return mapStateHelper;
		},
		isListMode: function(yep) {
			mapStateHelper.initialize();

			if (yep === undefined) {
				return !!mapStateHelper.state.lim;
			}

			mapStateHelper.state.lim = !!yep;

			return mapStateHelper;
		}

	};

	_.extend(mapStateHelper, Backbone.Events);
	mapStateHelper.initialize();
	return mapStateHelper;
});

