define('views/account', [
	'underscore',
	'backbone',
	'views/_view',
	'views/modals/login',
	'views/blocks/closeButton',
	// 'views/blocks/accountMenu',
	'casirest2/helpers/data'
], function (
	_,
	Backbone,
	View,
	LoginModalView,
	CloseButtonBlock,
	// AccountMenuBlock,
	DataHelper
) {
	'use strict';

	return View.extend({
		className: 'account',

		render: function () {
			var v = this;

			// close button
			var closeButton = new CloseButtonBlock({
				remove: false,
				inverted: true
			}).appendTo(v);
			v.listenTo(closeButton, 'click', function () {
				window.myApp.subNavigate('', {trigger: true});
			});

			v.listenTo(window.myApp, 'route', function () {
				var p = Backbone.history.fragment.split('/');
				if (p.length < 2 || p[1] !== 'account') {
					v.hide();
				}
			});

			// no menu until we have more than "Personal Settings", perhaps reactivate for invoices?
			// v.menu = new AccountMenuBlock().appendTo(v);
		},
		renderView: function (view) {
			var v = this;

			v.trigger('empty');

			if (!DataHelper.isLoggedIn()) {
				var login = new LoginModalView();
				window.myApp.view.renderView(login);

				v.listenToOnce(login, 'remove', function () {
					if (DataHelper.isLoggedIn()) {
						v.renderView(view);
					}
				});

				return v;
			}

			view.appendTo(v);
			v.once('empty', function () {
				view.remove();
			});
			v.show();
			return v;
		},

		hide: function () {
			var v = this;
			v.$el.parent().removeClass('app__area--account-visible');

			_.delay(function () {
				v.trigger('empty');
			}, 500);
		},
		show: function () {
			this.$el.parent().addClass('app__area--account-visible');
		}
	});
});

