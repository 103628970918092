define('helpers/time',[
	'backbone',
	'underscore',
	'lib/moment',
	'helpers/settings'
],
function (
	Backbone,
	_,
	moment,
	SettingsHelper
) {
	'use strict';
	
	/**
	 * TimeHelper
	 *
	 * Helper to for managing time in EWI3.
	 * Required by all calls from the mapView
	 * 
	 * There is always one global instance
	 * other instance used to change a booking
	 * 
	 * @module helpers/time
	 * @class TimeHelper
	 * @author Philip <philip@whitespace.gmbh>
	 */
	
	var TimeHelper = function () {
		var h = this;
		
		h.initialize.done = 0;
		
		h.providerSettingsModel = SettingsHelper.getProviderSettings();
		
		// refresh the settings. Settings are initially deployt with EWI3 and can change over time
		h.listenToOnce(h.providerSettingsModel, 'sync', function () {
			h.providerSettingsUpdate();
		});
		h.listenTo(h.providerSettingsModel, 'change:defaultBookingModel', function () {
			h.providerSettingsUpdate();
		});

		h.initialize();
	};
	
	_.extend(TimeHelper.prototype, Backbone.Events);

	/**
	 * Returns true, if this TimeHelper instance is ready to use.
	 * Otherwise returns false. You can use the `initialized`-Event
	 * to get notified when the instance is ready as well.
	 *
	 * @returns {boolean}
	 */
	TimeHelper.prototype.isReady = function() {
		return !!this.initialize.done;
	};
	
	TimeHelper.prototype.initialize = function () {
		var h = this;
		
		h.start = h.round(moment());
		h.startMin = h.round(moment(), 'floor');
		h.end = h.start.clone().add(moment.duration(h.providerSettingsModel.get('defaultBookingModel').minBookingLength));
		h.endMin = h.start.clone().add(moment.duration(h.providerSettingsModel.get('defaultBookingModel').minBookingLength));
		h.step = moment.duration(h.providerSettingsModel.get('defaultBookingModel').interval);
		h.max = moment().add(moment.duration(h.providerSettingsModel.get('defaultBookingModel').maxAdvance)).subtract(1, 'day').endOf('day');
		
		/**
		 *  delay TimeHelper change events (too many events, while dealing with the calendar)
		 */
		var delayedTimeHelperEvent = _.debounce(function () {
			h.trigger('change');
		}, 50);
		h.on('realtimeChange', function () {
			delayedTimeHelperEvent();
		});
		
		
		h.initialize.done = 1;
		h.trigger('initialized');
	};

	TimeHelper.prototype.providerSettingsUpdate = function () {
		var h = this;
		
		h.startMin = h.round(moment(), 'floor');
		h.endMin = h.start.clone().add(moment.duration(h.providerSettingsModel.get('defaultBookingModel').minBookingLength));
		h.step = moment.duration(h.providerSettingsModel.get('defaultBookingModel').interval);
		h.max = moment().add(moment.duration(h.providerSettingsModel.get('defaultBookingModel').maxAdvance)).subtract(1, 'day').endOf('day');

		h.update();
	};
	
	
	/**
	 * Returns a rounded moment object of a given time.
	 * @param {date} moment - some date
	 * @param {date} method - method for rounding (floor / ceil) – defaults to proiderSetting
	 * @returns {moment} rounded value of {date}
	 */
	TimeHelper.prototype.round = function (date, method) {
		var h = this;
		if (!method) {
			if (h.providerSettingsModel.has('bookingUISettings')) {
				method = h.providerSettingsModel.get('bookingUISettings').bookingFormStartInterval === 'CURRENT' ? 'floor' : 'ceil';
			} else {
				method = 'ceil';
			}
		}

		var step = h.step || moment.duration(h.providerSettingsModel.get('defaultBookingModel').interval);

		return moment(Math[method]((date.valueOf() / step.as('milliseconds'))) * step.as('milliseconds'));
	};
	
	
	/**
	 * Returns the requested object
	 * @param {key} string - name
	 * @returns {moment}
	 */
	TimeHelper.prototype.get = function (key) {
		var h = this;
		if (!_.isUndefined(h[key])) {
			return h[key];
		}

		return null;
	};
	
	
	/**
	 * Set key / value
	 * @param {key} string - name
	 * @param {value} moment - object to save
	 * @returns {bool}
	 */
	TimeHelper.prototype.set = function (key, value) {
		var h = this;
		if (!_.isUndefined(h[key])) {
			// start & end have to be valid moment objects
			if ((key === 'start' || key === 'end') && !(value instanceof moment)) {
				return false;
			}
			h[key] = h.round(value);
			h.trigger('realtimeChange');

			return true;
		}
		
		return false;
	};
		
		
		
	/**
	 * Update start and end at the same time
	 * @param {start} moment - time of start
	 * @param {end} moment - time of end
	 * @returns nothing
	 */
	TimeHelper.prototype.update = function (start, end) {
		var h = this,
			endchanged = false;
		
		if (start && !h.get('start').isSame(start)) {
			h.set('start', start);
		}
		if (end && !h.get('end').isSame(end)) {
			h.set('end', end);
			endchanged = true;
		}
		
		// prevent hourly jumps
		_.defer( function() {
			if (endchanged) { h.adjustStart(); }
			h.adjustEnd();
			h.adjustStartMin();
			h.adjustEndMin();
		});
	};
		

	// *********************************************
	// ********* picker helper functions ***********
	// *********************************************
	
	/**
	 * Adjusts the start, when start is after end + minBookingLength
	 * Called after setting new end
	 * @returns nothing
	 */
	TimeHelper.prototype.adjustStart = function () {
		var h = this;
		var lag = moment.duration(h.providerSettingsModel.get('defaultBookingModel').minBookingLength);
		var endWithoutLag = h.end.clone().subtract(lag);
		
		if (h.get('start').isAfter(endWithoutLag)) {
			h.set('start', endWithoutLag);
		}
	};
	
	/**
	 * Adjusts the end, when end is before start + minBookingLength
	 * Called after setting new start or end
	 * @returns nothing
	 */
	TimeHelper.prototype.adjustEnd = function () {
		var h = this;
		var lag = moment.duration(h.providerSettingsModel.get('defaultBookingModel').minBookingLength);
		var startWithLag = h.start.clone().add(lag);
		if (startWithLag.isAfter(h.end)) {
			h.set('end', startWithLag);
		}
	};

	/**
	 * Adjusts the minimum ending, to current start + minBookingLength, adjust end if needed
	 * Called after setting new start or end
	 * @returns nothing
	 */
	TimeHelper.prototype.adjustEndMin = function () {
		var h = this;
		var lag = moment.duration(h.providerSettingsModel.get('defaultBookingModel').minBookingLength);
		h.set('endMin', h.start.clone().add(lag)); // always set min of end to start
		if (h.end.isBefore(h.endMin)) {
			h.set('end', h.start.clone().add(lag));
		}
	};
	
	/**
	 * Adjusts mininum of start (mostly when the current time moves in next interval)
	 * Called after setting new start or end
	 * @returns nothing
	 */
	TimeHelper.prototype.adjustStartMin = function () {
		var h = this;
		if (h.start.isSameOrBefore(moment())) {
			h.set('start', h.round(moment(), 'floor'));
			h.set('startMin', h.round(moment(), 'floor'));
		}
	};
	
	
	/**
	 * Get the mininmum ending
	 * @param {start} moment - start for mininmum ending
	 * @returns {moment}
	 */
	TimeHelper.prototype.getEndMin = function (start) {
		return start.clone().add(moment.duration(this.providerSettingsModel.get('defaultBookingModel').minBookingLength));
	};
	
	return TimeHelper;
});

