define('casirest2/models/providerSettings',[
	'./_',
], function (Model) {
	'use strict';

	/**
	 * @class ProviderSettingsModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/providers/default/settings',
		isAlwaysSingle: true
	});
});

