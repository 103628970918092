define('views/blocks/filterBookeeType', [
	'underscore',
	'../_view',
	'./filterTypeButton',
	'helpers/template',
	'casirest2/collections/bookeeType'
], function (
	_,
	View,
	FilterTypeButtonView,
	templateHelper,
	BookeeTypeCollection
) {
	'use strict';

	return View.extend({
		className: 'panelfilter__type',

		events: {},

		initialize: function () {
			_.bindAll(this, 'render', 'addFilterType');
			this.collection = new BookeeTypeCollection();
		},

		render: function () {
			var v = this;

			v.collection.filterByProvider('default');
			v.collection.comparator = 'name';
			v.listenTo(v.collection, 'add', v.addFilterType);
			v.listenTo(v.collection, 'sync', function() {
				v.trigger('loading', false);
			});
			

			templateHelper('blocks/filterType', {
				filterTypeName: 'Fahrzeugklassen'
			}, function (html) {
				v.$el.html(html);
				v.trigger('loading', true);
				v.collection.fetch();
			});
		},
		addFilterType: function(filterBookeeType) {
			var v = this;
			new FilterTypeButtonView({model: filterBookeeType}).appendTo(v, '.panelfilter__buttonlist', { order: v.collection });
		}
	});
});

