define('views/panels/place', [
	'underscore',
	'helpers/template',
	'helpers/settings',
	'views/_panel',
	'views/blocks/remarks',
	'views/blocks/bookee',
	'views/blocks/placePanelAlert',
	'views/panels/remarks',
	'views/panels/unavailability',
	'views/panels/bookee',
	'casirest2/models/bookee',
	'casirest2/collections/remark'
], function (
	_,
	templateHelper,
	SettingsHelper,
	PanelView,
	RemarkBlock,
	BookeeBlock,
	PlacePanelAlertBlock,
	RemarksPanelView,
	UnavailabilityPanelView,
	BookeePanelView,
	BookeeModel,
	RemarkCollection
) {
	'use strict';

	/**
	 * PlacePanel
	 *
	 * @module views/panels/place
	 * @class PlacePanelView
	 * @augments PanelBaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return PanelView.extend({
		panelName: ['place'],
		panelEvents: {
			'click .panel__button--showRemarks': 'openRemarksEvent',
			'click .panel__button--showUnavailability': 'showUnavailabilityEvent'
		},

		bookeeBlocks: {},

		/**
		 * @param {object} options
		 * @param {PlaceModel} options.model
		 * @param {BookingProposalCollection} options.proposalCollection
		 * @param {TimeHelper} options.timeHelper
		 * @returns {module:views/panels/place}
		 * @private
		 */
		_initialize: function (options) {
			var v = this;
			v.bookeeBlocks = {};

			v.timeHelper = options.timeHelper;
			v.proposals = options.proposalCollection;
			v.renderCallback = options.renderCallback;

			if (!v.proposals) {
				throw new Error('PlacePanelView: `options.proposalCollection` is not given, but required!');
			}

			return v;
		},


		render: function () {
			var v = this;

			// Close panel if place model was removed
			v.listenTo(v.model.collection, 'remove', function (removedModel) {
				if (removedModel.id === v.model.id) {
					v.remove();
				}
			});

			templateHelper('panels/place', {
				remarks: v.model.has('remarks'),
				warning: v.model.get('remarks') ? _.filter(v.model.get('remarks'), function (remark) {
					return _.contains(remark.infoType, 'WARNING');
				}) : null
			}, function (html) {
				var alarms = new RemarkCollection();

				// get Bookees of Place – sort them.
				v.bookees = v.model.getBookees(v);
				v.bookees.comparator = function (bookee) {
					return [bookee.get('bookeeType').name, bookee.get('bookeeProduct').name, bookee.get('licenseNumber')];
				};
				v.bookees.sort();

				v.$el.html(html);

				v.listenToAndCall(v.model, 'change:name change:remarks', v.updateHero);

				// Alarms
				v.listenTo(alarms, 'add', function (alarm) {
					var $alerts = v.$el.find('.panel__alerts');
					$alerts.removeClass('panel__alerts--hidden');

					new PlacePanelAlertBlock({model: alarm}).appendTo(v, $alerts);
				});
				alarms.add(v.model.getAlarms(v).toJSON());

				// Remarks Button
				v.listenToAndCall(v.model.getRemarks(v), 'add remove', function (c) {
					v.$el.find('.panel__button--showRemarks').toggleClass('panel__button--hidden', c.length === 0);
				});

				// Warnings
				v.listenToAndCall(v.model.getWarnings(v), 'add remove', function (c) {
					v.$el.find('.panel__button--showRemarks').toggleClass('panel__button--warning', c.length > 0);
					v.$el.find('.button__info').toggleClass('button--hidden', c.length === 0);
				});

				// Bookees
				v.listenToAndCall(v.bookees, 'sync', v.updateLoader);
				v.listenToAndCall(v.proposals, 'sync', v.updateLoader);
				v.listenTo(v.bookees, 'add', v.addBookee);
				v.bookees.each(v.addBookee);
				v.updateLoader();

				if (_.isFunction(v.renderCallback)) {
					v.renderCallback(v);
				}
			});
		},

		updateHero: function () {
			var v = this,
				image = v.model.getImage({
					onlyMarketing: true
				});

			v.$el.find('.panel__heroimage').attr({
				src: image ? (image.get('reference') + '?width=450') : SettingsHelper.getRootPath() + 'img/placedummy.jpg',
				alt: v.model.get('name')
			});
			v.$el.find('.panel__innerheadline').text(v.model.get('name'));
		},
		updateLoader: function () {
			var v = this;

			v.loading((!v.bookees || v.bookees.syncing) || (!v.proposals || v.proposals.syncing));

			// keine bookees
			if (!v.bookees.length) {
				v.remove();
			}
		},

		addBookee: function (bookee) {
			var v = this,
				view = new BookeeBlock({
					model: bookee,
					place: v.model,
					proposals: v.proposals,
					parent: v,
					timeHelper: v.timeHelper
				}).appendTo(v, v.$el.find('.panel__bookees'));

			v.bookeeBlocks[bookee.id] = view;
			v.listenTo(bookee.collection, 'remove', function (removed) {
				if (removed.id === bookee.id) {
					v.bookeeBlocks[bookee.id] = null;
					view.remove();
				}
			});
			v.listenToOnce(view, 'priceSync', function () {
				v.$el.find('.panel__listlegend--hidden').removeClass('panel__listlegend--hidden');
			});
		},


		openRemarks: function () {
			var v = this,
				panel;

			// remarksPanel is already visible.
			if (v.getChildPanel() instanceof RemarksPanelView) {
				return;
			}
			if (v.getChildPanel()) {
				v.getChildPanel().remove();
			}

			panel = new RemarksPanelView({
				collection: v.model.getRemarks(),
				showAlerts: false
			});
			v.listenToOnce(panel, 'remove', function () {
				v.$el.find('.panel__button--showRemarks').removeClass('panel__button--active');
				window.myApp.subNavigate('place/' + v.model.id, {trigger: true});
			});

			v.$el.find('.panel__button--showRemarks').addClass('panel__button--active');
			v.renderChildPanel(panel);
			window.myApp.subNavigate('place/' + this.model.id + '/about');
		},
		openRemarksEvent: function () {
			window.myApp.subNavigate('place/' + this.model.id + '/about', {trigger: true});
		},

		showUnavailability: function () {
			var v = this,
				panel;

			if (v.getChildPanel() instanceof UnavailabilityPanelView) {
				return;
			}
			if (v.getChildPanel()) {
				v.getChildPanel().remove();
			}

			panel = new UnavailabilityPanelView({
				model: v.model,
				timeHelper: v.timeHelper
			});
			v.listenToOnce(panel, 'remove', function () {
				v.$el.find('.panel__button--showUnavailability').removeClass('panel__button--active');
				if (location.hash.indexOf('/place/' + v.model.id) >= 0) {
					window.myApp.subNavigate('place/' + v.model.id, {trigger: true});
				}
			});

			v.$el.find('.panel__button--showUnavailability').addClass('panel__button--active');
			v.renderChildPanel(panel);
			window.myApp.subNavigate('place/' + this.model.id + '/availability');
		},
		showUnavailabilityEvent: function () {
			window.myApp.subNavigate('place/' + this.model.id + '/availability', {trigger: true});
		},

		openBookee: function (bookee) {
			var v = this,
				panel;

			if (!(bookee instanceof BookeeModel) && v.bookees) {
				bookee = v.bookees.get(bookee);
			}
			if (!bookee) {
				return;
			}

			if (
				bookee &&
				v.getChildPanel() &&
				v.getChildPanel().model &&
				v.getChildPanel().model instanceof BookeeModel &&
				v.getChildPanel().model.id === v.model.id
			) {
				return;
			}
			if (v.getChildPanel()) {
				v.getChildPanel().remove();
			}

			panel = new BookeePanelView({
				model: bookee,
				proposalCollection: v.proposals,
				timeHelper: v.timeHelper,
				place: v.model
			});
			v.listenTo(panel, 'remove', function () {
				if (v.bookeeBlocks[bookee.id]) {
					v.bookeeBlocks[bookee.id].$el.removeClass('panel__button--active');
				}

				if (location.hash.indexOf('/place/' + v.model.id) >= 0) {
					window.myApp.subNavigate('place/' + v.model.id, {trigger: true});
				}
			});

			if (v.bookeeBlocks[bookee.id]) {
				v.bookeeBlocks[bookee.id].$el.addClass('panel__button--active');
			}
			v.renderChildPanel(panel);
			window.myApp.subNavigate('place/' + this.model.id + '/' + bookee.id);
		}
	});
});

