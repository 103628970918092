define('templates/panels/more',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/more"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<ul class=\"more__links more__links--internal\"></ul>\n\n<ul class=\"more__links more__links--external\"></ul>\n";
},"useData":true});

return this["JST"];

});
