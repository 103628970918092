define('helpers/string', ['../strings.' + LANGUAGE], function (strings) {
	'use strict';

	var stringHelper = {};

	stringHelper.get = function (key, replacements) {
		var s = strings[key];
		if (!s) {
			throw new Error('Ups. String with ID `' + key + '` not found… :(');
		}

		replacements = replacements || {};
		for (var i in replacements) {
			if (replacements.hasOwnProperty(i)) {
				s = s.replace('{{' + i + '}}', replacements[i]);
			}
		}

		return s;
	};

	return stringHelper;
});

