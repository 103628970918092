define('helpers/settings', [
	'backbone',
	'underscore',
	'helpers/store',
	'casirest2/helpers/data',
	'casirest2/models/endpointSettings',
	'casirest2/models/providerSettings',
	'casirest2/models/provider'
], function (
	Backbone,
	_,
	storeHelper,
	DataHelper,
	EndpointSettingsModel,
	ProviderSettingsModel,
	ProviderModel
) {
	'use strict';

	/**
	 * SettingsHelper
	 *
	 * Helper to load and cache all settings, EWI3 requires to work well.
	 * Internally this works by fetching these models:
	 *
	 * - EndpointSettings
	 * - ProviderSettings
	 *
	 * To make app starts faster, these models values are cached via Store-
	 * Helper, so it's required to watch the model for changes!
	 *
	 * @module helpers/settings
	 * @class SettingsHelper
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	var SettingsHelper = {};
	_.extend(SettingsHelper, Backbone.Events);

	var secrets = {
		endpointSettings: new EndpointSettingsModel(JSON.parse('@@CONFIGURATION_DEFAULT_ENDPOINT_SETTINGS') || {}),
		providerSettings: new ProviderSettingsModel(JSON.parse('@@CONFIGURATION_DEFAULT_PROVIDER_SETTINGS') || {}),
		otherProviderSettings: {},
		provider: new ProviderModel(JSON.parse('@@CONFIGURATION_DEFAULT_PROVIDER') || {}),
		otherProvider: {},
		build: {
			id: '@@CONFIGURATION_BUILD_ID' || null,
			commit: '@@CONFIGURATION_BUILD_COMMIT' || null,
			production: !!('@@CONFIGURATION_PRODUCTION') || false,
			environment: '@@CONFIGURATION_BUILD_ENVIRONMENT' || null
		},
		endpointUrl: '@@CONFIGURATION_ENDPOINT_URL' || null,
		authKey: '@@CONFIGURATION_AUTH_KEY' || null,
		technicalAdmin: '@@CONFIGURATION_TECHNICAL_ADMIN' || null,
		markerConfig: JSON.parse('@@CONFIGURATION_MARKER_CONFIG') || {},
		rootPath: '@@ROOT_PATH'
	};


	_.each(['endpointSettings', 'providerSettings'], function(id) {
		var storeKey = 'settings-' + id.replace('Settings', ''),
			model = secrets[id],
			local = storeHelper.get(storeKey),
			fetchDelay = 0;

		if(local) {
			model.set(local);
			model.trigger('sync', model, null, {});
			fetchDelay = 10000;
		}

		model.on('sync', function () {
			storeHelper.set(storeKey, model.toJSON());
		});

		DataHelper.on('auth:loggedIn auth:loggedOut', function() {
			model.fetch();
		});

		_.delay(function() {
			model.fetch();
		}, fetchDelay);
	});


	/**
	 * Returns the EndpointSettingsModel. My not be ready, so be sure
	 * to watch for changes with `change:`.
	 *
	 * @returns {EndpointSettingsModel}
	 */
	SettingsHelper.getEndPointSettings = function() {
		return secrets.endpointSettings;
	};

	/**
	 * Returns the ProviderSettingsModel. My not be ready, so be sure
	 * to watch for changes with `change:`.
	 *
	 * @param {string} [providerId] ID of provider to return settings of, defaults to default provider
	 * @returns {ProviderSettingsModel}
	 */
	SettingsHelper.getProviderSettings = function(providerId) {
		if(providerId === undefined || providerId === secrets.providerSettings.id) {
			return secrets.providerSettings;
		}
		if(secrets.otherProviderSettings[providerId]) {
			return secrets.otherProviderSettings[providerId];
		}

		secrets.otherProviderSettings[providerId] = new ProviderSettingsModel({id: providerId});
		secrets.otherProviderSettings[providerId].urlRoot = '/providers/' + providerId + '/settings';
		secrets.otherProviderSettings[providerId].fetch();
		return secrets.otherProviderSettings[providerId];
	};

	/**
	 * Returns the ProviderModel. My not be ready, so be sure
	 * to watch for changes with `change:`.
	 *
	 * @param {string} [providerId] ID of provider to return object of, defaults to default provider
	 * @returns {ProviderModel}
	 */
	SettingsHelper.getProvider = function(providerId) {
		if(providerId === undefined || providerId === secrets.provider.id) {
			return secrets.provider;
		}
		if(secrets.otherProvider[providerId]) {
			return secrets.otherProvider[providerId];
		}

		secrets.otherProvider[providerId] = new ProviderModel({id: providerId});
		secrets.otherProvider[providerId].urlRoot = '/providers/' + providerId;
		secrets.otherProvider[providerId].fetch();
		return secrets.otherProvider[providerId];
	};

	/**
	 * Returns the Build ID of this instance
	 *
	 * @returns {string|null}
	 */
	SettingsHelper.getBuildId = function() {
		return secrets.build.id;
	};

	/**
	 * Returns the Commit Hash of this instance
	 *
	 * @returns {string|null}
	 */
	SettingsHelper.getBuildCommit = function() {
		return secrets.build.commit;
	};

	/**
	 * Returns the Build Environment of this instance
	 *
	 * @returns {string|null}
	 */
	SettingsHelper.getBuildEnvironment = function() {
		return secrets.build.environment;
	};

	/**
	 * Returns true, if this instance is an production build…
	 *
	 * @returns {boolean}
	 */
	SettingsHelper.isProduction = function() {
		return !!secrets.build.production;
	};

	/**
	 * Returns the Endpoint URL
	 *
	 * @returns {string|null}
	 */
	SettingsHelper.getEndpointURL = function() {
		return secrets.endpointUrl;
	};

	/**
	 * Returns the Authorization Key
	 *
	 * @returns {string|null}
	 */
	SettingsHelper.getAuthKey = function() {
		return secrets.authKey;
	};
	
	/**
	 * Returns the Technical Admin
	 *
	 * @returns {string|null}
	 */
	SettingsHelper.getTechnicalAdmin = function() {
		return secrets.technicalAdmin;
	};

	/**
	 * Returns the Marker Configuration Plain Object
	 *
	 * @returns {object|null}
	 */
	SettingsHelper.getMarkerConfig = function() {
		return secrets.markerConfig;
	};

	/**
	 * Returns the Marker Configuration Plain Object
	 *
	 * @returns {object|null}
	 */
	SettingsHelper.getMarkerConfig = function() {
		return secrets.markerConfig;
	};

	/**
	 * True, if the configured endpoint es a testing one…
	 *
	 * @returns {boolean}
	 */
	SettingsHelper.isDeveloperEndpoint = function() {
		return secrets.endpointUrl.indexOf('test.') > -1 || secrets.endpointUrl.indexOf('preview.') > -1;
	};

	/**
	 * Returns the app's relative root path
	 * This is `/` usually, but for the app bundle, this my be changed…
	 *
	 * @returns {string}
	 */
	SettingsHelper.getRootPath = function() {
		return secrets.rootPath;
	};


	return SettingsHelper;
});

