define('views/account/settingsAccessMedia', [
	'jquery',
	'views/_account'
], function (
	$,
	AccountBaseView
) {
	'use strict';

	return AccountBaseView.extend({
		tagName: 'label',
		className: 'customerSettingsAccessMedia customerSettings__field field',
		
		_initialize: function (options) {
			this.readonly = options.readonly || false;
		},

		render: function () {
			var v = this,
				$span,
				$input;

			$span = $('<span class="field__label field__label--account" />').appendTo(v.$el);
			v.listenToAndCall(v.model, 'change:id', function() {
				if(v.model.id) {
					$span.text('Karte');
				}else{
					$span.text('Karte hinzufügen');
				}
			});

			$input = $('<input type="text" />')
				.addClass('field__input field__input--account field__input--text')
				.appendTo(v.$el);
			if (v.readonly) {
				$input.attr('readonly', v.readonly);
			}

			v.listenToAndCall(v.model, 'change:number', function() {
				$input.val(v.model.get('number'));
			});
			v.listenToAndCall(v.model, 'change:id', function() {
				$input.attr('name', v.model.id ? 'accessmedia-' + v.model.id : 'accessmedia-' + (Math.round(Math.random() * 9000) + 1000));
			});
		}
	});
});

