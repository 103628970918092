define('casirest2/collections/bookingProposal',['./_', '../models/bookingProposal'], function (Collection, BookingProposalModel) {
	'use strict';

	/**
	 * @class BookingProposalCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: BookingProposalModel,
		filterMethods: ['bookee', 'place', 'city', 'bounds', 'location', 'provider', 'localities', 'timeRange'],
		liveInterval: 90000
	});
});

