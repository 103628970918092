define('templates/menu',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/menu"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<nav class=\"menu__cnt\">\n	<ul class=\"menu__list\"></ul>\n</nav>\n";
},"useData":true});

return this["JST"];

});
