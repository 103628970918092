define('views/modals/changeBookingRemark', [
	'underscore',
	'jquery',
	'views/_modal',
	'views/modals/prompt',
	'helpers/template',
	'moment'
], function (
	_,
	$,
	ModalBaseView,
	PromptModalView,
	templateHelper,
	moment
) {
	'use strict';

	/**
	 * ChangeBookingRemarkModalView
	 *
	 * @module views/modals/changeBookingRemark
	 * @class ChangeBookingRemarkModalView
	 * @augments ModalBaseView
	 */
	var ChangeBookingRemarkModalView = ModalBaseView.extend({
		modalName: 'changeBookingRemark',
		modalEvents: {
			'click .changeBookingRemark': 'change',
			'click .changeBookingRemark__close': 'remove'
		},

		__initialize: function (options) {
			this.options = options || null;
			this.booking = options.booking || null;
		},

		/**
		 * Render method
		 * @returns {ChangeBookingRemarkModalView}
		 */
		render: function () {
			var v = this;

			if (!v.booking.id) {
				return v;
			}

			// search for image in remarks. 1. bookeeProduct 2. bookee.
			var bookeeImage = v.booking.getImage();

			templateHelper('modals/changeBookingRemark', {
				start: moment(v.booking.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				end: moment(v.booking.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY HH:mm'),
				bookingRemark: v.booking.get('bookingRemark'),
				bookee: v.booking.get('bookee'),
				isFixBookee: v.booking.get('bookee').locality === 'FIX',
				bookeeImage: bookeeImage ? bookeeImage.toJSON() : {},
				errors: v.errors
			}, function (html) {
				v.$el.html(html);
			});

			return v;
		},

		change: function () {
			var v = this;
			v.loading(true);

			var newBookingRemark = v.$el.find('.bookingRemark').val();
			v.booking.updateAdditionalData({
				bookingRemark: newBookingRemark || ''
			}, function(error) {
				if(!error) {
					v.remove();
					v.trigger('bookingChanged', v.booking);
					window.myApp.view.currentBookingView.collection.fetch();
					return;
				}
				v.loading(false);

				window.myApp.view.renderView(new PromptModalView({
					title: 'Fehler',
					message: 'Die Buchungsbemerkung konnte nicht gespeichert werden',
					buttonText: 'OK',
					type: 'error'
				}));
			});
		}
	});

	return ChangeBookingRemarkModalView;
});

