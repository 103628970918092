define('casirest2/collections/customer',['./_', '../models/customer', '../helpers/data'], function (Collection, CustomerModel, DataHelper) {
	'use strict';

	/**
	 * @class CustomerCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: CustomerModel
	}, {
		/**
		 * Request a password reset
		 *
		 * @param {object} options
		 * @param {number} options.provId Provider ID
		 * @param {string} [options.email] email address of customer
		 * @param {string} [options.name] last name of customer
		 * @param {string} [options.number] customer number
		 * @param {string} [options.cardNumber] number of access card
		 * @param {string} [options.authChannel]
		 * @param {function} [cb] Callback
		 * @returns {DataHelperXHR}
		 */
		requestPasswordReset: function(options, cb) {
			return DataHelper.ajax({
				type: 'POST',
				url: '/passwords/requestReset',
				data: options,
				success: function () {
					cb(null);
				},
				report: function(msg, $xhr) {
					if($xhr.status === 403) {
						return false;
					}
				},
				error: function () {
					cb(true);
				}
			});
		},

		/**
		 * Apply a password reset
		 *
		 * @param {object} options
		 * @param {string} [options.secret] the user's secret
		 * @param {string} [options.newPassword] new password to set
		 * @param {function} [cb] Callback
		 * @returns {DataHelperXHR}
		 */
		applyPasswordReset: function(options, cb) {
			return DataHelper.ajax({
				type: 'PUT',
				url: '/passwords/' + encodeURIComponent(options.secret),
				data: {newPassword: options.newPassword},
				success: function () {
					cb(null);
				},
				error: function () {
					cb(true);
				}
			});
		}
	});
});

