define('strings.de', [], function () {
	'use strict';
	
	return {
		'language.id': 'de',
		'language.name': 'Deutsch',
		'menu.items.find': 'Finden',
		'menu.items.filter': 'Filter',
		'menu.items.bookings': 'Fahrten',
		'menu.items.more': 'Mehr',
		'panels.bookee.reserve': 'Jetzt reservieren<span class=\'button__info\'>(kostenpflichtig)</span>',
		'panels.bookee.book': 'Buchen',
		'panels.more.headline': '{{provider.name}}',
		'list.headline': 'Buchungsvorschläge',
		'list.empty': 'Für den aktuellen Ort wurden keine Fahrzeuge gefunden. Wählen Sie mithilfe der Suche einen anderen Ort.',
		'list.place.panel': 'Stations&shy;infos',
		'list.place.occupancies': 'Verfügbar&shy;keiten',
		'list.place.maps.mobile': 'in Maps öffnen',
		'list.place.maps.desktop': 'auf Karte anzeigen',
		'list.bookee.info': 'Info',
		'currentBooking.label.running': 'Buchung',
		'currentBooking.time.label.start': 'ab',
		'currentBooking.time.label.end': 'bis',
		'currentBooking.time.format.date': 'D. MMM',
		'currentBooking.time.format.time': 'H:mm',
		'currentBooking.action.starttrip.label': 'aufschließen',
		'currentBooking.action.endtrip.label': 'abschließen',
		'currentBooking.action.trips.door': 'Tür',
		'currentBooking.action.reopen.label': 'Fahrt erneut beenden',
		'currentBooking.action.cancel.label': 'Buchung stornieren',
		'currentBooking.action.stop.label': 'Buchung beenden',
		'currentBooking.action.enlarge.label': 'Ende ändern',
		'currentBooking.action.showDetails': 'Details zu Station/Fahrzeug',
		'constraints.batteryCharge.title': 'Batterielevel',
		'constraints.batteryCharge.message': 'Das Fahrzeug hat zur Zeit der Buchung nur noch eine Akkuladung von {{chargeLevel}}%.',
		'constraints.overlappingBookings.title': 'Parallele Buchung',
		'constraints.overlappingBookings.message': 'Sie haben parallele Buchungen. Wollen Sie trotzdem buchen?',
		'cancelBooking.action.stop.label': 'Buchung beenden',
		'booking.options': 'Optionen',
		'booking.action.trips.door': 'Tür',
		'booking.action.starttrip.label': 'aufschließen',
		'booking.action.endtrip.label': 'abschließen',
		'bookings.error.starttrip': 'Leider konnte {{bookeeName}} nicht geöffnet werden. Bitte versuchen Sie es erneut oder melden sich bei der Hotline.',
		'customerPassword.setPin': 'PIN setzen',
		'customerPassword.setPinHeadline': 'Karten-PIN',
		'customerPassword.setPinAndPassword': 'Passwort & PIN setzen',
		'customerPassword.setPinAndPasswordHeadline': 'Passwort & Karten-PIN',
		'customerPassword.oldPassword': 'Aktuelles Passwort',
		'customerPassword.newPassword': 'Neues Passwort',
		'login.password.label': 'Passwort',
		'customerPassword.setPinAndPasswordError': 'Das aktuelle Passwort ist nicht korrekt.<br>Änderung nicht möglich.',
		'account.customerBookings.bookings.future': 'Zukünftige Fahrten',
		'account.customerBookings.bookings.current': 'Laufende Fahrten',
		'customerBookings.empty': 'Keine Fahrten'
	};
});
