define('helpers/booking',[
	'backbone',
	'underscore',
	'helpers/settings'
], function (
	Backbone,
	_,
	SettingsHelper
) {
	'use strict';

	var BookingHelper = {};
	var priv = {};
	_.extend(BookingHelper, Backbone.Events);

	// create new ProviderSettingsModel
	priv.providerSettingsModel = SettingsHelper.getProviderSettings();

	// load providerSettings from API
	priv.getSettings = function () {
		priv.defaultBookingModel = priv.providerSettingsModel.get('defaultBookingModel');
		priv.bookingUISettings = priv.providerSettingsModel.get('bookingUISettings');

		BookingHelper.trigger('sync');
		BookingHelper.init = true;
	};


	// get settings after login
	BookingHelper.listenTo(priv.providerSettingsModel, 'sync', priv.getSettings);

	// return current bookingModel
	BookingHelper.bookingModel = function () {
		return _.isUndefined(priv.currentBookingModel) ? priv.defaultBookingModel : priv.currentBookingModel;
	};

	// return current bookingSettings
	BookingHelper.bookingUISettings = function () {
		return priv.bookingUISettings;
	};
	
	// retrun current pinPassWordSettings
	BookingHelper.pinPasswordChangeSettings = function () {
		var bookingUISettings = BookingHelper.bookingUISettings();
		var pinPasswordSetting = bookingUISettings.pinPasswordChangeAllowed;
		return {
			'pinAllowed': pinPasswordSetting === 'PINONLY' || pinPasswordSetting === 'PINANDPASSWORD',
			'passwordAllowed': pinPasswordSetting === 'PASSWORDONLY' || pinPasswordSetting === 'PINANDPASSWORD',
			'pinPasswordIdentical': pinPasswordSetting === 'PINANDPASSWORDIDENTICAL',
			'isSomethingChangeable': pinPasswordSetting !== 'NONE'
		};
	};

	priv.getSettings();
	return BookingHelper;
});

