define('views/mapFreeFloatArea', [
	'underscore',
	'views/_view',
	'helpers/gmaps'
], function (
	_,
	BaseView,
	GoogleMapsHelper
) {
	'use strict';

	/**
	 * MapFreeFloatAreaView
	 *
	 * View which represents a FreeFloatArea in our map. render() will
	 * render this area in our map as a polygon.
	 *
	 * @module views/mapFreeFloatArea
	 * @class MapFreeFloatAreaView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({

		/**
		 * Initializes the MapFreeFloatAreaView
		 *
		 * @param {Object} options
		 * @param {FreeFloatAreaModel} options.model FreeFloatAreaModel to render
		 * @param {MapView} options.map Reference to MapView instance to render the polygon on
		 * @constructor
		 */
		_initialize: function (options) {
			this.map = options.map;
		},

		/**
		 * Renders the Area in our Map
		 *
		 * @returns {MapFreeFloatAreaView}
		 */
		render: function () {
			var v = this;

			v.listenTo(v.model, 'change:bookeeAreas', v.renderBookeeAreas);
			v.renderBookeeAreas();

			return v;
		},
		renderBookeeAreas: function () {
			var v = this,
				bookeeAreas = _.sortBy(v.model.get('bookeeAreas'), function (area) {
					if (area.areaType === 'CORE') {
						return [3, area];
					}
					if (area.areaType === 'EXT1') {
						return [2, area];
					}
					if (area.areaType === 'EXT2') {
						return [1, area];
					}
					return [0, area];
				});
			v.polygons = v.polygons || [];

			_.each(v.polygons, function (polygon) {
				polygon.setMap(null);
			});

			v.polygons = [];
			_.each(bookeeAreas, function (area, i) {
				var nextArea = bookeeAreas.length > (i + 1) ? bookeeAreas[i + 1] : null;
				if (nextArea !== null) {
					nextArea = nextArea.includePolygons;
				}

				v.renderBookeeArea(area.includePolygons, area.excludePolygons, nextArea);
			});
			return v;
		},
		renderBookeeArea: function (includePolygons, excludePolygons, nextPolygons) {
			var v = this,
				pols = [],
				area = [
					{
						polygons: includePolygons,
						direction: 1
					},
					{
						polygons: excludePolygons,
						direction: -1
					}
				],
				polygon;

			if (nextPolygons) {
				area.push({
					polygons: nextPolygons,
					direction: -1
				});
			}

			_.each(area, function (polygonObject) {
				if (polygonObject.polygons.length) {
					_.each(polygonObject.polygons, function (polygon) {
						var coords = [],
							points = polygon.points;

						_.each(points, function (point) {
							coords.push(new GoogleMapsHelper.maps.LatLng(point.latitude, point.longitude));
						});

						// change direction so it gets substracted
						if (polygonObject.direction === -1) {
							coords.reverse();
						}

						pols.push(coords);
					});
				}
			});

			// render to map
			if (pols.length) {
				polygon = new GoogleMapsHelper.maps.Polygon({
					paths: pols,
					clickable: true,
					strokeColor: '#000000', // config ?
					strokeOpacity: 0.3,
					strokeWeight: 3,
					fillColor: '#000000', // config ?
					fillOpacity: 0.05,
					map: v.map.map
				});

				polygon.addListener('mouseover', function () {
					this.setOptions({fillOpacity: 0.1});
				});
				polygon.addListener('mouseout', function () {
					this.setOptions({fillOpacity: 0.05});
				});
				polygon.addListener('click', function () {
					v.map.closePanel();
				});

				v.polygons.push(polygon);
			}
		},

		show: function () {
			var v = this;
			_.each(v.polygons, function (polygon) {
				polygon.setMap(v.map.map);
			});
			return v;
		},
		hide: function () {
			var v = this;
			_.each(v.polygons, function (polygon) {
				polygon.setMap(null);
			});
			return v;
		}
	});
});

