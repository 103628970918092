define('views/mapFreeFloatAreaToggle', [
	'underscore',
	'jquery',
	'views/_view',
	'helpers/mapState',
	'helpers/settings'
], function (_, $, BaseView, MapStateHelper, SettingsHelper) {
	'use strict';

	/**
	 * MapFreeFloatAreaToggleView
	 *
	 * @module views/mapFreeFloatAreaToggle
	 * @class MapFreeFloatAreaToggleView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		className: 'map__ffatoggle',

		/**
		 * Renders the Area in our Map
		 *
		 * @returns {MapFreeFloatAreaView}
		 */
		render: function () {
			var v = this,
				$label = $('<label class="map__controllabel"><input class="map__controlcheck" type="checkbox" /> Zonen anzeigen</label>').appendTo(v.$el),
				$i = $label.find('input');

			v.listenToAndCall(MapStateHelper, 'change:ffa', function() {
				$i.prop('checked', MapStateHelper.showFreeFloatAreas());
			});

			v.listenToAndCall(SettingsHelper.getEndPointSettings(), 'change:bookingInterfaceType', function(s) {
				v.$el.toggleClass('map__ffatoggle--hidden', s.get('bookingInterfaceType') === 'MAP_WIDGET');
			});

			$i.change(function() {
				MapStateHelper.showFreeFloatAreas(!!$i.prop('checked')).apply();
			});

			return v;
		}
	});
});

