define('templates/blocks/customerFavorites',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/customerFavorites"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"favs__inner\">\n\n	<h3 class=\"favs__headline\">Meine Favoriten</h3>\n	<ul class=\"favs__list\"></ul>\n	<button type=\"button\" class=\"button favs__addbutton addFav\">Neuer Favorit</button>\n\n</div>\n\n<div class=\"favs__addFavOverlay\">\n	<span class=\"favs__addFavText\">Verschieben Sie die Karte, bis das Fadenkreuz korrekt platziert ist.</span>\n	<div class=\"favs__actions\">\n		<label class=\"favs__field field\">\n			<span class=\"field__label\">Name des Favoriten</span>\n			<input name=\"name\" type=\"text\" class=\"field__input field__input--text\" value=\""
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "\" required/>\n		</label>\n		<button type=\"button\" class=\"button favs__saveFav\">Sichern</button>\n		<button type=\"button\" class=\"button button--outline favs__cancelFav\">Abbrechen</button>\n	</div>\n</div>\n";
},"useData":true});

return this["JST"];

});
