define('casirest2/models/place',[
	'./_',
	'../collections/bookee',
	'../collections/remark',
	'./remark'
], function (
	Model,
	BookeeCollection,
	RemarkCollection,
	RemarkModel
) {
	'use strict';

	/**
	 * @class PlaceModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/places',
		expand: ['remark', 'bookee.bookeeType', 'bookee.remark', 'bookee.bookeeProduct.remark', 'provider.remark'],

		/**
		 * Returns a BookeeCollection containing all Bookees
		 * @param {BaseView} [view]
		 * @returns {BookeeCollection}
		 */
		getBookees: function (view) {
			return this._buildSubCollection({
				collection: BookeeCollection,
				attribute: 'bookees',
				view: view
			});
		},

		/**
		 * Returns a RemarkModel containing an image for this place
		 * @param {object} [options]
		 * @param {boolean} [options.onlyMarketing] Set to true, to return marketing images only
		 * @returns {RemarkModel}
		 */
		getImage: function (options) {
			var m = this,
				bookeeImage = m._getImage(m.get('remarks'), options || {});

			if (bookeeImage) {
				return new RemarkModel(bookeeImage);
			}

			return null;
		},

		/**
		 * Internally used by getImage()
		 * @private
		 */
		_getImage: function (remarks, options) {
			var imageRemarks = _.filter(remarks, function (remark) {
				return remark.contentType.indexOf('image') !== -1;
			});

			// only one image => return
			if (imageRemarks.length === 1 && !options.onlyMarketing) {
				return imageRemarks[0];
			}

			// search for marketing image => return
			if (imageRemarks.length >= 1) {
				return _.find(imageRemarks, function (remark) {
						return _.find(remark.infoType, function (infoType) {
							return infoType === 'MARKETING';
						});
					}) || (options.onlyMarketing ? null : imageRemarks[0]);
			}

			return null;
		},

		/**
		 * Returns a RemarkCollection containing all remarks
		 * @param {BaseView} [view]
		 * @returns {RemarkCollection}
		 */
		getRemarks: function(view) {
			return this._buildSubCollection({
				collection: RemarkCollection,
				attribute: 'remarks',
				view: view
			});
		},

		/**
		 * Returns a RemarkCollection containing all alarms
		 * @param {BaseView} [view]
		 * @returns {RemarkCollection}
		 */
		getAlarms: function(view) {
			return this._buildSubCollection({
				collection: RemarkCollection,
				attribute: 'remarks',
				filter: function (remark) {
					return _.contains(remark.infoType, 'ALERT');
				},
				view: view
			});
		},

		/**
		 * Returns a RemarkCollection containing all warnings
		 * @param {BaseView} [view]
		 * @returns {RemarkCollection}
		 */
		getWarnings: function(view) {
			return this._buildSubCollection({
				collection: RemarkCollection,
				attribute: 'remarks',
				filter: function (remark) {
					return _.contains(remark.infoType, 'WARNING');
				},
				view: view
			});
		}
	});
});

