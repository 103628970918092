define('views/map', [
	'underscore',
	'jquery',
	'markerClusterer',
	'helpers/gmaps',
	'helpers/mapState',
	'helpers/settings',
	'helpers/store',
	'helpers/time',
	'casirest2/helpers/data',
	'casirest2/collections/freeFloatArea',
	'casirest2/models/place',
	'casirest2/models/bookee',
	'views/_view',
	'views/mapPlace',
	'views/mapFreeFloatArea',
	'views/panels/place',
	'views/panels/placeWidgetInfo',
	'views/panels/bookee',
	'views/panels/filter',
	'views/modals/prompt',
	'views/mapFreeFloatAreaToggle'
], function (
	_,
	$,
	MarkerClusterer,
	GoogleMapsHelper,
	MapStateHelper,
	SettingsHelper,
	StoreHelper,
	TimeHelper,
	DataHelper,
	FreeFloatAreaCollection,
	PlaceModel,
	BookeeModel,
	BaseView,
	MapPlaceView,
	MapFreeFloatAreaView,
	PlacePanelView,
	PlaceWidgetInfoPanelView,
	BookeePanelView,
	FilterPanelView,
	PromptModalView,
	MapFreeFloatAreaToggleView
) {
	'use strict';

	/**
	 * MapView
	 *
	 * Central element of this application. Basically renders
	 * a Google Map with all places and their occupancies.
	 *
	 * @module views/map
	 * @class MapView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		className: 'map',

		/**
		 * Array of MapPlaceView instances currently loaded
		 *
		 * @type {Object.<MapPlaceView>}
		 */
		placeViews: {},

		/**
		 * Array of MapFreeFloatAreaView instances currently loaded
		 *
		 * @type {Object.<MapFreeFloatAreaView>}
		 */
		freeFloatAreaViews: {},

		/**
		 * The MapPlaceView which is currently highlighted
		 *
		 * @type {MapPlaceView|null}
		 */
		highlightedPlaceView: null,


		/**
		 * Initialises the map by receiving the TimeHelper instance.
		 *
		 * @param {Object} [options]
		 * @param {TimeHelper} options.timeHelper TimeHelper Instance this View should use. Default: new TimeHelper()
		 * @param {PlaceCollection} options.placeCollection PlaceCollection this View should use
		 * @param {BookingProposalCollection} options.proposalCollection ProposalCollection this View should use
		 * @param {FreeFloatAreaCollection} [options.freeFloatAreaCollection] FreeFloatAreaCollection this View should use
		 * @constructor
		 */
		_initialize: function (options) {
			var v = this;
			v.timeHelper = options.timeHelper;
			v.placeCollection = options.placeCollection;
			v.proposalCollection = options.proposalCollection;
			v.freeFloatAreaCollection = options.freeFloatAreaCollection || new FreeFloatAreaCollection();
		},

		/**
		 * Renders the Map
		 *
		 * @returns {MapView}
		 */
		render: function () {
			var v = this,
				endpointSettings = SettingsHelper.getEndPointSettings(),
				defaultMapStyles = [
					{
						'featureType': 'poi',
						'elementType': 'labels',
						'stylers': [{'visibility': 'off'}]
					}
				];

			if (v.render.time) {
				return v;
			}
			v.render.time = new Date().getTime();

			// Initialize Google Maps
			v.map = new GoogleMapsHelper.maps.Map(v.$el.get(0), {
				zoom: MapStateHelper.getZoomLevel(),
				center: MapStateHelper.getCenter(),
				disableDefaultUI: false,
				mapTypeControl: false,
				streetViewControl: false,
				styles: defaultMapStyles
			});
			v.listenToAndCall(endpointSettings, 'change:bookingInterfaceType', function () {
				if (endpointSettings.get('bookingInterfaceType') === 'MAP_WIDGET') {
					v.map.setOptions({
						mapTypeControl: true,
						styles: [
							{
								elementType: 'geometry',
								stylers: [{color: '#f5f5f5'}]
							},
							{
								elementType: 'labels.icon',
								stylers: [{visibility: 'off'}]
							},
							{
								elementType: 'labels.text.fill',
								stylers: [{color: '#616161'}]
							},
							{
								elementType: 'labels.text.stroke',
								stylers: [{color: '#f5f5f5'}]
							},
							{
								featureType: 'administrative.land_parcel',
								elementType: 'labels.text.fill',
								stylers: [{color: '#bdbdbd'}]
							},
							{
								featureType: 'poi',
								elementType: 'geometry',
								stylers: [{color: '#eeeeee'}]
							},
							{
								featureType: 'poi',
								elementType: 'labels.text.fill',
								stylers: [{color: '#757575'}]
							},
							{
								featureType: 'poi.park',
								elementType: 'geometry',
								stylers: [{color: '#e5e5e5'}]
							},
							{
								featureType: 'poi.park',
								elementType: 'labels.text.fill',
								stylers: [{color: '#9e9e9e'}]
							},
							{
								featureType: 'road',
								elementType: 'geometry',
								stylers: [{color: '#ffffff'}]
							},
							{
								featureType: 'road.arterial',
								elementType: 'labels.text.fill',
								stylers: [{color: '#757575'}]
							},
							{
								featureType: 'road.highway',
								elementType: 'geometry',
								stylers: [{color: '#dadada'}]
							},
							{
								featureType: 'road.highway',
								elementType: 'labels.text.fill',
								stylers: [{color: '#616161'}]
							},
							{
								featureType: 'road.local',
								elementType: 'labels.text.fill',
								stylers: [{color: '#9e9e9e'}]
							},
							{
								featureType: 'transit.line',
								elementType: 'geometry',
								stylers: [{color: '#e5e5e5'}]
							},
							{
								featureType: 'transit.station',
								elementType: 'geometry',
								stylers: [{color: '#eeeeee'}]
							},
							{
								featureType: 'water',
								elementType: 'geometry',
								stylers: [{color: '#c9c9c9'}]
							},
							{
								featureType: 'water',
								elementType: 'labels.text.fill',
								stylers: [{color: '#9e9e9e'}]
							}
						]
					});
				} else {
					v.map.setOptions({
						mapTypeControl: false,
						styles: defaultMapStyles
					});
				}
			});

			// Initialize Marker Clusterer
			v.clusterer = new MarkerClusterer(v.map, [], {
				maxZoom: 13,
				styles: v.getClusterStyles(),
				ignoreHiddenMarkers: true,
				minimumClusterSize: 4,
				zoomOnClick: false
			});
			// hijack clusterer event for own paning to cluster (2 steps for an even better experiance)
			GoogleMapsHelper.maps.event.addListener(v.clusterer, 'clusterclick', function (cluster) {
				var currentZoom = MapStateHelper.getZoomLevel();
				v.openLocation(cluster.getCenter(), currentZoom + 1);

				_.delay(function () {
					v.openLocation(cluster.getCenter(), currentZoom + 2);
				}, 600);
			});
			v.listenTo(SettingsHelper.getProviderSettings(), 'change:provId', function () {
				v.clusterer.setStyles(v.getClusterStyles());
				v.clusterer.repaint();
			});
			v.listenToAndCall(endpointSettings, 'change:bookingInterfaceType', function () {
				v.clusterer.setGridSize(endpointSettings.get('bookingInterfaceType') !== 'MAP_WIDGET' ? 53 : 80);
				v.clusterer.setStyles(v.getClusterStyles());
				v.clusterer.repaint();
			});

			// Helper Overlay for bounds
			v.overlay = new GoogleMapsHelper.maps.OverlayView();
			v.overlay.draw = function () {
			};
			v.overlay.setMap(v.map);


			// Events: MapStateHelper -> MapView
			v.listenTo(MapStateHelper, 'change:ffa', function () {
				v.updateFreeFloatAreas();
			});
			v.listenTo(MapStateHelper, 'change:zom', v.updateGeoLocationCircleVisibility);

			// Events: TimeHelper -> MapView
			v.listenTo(v.timeHelper, 'change', function () {
				v.updateFreeFloatAreas();
			});

			// Events: MapView -> MapStateHelper
			GoogleMapsHelper.maps.event.addListener(v.map, 'dragend', function () {
				MapStateHelper.setCenter(v.map.getCenter())
					.setZoomLevel(v.map.getZoom())
					.apply();
			});
			GoogleMapsHelper.maps.event.addListener(v.map, 'bounds_changed', _.debounce(function () {
				if (!MapStateHelper.isListMode()) {
					MapStateHelper.setCenter(v.map.getCenter())
						.setZoomLevel(v.map.getZoom())
						.apply();
				}
			}, 800));

			// Google Maps render hotfix
			GoogleMapsHelper.maps.event.addListenerOnce(v.map, 'idle', function () {
				GoogleMapsHelper.maps.event.trigger(v.map, 'resize');

				if (!v.openLocation.initialized) {
					v.openLocation();
				}

				v.$el.addClass('map--loaded');
			});

			// Close Panel on click
			GoogleMapsHelper.maps.event.addListener(v.map, 'click', function () {
				v.closePanel();
			});

			// Handle Resizes
			$(window).on('resize', v.resize);
			v.once('remove', function () {
				$(window).off('resize', v.resize);
			});


			// Data Events: Places
			v.listenTo(v.placeCollection, 'add', v._addPlace);
			v.listenTo(v.placeCollection, 'remove', v._removePlace);
			v.placeCollection.each(v._addPlace);

			// Data Events: FreeFloatAreas
			v.listenTo(v.placeCollection, 'sync', v.updateFreeFloatAreas);
			v.listenTo(SettingsHelper.getProviderSettings(), 'change:provId', v.updateFreeFloatAreas);
			v.listenTo(v.freeFloatAreaCollection, 'add', v._addFreeFloatArea);
			v.listenTo(v.freeFloatAreaCollection, 'remove', v._removeFreeFloatArea);
			v.updateFreeFloatAreas();

			// move highligted place if panel is to wide
			v.listenTo(window.myApp.view, 'render:panel', function () {
				_.defer(function () {
					if (v.highlightedPlaceView) {
						v._openPlaceCenterPlace(v.highlightedPlaceView.model);
					}
				});
			});

			// Render FreeFloatAreaToggleView
			v.mapFreeFloatAreaToggle = new MapFreeFloatAreaToggleView().appendTo(v, v.$bookingArea);

			// Visibility Fix for Google Maps after List Mode
			v.listenTo(MapStateHelper, 'change:lim', function () {
				GoogleMapsHelper.maps.event.trigger(v.map, 'resize');
			});

			return this;
		},

		/**
		 * Generates the Cluster Styles by using public
		 * MapPlaceView methods. This is not the nicest
		 * method, but works well…
		 *
		 * @returns {Array<Object>}
		 */
		getClusterStyles: function () {
			var result = [],
				icons = new MapPlaceView().updateMarkerConfig().getIcons(),
				prefix = 'iconCluster';

			if (SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET') {
				prefix += 'Widget';
			}

			for (var i = 1; i <= 5; i += 1) {
				result.push(icons[prefix + i]);
			}

			return result;
		},

		/**
		 * For performance purposes, fetch() doesn't use real bounds but
		 * extended bounds. Each side gets larger.
		 *
		 * This method takes the Google Maps bounds an returns extended
		 * bounds.
		 *
		 * @param {google.maps.LatLngBounds} bounds
		 * @param {Number} zoom Zoom Level
		 * @returns {google.maps.LatLngBounds}
		 */
		extendBounds: function (bounds, zoom) {
			if (zoom < 12) {
				return bounds;
			}

			var lng = Math.abs(bounds.getNorthEast().lng() - bounds.getSouthWest().lng()) / 2,
				lat = Math.abs(bounds.getNorthEast().lat() - bounds.getSouthWest().lat()) / 2;

			if (zoom <= 6) {
				lng *= 2;
				lat *= 2;
			}

			return new GoogleMapsHelper.maps.LatLngBounds(
				{lat: bounds.getSouthWest().lat() - lat, lng: bounds.getSouthWest().lng() - lng},
				{lat: bounds.getNorthEast().lat() + lat, lng: bounds.getNorthEast().lng() + lng}
			);
		},

		/**
		 * Handles map resizes by centering the location again…
		 * @returns {MapView}
		 */
		resize: function () {
			this.openLocation();
			return this;
		},

		/**
		 * Adds a single place to the map. For internal use only.
		 *
		 * @param {PlaceModel} place PlaceModel to add to the map.
		 * @returns {MapView}
		 * @private
		 */
		_addPlace: function (place) {
			var v = this,
				view = new MapPlaceView({
					map: v,
					model: place,
					proposals: v.proposalCollection
				});

			if (v.placeViews[place.id]) {
				return v;
			}

			v.placeViews[place.id] = view;
			view.render();
			return v;
		},

		/**
		 * Removes a single place from the map. For internal use only.
		 *
		 * @param {PlaceModel} place PlaceModel to remove from the map.
		 * @returns {MapView}
		 */
		_removePlace: function (place) {
			var v = this;

			if (v.placeViews[place.id]) {
				v.placeViews[place.id].remove();
				delete v.placeViews[place.id];
			}
			if (v.highlightedPlaceView && v.highlightedPlaceView.id === place.id) {
				v.closePanel();
			}

			return v;
		},

		/**
		 * Open a single location on the map. Optionally it's also possible to
		 * change the zoom level as well. Uses Google Maps panTo() to animate
		 * the movement, if the change is less than both the width and height
		 * of the map.
		 *
		 * @param {LatLng} [center] Center as GoogleMaps LatLng Object. Defaults to the current MapState center
		 * @param {Number} [zoom] Zoom Level, defaults to the current MapState value
		 * @returns {MapView}
		 */
		openLocation: function (center, zoom) {
			var v = this,
				centerMethod = 'setCenter';

			v.openLocation.initialized = true;
			center = center || MapStateHelper.getCenter();
			zoom = zoom || MapStateHelper.getZoomLevel();

			if (v.render.time && new Date().getTime() - v.render.time > (1000 * 5)) {
				centerMethod = 'panTo';
			}

			v.map[centerMethod](center);
			v.map.setZoom(zoom);

			return v;
		},

		/**
		 * Used by BookingAreaView.openPlace() to highlight
		 * a single place on the map.
		 *
		 * @param {PlaceModel} place
		 * @returns {MapView}
		 */
		highlightPlace: function (place) {
			var v = this;
			v.highlightedPlaceView = v.placeViews[place.id];
			v.highlightedPlaceView.highlight();
			return v;
		},

		/**
		 * Used by BookingAreaView.openPlace() to reverse the
		 * highlighted place…
		 *
		 * @returns {MapView}
		 */
		unhighlightPlace: function () {
			var v = this;

			if (v.highlightedPlaceView) {
				var view = v.highlightedPlaceView;
				v.highlightedPlaceView = null;

				_.delay(function () {
					view.highlight(false);
				}, $('body').width() <= 400 ? 250 : 0); // delay marker change on mobile devices
			}

			return v;
		},

		/**
		 * Internally used to center the map to the given point.
		 * Tries to be smart sometimes.
		 *
		 * @param {PlaceModel} place PlaceModel to center, has to be fetched
		 * @param {Boolean} [deferred] True, if this call is already deferred
		 * @private
		 */
		_openPlaceCenterPlace: function (place, deferred) {
			var v = this,
				isSmartphone = $('body').width() <= 400,
				panelsWidth = 0,
				projection,
				point,
				visible;

			// Smartphone, etc:
			// Defer map scrolling till panel is open
			if (isSmartphone && !deferred) {
				_.delay(function () {
					v._openPlaceCenterPlace(place, true);
				}, 400);
				return;
			}
			if (isSmartphone) {
				MapStateHelper
					.setCenter({
						lat: place.get('geoPosition').latitude,
						lng: place.get('geoPosition').longitude
					})
					.setZoomLevel(17) // Why 17? Because it looks good.
					.apply();
				return;
			}

			visible = v.isPlaceNicelyVisible(place);
			if (
				(visible && MapStateHelper.getZoomLevel() >= 14) ||
				MapStateHelper.isListMode()
			) {
				return;
			}

			// move center if panel is opened
			projection = v.overlay.getProjection();
			if (projection) {
				_.each(window.myApp.view.getOpenedPanel(), function (panelView) {
					panelsWidth += panelView.$el.outerWidth();
				});

				point = projection.fromLatLngToContainerPixel(
					new GoogleMapsHelper.maps.LatLng(
						place.get('geoPosition').latitude,
						place.get('geoPosition').longitude
					)
				);
				point.x -= Math.round(panelsWidth / 2);
				point = projection.fromContainerPixelToLatLng(point);
			} else {
				point = {
					lat: place.get('geoPosition').latitude,
					lng: place.get('geoPosition').longitude
				};
			}

			if (MapStateHelper.getZoomLevel() < 14) {
				MapStateHelper.setZoomLevel(MapStateHelper.getZoomLevel() + 1);
				GoogleMapsHelper.maps.event.addListenerOnce(v.map, 'idle', function () {
					v._openPlaceCenterPlace(place);
				});
			}

			MapStateHelper.setCenter(point).apply();
		},

		/**
		 * Asks the browser for the current geolocation and compass data and
		 * visualize this data in our map.
		 *
		 * @param {function} [cb] Optional Callback
		 * @returns {MapView}
		 */
		openMyPosition: function (cb) {
			var v = this,
				cbSent = false,
				hasFirstPosition;

			if (v.geoLocationWatcher) {
				if (v.geoLocationCircle) {
					v.openLocation(v.geoLocationCircle.getCenter());
				}
				if (_.isFunction(cb)) {
					cb(null);
				}
				return v;
			}
			if (!navigator.geolocation) {
				if (_.isFunction(cb)) {
					cb(new Error('Browser doesn\'t support navigator.geolocation!'));
				}

				return v;
			}

			v.geoLocationWatcher = navigator.geolocation.watchPosition(function (position) {
				var icons;

				if (!hasFirstPosition) {
					hasFirstPosition = true;
					v.openLocation({
						lat: position.coords.latitude,
						lng: position.coords.longitude
					}, MapStateHelper.getZoomLevel() < 17 ? 17 : MapStateHelper.getZoomLevel());

					if (_.isFunction(cb)) {
						_.defer(function () {
							cb(null);
						});
					}

					v.geoLocationCleanUpInterval = setInterval(function () {
						v.disableGeoWatchingIfNotRequired();
					}, 10000);
				}

				// Accuracy Circle
				if (!v.geoLocationCircle) {
					v.geoLocationCircle = new GoogleMapsHelper.maps.Circle({
						strokeColor: '#3270B2',
						strokeOpacity: 0.6,
						strokeWeight: 1,
						fillColor: '#3270B2',
						fillOpacity: 0.15,
						map: v.map,
						center: {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						},
						radius: Math.max(position.coords.accuracy, 10),
						clickable: false
					});
				} else {
					v.geoLocationCircle.setCenter({
						lat: position.coords.latitude,
						lng: position.coords.longitude
					});
					v.geoLocationCircle.setRadius(position.coords.accuracy);
					v.geoLocationCircle.setMap(v.map);
				}

				v.updateGeoLocationCircleVisibility();

				// Point Marker
				if (!v.geoLocationMarker) {
					icons = new MapPlaceView().updateMarkerConfig().getIcons();
					v.geoLocationMarker = new GoogleMapsHelper.maps.Marker({
						icon: icons.iconPosition,
						icons: icons,
						map: v.map,
						position: {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						},
						zIndex: 20,
						infoWindow: null,
						optimized: false,
						rotation: 45,
						clickable: false
					});
				} else {
					v.geoLocationMarker.setPosition({
						lat: position.coords.latitude,
						lng: position.coords.longitude
					});
					v.geoLocationMarker.setMap(v.map);
				}

				window.addEventListener('deviceorientation', v._onGeoWatchingCompassEvent);
			}, function (error) {
				v.geoLocationWatcher = null;

				if (error && error.code === 1) {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Ortung fehlgeschlagen',
							message: 'Leider haben wir nicht die nötigen Berechtigungen, um eine Ortung durchführen zu können.',
							type: 'error'
						})
					);
				} else {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Ortung fehlgeschlagen',
							message: 'Leider konnten wir dich nicht orten. Bitte versuche es später erneut.',
							type: 'error'
						})
					);
				}

				if (!error.code || [1, 2, 3].indexOf(error.code) === -1) {
					Raven.captureException(new Error(error.toString()));
				}

				if (!cbSent) {
					cbSent = true;
					if (_.isFunction(cb)) {
						cb(new Error('Browser doesn\'t support navigator.geolocation!'));
					}
				}
			}, {
				enableHighAccuracy: true
			});

			return v;
		},

		/**
		 * Updates GeoLocation Accuracy Circle visibility
		 * (shouldn't be too small, otherwise looks strange)
		 *
		 * @returns {MapView}
		 */
		updateGeoLocationCircleVisibility: function () {
			var v = this,
				projection,
				circleSize;

			if (!v.geoLocationCircle) {
				return v;
			}

			projection = v.overlay.getProjection();
			circleSize = (
				projection.fromLatLngToContainerPixel(
					v.geoLocationCircle.getBounds().getNorthEast()
				).x - projection.fromLatLngToContainerPixel(
					v.geoLocationCircle.getBounds().getSouthWest()
				).x
			);

			v.geoLocationCircle.setVisible(circleSize > 80);
			return v;
		},

		/**
		 * Internally used by `openMyPosition`, callback for compass data
		 *
		 * @param {DeviceOrientationEvent} e
		 * @private
		 */
		_onGeoWatchingCompassEvent: function (e) {
			var v = this,
				direction;

			if (!v.geoLocationMarker) {
				return;
			}

			if (typeof e.webkitCompassHeading !== 'undefined') {
				direction = e.webkitCompassHeading;
				if (typeof window.orientation !== 'undefined') {
					direction += window.orientation;
				}
			}
			else if (e.alpha) {
				// http://dev.w3.org/geo/api/spec-source-orientation.html#deviceorientation
				direction = 360 - e.alpha;
			}
			else {
				v.geoLocationMarker.setOptions({
					icon: v.geoLocationMarker.icons.iconPosition
				});
				return;
			}

			v._applyGeoWatchingDirectionMarker(direction);
		},

		/**
		 * This method will update the position marker setup by `openMyPosition` with
		 * the correct direction information. This works by rotating the marker image
		 * within an canvas.
		 *
		 * @param {number} direction
		 * @private
		 */
		_applyGeoWatchingDirectionMarker: function (direction) {
			var v = this,
				img = v.directionMarker,
				icon = v.geoLocationMarker.icons.iconPositionDirection,
				canvas = document.createElement('canvas'),
				angle = direction * Math.PI / 180,
				context;

			if (!img) {
				img = v.directionMarker = new Image();
				img.src = icon.url;

				img.addEventListener('load', function () {
					v._applyGeoWatchingDirectionMarker(direction);
				});
				return;
			}

			canvas.width = icon.size.width * 2;
			canvas.height = icon.size.height * 2;
			context = canvas.getContext('2d');

			context.clearRect(0, 0, canvas.width, canvas.height);
			context.save();
			context.translate(canvas.width / 2, canvas.height / 2);
			context.rotate(angle);
			context.translate(-1 * (canvas.width / 2), -1 * (canvas.height / 2));
			context.drawImage(img, 0, 0, canvas.width, canvas.height);
			context.restore();

			v.geoLocationMarker.setOptions({
				icon: _.extend({}, icon, {url: canvas.toDataURL('image/png')})
			});
		},

		/**
		 * Checks, if the position is contained within our extended bounds. If not,
		 * stop watching for my location.
		 *
		 * @params {Boolean} [enforce] disable geowatching everytimes…
		 * @returns {MapView}
		 */
		disableGeoWatchingIfNotRequired: function (enforce) {
			var v = this,
				bounds = v.map.getBounds(),
				extendedBounds = v.extendBounds(bounds, v.map.getZoom());

			if (!enforce && extendedBounds.contains(v.geoLocationCircle.getCenter())) {
				return v;
			}

			if (v.geoLocationWatcher) {
				return v;
			}

			navigator.geolocation.clearWatch(v.geoLocationWatcher);
			v.geoLocationWatcher = null;

			if (v.geoLocationCircle) {
				v.geoLocationCircle.setMap(null);
			}
			if (v.geoLocationMarker) {
				v.geoLocationMarker.setMap(null);
			}

			window.removeEventListener('deviceorientation', v._onGeoWatchingCompassEvent);
			return v;
		},

		/**
		 * Proxy for BookingAreaView.closePanel()
		 * Used to close all Panels on map clicks in empty spaces…
		 */
		closePanel: function () {
			window.myApp.view.bookingAreaView.closePanel();
		},

		/**
		 * Get the bounds array with the bounds which are nicely
		 * visible on our map. This excludes bounds behind UI elements.
		 *
		 * Usually it's not required to call this manually, as this method
		 * is executed on resize and drag events.
		 *
		 * @returns {Array.<LatLngBounds>}
		 */
		getVisibleMarkerBounds: function () {
			var v = this,
				bounds = [],
				squares = [],
				panelsWidth = 0,
				projection,
				screenWidth,
				screenHeight;

			projection = v.overlay.getProjection();
			if (!projection) {
				return bounds; // map not ready yet
			}

			screenWidth = v.$el.width();
			screenHeight = v.$el.height();

			_.each(window.myApp.view.getOpenedPanel(), function (panelView) {
				panelsWidth += panelView.$el.outerWidth();
			});

			// main bound
			bounds.push(
				new GoogleMapsHelper.maps.LatLngBounds(
					projection.fromContainerPixelToLatLng(new GoogleMapsHelper.maps.Point(30 + panelsWidth, screenHeight - 30)),
					projection.fromContainerPixelToLatLng(new GoogleMapsHelper.maps.Point(screenWidth - 70, 160))
				)
			);

			// upper bound
			bounds.push(
				new GoogleMapsHelper.maps.LatLngBounds(
					projection.fromContainerPixelToLatLng(new GoogleMapsHelper.maps.Point(30 + panelsWidth, 160)),
					projection.fromContainerPixelToLatLng(new GoogleMapsHelper.maps.Point(screenWidth - 420, 60))
				)
			);

			// draw bounds
			if (window.debug) {
				squares = _.map(bounds, function (bounds) {
					return new GoogleMapsHelper.maps.Rectangle({
						strokeWeight: 0,
						fillColor: '#AA0000',
						fillOpacity: 0.40,
						map: v.map,
						bounds: bounds
					});
				});

				_.delay(function () {
					_.each(squares, function (square) {
						square.setMap(null);
					});
				}, 2500);
			}

			return bounds;
		},

		/**
		 * Returns true, if this place would be nicely visible on the map,
		 * meaning no UI elements obove it etc. Modals are ignored! Also,
		 * this function doesn't use the maps zoom level.
		 *
		 * @param {PlaceModel} place PlaceModel to search
		 * @returns {boolean}
		 */
		isPlaceNicelyVisible: function (place) {
			var v = this;
			return !!_.find(v.getVisibleMarkerBounds(), function (bounds) {
				return bounds.contains({
					lat: place.get('geoPosition').latitude,
					lng: place.get('geoPosition').longitude
				});
			});
		},

		/**
		 * Checks visible Places for FreeFloatAreas and updates
		 * these areas on the map.
		 *
		 * @returns {MapView}
		 */
		updateFreeFloatAreas: function () {
			var v = this,
				freeFloaters = v.placeCollection.filter(function (p) {
					return !p.get('isFixed');
				}),
				providerIds = [],
				url;

			if (
				!freeFloaters.length ||
				!MapStateHelper.showFreeFloatAreas() ||
				SettingsHelper.getEndPointSettings().get('bookingInterfaceType') === 'MAP_WIDGET' // (not for Widget)
			) {
				return v.showFreeFloatAreas(false);
			}

			// TimeHelper not ready yet: try it again when helper is ready
			if (!v.timeHelper.isReady()) {
				return v.listenToOnce(v.timeHelper, 'initialized', v.updateFreeFloatAreas);
			}

			v.showFreeFloatAreas(true);

			// add default provider ID if available
			if (SettingsHelper.getProviderSettings().has('provId')) {
				providerIds.push(SettingsHelper.getProviderSettings().get('provId'));
			} else {
				providerIds.push('default');
			}

			// add provider ids for loaded freefloaters
			_.each(freeFloaters, function (place) {
				if (_.indexOf(providerIds, place.get('provId')) === -1) {
					providerIds.push(place.get('provId'));
				}
			});


			v.freeFloatAreaCollection.resetFilter();
			v.freeFloatAreaCollection.filterByTimeRange(
				v.timeHelper.get('start'),
				v.timeHelper.get('end')
			);
			_.each(providerIds, function (providerId) {
				v.freeFloatAreaCollection.filterByProvider(providerId);
			});

			url = v.freeFloatAreaCollection.url();
			if (v.updateFreeFloatAreas.lastURL && v.updateFreeFloatAreas.lastURL === url) {
				if (v.updateFreeFloatAreas.lastXHR) {
					v.updateFreeFloatAreas.lastXHR.abort();
				}
				return v;
			}

			v.updateFreeFloatAreas.lastURL = url;
			v.updateFreeFloatAreas.lastXHR = v.freeFloatAreaCollection.fetch();
			return v;
		},

		/**
		 * Show or hide all FreeFloatAreaViews on the map. Usually you don't
		 * have to use this method. Change the state via MapStateHelper instead.
		 *
		 * @param {boolean} [showIt]
		 * @returns {MapView}
		 */
		showFreeFloatAreas: function (showIt) {
			var v = this;
			showIt = showIt !== false;

			v.showFreeFloatAreas.visible = showIt;
			_.each(v.freeFloatAreaViews, function (view) {
				view[showIt ? 'show' : 'hide']();
			});

			return v;
		},

		/**
		 * Adds a single FreeFloatArea to the map. For internal use only.
		 *
		 * @param {FreeFloatAreaModel} area FreeFloatArea to add to the map.
		 * @returns {MapView}
		 * @private
		 */
		_addFreeFloatArea: function (area) {
			var v = this,
				view = new MapFreeFloatAreaView({
					map: v,
					model: area
				});

			if (v.freeFloatAreaViews[area.id]) {
				return v;
			}

			v.freeFloatAreaViews[area.id] = view;
			view.render();
			return v;
		},

		/**
		 * Removes a single FreeFloatArea from the map. For internal use only.
		 *
		 * @param {FreeFloatAreaModel} area FreeFloatAreaModel to remove from the map.
		 * @returns {MapView}
		 */
		_removeFreeFloatArea: function (area) {
			var v = this;

			if (v.freeFloatAreaViews[area.id]) {
				v.freeFloatAreaViews[area.id].remove();
				delete v.freeFloatAreaViews[area.id];
			}

			return v;
		}

	});
});

