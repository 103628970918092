define('casirest2/models/customerFavorite',['./_'], function (Model) {
	'use strict';

	/**
	 * @class CustomerFavoriteModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/customers/default/settings/favoriteplaces'
	});
});

