define('templates/panels/place',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/place"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel__hero\">\n	<img class=\"panel__heroimage\" src=\"/img/placedummy.jpg\" alt=\"\" />\n	<h1 class=\"panel__headline panel__headline--hero\"><span class=\"panel__innerheadline\"></span></h1>\n</div>\n\n<div class=\"panel__alerts panel__alerts--hidden\"></div>\n\n\n<button class=\"panel__button panel__button--showRemarks panel__button--more\" type=\"button\">Standortinformationen<span class=\"button__info\">Achtung! Es liegen Warnungen vor.</span></button>\n\n<button class=\"panel__button panel__button--showUnavailability panel__button--more\" type=\"button\">Verfügbarkeitsanzeige</button>\n\n\n<h2 class=\"panel__subheadline\">Fahrzeuge</h2>\n<div class=\"panel__bookees\"></div>\n\n<div class=\"panel__listlegend panel__listlegend--hidden\"><span class=\"panel__legendsymbol\">*</span>Zeitpreis ohne Kilometerkosten. Für nicht eingeloggte Nutzer wird ein Standardtarif zugrunde gelegt.</div>\n";
},"useData":true});

return this["JST"];

});
