define('helpers/store', [
	'backbone',
	'underscore'
], function (
	Backbone,
	_
) {
	'use strict';


	/**
	 * StoreHelper
	 *
	 * Helper to load and get stuff from localStorage. Allows to save/load
	 * plain JavaScript Objects as well by using JSON.stringify internally.
	 * Prefixes keys with `ewi3-` to avoid collisions.
	 *
	 * @class StoreHelper
	 * @augments Backbone.Events
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	var StoreHelper = function() {

		/**
		 * Get object by given key.
		 *
		 * @param {String} key Key to get value from.
		 * @returns {Object}
		 */
		this.get = function (key) {
			try {
				return JSON.parse(localStorage['ewi3-' + key]);
			}
			catch (err) {
				// @todo report to Raven to find alternative solutions
				return undefined;
			}
		};

		/**
		 * Set object in localStorage with the given key.
		 *
		 * @param {String} key Key to get value from.
		 * @param {Object} value Key to get value from.
		 * @returns {Boolean} True, if set was successful, otherwise false
		 */
		this.set = function (key, value) {
			try {
				var oldValue = localStorage['ewi3-' + key],
					newValue = JSON.stringify(value);

				localStorage['ewi3-' + key] = newValue;

				if(oldValue !== newValue) {
					StoreHelper.trigger('change:' + key, value);
					StoreHelper.trigger('change', {key: key, value: value});
				}

				return true;
			}
			catch (err) {
				// @todo report to Raven to find alternative solutions
				return false;
			}
		};

		/**
		 * Delete all values in StoreHelper
		 *
		 * @returns {StoreHelper}
		 */
		this.reset = function() {
			for(var i in localStorage) {
				if(localStorage.hasOwnProperty(i) && i.indexOf('casi-') === 0) {
					try {
						delete localStorage[i];
					}
					catch(error) {
						// do nothing
					}
				}
			}

			return StoreHelper;
		};
	};

	_.extend(StoreHelper, Backbone.Events);


	/**
	 * @type {StoreHelper}
	 * @module helpers/store
	 */
	return new StoreHelper();
});

