define('templates/halMode',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/halMode"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"halMode__wrap\">\n    <div class=\"halMode__progress\">&nbsp;</div>\n</div>\n\n<span class=\"halMode__label halMode__label--wtf\">WTF?</span>\n<span class=\"halMode__label halMode__label--hal\">Wie bei HAL</span>\n<span class=\"halMode__label halMode__label--cache\">new EWI3Cache()</span>\n<span class=\"halMode__label halMode__label--ok\">Happy Developers</span>";
},"useData":true});

return this["JST"];

});
