define('casirest2/models/endpointSettings',[
	'./_',
	'../collections/extraMenuEntry',
	'./place'
], function (
	Model,
	ExtraMenuEntryCollection,
	PlaceModel
) {
	'use strict';

	/**
	 * @class EndpointSettingsModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/endpoints/default/settings',
		isAlwaysSingle: true,
		expand: ['place', 'businessModel', 'extraMenuEntry'],
		
		/**
		 * Get ExtraMenuEntries of this Settings
		 * @returns {ExtraMenuEntryCollection}
		 */
		getExtraMenuEntries: function (view) {
			return this._buildSubCollection({
				collection: ExtraMenuEntryCollection,

				attribute: 'extraMenuEntries',
				view: view
			});
		},
		getDefaultPlace: function() {
			if(!this.get('defaultPlace')) {
				return null;
			}

			return new PlaceModel(
				this.get('defaultPlace')
			);
		}
	});
});

