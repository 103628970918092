define('casirest2/collections/remark',['./_', '../models/remark'], function (Collection, RemarkModel) {
	'use strict';

	/**
	 * @class RemarkCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: RemarkModel,
		filterMethods: [],
		comparator: 'severity'
	});
});

