define('views/_panel', [
	'underscore',
	'views/_view'
], function (
	_,
	View
) {
	'use strict';

	/**
	 * @module views/_panel
	 * @class PanelBaseView
	 * @augments BaseView
	 */
	return View.extend({
		parent: null,
		child: null,
		isRoot: false,

		mainEvents: {
			'click button.button--close': 'remove'
		},

		events: function () {
			return _.extend({}, this.mainEvents, this.panelEvents);
		},

		className: function () {
			var v = this,
				classNames = [];

			// panelModifiers
			if (v.panelModifier) {
				if (_.isArray(v.panelModifier)) {
					_.each(v.panelModifier, function (panelModifier) {
						classNames.push('panel--' + panelModifier);
					});
				} else {
					classNames.push('panel--' + v.panelModifier);
				}
			}

			// panelNames as main classes
			if (v.panelName) {
				if (_.isArray(v.panelName)) {
					_.each(v.panelName, function (panelName) {
						classNames.push('panel' + panelName);
					});
				} else {
					classNames.push('panel' + v.panelName);
				}
			}

			return classNames.length ? 'panel ' + classNames.join(' ') : 'panel';
		},

		loading: function (isLoading) {
			this.$el.toggleClass('loading', !!isLoading);
		},

		getChildPanel: function () {
			return window.myApp.view.getChildPanelOf(this);
		},
		renderChildPanel: function (view) {
			return window.myApp.view.renderChildPanelOf(this, view);
		}
	});
});

