define('views/panels/remarks', [
	'underscore',
	'views/_panel',
	'views/blocks/remarks',
	'helpers/template'
], function (
	_,
	PanelView,
	RemarksBlock,
	templateHelper
) {
	'use strict';

	return PanelView.extend({
		panelName: ['remarks'],

		_initialize: function (options) {
			options = options || {};
			this.showAlerts = options.showAlerts === undefined ? true : options.showAlerts;
		},

		render: function () {
			var v = this;

			templateHelper('panels/remarks', {
				panelInvert: true,
			}, function (html) {
				v.$el.html(html);

				// render Remarks
				var remarksBlock = new RemarksBlock({
					remarks: v.collection.toJSON(),
					showAlerts: v.showAlerts
				});
				remarksBlock.appendTo(v, '.panel__remarks');
			});
		}
	});
});

