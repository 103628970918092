define('templates/panels/placeWidgetInfo',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/placeWidgetInfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "	<div class=\"remarks__roaminglogowrap\">\n		<img class=\"remarks__roaminglogo\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.logo : depth0)) != null ? stack1.reference : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.logo : depth0)) != null ? stack1.subject : stack1), depth0))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<dt class=\"datalist__key\">Adresse</dt><dd class=\"datalist__value\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.address : stack1)) != null ? stack1.street : stack1), depth0))
    + "&nbsp;"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNr : stack1), depth0))
    + "<br>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.address : stack1)) != null ? stack1.postalCode : stack1), depth0))
    + "&nbsp;"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0))
    + "</dd>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<a class=\"remarks__button button remarks__button--roaming\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.placeUrl : depth0)) != null ? stack1.content : stack1), depth0))
    + "\" target=\"_blank\">Stations&shy;details</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<a class=\"remarks__button button\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.provider : depth0)) != null ? stack1.website : stack1), depth0))
    + "\" target=\"_blank\">Website des Anbieters</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<a class=\"remarks__button button\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.provUrl : depth0)) != null ? stack1.content : stack1), depth0))
    + "\" target=\"_blank\">Website des Anbieters</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel__remarks panel__remarks--place\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<h1 class=\"panel__headline panel__headline--station\">Stationsinfos</h1>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.logo : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<dl class=\"remarks__datalist datalist datalist--mapwidget\">\n	<dt class=\"datalist__key\">Anbieter</dt><dd class=\"datalist__value\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.provider : depth0)) != null ? stack1.name : stack1), depth0))
    + "</dd>\n\n	<dt class=\"datalist__key\">Station</dt><dd class=\"datalist__value\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.name : stack1), depth0))
    + "</dd>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.place : depth0)) != null ? stack1.address : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</dl>\n\n<div class=\"remarks__buttonlist\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.placeUrl : depth0)) != null ? stack1.content : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.provider : depth0)) != null ? stack1.website : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.provUrl : depth0)) != null ? stack1.content : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.placeUrl : depth0)) != null ? stack1.content : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});

return this["JST"];

});
