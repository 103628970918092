define('casirest2/models/price',['underscore', './_', '../collections/priceItem', '../models/priceItem'], function (_, Model, PriceItemCollection, PriceItemModel) {
	'use strict';

	/**
	 * @class PriceModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/prices',
		expand: [],

		/**
		 * Returns the PriceItemCollection for this Price Model
		 *
		 * @param {BaseView} [view]
		 * @returns {PriceItemCollection}
		 */
		getPriceItems: function(view) {
			return this._buildSubCollection({
				collection: PriceItemCollection,
				attribute: 'priceItems',
				view: view
			});
		},

		/**
		 * Returns the first PriceItemModel for this Price Model with the given Category
		 *
		 * @param {String} costCategory
		 * @param {BaseView} [view]
		 * @returns {PriceItemModel}
		 */
		getPriceItem: function(costCategory, view) {
			return this.getPriceItems(view).findWhere({costCategory: costCategory});
		},
		
		/**
		 * Returns a combined PriceItemModel for this Price Model with the given Category
		 *
		 * @param {String} costCategory
		 * @param {BaseView} [view]
		 * @returns {PriceItemModel}
		 */
		getCombinedPriceItem: function(costCategory, view) {
			var priceItems = this.getPriceItems(view).where({costCategory: costCategory});

			return _.reduce(priceItems, function(combi, item) {
				if (item.get('costTypeName') && combi.get('costTypeName')) {
					combi.set('costTypeName', combi.get('costTypeName') + ' + ' + item.get('costTypeName'));
				}
				
				if (item.get('priceIncl').amount && combi.get('priceIncl').amount) {
					combi.get('priceIncl').amount = combi.get('priceIncl').amount + item.get('priceIncl').amount;
				}
				
				if (item.get('vat').amount && combi.get('vat').amount) {
					combi.get('vat').amount = combi.get('vat').amount + item.get('vat').amount;
				}
				
				return combi;
			});
		}
	});
});

