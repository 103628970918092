define('templates/modals/bookingDetails',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/bookingDetails"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<img class=\"modal__image modal__image--edge\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1), depth0))
    + "?width=600\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<subhead class=\"modal__remark\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookingRemark : stack1), depth0))
    + "</subhead>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " (spätestens)";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1), depth0))
    + ")";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<dt class=\"datalist__key\">Kennzeichen</dt><dd class=\"datalist__value\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "</dd>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<dt class=\"datalist__key\">Merkmale</dt><dd class=\"datalist__value\"><ul class=\"datalist__hlist\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.attributes : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul></dd>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<li class=\"datalist__hlistitem\">"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "</li>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<dt class=\"datalist__key\">Buchungsmerkmale</dt><dd class=\"datalist__value\">\n		<ul class=\"datalist__innerlist\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.addProps : stack1),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n	</dd>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "				<li class=\"datalist__innerlistitem datalist__innerlistitem--"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.addPropType : depth0)) != null ? stack1.dataType : stack1), depth0))
    + "\"><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.addPropType : depth0)) != null ? stack1.name : stack1), depth0))
    + "</strong>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return ": "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.value : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<h1 class=\"modal__headline\">Ihre Buchung</h1>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookingRemark : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<dl class=\"modal__datalist datalist\">	\n	<dt class=\"datalist__key datalist__key--starttime\">Beginn</dt><dd class=\"datalist__value datalist__value--starttime\">"
    + alias4(((helper = (helper = helpers.start || (depth0 != null ? depth0.start : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data}) : helper)))
    + " Uhr</dd>\n	\n	<dt class=\"datalist__key datalist__key--endtime\">Ende</dt><dd class=\"datalist__value datalist__value--endtime\">"
    + alias4(((helper = (helper = helpers.end || (depth0 != null ? depth0.end : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end","hash":{},"data":data}) : helper)))
    + " Uhr"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFixBookee : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</dd>\n	\n	<dt class=\"datalist__key\">Station</dt><dd class=\"datalist__value\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1), depth0))
    + ", "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.city : stack1)) != null ? stack1.name : stack1), depth0))
    + "</a></dd>\n	\n	<dt class=\"datalist__key\">Fahrzeug</dt><dd class=\"datalist__value\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + " <small>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</small></dd>\n	\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.attributes : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.addProps : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</dl>\n\n<div class=\"modal__buttonwrap\">\n	<button class=\"modal__button button button--autofit printButton\" type=\"button\">Ausdrucken</button>\n</div>\n";
},"useData":true});

return this["JST"];

});
