define('templates/panels/bookee',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/panels/bookee"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + ")";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "	<img class=\"panel__bookeeimage\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1), depth0))
    + "?width=450\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.booking : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.displayName : stack1), depth0))
    + "\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.timerangeFromProposal : depth0)) != null ? stack1.isDifferent : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"panel__alerts\">\n	<div class=\"panel__alert panel__alert--place alert\">\n		<h3 class=\"alert__headline\">Abweichender Buchungszeitraum</h3>\n		<p class=\"panel__text\">Die Verfügbarkeit dieses Fahrzeugs weicht vom angefragten Zeitraum ab! Bitte beachten Sie den angepassten Buchungszeitraum.</p>\n		<p class=\"panel__text\">Start: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timerangeFromProposal : depth0)) != null ? stack1.start : stack1), depth0))
    + "</p>\n		<p class=\"panel__text\">Ende: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timerangeFromProposal : depth0)) != null ? stack1.end : stack1), depth0))
    + "</p>\n	</div>\n</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"panels.bookee.reserve",{"name":"string","hash":{},"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"panels.bookee.book",{"name":"string","hash":{},"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"panel__prices prices\">\n	<dl class=\"prices__list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.prices : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</dl>\n</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "		<dt class=\"prices__type\">"
    + alias1(((helper = (helper = helpers.costTypeName || (depth0 != null ? depth0.costTypeName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"costTypeName","hash":{},"data":data}) : helper)))
    + "</dt>\n		<dd class=\"prices__amount\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.priceIncl : depth0)) != null ? stack1.amountFormated : stack1), depth0))
    + "</dd>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"panel__bookeeDetails bookeeDetails\">\n	<ul class=\"bookeeDetails__attributeList\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.attributes : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>\n<div class=\"panel__remarks panel__remarks--bookee\"></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return "		<li class=\"bookeeDetails__attribute\">"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel__remarks panel__remarks--bookee panel__remarks--noattribs\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h1 class=\"panel__headline panel__headline--bookee\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + " <small class=\"panel__smallinline\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1), depth0))
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</small></h1>	\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.isFlexOrFloat : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<button class=\"button button--bookee button--panel button--book createPrelimBooking\" type=\"button\" style=\"display: none;\">"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isFlexOrFloat : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "</button>\n<button class=\"button button--bookee button--panel button--disabled button--book notAvailable\" type=\"button\" disabled=\"disabled\" style=\"display: none;\">Nicht verfügbar<span class=\"button__info\">(Im aktuellen Suchzeitraum)</span></button>\n<button class=\"button button--bookee button--panel button--disabled button--loading loading--button\" type=\"button\" disabled=\"disabled\" style=\"display: none;\">Prüfe Verfügbarkeit</button>\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.prices : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.attributes : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});

return this["JST"];

});
