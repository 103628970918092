define('casirest2/models/addPropType',[
	'./_',
], function (Model) {
	'use strict';

	/**
	 * @class AddPropTypeModel
	 * @author Philip <philip@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/addPropType',
	});
});

