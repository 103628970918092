define('templates/modals/confirmBooking',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/confirmBooking"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "	<img class=\"modal__image modal__image--car\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1), depth0))
    + "?width=450\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<img class=\"modal__dataimage\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1), depth0))
    + "?width=100\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\" onerror=\"this.onerror=null;this.src='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ewi3 : depth0)) != null ? stack1.rootPath : stack1), depth0))
    + "img/404.png'\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + ")";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"modal__data\"><div class=\"modal__label\">Station:</div> <div class=\"modal__value\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " (spätestens)";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "	<div class=\"modal__data modal__data--price\"><div class=\"modal__label\">Zeitpreis:</div> <div class=\"modal__value\">"
    + container.escapeExpression(((helper = (helper = helpers.price || (depth0 != null ? depth0.price : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"price","hash":{},"data":data}) : helper)))
    + "</div></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"modal__constraints constraints\">\n		<h2 class=\"constraints__headline\">Wichtiger Hinweis!</h2>\n		<ul class=\"constraints__list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.constraints : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n	</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "			<li class=\"constraints__item\">\n				<h3 class=\"constraints__title\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n				<p class=\"constraints__message\">"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n			</li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"modal__promotionCode\">\n		<label class=\"additionals__field field\">\n			<input name=\"promotionCode\" type=\"text\" placeholder=\"Promotion-Code"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.promotionCode : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" class=\"field__input field__input--text"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.errors : depth0)) != null ? stack1.promotionCode : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" value=\"\" />\n		</label>\n	</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " ungültig";
},"19":function(container,depth0,helpers,partials,data) {
    return " field__input--error";
},"21":function(container,depth0,helpers,partials,data) {
    return "	<button class=\"button button--outline button--small computePrice\">Kostenabschätzung</button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "Hinweis akzeptieren und ";
},"25":function(container,depth0,helpers,partials,data) {
    return "Buchung";
},"27":function(container,depth0,helpers,partials,data) {
    return "Reservierung";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing, alias5="function";

  return "<h1 class=\"modal__headline\">Prüfen &amp; Bestätigen</h1>\n\n<div class=\"modal__baseData\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"modal__data modal__data--bookee\"><div class=\"modal__label\">Fahrzeug:</div> <div class=\"modal__value\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookeeImage : depth0)) != null ? stack1.reference : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "<small class=\"modal__subvalue\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</small>\n		<span class=\"modal__fuelLevel fuelLevel\"></span>\n	</div></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"modal__data modal__data--start\"><div class=\"modal__label\">Von:</div> <div class=\"modal__value\">"
    + alias3(((helper = (helper = helpers.start || (depth0 != null ? depth0.start : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"start","hash":{},"data":data}) : helper)))
    + " Uhr</div></div>\n\n	<div class=\"modal__data modal__data--end\"><div class=\"modal__label\">Bis:</div> <div class=\"modal__value\">"
    + alias3(((helper = (helper = helpers.end || (depth0 != null ? depth0.end : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"end","hash":{},"data":data}) : helper)))
    + " Uhr"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isFixBookee : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.price : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	\n</div>\n\n\n<div class=\"modal__additionalData additionals\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.constraints : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"modal__bookingRemark\">\n		<h2 class=\"modal__subhead\">Buchungsbemerkungen</h2>\n\n		<label class=\"additionals__field field\">\n			<span class=\"field__label\">Merktext</span>\n			<input name=\"bookingRemark\" type=\"text\" class=\"field__input field__input--text\" value=\"\" />\n		</label>\n	</div>\n	\n	\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.offerPromotionCodes : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.offerPriceComputation : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	\n</div>\n\n\n<div class=\"modal__buttonwrap modal__buttonwrap--complex\">\n	<button class=\"modal__button modal__button--book button button--account confirmBooking\" type=\"button\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.constraints : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFixBookee : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + " bestätigen</button>\n</div>\n";
},"useData":true});

return this["JST"];

});
