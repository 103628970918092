define('views/blocks/placePanelAlert', ['jquery', 'views/_view'], function ($, BaseView) {
	'use strict';

	return BaseView.extend({
		className: 'panel__alert panel__alert--place alert',

		render: function () {
			var v = this,
				$h3 = $('<h3 class="alert__headline" />').appendTo(v.$el),
				$p = $('<p class="panel__text" />').appendTo(v.$el),
				$img = $('<img class="remarks__image"/>');

			v.listenToAndCall(v.model, 'change:subject', function() {
				$h3.text(v.model.get('subject'));
			});

			v.listenToAndCall(v.model, 'change:content', function() {
				if (v.model.get('content')) {
					$p.text(v.model.get('content'));
				}
			});
			
			v.listenToAndCall(v.model, 'change:reference', function() {
				if (v.model.get('reference')) {
					$img.attr('src', v.model.get('reference'));
					$img.appendTo($p);
				} else {
					$img.detach();
				}
			});
		}
	});
});

