define('views/blocks/booking', [
	'underscore',
	'views/_view',
	'helpers/template',
	'moment',
	'jquery',
	'views/modals/endTrip',
	'views/modals/prompt',
	'casirest2/collections/priceItem',
	'helpers/string'
], function (
	_,
	View,
	templateHelper,
	moment,
	$,
	EndTripModalView,
	PromptModalView,
	PriceItemCollection,
	stringHelper
) {
	'use strict';

	/**
	 * BookingBlockView
	 *
	 * @module views/blocks/booking
	 * @class BookingBlockView
	 * @augments BaseView
	 */
	return View.extend({
		tagName: 'tr',
		className: 'bookings__row',
		visibleOptions: false,

		events: {
			'click .bookings__vehicle': 'goToBookee',
			'click .bookings__station': 'goToPlace',
			'click .bookings__button--details': 'showBookingDetails',
			'click .bookings__button--enlarge': 'enlargeBooking',
			'click .bookings__button--change': 'changeBooking',
			'click .bookings__button--editremark': 'editremarkBooking',
			'click .bookings__button--stop': 'stopBooking',
			'click .bookings__button--cancel': 'cancelBooking',
			'click .bookings__button--startable': 'startTrip',
			'click .bookings__button--endable': 'endTrip',
			'click .bookings__button--reopenable': 'reopenDoor',
			'click .bookings__button--abortendtrip': 'abortEndTrip',
			'click .bookings__morebutton': 'toggleMore'
		},

		/**
		 * Renders the item.
		 *
		 * @returns {BookingBlockView}
		 */
		render: function () {
			var v = this;

			v.renderTemplate(function () {
				v.listenTo(v.model, 'change:changeInfo change:tripState', v.updateActions);
				v.listenTo(v.model, 'change:bookee change:timeRange change:bookingRemark', v.renderTemplate);
			});

			return v;
		},

		renderTemplate: function (cb) {
			var v = this,
			end,
			duration;
			
			if (v.model.getBookee().get('locality') === 'FLOAT') {
				duration = moment.duration(moment(v.model.get('timeRange').end).diff( moment() ));
				end = 'spätestens ' + duration.humanize(true);
			} else {
				end = moment(v.model.get('timeRange').end, moment.ISO_8601).format('DD.MM.YYYY[,&nbsp;]HH:mm[&#8239;Uhr]');
			}
			// search for image in remarks. 1. bookeeProduct 2. bookee.
			var bookeeImage = v.model.getImage();

			var prices = null;
			if (v.model && v.model.get('price')) {
				prices = new PriceItemCollection(v.model.get('price').priceItems);
			}

			templateHelper('blocks/booking', {
				booking: v.model.toJSON(),
				bookeeImage: bookeeImage ? bookeeImage.toJSON() : {},
				start: moment(v.model.get('timeRange').start, moment.ISO_8601).format('DD.MM.YYYY[,&nbsp;]HH:mm[&#8239;Uhr]'),
				end: end,
				isFlexDontShow: v.model.get('bookee').locality === 'FLEX',
				isFlexShowUnderConditions: (v.model.get('bookee').locality === 'FLEX' &&
					!(_.indexOf(v.model.get('changeInfo').changePossibilities, 'STOP') !== -1 &&
					_.indexOf(v.model.get('changeInfo').changePossibilities, 'STARTABLE') !== -1)),
				prices: prices ? prices.prepareForRender() : {}
			}, function (html) {
				v.$el.html(html);

				// Pedelec Icon Quickfix
				if (v.model.getBookee() && v.model.getBookee() && v.model.getBookee().isPedelec()) {
					v.$el.find('.bookings__vehicle').addClass('bookings__vehicle--pedelec');
				}
				if (v.model.getBookee() && v.model.getBookee() && v.model.getBookee().isCargoWheel()) {
					v.$el.find('.bookings__vehicle').addClass('bookings__vehicle--cargowheel');
				}

				v.updateActions();
				if (_.isFunction(cb)) {
					cb();
				}
			});
			return v;
		},

		updateActions: function () {
			var v = this;

			v.$el.find('.bookings__action').hide();
			v.$el.find('.bookings__action--details').show();

			_.each(v.model.get('changeInfo').changePossibilities, function (possibility) {
				v.$el.find('.bookings__action--' + possibility.toLowerCase()).show();
			});
			if(v.model.get('changeInfo').changePossibilities.indexOf('NOTSTARTABLE') === -1) {
				v.$el.find('.bookings__action--startable').show();
			}else{
				v.$el.find('.bookings__action--startable').hide();
			}

			if (v.model.has('tripState')) {
				v.$el.find('.bookings__action--' + v.model.get('tripState').toLowerCase()).show();
			}
		},

		goToBookee: function (e) {
			e.preventDefault();
			window.myApp.subNavigate(
				'place/' + this.model.get('bookee').place.id + '/' + this.model.get('bookee').id,
				{trigger: true}
			);
		},
		goToPlace: function (e) {
			e.preventDefault();
			window.myApp.subNavigate('place/' + this.model.get('bookee').place.id, {trigger: true});
		},

		toggleOptions: function (down) {
			var v = this;

			if (down) {
				v.$el.find('.bookings__actions').slideDown(100);
			} else {
				v.$el.find('.bookings__actions').slideUp(100);
			}
			v.visibleOptions = down;
		},
		toggleMore: function (e) {
			var v = this;
			e.preventDefault();
			e.stopPropagation();

			v.toggleOptions(!v.visibleOptions);
			v.model.fetch();

			// close options when clicking outside
			if (v.visibleOptions === true) {
				$('html').one('click', function () {
					v.toggleOptions(false);
				});
			}
		},

		showBookingDetails: function () {
			var v = this;

			require(['views/modals/bookingDetails'], function (BookingDetailsView) {
				v.bookingDetailsView = new BookingDetailsView({
					model: v.model
				});

				window.myApp.view.renderView(v.bookingDetailsView);
			});
		},

		enlargeBooking: function () {
			var v = this;

			require(['views/modals/enlargeBooking'], function (EnlargeBookingView) {
				v.changeBookingView = new EnlargeBookingView({
					booking: v.model
				});

				window.myApp.view.renderView(v.changeBookingView);

				v.listenTo(v.changeBookingView, 'bookingChanged', function () {
					v.model.fetch();
				});
			});
		},

		changeBooking: function () {
			var v = this;

			require(['views/modals/changeBooking'], function (ChangeBookingView) {
				v.changeBookingView = new ChangeBookingView({
					booking: v.model
				});

				window.myApp.view.renderView(v.changeBookingView);

				v.listenTo(v.changeBookingView, 'bookingChanged', function () {
					v.model.fetch();
				});
			});
		},

		editremarkBooking: function () {
			var v = this;

			require(['views/modals/changeBookingRemark'], function (ChangeBookingRemarkView) {
				v.changeBookingRemarkView = new ChangeBookingRemarkView({
					booking: v.model
				});

				window.myApp.view.renderView(v.changeBookingRemarkView);

				v.listenTo(v.changeBookingRemarkView, 'bookingChanged', function () {
					v.model.fetch();
				});
			});
		},

		stopBooking: function () {
			var v = this;

			require(['views/modals/stopBooking'], function (StopBookingView) {
				v.stopBookingView = new StopBookingView({
					booking: v.model
				});

				window.myApp.view.renderView(v.stopBookingView);

				v.listenTo(v.stopBookingView, 'bookingStopped', function () {
					v.model.collection.fetch();
					v.model.collection.remove(v.model);
				});
			});
		},

		cancelBooking: function () {
			var v = this;

			require(['views/modals/cancelBooking'], function (CancelBookingView) {
				v.cancelBookingView = new CancelBookingView({
					booking: v.model
				});

				window.myApp.view.renderView(v.cancelBookingView);

				v.listenTo(v.cancelBookingView, 'bookingCanceled', function () {
					v.model.collection.fetch();
					v.model.collection.remove(v.model);
				});
			});
		},

		/**
		 * This is very, very hacky! I'm sorry.
		 * @author Sebastian
		 */
		startTrip: function () {
			var v = this;

			if(v.model.get('changeInfo').changePossibilities.indexOf('NOTSTARTABLE') > -1) {
				if(v.waitingModal) {
					v.waitingModal.remove();

					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Öffnen fehlgeschlagen',
							message: stringHelper.get('bookings.error.starttrip', {name: v.model.get('bookee').displayName}),
							type: 'error'
						})
					);
				}

				return;
			}

			if(v.model.get('changeInfo').changePossibilities.indexOf('STARTABLE') === -1) {
				if(!v.waitingModal) {
					v.waitingModal = new PromptModalView({
						title: 'Bitte warten…',
						message: 'Dein Fahrzeug wird geöffnet, sobald es bereit ist. Das kann einen Augenblick dauern. Bitte warte solange.',
						type: 'loading',
						buttonText: 'Abbrechen',
						cb: function() {
							v.trigger('startTripCanceled');
						}
					});
					window.myApp.view.renderView(v.waitingModal);
				}

				var t = setTimeout(function () {
					v.model.fetch({
						success: function () {
							v.startTrip();
						},
						error: function () {
							new PromptModalView({
								title: 'Öffnen fehlgeschlagen',
								message: stringHelper.get('bookings.error.starttrip', {name: v.model.get('bookee').displayName}),
								type: 'error'
							});
						}
					});
				}, 10000);
				this.on('startTripCanceled', function () {
					clearTimeout(t);
				});

				return;
			}

			v.model.startTrip({
				success: function () {
					if(v.waitingModal) {
						v.waitingModal.remove();
					}

					var message = '';
					if (v.model.get('bookee').bookeeType.id === '1132' || v.model.get('bookee').bookeeType.id === '13') { //pedelec
						if (
							_.has(v.model.get('flexInfo'), 'currentSlot') && _.has(v.model.get('flexInfo').currentSlot, 'name')
						) {
							message = 'Bitte entnehmen Sie das ' + v.model.get('bookee').displayName + ' aus ' + v.model.get('flexInfo').currentSlot.name;
						} else {
							message = 'Bitte schließen Sie das ' + v.model.get('bookee').displayName + ' auf (am Fahrradständer).';
						}
					} else {
						message = 'Ihr ' + v.model.get('bookee').displayName;
						if (_.has(v.model.get('bookee'), 'licenseNumber')) {
							message = message + ' (' + v.model.get('bookee').licenseNumber + ') ';
						}
						message = message + ' wurde geöffnet und die Fahrt hat begonnen.';
					}

					require(['views/modals/prompt'], function (SuccessModal) {
						var successModal = new SuccessModal({
							title: 'Buchung gestartet',
							message: message,
							type: 'success'
						});
						window.myApp.view.renderView(successModal);
					});

					v.model.fetch();
				},
				report: function(msg) {
					return msg.indexOf('HANDLERERROR: EBUS_GENERAL: ') !== 0;
				},
				error: function($xhr) {
					if(v.waitingModal) {
						v.waitingModal.remove();
					}

					if(
						$xhr.responseJSON &&
						$xhr.responseJSON.errorMessage &&
						$xhr.responseJSON.errorMessage.indexOf('HANDLERERROR: EBUS_GENERAL: ') === 0
					) {
						window.myApp.view.renderView(
							new PromptModalView({
								title: 'Öffnen fehlgeschlagen',
								message: stringHelper.get('bookings.error.starttrip', {name: v.model.get('bookee').displayName}),
								type: 'error' 
							})
						);
					}
				}
			});
		},
		endTrip: function () {
			var v = this;

			// endTrip: FLEX needs Pop-Up to get the returnPlace first.
			if (v.model.get('flexInfo') && v.model.get('flexInfo').returnPlaces && v.model.get('flexInfo').returnPlaces.length > 0) {
				v.endTripModal = new EndTripModalView({
					model: v.model
				});
				window.myApp.view.renderView(v.endTripModal);

				v.listenTo(v.endTripModal, 'placeSelected', function (placeId) {
					v.model.endTrip({
						data: JSON.stringify({
							placeId: placeId || v.model.get('bookee').place.id
						}),
						contentType: 'application/json',
						success: function (data) {

							var message = '';
							if (v.model.get('bookee').bookeeType.id === '1132' || v.model.get('bookee').bookeeType.id === '13') { //pedelec
								if (data && _.has(data, 'name')) {
									message = 'Bitte stellen Sie das ' + v.model.get('bookee').displayName + ' in ' + data.name + ' zurück.';
								} else {
									message = 'Bitte schließen Sie das ' + v.model.get('bookee').displayName + ' mit dem Schloss am Stellplatz an';
								}
							} else {
								message = 'Fahrt beendet. Vielen Dank.';
							}

							require(['views/modals/prompt'], function (SuccessModal) {
								var successModal = new SuccessModal({
									title: 'Buchung beendet',
									message: message,
									type: 'success'
								});
								window.myApp.view.renderView(successModal);
							});

							v.model.collection.fetch();
						}
					});
				});
			} else {
				v.model.endTrip({
					contentType: 'application/json',
					success: function (data) {

						var message = '';
						if (v.model.get('bookee').bookeeType.id === '1132' || v.model.get('bookee').bookeeType.id === '13') { // emobil Pedelec
							if (data && _.has(data, 'name')) {
								message = 'Bitte ' + v.model.get('bookee').displayName + ' in ' + data.name + ' zurückgeben.';
							} else {
								message = 'Bitte schließen Sie das ' + v.model.get('bookee').displayName + ' mit dem Schloss am Stellplatz an';
							}
						} else {
							message = 'Fahrt beendet. Vielen Dank.';
						}

						require(['views/modals/prompt'], function (SuccessModal) {
							var successModal = new SuccessModal({
								title: 'Buchung beendet',
								message: message,
								type: 'success'
							});
							window.myApp.view.renderView(successModal);
						});

						v.model.collection.fetch();
					}
				});
			}
		},
		reopenDoor: function () {
			var v = this;

			v.model.reopenDoor({
				success: function (data) {

					var message = '';
					if (v.model.get('bookee').bookeeType.id === '1132' || v.model.get('bookee').bookeeType.id === '13') { // emobil Pedelec
						if (
							_.has(v.model.get('flexInfo'), 'currentSlot') && _.has(v.model.get('flexInfo').currentSlot, 'name')
						) {
							message = 'Bitte gehen Sie zu ' + (data && data.name ? data.name : v.model.get('flexInfo').currentSlot.name);
						}
					} else {
						message = 'Bitte erneut versuchen.';
					}


					require(['views/modals/prompt'], function (SuccessModal) {
						var successModal = new SuccessModal({
							title: 'Erneut geöffnet',
							message: message,
							type: 'success'
						});
						window.myApp.view.renderView(successModal);
					});

					v.model.fetch();
				}
			});
		},
		abortEndTrip: function () {
			var v = this;

			v.model.abortEndTrip({
				success: function () {
					require(['views/modals/prompt'], function (SuccessModal) {
						var successModal = new SuccessModal({
							title: 'Erfolgreich',
							message: 'Die Rückgabe wurde abgebrochen. Schöne Weiterfahrt.',
							type: 'success'
						});
						window.myApp.view.renderView(successModal);
					});

					v.model.fetch();
				}
			});
		}
	});
});

