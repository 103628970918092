define('views/account/settingsAccessMedias', [
	'views/_account',
	'views/account/settingsAccessMedia',
	'casirest2/models/accessMedia',
	'helpers/settings'
], function (
	AccountBaseView,
	SettingsAccessMediaAccountView,
	AccessMediaModel,
	SettingsHelper
) {
	'use strict';

	return AccountBaseView.extend({
		className: 'customerSettingsAccessMedias',

		render: function () {
			var v = this,
			uiSettings = SettingsHelper.getProviderSettings().get('bookingUISettings');
			v.dataReadOnly = uiSettings.customerAccessCardChangeAllowed === 'DISPLAY';
			v.dataHidden = uiSettings.customerAccessCardChangeAllowed === 'NONE';

			if (!v.dataHidden) {
				v.listenTo(v.collection, 'add', v.addAccessMedia);
				v.collection.each(v.addAccessMedia);
			}

			v.listenToAndCall(v.collection, 'sync', function() {
				if(v.collection.length === 0 && v.$el.children().length === 0 && !v.dataReadOnly && !v.dataHidden) {
					v.addAccessMedia(new AccessMediaModel());
				}
			});
		},
		addAccessMedia: function(media, append) {
			var v = this,
				view = new SettingsAccessMediaAccountView({
					model: media,
					readonly: v.dataReadOnly
				})[append === true ? 'appendTo' : 'prependTo'](v);

			v.trigger('add', view);

			if(!media.id) {
				v.once('add', function() {
					view.remove();
				});
			}
			v.listenTo(v.collection, 'remove', function(removed) {
				if(removed.id === media.id) {
					view.remove();
				}
			});
		}
	});
});

