define('views/panels/unavailability', [
	'underscore',
	'backbone',
	'views/_panel',
	'helpers/template',
	'views/blocks/unavailabilityBookee'
], function (
	_,
	Backbone,
	PanelView,
	templateHelper,
	UnavailabilityBookeeBlockView
) {
	'use strict';

	return PanelView.extend({
		panelName: ['unavailability'],

		_initialize: function (options) {
			this.timeHelper = options.timeHelper;
			if (!this.timeHelper) {
				throw new Error('UnavailabilityPanelView: no timeHelper instance given!');
			}

			this.collection = this.model.getBookees(this);
			this.collection.comparator = function (bookee) {
				return [bookee.get('bookeeType').name, bookee.get('bookeeProduct').name, bookee.get('licenseNumber')];
			};
			this.collection.sort();
		},

		render: function () {
			var v = this;

			templateHelper('panels/unavailability', function (html) {
				v.$el.html(html);

				v.listenTo(v.collection, 'add', v.addBookee);
				v.collection.each(v.addBookee);
			});
		},
		addBookee: function (bookee) {
			var v = this,
				blockView = new UnavailabilityBookeeBlockView({
					model: bookee,
					timeHelper: v.timeHelper
				}).appendTo(v);

			v.listenTo(v.collection, 'remove', function (removed) {
				if (removed.id === bookee.id) {
					blockView.remove();
				}
			});
		}
	});
});

