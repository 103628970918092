define('casirest2/collections/bookeeType',['./_', '../models/bookeeType'], function (Collection, BookeeTypeModel) {
	'use strict';

	/**
	 * @class BookeeTypeCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: BookeeTypeModel,
		filterMethods: ['provider']
	});
});

