define('casirest2/models/prelimBooking',[
	'./_',
	'../helpers/data',
	'../models/booking',
	'../collections/addPropType'
], function (
	Model,
	DataHelper,
	BookingModel,
	AddPropTypeCollection
) {
	'use strict';

	/**
	 * @class PrelimBookingModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	var PrelimBookingModel = Model.extend({
		urlRoot: '/prelim_bookings',
		expand: ['addPropType', 'price'],
		isAlwaysSingle: true,

		initialize: function (options) {
			// cancelBooking
			if (_.isObject(options) && _.isString(options.cancelBooking)) {
				this.url = '/bookings/' + options.cancelBooking + '/cancel';
			}

			// stopBooking
			if (_.isObject(options) && _.isString(options.stopBooking)) {
				this.url = '/bookings/' + options.stopBooking + '/stop';
			}
		},

		cancelBooking: function (bookingID) {
			return new PrelimBookingModel({
				cancelBooking: bookingID
			});
		},

		stopBooking: function (bookingID) {
			return new PrelimBookingModel({
				stopBooking: bookingID
			});
		},

		confirm: function () {
			var ExtendedBookingModel = BookingModel.extend({
				urlRoot: '/prelim_bookings/' + this.id + '/confirm'
			});
			return new ExtendedBookingModel();
		},
		
		/**
		 * Returns a AddPropTypeCollection containing all addPropTypes
		 * @param {BaseView} [view]
		 * @returns {AddPropTypeCollection}
		 */
		getAddPropTypes: function (view) {
			return this._buildSubCollection({
				collection: AddPropTypeCollection,
				attribute: 'addPropTypes',
				view: view
			});
		},
		
		/**
		 * Returns a PriceItemCollection containing all prices
		 * @param {BaseView} [view]
		 * @returns {PriceItemCollection}
		 */
		getPrice: function (view) {
			return this._buildSubCollection({
				collection: PriceItemCollection,
				attribute: 'price',
				view: view
			});
		},
		
		computePrice: function (params, options) {
			var url = '/prelim_bookings/' + this.id + '/price',
				queryStrings = [];
				
			_.each(params, function (v, k) {
				queryStrings.push(
					encodeURIComponent(k) + '=' + encodeURIComponent(v)
				);
			});
			url += '?' + queryStrings.join('&');
			
			return DataHelper.ajax(_.extend({}, options, {
				type: 'GET',
				url: url
			}));
		},
		
		// overwrite destroy because of expanding/id f*ck
		destroy: function (options) {
			return DataHelper.ajax(_.extend({}, options, {
				type: 'DELETE',
				url: this.url({noParams: true}) + '/' + this.id
			}));
		}
	});

	return PrelimBookingModel;
});

