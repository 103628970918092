define('templates/modals/enlargeBooking',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/enlargeBooking"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1), depth0))
    + ")";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal__data\"><div class=\"modal__label\">Station:</div> <div class=\"modal__value\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "	<div class=\"modal__data\"><div class=\"modal__label modal__timeformat\">Bis:</div> <div class=\"modal__value modal__timeformat\">"
    + container.escapeExpression(((helper = (helper = helpers.end || (depth0 != null ? depth0.end : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"end","hash":{},"data":data}) : helper)))
    + "</div></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"modal__data modal__data--enlargepicker\">\n		<div class=\"modal__label modal__label--enlargepicker modal__timeformat\">Bis:</div>\n		<div class=\"modal__value modal__value--enlargepicker\">\n			<div class=\"modal__pickerwrap enlargeBooking__pickerwrap\"></div>\n		</div>\n	</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"modal__constraints constraints\">\n	<h2 class=\"constraints__headline\">Wichtiger Hinweis!</h2>\n	<ul class=\"constraints__list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.constraints : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "		<li class=\"constraints__item\">\n			<h3 class=\"constraints__title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n			<p class=\"constraints__message\">"
    + alias4(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "</p>\n		</li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"enlargeBooking__button enlargeBooking__button--changeAccept button button--invert\" type=\"button\">Hinweis akzeptieren und Buchung ändern</button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "		<button class=\"enlargeBooking__button enlargeBooking__button--change button button--invert\" type=\"button\">Buchung ändern</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h1 class=\"modal__headline\">Buchungsdaten</h1>\n\n<div class=\"modal__data\"><div class=\"modal__label\">Fahrzeug:</div> <div class=\"modal__value\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "<small class=\"modal__subvalue\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.bookeeType : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</small></div></div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.bookee : depth0)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"modal__data\"><div class=\"modal__label\">Von:</div> <div class=\"modal__value modal__timeformat\">"
    + alias2(((helper = (helper = helpers.start || (depth0 != null ? depth0.start : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"start","hash":{},"data":data}) : helper)))
    + "</div></div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.showTimeAsTextInsteadOfTimePicker : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.constraints : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n<div class=\"modal__buttonwrap modal__buttonwrap--complex\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.constraints : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});

return this["JST"];

});
