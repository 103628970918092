define('views/blocks/bookee', [
	'jquery',
	'underscore',
	'helpers/settings',
	'casirest2/helpers/data',
	'views/_view'
], function ($, _, SettingsHelper, DataHelper, View) {
	'use strict';

	/**
	 * BookeeBlockView
	 *
	 * @module views/blocks/bookee
	 * @class BookeeBlockView
	 * @augments BaseView
	 */
	return View.extend({
		tagName: 'button',
		className: 'panel__button panel__button--showBookee panel__button--more',
		events: {
			'click': 'openBookee'
		},

		_initialize: function (options) {
			this.proposals = options.proposals;
			this.parent = options.parent;
			this.place = options.place;
			this.timeHelper = options.timeHelper;
		},

		/**
		 * Renders the item.
		 *
		 * @returns {BookeeBlockView}
		 */
		render: function () {
			var v = this,
				$name = $('<span class="panel__bookeeName" />').appendTo(v.$el),
				$button = $('<span class="panel__buttoninfo" />').appendTo(v.$el),
				$meta = $('<span class="panel__metaData" />').appendTo($button),
				$fuel = $('<span class="panel__fuelLevel fuelLevel" />').appendTo($button),
				$fuelbar = $('<span class="fuelLevel__level" />').appendTo($fuel),
				$price = $('<span class="panel__price" />').appendTo(v.$el),
				$bookeeAvailabilitysize = $('<span class="panel__availabilitysize availabilitysize" />').appendTo(v.$el),
				$bookeeAvailable = $('<span class="availabilitysize__available" />').appendTo($bookeeAvailabilitysize),
				$bookeeAmount = $('<span class="availabilitysize__amount" />').appendTo($bookeeAvailabilitysize);

			v.price = v.model.getPrice();

			v.listenToAndCall(v.model, 'change:displayName', function () {
				$name.text(v.model.get('displayName'));
			});
			v.listenToAndCall(v.model, 'change:bookeeType change:licenseNumber', function () {
				var meta = v.model.get('bookeeType').name;
				if (v.model.has('licenseNumber')) {
					meta += ' (' + v.model.get('licenseNumber') + ')';
				}
				$meta.text(meta);
			});
			v.listenToAndCall(v.model, 'change:bookeeType', function () {
				v.$el.toggleClass('panel__button--pedelec', v.model.isPedelec());
			});
			v.listenToAndCall(v.model, 'change:bookeeType', function () {
				v.$el.toggleClass('panel__button--cargowheel', v.model.isCargoWheel());
			});
			v.listenToAndCall(v.model, 'change:isElectroMobile change:fuelLevel', function () {
				var level = v.model.get('isElectroMobile') ? v.model.get('fuelLevel') : null;

				$fuel
					.toggleClass('fuelLevel--hidden', !level)
					.attr('title', level ? ('Ladestand: ' + level + ' %') : '');

				$fuelbar.css('width', (level || '0') + '%');
			});
			
			v.listenToAndCall(v.model, 'change:isPool', function () {
				$bookeeAvailabilitysize.toggleClass('availabilitysize--hidden', !v.model.get('isPool'));
			});
			v.listenToAndCall(v.proposals, 'sync reset', function () {
				var proposal = v.proposals.find(function (proposal) {
					return proposal.get('bookee').id === v.model.id;
				});
				
				if (proposal && proposal.has('availabilitySize')) {
					$bookeeAvailable.text(proposal.get('availabilitySize'));
				} else {
					$bookeeAvailable.text(0);
				}
				$bookeeAmount.text('X');

				v.$el.toggleClass('panel__button--available', !!proposal);
			});

			v.listenToAndCall(v.timeHelper, 'change', function () {
				v.price.resetFilter();
				v.price.addFilter({
					start: v.timeHelper.get('start').toJSON(),
					end: v.timeHelper.get('end').toJSON()
				});

				v.refreshPrice();
			});

			// Prices
			v.listenTo(v.price, 'sync', function () {
				var priceItem = v.price.getCombinedPriceItem('DRIVING_TIME');
				$price.html(priceItem ? priceItem.format() + '<span class="panel__priceremark">*</span>' : '');
				v.trigger('priceSync');
			});
			v.listenTo(DataHelper, 'auth:loggedIn auth:loggedOut', function () {
				v.refreshPrice();
			});

			return v;
		},

		refreshPrice: function () {
			if ((SettingsHelper.getProviderSettings().get('bookingUISettings') || {}).offerPriceComputation) {
				this.price.fetch({
					report: function (msg) {
						return !msg || msg.indexOf('PRICE_COMPUTATION_NOT_POSSIBLE: PRICE_COMPUTATION_NOT_POSSIBLE: ') !== 0;
					}
				});
			}
		},

		openBookee: function () {
			var v = this;
			v.parent.openBookee(v.model);
		}
	});
});

