define('views/menu', [
	'backbone',
	'underscore',
	'views/_view',
	'helpers/template',
	'helpers/mapState',
	'helpers/string',
	'helpers/settings',
	'views/blocks/menuItem',
	'casirest2/helpers/data'
], function (
	Backbone,
	_,
	View,
	templateHelper,
	mapStateHelper,
	stringHelper,
	SettingsHelper,
	MenuItemBlockView,
	DataHelper
) {
	'use strict';

	/**
	 * MenuView
	 *
	 * @module views/menu
	 * @class MenuView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return View.extend({
		className: 'menu',

		/**
		 * @returns {MenuView}
		 */
		render: function () {
			var v = this,
				items;

			items = [
				{
					sort: 10,
					name: 'more',
					label: stringHelper.get('menu.items.more'),
					route: 'more',
					activeWhen: function () {
						var p = Backbone.history.fragment.split('/');
						return ((
									p.length >= 2 && (
										p[1] === 'login' ||
										p[1] === 'more'
									)) || (
									p.length >= 3 && (
										p[1] === 'account' &&
										p[2] === 'settings'
									)
								));
					},
					activeListeners: [
						{
							object: window.myApp,
							events: 'route'
						}
					]
				},
				{
					sort: 8,
					name: 'login',
					label: 'Login',
					route: 'login',
					showListeners: [{
						object: DataHelper,
						events: 'auth:loggedIn auth:loggedOut'
					}],
					showWhen: function () {
						return !DataHelper.isLoggedIn();
					}
				},
				{
					sort: 8,
					name: 'logout',
					label: 'Logout',
					route: 'logout',
					showListeners: [{
						object: DataHelper,
						events: 'auth:loggedIn auth:loggedOut'
					}],
					showWhen: function () {
						return DataHelper.isLoggedIn();
					}
				},
				{
					sort: 7,
					name: 'account',
					label: 'Mein Konto',
					route: 'account/settings',
					showListeners: [{
						object: DataHelper,
						events: 'auth:loggedIn auth:loggedOut'
					}],
					showWhen: function () {
						return DataHelper.isLoggedIn();
					}
				},
				{
					sort: 3,
					name: 'bookings',
					label: stringHelper.get('menu.items.bookings'),
					route: 'account/bookings'
				},
				{
					sort: 2,
					name: 'filter',
					label: stringHelper.get('menu.items.filter'),
					route: 'filter',
					badge: function () {
						if (mapStateHelper.isFiltered()) {
							return mapStateHelper.filterByBookeeTypeIds().length;
						}
						return 0;
					},
					badgeListeners: [{
						object: mapStateHelper,
						events: 'change:flt change:fbt'
					}]
				},
				{
					sort: 1,
					name: 'find',
					label: stringHelper.get('menu.items.find'),
					route: '',
					activeWhen: function () {
						var p = Backbone.history.fragment.split('/');
						return p.length === 1 || (p.length >= 2 && p[1] === 'place');
					},
					activeListeners: [
						{
							object: window.myApp,
							events: 'route'
						}
					]
				}
			];
			

			templateHelper('menu', function (html) {
				v.$el.html(html);

				_.each(_.sortBy(items, 'sort'), function (item) {
					new MenuItemBlockView(item).appendTo(v, '.menu__list');
				});
			});

			return v;
		}
	});
});

