define('casirest2/collections/unavailabilityTimeRange',['./_', '../models/unavailabilityTimeRange'], function (Collection, UnavailabilityTimeRangeModel) {
	'use strict';

	/**
	 * @class UnavailabilityTimeRangeCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: UnavailabilityTimeRangeModel,
		filterMethods: ['timeRange']
	});
});

