define('casirest2/collections/accessMedia',['./_', '../models/accessMedia'], function (Collection, AccessMediaModel) {
	'use strict';

	/**
	 * @class AccessMediaCollection
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Collection.extend({
		model: AccessMediaModel,
		filterMethods: []
	});
});

