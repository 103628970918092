define('casirest2/models/booking',[
	'underscore',
	'./_',
	'./bookee',
	'casirest2/helpers/data'
], function (_, Model, BookeeModel, DataHelper) {
	'use strict';

	/**
	 * @class BookingModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/bookings',
		expand: ['bookee.place', 'bookee.place.city', 'bookee.remark', 'bookee.bookeeProduct.remark', 'bookee.bookeeType', 'changeInfo', 'price', 'flexInfo.slot', 'flexInfo.place', 'addProp.addPropType'],

		/**
		 * Returns the BookeModel for this booking
		 * @returns {BookeeModel}
		 */
		getBookee: function () {
			return new BookeeModel(this.get('bookee'));
		},

		/**
		 * Returns a RemarkModel containing an image for this booking
		 * @param {object} [options]
		 * @param {boolean} [options.onlyMarketing] Set to true, to return marketing images only
		 * @returns {RemarkModel}
		 */
		getImage: function (options) {
			return this.getBookee().getImage(options);
		},

		/**
		 * Updates additional booking data
		 *
		 * @param {object} options
		 * @param {string} [options.bookingRemark]
		 * @param {function} [cb] Callback
		 * @returns {BookingModel}
		 */
		updateAdditionalData: function (options, cb) {
			var m = this;

			cb = cb || function () {
			};
			m.save({}, {
				url: '/bookings/' + m.id + '/additional',
				data: options,
				success: function () {
					cb();
				},
				error: function (context, $xhr, textStatus, errorThrown) {
					cb(errorThrown || textStatus || true);
				}
			});
			return m;
		},

		/**
		 * Start Trip
		 *
		 * @param {object} [options]
		 * @returns {DataHelperXHR}
		 */
		startTrip: function (options) {
			return DataHelper.ajax(_.extend({}, options, {
				type: 'POST',
				url: this.url({noParams: true}) + '/starttrip'
			}));
		},

		/**
		 * End Trip
		 *
		 * @param {object} [options]
		 * @returns {DataHelperXHR}
		 */
		endTrip: function (options) {
			return DataHelper.ajax(_.extend({}, options, {
				type: 'POST',
				url: this.url({noParams: true}) + '/endtrip'
			}));
		},

		/**
		 * Reopen Door
		 *
		 * @param {object} [options]
		 * @returns {DataHelperXHR}
		 */
		reopenDoor: function (options) {
			return DataHelper.ajax(_.extend({}, options, {
				type: 'POST',
				url: this.url({noParams: true}) + '/reopendoor'
			}));
		},

		/**
		 * Abort EndTrip
		 *
		 * @param {object} [options]
		 * @returns {DataHelperXHR}
		 */
		abortEndTrip: function (options) {
			return DataHelper.ajax(_.extend({}, options, {
				type: 'POST',
				url: this.url({noParams: true}) + '/abortendtrip'
			}));
		},

		/**
		 * Returns a true if booking is current
		 *
		 * @see https://cantaloupe.cantamen.de/admin/issues/16237
		 * @returns {Boolean}
		 */
		isCurrent: function () {

			// Ausnahme: stop kommt zusammen mit notstartable
			if (
				this.get('changeInfo') &&
				_.indexOf(this.get('changeInfo').changePossibilities, 'STOP') > -1 &&
				_.indexOf(this.get('changeInfo').changePossibilities, 'NOTSTARTABLE') > -1
			) {
				return true;
			}

			// Stop ist nie isCurrent()
			return (!this.get('changeInfo') || _.indexOf(this.get('changeInfo').changePossibilities, 'STOP') === -1);
		}
	});
});

