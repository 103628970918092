define('templates/modals/resetPassword',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/modals/resetPassword"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "					<option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"reset__content\">\n	<form class=\"reset__form\">\n		<label class=\"reset__field field\">\n			<span class=\"field__label\">E-Mail-Adresse</span>\n			<input name=\"email\" type=\"email\" class=\"field__input field__input--text\" value=\"\" autofocus />\n		</label>\n		\n		<div class=\"reset__divider\">oder</div>\n		\n		<label class=\"reset__field field\">\n			<span class=\"field__label\">Kundennummer</span>\n			<input name=\"number\" type=\"text\" class=\"field__input field__input--text\" value=\"\" />\n		</label>\n		\n		<div class=\"reset__divider\">oder</div>\n		\n		<label class=\"reset__field field\">\n			<span class=\"field__label\">Kartennummer</span>\n			<input name=\"cardNumber\" type=\"text\" class=\"field__input field__input--text\" value=\"\" />\n		</label>\n		\n		<label class=\"reset__field field\">\n			<span class=\"field__label\">Organisation</span>\n			<select class=\"reset__select field__select\" name=\"provId\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.providers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</select>\n		</label>\n		<button class=\"reset__submit button\">Passwort zurücksetzen</button>\n		\n	</form>\n</div>\n";
},"useData":true});

return this["JST"];

});
