define('views/listItem', [
	'underscore',
	'jquery',
	'views/_view',
	'views/listItemDetails',
	'views/mapPlace'
], function (
	_,
	$,
	BaseView,
	ListItemDetailsView,
	MapPlaceView
) {
	'use strict';

	/**
	 * ListItemView
	 *
	 * @module views/listItem
	 * @class ListItemView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		className: 'listitem listitem--opened',
		events: {
			'click .listitem__header': 'toggle'
		},

		_initialize: function (options) {
			var v = this;
			v.proposals = options.proposals;
			v.timeHelper = options.timeHelper;
		},

		/**
		 * @returns {MapView}
		 */
		render: function () {
			var v = this,
				$header = $('<div class="listitem__header listitem__header--opened" />').appendTo(v.$el),
				$iconwrap,
				$infowrap;

			v.$body = $('<div class="listitem__body" />').appendTo(v.$el);
			v.initialProposalSync = false;

			// get marker config
			v.updateMarkerConfig();
			v.icons = v.getIcons();
			v.listenTo(v.model, 'change:provId', v.updateMarkerConfig);

			// initialSync
			v.listenToOnce(v.proposals, 'sync', function () {
				v.initialProposalSync = true;
				//v.updateLabel();
			});

			// iconwrapper
			$iconwrap = $('<div class="listitem__iconwrap listitem__headercell" />').appendTo($header);

			// icon
			v.$icon = $('<span class="listitem__icon" />').appendTo($iconwrap);
			v.$label = $('<span class="listitem__label" />').appendTo(v.$icon);
			v.listenTo(v.model, 'change:provId', v.updateIcon);
			v.listenTo(v.proposals, 'sync reset request', v.updateIcon);
			v.updateIcon();

			// label
			v.listenTo(v.model, 'change:provId', v.updateLabel);
			v.listenTo(v.proposals, 'reset request sync', v.updateLabel);
			v.updateLabel();

			// infowrapper
			$infowrap = $('<div class="listitem__wrap listitem__headercell" />').appendTo($header);

			// title
			v.$title = $('<span class="listitem__title" />').appendTo($infowrap);
			v.listenToAndCall(v.model, 'change:name', function () {
				v.$title.html(v.model.get('name').replace(/\s*\/\s*/g, '&#8239;/&thinsp;'));
			});

			// occupancy
			v.$occupancy = $('<span class="listitem__occupancy" />').appendTo($infowrap);
			v.listenTo(v.model, 'change:provId', v.updateOccupancy);
			v.listenTo(v.proposals, 'reset request sync', v.updateOccupancy);
			v.updateOccupancy();

			// distance
			v.$distance = $('<span class="listitem__distance listitem__headercell" />').appendTo($header);
			v.listenToAndCall(v.model, 'change:distance', function () {
				var d = v.model.get('distance'),
					t;

				if (d < 1000 && d > 5) {
					t = Math.round(d / 10) + '0&#8239;m';
				}
				else if(d > 10) {
					t = (Math.round(d / 10) * 10 / 1000) + '&#8239;km';
				}
				else {
					t = '<&#8239;10&#8239;m';
				}

				v.$distance.html(t);
			});

			// $('<div class="listitem__togglewrap listitem__headercell"><button class="button listitem__toggle listitem__button">Detail</button></div>').appendTo($header);

			// this is basically a bad idea, but see i15864
			v.renderChild();

			return v;
		},

		/**
		 * Toggles the item details and renders the details if required
		 *
		 * @returns {ListItemView}
		 */
		toggle: function () {
			var v = this;

			if (!v.childRendered) {
				this.renderChild();
			}

			v.$el.toggleClass('listitem--opened');
			v.$el.find('.listitem__header').toggleClass('listitem__header--opened');
			return v;
		},

		/**
		 * Renders the ListItemDetailsView for this item
		 *
		 * @returns {ListItemView}
		 */
		renderChild: function() {
			var v = this;
			if(v.childRendered) {
				return v;
			}

			new ListItemDetailsView({
				model: v.model,
				proposals: v.proposals,
				timeHelper: v.timeHelper
			}).appendTo(v, v.$body);

			v.childRendered = true;
			return v;
		},

		/**
		 * Gets the right marker config for this marker
		 * and saves it in v.config. Uses MapPlaceView
		 * implementation to do this.
		 *
		 * @returns {ListItemView}
		 */
		updateMarkerConfig: function () {
			var v = this,
				place = new MapPlaceView({
					model: v.model,
					proposals: v.proposals
				});

			place.updateMarkerConfig();
			v.config = place.config;
			return v;
		},

		/**
		 * Find and build the required icons, returns the structure
		 * for Marker.icons. Uses the MapPlace implementation to do
		 * this.
		 *
		 * @returns {Object}
		 */
		getIcons: function () {
			var v = this,
				p = new MapPlaceView({model: v.model});

			p.config = v.config;
			return p.getIcons();
		},

		/**
		 * Updates the marker icon
		 *
		 * @returns {ListItemView}
		 */
		updateIcon: function () {
			var v = this,
				fixed = !!v.model.get('isFixed'),
				available,
				icon;

			available = v.proposals.filter(function (proposal) {
				return v.model.id === proposal.get('bookee').placeId;
			}).length;

			if (!fixed) {
				icon = 'iconFreeFloater';
			}
			else if (available === 0) {
				icon = 'iconDefault';
			}
			else if (available > 0) {
				icon = 'iconFree';
			}
			else {
				icon = 'iconDefault';
			}

			if (v.highlighted) {
				icon += 'Selected';
			}

			v.$icon.attr('class', 'listitem__icon listitem__icon--' + icon);

			icon = v.icons[icon];
			if (!icon) {
				return v;
			}

			v.$icon.css({
				background: 'url("' + icon.url + '")',
				width: icon.size.width,
				height: icon.size.height
			});

			v.$label.css(_.extend({
				top: icon.labelOrigin ? icon.labelOrigin.y : null,
				left: icon.labelOrigin ? icon.labelOrigin.x : null
			}, v.config.label || {}));
			return v;
		},

		/**
		 * Get the label text the marker should have currently
		 *
		 * @returns {String}
		 */
		getLabelText: function () {
			var v = this,
				available;

			if (!v.model.get('isFixed') || !v.model.get('bookees') || !v.model.get('bookees').length) {
				return ' ';
			}

			available = v.proposals.filter(function (proposal) {
				return v.model.id === proposal.get('bookee').placeId;
			}).length;

			if ((!v.initialProposalSync || v.model.collection.syncing) && !available) {
				available = '?';
			}

			return available + '/' + v.model.get('bookees').length;
		},

		/**
		 * Updates the marker label span
		 *
		 * @returns {MapPlaceView}
		 */
		updateLabel: function () {
			var v = this,
				label = v.getLabelText();

			v.$label.text(label);
			return v;
		},

		/**
		 * Updates the occupancy <span />
		 * (2 Fahrzeuge frei / licensePlate)
		 */
		updateOccupancy: function () {
			var v = this,
				available;

			if (v.model.get('isFixed') === false && v.model.get('bookees').length === 1) {
				v.$occupancy.text(v.model.get('bookees')[0].licenseNumber);
				return;
			}
			if (!v.model.get('bookees') || !v.model.get('bookees').length) {
				v.$occupancy.empty();
				return;
			}

			available = v.proposals.filter(function (proposal) {
				return v.model.id === proposal.get('bookee').placeId;
			}).length;

			if ((!v.initialProposalSync || v.model.collection.syncing) && !available) {
				available = '?';
			}

			v.$occupancy.text(available + ' Fahrzeug' + (available === 1 ? '' : 'e') + ' frei');
		}
	});
});

