define('views/geoSearch', [
	'underscore',
	'jquery',
	'casirest2/helpers/data',
	'helpers/template',
	'helpers/gmaps',
	'helpers/settings',
	'helpers/mapState',
	'views/_view',
	'views/blocks/closeButton',
	'views/blocks/customerFavorites'
], function (
	_,
	$,
	DataHelper,
	templateHelper,
	google,
	SettingsHelper,
	MapStateHelper,
	BaseView,
	CloseButtonBlock,
	CustomerFavoritesBlock
) {
	'use strict';

	return BaseView.extend({
		className: 'geosearch',

		activeGeoSearch: false,

		events: {
			'click button.geosearch__button--mypos': 'locate',
			'click button.geosearch__button--favs': 'toggleFavs',
			'click button.geosearch__viewtype--map': 'openMap',
			'click button.geosearch__viewtype--list': 'openList'
		},

		_initialize: function (options) {
			this.bookingAreaView = options.bookingAreaView;
		},

		render: function () {
			var v = this;

			templateHelper('geosearch', {
				canGeolocate: !!navigator.geolocation
			}, function (html) {
				v.$el.html(html);

				v.closeButton = new CloseButtonBlock({inverted: true});
				v.closeButton.appendTo(v);
				v.closeButton.$el.hide();

				v.listenTo(v.closeButton, 'close', v.closeGeoSearch);

				v.initSearch();

				// toggle Favs, when user has logged in
				v.listenTo(DataHelper, 'auth:loggedIn', function () {
					v.$el.find('.geosearch__buttonwrap--favs').removeClass('geosearch__buttonwrap--hidden');
					v.renderFavs();
				});
				if (DataHelper.isLoggedIn()) {
					v.$el.find('.geosearch__buttonwrap--favs').removeClass('geosearch__buttonwrap--hidden');
					v.renderFavs();
				}

				// remove Favs, when user logs out
				v.listenTo(DataHelper, 'auth:loggedOut', function () {
					v.$el.find('.geosearch__buttonwrap--favs').addClass('geosearch__buttonwrap--hidden');
					if (v.customerFavorites) {
						v.customerFavorites.remove();
					}
				});
				if (!DataHelper.isLoggedIn()) {
					v.$el.find('.geosearch__buttonwrap--favs').addClass('geosearch__buttonwrap--hidden');
					if (v.customerFavorites) {
						v.customerFavorites.remove();
					}
				}
			});
		},

		initSearch: function () {
			var v = this;

			// set input to deal with
			var input = v.$el.find('input');
			var geoInput = input[0];
			input.on('keydown', function () {
				input.removeClass('field__input--error');
			});

			// select first element trick
			(function pacSelectFirst (input) {
				// store the original event binding function
				var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

				function addEventListenerWrapper (type, listener) {
					// Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
					if (type === 'keydown') {
						var origListener = listener;
						listener = function (event) {
							var suggestionSelected = $('.pac-item-selected').length > 0;
							if (event.which === 13 && !suggestionSelected) {
								var simulatedDownarrow = $.Event('keydown', {keyCode: 40, which: 40});
								origListener.apply(input, [simulatedDownarrow]);
							}
							origListener.apply(input, [event]);
						};
					}
					// add the modified listener
					_addEventListener.apply(input, [type, listener]);
				}

				if (input.addEventListener) {
					input.addEventListener = addEventListenerWrapper;
				}
				else if (input.attachEvent) {
					input.attachEvent = addEventListenerWrapper;
				}
			})(geoInput);
			
			
			// options for autocomplete (restrict by country)
			var options = {
				componentRestrictions: {
					country: ['DE', 'AT', 'CH']
				}
			};

			var autocomplete = new google.maps.places.Autocomplete(geoInput, options);

			autocomplete.addListener('place_changed', function () {
				var place = autocomplete.getPlace();
				if (!place.geometry) {
					input.addClass('field__input--error');
					return;
				}

				v.trigger('changemap', place.geometry);
				input.val('').blur();
			});
		},

		locate: function () {
			var v = this;

			if (!v.bookingAreaView) {
				return;
			}

			v.$el.find('button.geosearch__locateme').removeClass('button--error');
			v.$el.addClass('loading--overlay');

			v.bookingAreaView.openMyPosition(function (err) {
				if (err) {
					v.$el.removeClass('loading--overlay');
					v.$el.find('button.geosearch__locateme').addClass('button--error');
					return;
				}

				v.$el.find('.geosearch__options').slideUp(150);
				v.$el.removeClass('loading--overlay');
				v.$el.find('input').val('');
			});
		},

		renderFavs: function () {
			var v = this;
			// remove old Favs
			if (v.customerFavorites) {
				v.customerFavorites.remove();
			}
			v.customerFavorites = new CustomerFavoritesBlock();
			v.customerFavorites.appendTo(v, '.geosearch__favlist');
			v.listenTo(v.customerFavorites, 'gotoLatLng', function (fav) {
				v.toggleFavs();
				v.trigger('gotoLatLng', fav);
			});
		},

		toggleFavs: function () {
			var v = this;
			v.$el.find('.geosearch__favlist').slideToggle(150);
		},

		toggleGeoSearch: function (newState) {
			var v = this;
			v.activeGeoSearch = _.isBoolean(newState) ? newState : !v.activeGeoSearch;
			v.$el.toggleClass(v.className + '--active', v.activeGeoSearch);
			v.timeRangeBlock.$el.toggleClass(v.className + '__picker' + '--active', v.activeGeoSearch);
			v.closeButton.$el.toggle(newState);
		},

		closeGeoSearch: function () {
			var v = this;
			v.toggleGeoSearch(false);
		},

		openGeoSearch: function () {
			var v = this;
			v.toggleGeoSearch(true);
		}
	});
});

