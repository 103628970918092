define('casirest2/helpers/subscription',['backbone', 'underscore'], function (Backbone, _) {
	'use strict';

	/**
	 * SubscriptionHelper
	 *
	 * Handles subscriptions of casiREST models
	 * and collections…
	 *
	 * @module helpers/subscriptions
	 * @class SubscriptionHelper
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	var SubscriptionHelper = {};
	_.extend(SubscriptionHelper, Backbone.Events);

	/**
	 * @param {Backbone.Model|Backbone.Collection} c
	 * @param {Backbone.View} [view]
	 * @param {Object} [options]
	 * @returns {SubscriptionHelper}
	 */
	SubscriptionHelper.live = function (c, view, options) {
		options = options || {};
		c = c || {};
		c.liveListeners = c.liveListeners || 0;
		c.liveListeners += 1;

		if(c.liveListeners === 1) {
			c.liveVisibilityCallback = function(initial) {
				if(document.hidden && c.liveIds) {
					clearInterval(c.liveIds);
					delete c.liveIds;
				}
				else if(!document.hidden && !c.liveIds) {
					if (!c.syncing && options.fetch !== false || !initial) {
						c.fetch();
					}
					c.liveIds = setInterval(function () {
						if (!c.syncing) {
							c.fetch();
						}
					}, c.liveInterval);
				}
			};

			document.addEventListener('visibilitychange', c.liveVisibilityCallback, false);
			c.liveVisibilityCallback(true);
		}

		if (view instanceof Backbone.View) {
			view.once('remove', function () {
				c.unlive();
			});
		}

		else if (view === Infinity) {
			// do nothing
		}

		else if (view > 0) {
			_.delay(function () {
				c.unlive();
			}, view);
		}

		else {
			throw new Error('Attribute `view` must be a View or a Numer!');
		}

		return c;
	};
	SubscriptionHelper.unlive = function(c) {
		c.liveListeners -= 1;
		if (c.liveListeners <= 0) {

			// end interval
			if(c.liveIds) {
				clearInterval(c.liveIds);
				delete c.liveIds;
			}

			// visibility callback
			if(c.liveVisibilityCallback) {
				document.removeEventListener('visibilitychange', c.liveVisibilityCallback);
				delete c.liveVisibilityCallback;
			}
		}
	};

	return SubscriptionHelper;
});

