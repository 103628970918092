define('views/blocks/filterTypeButton', [
	'underscore',
	'helpers/mapState',
	'../_view',
	''
], function (
	_,
	mapStateHelper,
	View
) {
	'use strict';

	return View.extend({
		tagName: 'button',
		className: 'panelfilter__button',

		events: {
			'click': 'click'
		},

		initialize: function () {
			_.bindAll(this, 'render', 'setState', 'click');
		},

		render: function () {
			var v = this;

			v.$el.text(v.model.get('name'));
			v.setState();

			v.listenTo(mapStateHelper, 'change:fbt', v.setState);
		},
		setState: function () {
			var v = this,
				state = mapStateHelper.filterByBookeeTypeIds();

			v.$el[_.indexOf(state, v.model.id) > -1 ? 'addClass' : 'removeClass']('active');

			v.trigger('change');
		},
		click: function () {
			var v = this,
				state = mapStateHelper.filterByBookeeTypeIds(),
				index = _.indexOf(state, v.model.id);

			if (index > -1) {
				state.splice(index, 1);
			} else {
				state.push(v.model.id);
			}

			mapStateHelper.filterByBookeeTypeIds(state);
			v.setState();
			mapStateHelper.apply();
		}
	});
});

