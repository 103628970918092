define('helpers/handlebars', ['handlebars', 'helpers/string'], function (Handlebars, stringHelper) {
	'use strict';

	Handlebars.registerHelper('breaklines', function (text) {
		// text = Handlebars.Utils.escapeExpression(text);
		// *facepalm*
		text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
		return new Handlebars.SafeString(text);
	});

	Handlebars.registerHelper('string', function (id) {
		return new Handlebars.SafeString(
			stringHelper.get(id)
		);
	});

	Handlebars.registerHelper('striptags', function (input, allowed) {
		var returnTxt,
			tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
			commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

		// exit now if text is undefined 
		if (typeof input === 'undefined') {
			return new Handlebars.SafeString('');
		}

		// normalize allowed tags
		allowed = (((allowed || '<strong><em><p>') + '')
			.toLowerCase()
			.match(/<[a-z][a-z0-9]*>/g) || [])
			.join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)

		// replace all the things
		returnTxt = input.replace(commentsAndPhpTags, '')
			.replace(tags, function ($0, $1) {
				return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
			});

		// add some p's
		returnTxt = '<p>' + returnTxt.replace(/(\r\n|\n|\r)/gm, '</p><p>') + '</p>';
		return new Handlebars.SafeString(returnTxt);
	});


});

