define('casirest2/models/remark',[
	'./_',
], function (Model) {
	'use strict';

	/**
	 * @class RemarkModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/remark',
		expand: []
	});
});

