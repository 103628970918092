define('views/panels/more', [
	'backbone',
	'underscore',
	'views/_panel',
	'views/blocks/morePanelEntry',
	'helpers/template',
	'helpers/string',
	'helpers/settings',
	'views/blocks/morePanelInternalEntry',
	'casirest2/helpers/data'
], function (
	Backbone,
	_,
	PanelView,
	MorePanelItemBlockView,
	templateHelper,
	stringHelper,
	settingsHelper,
	MorePanelInternalEntryBlockView,
	DataHelper
) {
	'use strict';

	/**
	 * MorePanelView
	 *
	 * @module views/panels/more
	 * @class MorePanelView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return PanelView.extend({
		panelName: ['more'],
		panelEvents: {},

		render: function () {
			var v = this;
				
			templateHelper('panels/more', function (html) {
				v.$el.html(html);
				v.$ul = v.$('.more__links--external');
				v.collection = settingsHelper.getEndPointSettings().getExtraMenuEntries(v);

				v.listenTo(v.collection, 'add', v.addEntry);
				v.collection.each(v.addEntry);
				
				v.addInternalEntries();
			});

			v.once('remove', function() {
				var p = Backbone.history.fragment.split('/');
				if (p.length >= 2 && p[1] === 'more') {
					window.myApp.subNavigate('', {trigger: true});
				}
			});

			v.listenTo(window.myApp, 'route', function() {
				if (location.hash.indexOf('/more') === -1) {
					v.remove();
				}
			});
		},
		addEntry: function(entry) {
			var v = this,
				view = new MorePanelItemBlockView({model: entry}).appendTo(v.$ul);

			v.listenTo(v.collection, 'remove', function(removed) {
				if(removed.id === entry.id) {
					view.remove();
				}
			});
		},
		
		addInternalEntries: function () {
			var v = this,
				entries = [
					{
						name: 'account',
						label: 'Mein Konto',
						route: 'account/settings',
						showListeners: [{
							object: DataHelper,
							events: 'auth:loggedIn auth:loggedOut'
						}],
						showWhen: function () {
							return DataHelper.isLoggedIn();
						}
					},
					{
						name: 'login',
						label: 'Login',
						route: 'login',
						showListeners: [{
							object: DataHelper,
							events: 'auth:loggedIn auth:loggedOut'
						}],
						showWhen: function () {
							return !DataHelper.isLoggedIn();
						}
					},
					{
						name: 'logout',
						label: 'Logout',
						route: 'logout',
						showListeners: [{
							object: DataHelper,
							events: 'auth:loggedIn auth:loggedOut'
						}],
						showWhen: function () {
							return DataHelper.isLoggedIn();
						}
					}
				],
			$ul = v.$el.find('.more__links--internal');
			
			_.each(entries, function (entry) {
				new MorePanelInternalEntryBlockView(entry).appendTo($ul);
			});
		}
	});
});

