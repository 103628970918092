define('views/_view', [
	'underscore',
	'backbone',
	'jquery'
], function (
	_,
	Backbone,
	$
) {
	'use strict';

	/**
	 * @module views/_view
	 * @class BaseView
	 * @augments Backbone.View
	 */
	var BaseView = Backbone.View.extend({

		/**
		 * Automatically bind functions of inherited views to correct 'this' context
		 *
		 * @param {Object} [options]
		 * @returns {BaseView}
		 */
		initialize: function (options) {
			var v = this;

			for (var i in v) {
				if (_.isFunction(v[i])) {
					_.bindAll(v, i);
				}
			}

			if (_.isFunction(v._initialize)) {
				v._initialize(options || {});
			}

			return v;
		},

		/**
		 * appendTo($parent)
		 * Appends the view to another Backbone View
		 *
		 * @param {jQuery|Backbone.View} parent Either an jQuery-Object or another View to append this View to
		 * @param {jQuery|String} [child] Either an Selector or the real jQuery Object to append the View to
		 * @param {Object} [options] Options for appendTo
		 * @param {String} [options.appendMethod] Method to run on jQuery Object to append it, defaults to `appendTo`
		 * @param {Boolean|Backbone.Collection} [options.order] Append in correct order of the given Collection, defaults to `parent.collection`
		 * @returns {Backbone.View}
		 */
		appendTo: function (parent, child, options) {
			options = options || {};

			var v = this,
				$appendTo,
				appendMethod,
				order,
				orderCollection;

			if (parent instanceof $) {
				$appendTo = parent;
			}
			else if (parent instanceof Backbone.View) {
				$appendTo = parent.$el;
			}
			else {
				throw new Error('parent has to be either an jQuery-Object or another View to append this View to');
			}

			if (child instanceof $) {
				$appendTo = child;
			}
			else if (_.isString(child)) {
				$appendTo = $appendTo.find(child);
			}
			else if (_.isObject(child)) {
				options = _.extend({}, child);
			}

			order = options.order || null;
			appendMethod = options.appendMethod || 'appendTo';
			
			if (order instanceof Backbone.Collection) {
				orderCollection = order;
			}
			else if (order === true && parent.collection instanceof Backbone.Collection) {
				orderCollection = parent.collection;
			}

			if (orderCollection) {
				v._appendAt($appendTo, orderCollection);
			} else {
				v.$el[appendMethod]($appendTo);
			}

			if(!v._rendered) {
				v.render();

				if (parent instanceof Backbone.View) {
					parent.once('remove', function () {
						v.remove();
					});
				}

				v._rendered = true;
			}

			return this;
		},

		/**
		 * prependTo($parent)
		 * Prepends the view to another Backbone View
		 *
		 * @param {jQuery|Backbone.View} parent Either an jQuery-Object or another View to append this View to
		 * @param {jQuery|String} [child] Either an Selector or the real jQuery Object to append the View to
		 * @param {String} [appendMethod] Method to run on jQuery Object to append it, defaults to `prependTo`
		 * @returns {Backbone.View}
		 */
		prependTo: function (parent, child, appendMethod) {
			appendMethod = appendMethod || 'prependTo';
			this.appendTo(parent, child, {appendMethod: appendMethod});
			return this;
		},


		/**
		 * Internally used to add Views to an jQuery Object in correct order
		 *
		 * @param {jQuery} $appendTo
		 * @param {Backbone.Collection} collection Collection
		 * @private
		 */
		_appendAt: function ($appendTo, collection) {
			var v = this,
				$children = $appendTo.children(),
				index = collection.indexOf(this.model),
				inDOM = false;

			v.$el.attr('data-id', v.model.id);

			if (!$children.length) {
				v.$el.appendTo($appendTo);
				inDOM = true;
			}

			$children.each(function () {
				var $child = $(this),
					childModel = collection.get($child.attr('data-id')),
					childIndex = collection.indexOf(childModel);

				if (!childModel || childIndex < 0) {
					return;
				}

				if ($child && index < childIndex && !inDOM) {
					$child.before(v.$el);
					inDOM = true;
				}
			});

			if (!inDOM) {
				v.$el.appendTo($appendTo);
				inDOM = true;
			}
		},

		listenToAndCall: function (object, event, callback) {
			this.listenTo(object, event, function () {
				callback(object);
			});

			callback(object);
			return this;
		},

		setupOneWayBinding: function ($el, object, AttributeOrView) {
			var v = this,
				setter,
				i, r, val, selector;
			
			if (_.isString($el)) {
				selector = $el;
				$el = v.$el.find($el);
			}
			if (!($el instanceof $)) {
				throw new Error('Parameter `$el` has to be a string or an jQuery object…');
			}
			if (!$el.length) {
				// one last chance:
				if (selector && (!!navigator.userAgent.match(/Trident\/7\./) || window.navigator.userAgent.indexOf('Edge') > -1) ) {
					$el = v.$el.find(':input' + selector);
					if (!($el instanceof $) && !$el.length) {
						throw new Error('Everythings broken, sorry…');
					}
				} else {
					throw new Error('Parameter `$el` doesn\' match any elements…');
				}
			}

			setter = _.indexOf(['select', 'input'], $el.prop('tagName').toLowerCase()) > -1 ? 'val' : 'text';
			if (_.isString(object)) {
				AttributeOrView = object;
				object = null;
			}

			if (!object && ((v.model && !v.collection) || _.isString(AttributeOrView))) {
				object = v.model;
			}
			else if (!object && ((!v.model && v.collection) || AttributeOrView instanceof BaseView)) {
				object = v.collection;
			}
			else if (!object) {
				throw new Error('Parameter `object` isn\'t set, but I\'m not sure which object I should use then…');
			}

			if (object instanceof Backbone.Model) {
				if (!_.isString(AttributeOrView)) {
					throw new Error('Parameter `AttributeOrView` has to be a model in this case…');
				}

				i = AttributeOrView.split('.');
				r = i.shift();

				v.listenTo(object, 'change:' + r, function () {
					val = object.get(r);
					_.each(i, function (a) {
						if (val && val[a]) {
							val = val[a];
						} else {
							val = null;
						}
					});
					if (val) {
						$el[setter](val);
					}
				});

				val = object.get(r);
				_.each(i, function (a) {
					if (val && val[a]) {
						val = val[a];
					} else {
						val = null;
					}
				});
				if (val) {
					$el[setter](val);
				}
			}
			else if (object instanceof Backbone.Collection) {
				val = new AttributeOrView({collection: object});
				if (!(val instanceof BaseView)) {
					throw new Error('Parameter `AttributeOrView` has to be a View in this case…');
				}

				val.appendTo(v, $el);
			}
		},

		/**
		 * Renders all child views and create the required
		 * listenTo to add/remove child views if required…
		 *
		 * @param {BaseView} ChildView
		 * @param {Object} [options]
		 * @param {BaseCollection} [options.collection] Collection, otherwise this.collection is used
		 * @param {Object} [options.childOptions] Options to pass in new ChildView()
		 * @param {String} [options.modelAttr="model"] Attribute where the childs model is passed in. Defaults to `model`
		 * @param {jQuery|String} [options.where] Where append all the views?
		 * @returns {BaseView}
		 */
		renderChildren: function (ChildView, options) {
			var v = this,
				collection = options.collection || v.collection;

			function add (model) {
				var opt = {}, view;
				opt[options.modelAttr || 'model'] = model;

				view = new ChildView(_.extend({}, options.childOptions, opt));

				view.listenTo(collection, 'remove', function (removed) {
					if (removed.id === model.id) {
						view.remove();
					}
				});
				view.listenToAndCall(collection, 'sort', function() {
					view.appendTo(v, options.where || null, {order: collection, model: model});
				});
			}

			v.listenTo(collection, 'add', add);
			collection.each(add);
			return this;
		},


		/**
		 * remove()
		 * Entfernt die View
		 */
		remove: function () {
			this.trigger('remove');
			if (this.$el) {
				this.$el.remove();
			}
			this.stopListening();
			this.trigger('removed');
		}
	});

	return BaseView;
});

