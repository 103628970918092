define('templates/blocks/currentBooking',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["templates/blocks/currentBooking"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<span class=\"currentBookings__headlinelabel\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.time : depth0)) != null ? stack1.label : stack1), depth0))
    + "</span> <span class=\"currentBookings__headlinetime\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.time : depth0)) != null ? stack1.time : stack1), depth0))
    + "&nbsp;Uhr</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<span class=\"currentBookings__headlinelabel\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.time : depth0)) != null ? stack1.label : stack1), depth0))
    + "</span> <span class=\"currentBookings__headlinedate\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.time : depth0)) != null ? stack1.date : stack1), depth0))
    + "</span> <span class=\"currentBookings__headlinetime\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.time : depth0)) != null ? stack1.time : stack1), depth0))
    + "&nbsp;Uhr</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				<span class=\"currentBookings__plate\">("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.licenseNumber : stack1), depth0))
    + ")</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.city : stack1)) != null ? stack1.name : stack1), depth0))
    + ")\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<div class=\"currentBookings__remark\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookingRemark : stack1), depth0))
    + "\">\n			"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookingRemark : stack1), depth0))
    + "\n		</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<button class=\"currentBookings__button currentBookings__button--details button button--account\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"currentBooking.action.showDetails",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "	<button class=\"currentBookings__button currentBookings__button--"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + " button button--account\">"
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<h3 class=\"currentBookings__headline currentBookings__headline--doorCount"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.doorCount : stack1), depth0))
    + "\">"
    + ((stack1 = (helpers.string || (depth0 && depth0.string) || alias4).call(alias3,"currentBooking.label.running",{"name":"string","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias2(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"label","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.time : depth0)) != null ? stack1.shouldShowDate : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h3>\n\n<div class=\"currentBookings__info\">\n		<div class=\"currentBookings__vehicle currentBooking__vehicle--doorCount"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.doorCount : stack1), depth0))
    + "\">\n			"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.bookeeProduct : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.licenseNumber : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n\n		<div class=\"currentBookings__station\">\n			"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.city : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookingRemark : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"currentBookings__buttons\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bookee : stack1)) != null ? stack1.place : stack1)) != null ? stack1.isFixed : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.actions : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});

return this["JST"];

});
