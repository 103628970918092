define('views/listItemDetails', [
	'underscore',
	'jquery',
	'views/_view',
	'views/listItemBookee',
	'views/panels/unavailability',
	'helpers/mapState',
	'helpers/string',
	'helpers/settings'
], function (
	_,
	$,
	BaseView,
	ListItemBookeeView,
	UnavailabilityPanelView,
	MapStateHelper,
	StringHelper,
	SettingsHelper
) {
	'use strict';

	/**
	 * ListItemDetailsView
	 *
	 * @module views/listItemDetails
	 * @class ListItemDetailsView
	 * @augments BaseView
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return BaseView.extend({
		className: 'listitem__listbookees listbookees',
		events: {
			'click .listbookees__occupancies': 'openOccupancies',
			'click .listbookees__action--mobile': 'openMobileMaps',
			'click .listbookees__action--desktop': 'openDesktopMaps'
		},

		_initialize: function (options) {
			var v = this;
			v.proposals = options.proposals;
			v.timeHelper = options.timeHelper;
			v.bookeeCollection = v.model.getBookees(v);
			
			v.bookeeCollection.comparator = function (bookee) {
				return [bookee.get('bookeeType').name, bookee.get('bookeeProduct').name, bookee.get('licenseNumber')];
			};
			v.bookeeCollection.sort();
		},

		/**
		 * @returns {MapView}
		 */
		render: function () {
			var v = this,
				$ul = $('<ul class="listbookees__list" />').appendTo(v.$el),
				$buttons = $('<div class="listbookees__buttons" />').appendTo(v.$el);

			v.$placePanel = $('<button />')
				.attr('class', 'button listitem__button listbookees__action listitem__button--strong listbookees__place')
				.html(StringHelper.get('list.place.panel'))
				.appendTo($buttons);
			v.$occupancies = $('<button />')
				.attr('class', 'button listitem__button listbookees__action listitem__button--strong listbookees__occupancies')
				.html(StringHelper.get('list.place.occupancies'))
				.appendTo($buttons);

			$('<button />')
				.attr('class', 'button listitem__button listitem__button--strong listbookees__action listbookees__action--mobile listbookees__action--onmap')
				.html(StringHelper.get('list.place.maps.mobile'))
				.appendTo($buttons);

			$('<button />')
				.attr('class', 'button listitem__button listitem__button--strong listbookees__action listbookees__action--desktop listbookees__action--onmap')
				.html(StringHelper.get('list.place.maps.desktop'))
				.appendTo($buttons);

			v.renderChildren(ListItemBookeeView, {
				collection: v.bookeeCollection, // needs a comparator for i15665
				childOptions: {
					proposals: v.proposals,
					place: v.model,
					timeHelper: v.timeHelper
				},
				where: $ul
			});


			v.$placePanel.click(function () {
				if (!v.model.get('isFixed')) {
					return;
				}

				myApp.view.bookingAreaView.openPlace(v.model);
			});

			v.listenToAndCall(v.model, 'change:isFixed', v.updateVisibility);

			/** @see {@link https://cantaloupe.cantamen.de/admin/issues/15097|i15097} */
			v.listenToAndCall(
				SettingsHelper.getEndPointSettings(),
				'change:bookingInterfaceType change:businessModels',
				v.updateVisibility
			);

			return v;
		},
		updateVisibility: function () {
			var v = this,
				bm = SettingsHelper.getEndPointSettings().get('businessModels') || [],
				fix = _.find(bm, function (m) {
					return m.locality === 'FIX';
				});

			v.$occupancies.toggleClass('listbookees__occupancies--hidden', !fix || !v.model.get('isFixed'));
			v.$placePanel.toggleClass('listbookees__place--hidden', !v.model.get('isFixed'));
		},
		openOccupancies: function () {
			var v = this,
				app = window.myApp.view,
				panel;

			if (!v.model.get('isFixed')) {
				return;
			}

			if (app.getOpenedPanel(0) instanceof UnavailabilityPanelView) {
				return;
			}

			panel = new UnavailabilityPanelView({
				model: v.model,
				timeHelper: v.timeHelper
			});
			v.listenToOnce(panel, 'remove', function () {
				if (location.hash.indexOf('/place/' + v.model.id) >= 0) {
					window.myApp.subNavigate('', {trigger: true});
				}
			});

			v.$el.find('.panel__button--showUnavailability').addClass('panel__button--active');
			app.renderView(panel, {isRoot: true});

			window.myApp.subNavigate('place/' + this.model.id + '/availability');
		},
		openMobileMaps: function () {
			var v = this,
				url;

			if (/iPad|iPhone|iPod|MacIntel/.test(navigator.platform)) {
				url = 'http://maps.apple.com/?t=m&daddr=' +
					v.model.get('geoPosition').latitude + ',' + v.model.get('geoPosition').longitude + '&dirflag=w';
			} else {
				url = 'http://maps.google.com/maps?z=12&t=m&q=loc:' +
					v.model.get('geoPosition').latitude + '+' + v.model.get('geoPosition').longitude;
			}

			window.top.location.href = url;
		},
		openDesktopMaps: function () {
			var v = this;

			MapStateHelper.isListMode(false);
			MapStateHelper.apply();

			myApp.view.bookingAreaView.openPlace(v.model);
		}
	});
});

