define('views/modals/resetPassword', [
	'underscore',
	'jquery',
	'views/_modal',
	'helpers/template',
	'casirest2/collections/provider',
	'casirest2/collections/customer',
	'formparams'
], function (
	_,
	$,
	ModalBaseView,
	templateHelper,
	ProviderCollection,
	CustomerCollection
) {
	'use strict';

	/**
	 * ResetPasswordModalView
	 *
	 * @module views/modals/resetPassword
	 * @class ResetPasswordModalView
	 * @augments ModalBaseView
	 */
	var ResetPasswordModalView = ModalBaseView.extend({

		modalName: 'reset',
		modalEvents: {
			'submit .reset__form': 'submit'
		},

		/**
		 * @param {object} [options]
		 * @param {ProviderCollection} [options.collection] ProviderCollection this view should use
		 */
		__initialize: function (options) {
			var v = this;
			v.collection = options.collection || new ProviderCollection().addFilter('action', 'CUSTOMER_LOGIN');
		},

		/**
		 * Render method
		 * @returns {ResetPasswordModalView}
		 */
		render: function () {
			var v = this;

			templateHelper('modals/resetPassword', function (html) {
				v.$el.html(html);
				v.$providerSelector = v.$providerSelector || v.$el.find('[name="provId"]');

				v.listenTo(v.collection, 'add', v.addProvider);
				v.collection.each(v.addProvider);

				_.defer(function () {
					v.$el.find('input')[0].focus();
				});
			});

			// hide provider selection if not required
			v.listenToOnce(v.collection, 'sync', function () {
				if (v.collection.length === 1) {
					v.$providerSelector.parent().addClass('login__field--hidden');
				}
			});

			// redirect to map on remove
			v.on('remove', function () {
				window.myApp.subNavigate('', {trigger: true});
			});

			// sync collection
			if (!v.collection.syncing) {
				v.collection.fetch();
			}

			return v;
		},

		/**
		 * Adds a provider to the select options…
		 * @param {ProviderModel} provider
		 */
		addProvider: function (provider) {
			var v = this;

			v.$providerSelector.append('<option value="'+provider.id+'">'+provider.get('name')+'</option>');

			if (v.collection.length === 1) {
				v.$providerSelector.val(provider.id);
			}
		},

		submit: function (e) {
			var v = this;
			e.preventDefault();

			v.$el.addClass('loading');

			// prepare Values
			var values = $(e.target).formParams(false);

			// only one orga to choose from: no select needed
			if (v.collection.length === 1) {
				values.provId = v.collection.first().id;
			} else {
				values.provId = v.$providerSelector.val(); // stupid formParams fix
			}

			// clear empty values
			values = _.pick(values, function (value) {
				return !_.isEmpty(value);
			});

			if (!values.provId && (!values.email || !values.name || !values.number || !values.cardNumber)) {
				return;
			}

			CustomerCollection.requestPasswordReset(values, function(error) {
				if(!error) {
					v.remove();
					window.myApp.subNavigate('', {trigger: true});
					return;
				}

				var $frame = v.$el.find('.modal__frame').addClass('modal__frame--error');
				v.$el.removeClass('loading');

				_.delay(function () {
					$frame.removeClass('modal__frame--error');
				}, 1000);
			});
		}

	});

	return ResetPasswordModalView;
});

