define('casirest2/models/unavailability',['./_'], function (Model) {
	'use strict';

	/**
	 * @class UnavailabilityModel
	 * @author Sebastian <sebastian@whitespace.gmbh>
	 */
	return Model.extend({
		urlRoot: '/unavailability',

		/**
		 * Returns a UnavailabilityTimeRangeCollection
		 * containing all Time Ranges of this Bookee
		 * @param {BaseView} [view]
		 * @returns {UnavailabilityTimeRangeCollection}
		 */
		getBookees: function (view) {
			return this._buildSubCollection({
				collection: UnavailabilityTimeRangeCollection,
				attribute: 'unavailabilityTime',
				view: view
			});
		}
	});
});

