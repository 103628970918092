define('casirest2/models/priceItem',['./_',], function (Model) {
	'use strict';

	/**
	 * @class PriceItemModel
	 */
	return Model.extend({
		urlRoot: '/price',
		expand: [],

		/**
		 * Formats the price in a human readable string
		 *
		 * @param {string} [priceName] defaults to priceIncl
		 * @returns {string}
		 */
		format: function (priceName) {
			priceName = priceName || 'priceIncl';

			// browser supports toLocaleString with options
			if (typeof Intl === 'object' && typeof Intl.NumberFormat === 'function') {
				return parseFloat((this.get(priceName).amount / 100).toFixed(2)).toLocaleString(window.navigator.language || 'de-DE', {
					style: 'currency',
					currency: this.get(priceName).code || 'EUR',
					minimumFractionDigits: 2
				});
			}

			// no browser support for toLocaleString
			else {
				var price = parseFloat((this.get(priceName).amount / 100)).toFixed(2);
				if (this && this.get(priceName).code === 'EUR') {
					return price.replace('.', ',') + ' €';
				}
				return price + ' ' + (this.get(priceName).code || 'EUR');
			}
		},

		add: function (amount) {
			this.get('priceIncl').amount = this.get('priceIncl').amount + amount;
		},

		subtract: function (amount) {
			this.get('priceIncl').amount = this.get('priceIncl').amount - amount;
		}
	});
});

