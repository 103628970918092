define('views/modals/computePrice', [
	'underscore',
	'jquery',
	'views/_modal',
	'helpers/template',
	'casirest2/models/bookee',
	'casirest2/models/price'
], function (
	_,
	$,
	ModalBaseView,
	templateHelper,
	BookeeModel,
	PriceModel
) {
	'use strict';

	/**
	 * ComputePriceView
	 *
	 * @module views/modals/computePrice
	 * @class ComputePriceView
	 * @augments BaseView
	 * @author Philip <philip@whitespace.gmbh>
	 */
	var ComputePriceModal = ModalBaseView.extend({
		className: 'computeprice modal',
		
		modalEvents: {
			'click .close': 'remove',
			'click .computeprice__preset': 'fillFromPreset'
		},
		
		_initialize: function (options) {
			var v = this;
			v.bookee = options.bookee || new BookeeModel();
		},

		/**
		 * @returns {ComputePriceView}
		 */
		render: function () {
			var v = this;
			v.presets = [
				{ hours: 0, minutes: 30, title: '½'},
				{ hours: 1, minutes: 0, title: '1'},
				{ hours: 2, minutes: 30, title: '2½'},
				{ hours: 4, minutes: 0, title: '4'},
				{ hours: 8, minutes: 0, title: '8'}
			];
			
			
			v.requestCount = 0;
			
			// show Duration?
			v.showDuration = v.bookee.get('locality') === 'FLOAT';
			
			templateHelper('modals/computeprice', {
				showDuration: v.showDuration,
				presets: v.presets
			}, function (html) {
				v.$el.removeClass('loading--overlay');
				v.$el.html(html);
				
				v.$hourInput = v.$el.find('input[name=duration--hours]');
				v.$minuteInput = v.$el.find('input[name=duration--minutes]');
				v.$distanceInput = v.$el.find('input[name=distance]');
				
				v.$durationValue = v.$el.find('.computeprice__value--duration');
				v.$distanceValue = v.$el.find('.computeprice__value--distance');
				v.$values = v.$el.find('.computeprice__value');
				
				v.$el.find('input').on('keydown change', _.debounce(v.getComputedPrice, 300));
			});
		},
		
		fillFromPreset: function (e) {
			var v = this,
				$preset = v.$el.find(e.target);
			
			if ($preset.attr('data-id') && v.presets[$preset.attr('data-id')]) {
				v.$hourInput.val(v.presets[$preset.attr('data-id')].hours).trigger('change');
				v.$minuteInput.val(v.presets[$preset.attr('data-id')].minutes).trigger('change');
			}
		},
		
		getComputedPrice: function() {
			var v = this,
				options = {},
				computedPrice,
				durationInput,
				distanceInput;
			
			if (v.showDuration) {
				durationInput = parseInt(v.$hourInput.val()) * 60 + parseInt(v.$minuteInput.val());
				options.duration = isNaN(durationInput) ? 0 : durationInput * 60;
			}
			
			distanceInput = parseInt(v.$distanceInput.val());
			options.plannedDistance = isNaN(distanceInput) ? 0 : distanceInput * 1000;
			
			_.each(options, function (v, k) {
				if (v === 0) {
					delete options[k];
				}
			});
			
			if (options.length === 0) {
				return;
			}
			
			v.$values.addClass('loading loading--value');
			v.requestCount = v.requestCount + 1;
			
			computedPrice = v.model.computePrice(options, {
				success: function (data) {
					var priceModel = new PriceModel(data);
					
					if (distanceInput > 0) {
						v.$distanceValue.text(priceModel.getPriceItem('DRIVING_DISTANCE').format());
					}
					if (durationInput > 0) {
						v.$durationValue.text(priceModel.getPriceItem('DRIVING_TIME').format());
					}
				},
				error: function () {
					if (distanceInput > 0) {
						v.$distanceValue.text('–');
					}
					if (durationInput > 0) {
						v.$durationValue.text('–');
					}
				},
				complete: function () {
					v.requestCount = v.requestCount - 1;
					if (v.requestCount === 0) {
						v.$values.removeClass('loading loading--value');
					}
				}
			});
		}
	});
	
	return ComputePriceModal;
});

