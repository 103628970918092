define('views/panels/filter', [
	'underscore',
	'views/_panel',
	'views/blocks/filterBookeeType',
	'helpers/template',
	'helpers/mapState'
], function (
	_,
	PanelView,
	FilterBookeeTypeBlockView,
	templateHelper,
	mapStateHelper
) {
	'use strict';

	return PanelView.extend({
		panelName: ['filter'],
		panelEvents: {
			'click button.resetFilter': 'resetFilter',
			'click button.applyFilter': 'remove'
		},

		render: function () {
			var v = this,
				filterBookeeTypeView = new FilterBookeeTypeBlockView();
				
			v.listenTo(filterBookeeTypeView, 'loading', function(isLoading) {
				v.loading(isLoading);
			});

			templateHelper('panels/filter', function (html) {
				v.$el.html(html);

				filterBookeeTypeView.appendTo(v, '.panelfilter__types');
			});
			
			v.listenTo(mapStateHelper, 'change:fbt', function () {
				// auto-activate filter
				mapStateHelper.isFiltered(
					!!mapStateHelper.filterByBookeeTypeIds().length
				);
				mapStateHelper.apply();
			});
		},
		
		resetFilter: function () {
			mapStateHelper.filterByBookeeTypeIds([]);
			mapStateHelper.apply();
		}
	});
});

