define('views/blocks/customerFavorites', [
	'underscore',
	'views/_view',
	'helpers/template',
	'casirest2/helpers/data',
	'casirest2/collections/customerFavorite',
	'casirest2/models/customerFavorite',
	'views/blocks/customerFavoritePlace',
	'views/modals/prompt',
	'helpers/mapState'
], function (
	_,
	View,
	templateHelper,
	DataHelper,
	CustomerFavoriteCollection,
	CustomerFavoriteModel,
	CustomerFavoritePlaceBlock,
	PromptModalView,
	MapStateHelper
) {
	'use strict';

	return View.extend({
		className: 'favs',

		events: {
			'click button.addFav': 'newFav',
			'click button.favs__saveFav': 'saveNewFav'
		},

		render: function () {
			var v = this;

			if (!DataHelper.isLoggedIn()) {
				return;
			}

			v.collection = new CustomerFavoriteCollection();
			v.collection.on('add', v.renderFav);
			v.collection.fetch();

			templateHelper('blocks/customerFavorites', function (html) {
				v.$el.html(html);
				
				// @TODO: hide "add Fav"-Button in ListMode. We need an other solution there
				// @SEE: https://cantaloupe.cantamen.de/admin/issues/15391
				v.listenToAndCall(MapStateHelper, 'change:lim', function () {
					v.$el.find('.favs__addbutton').toggle(!MapStateHelper.isListMode());
				});
			});
		},

		renderFav: function (fav) {
			var v = this;

			var customerFav = new CustomerFavoritePlaceBlock({
				model: fav
			});

			customerFav.appendTo(v, '.favs__list');

			v.listenTo(customerFav, 'setFavorite', function (geoRange) {
				v.trigger('gotoLatLng', {
					lat: geoRange.position.latitude,
					lng: geoRange.position.longitude
				});
			});
		},

		newFav: function () {
			var v = this;
			v.$el.addClass('favs--adding');
			v.$el.find('input[name=name]').val('');
			v.$el.find('.favs__cancelFav').one('click', function () {
				v.$el.removeClass('favs--adding');
			});
		},

		saveNewFav: function () {
			var v = this;

			var newFav = new CustomerFavoriteModel({
				'default': false,
				'geoRange': {
					'position': {
						'latitude': MapStateHelper.getCenter().lat(),
						'longitude': MapStateHelper.getCenter().lng()
					},
					'range': Math.pow(2, (15 - MapStateHelper.getZoomLevel())) * 1640.42
				},
				'name': v.$el.find('input[name=name]').val() || 'Favorit ' + (v.collection.length + 1)
			});

			v.$el.removeClass('favs--adding');

			newFav.save(null, {
				success: function () {
					v.collection.fetch();
				},
				error: function (model, response) {
					window.myApp.view.renderView(
						new PromptModalView({
							title: 'Speichern fehlgeschlagen',
							message: (
								response && response.responseJSON && response.responseJSON.errorMessage ?
									response.responseJSON.errorMessage :
									'Es ist ein unbekannter Fehler aufgetreten, bitte versuche es später erneut…'
							),
							type: 'error'
						})
					);
				}
			});
		}
	});
});

